
import { Component, Mixins } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'
import * as lineupStore from '@/store/lineup'
import divisionClient from '@/clients/divisionsClient'
import { GameLineupWithRotation } from '@/models/Lineup'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import Loading from '@/elements/Loading.vue'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import { RecalculateLineupMixin } from '@/common/RecalculateLineupMixin'
import { CurrentGameLineupWithRotationMixin } from '@/common/CurrentGameLineupWithRotationMixin'
import { TypeProgramGameFormatInfo } from '@/GeneratedTypes/ListInfo/TypeProgramGameFormatInfo'
import { gameFormatToPlayersPerSeg } from '@/common/PlayersPerSegmentConversion'
import { cloneDeep } from 'lodash'
import { inactivePlayers } from '@/services/lineupService'

@Component({
  components: { Breadcrumbs, Loading },
})
export default class ChangeGameFormat extends Mixins(
  RecalculateLineupMixin,
  CurrentGameLineupWithRotationMixin
) {
  @Mutation(lineupStore.mutationNames.upsertLineupWithRotation, { namespace: lineupStore.namespace })
  upsertLineup!: ({ lineup }: { lineup: GameLineupWithRotation }) => void

  gameFormats: TypeProgramGameFormatInfo[] = [] as TypeProgramGameFormatInfo[]
  loading = false

  private breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.GAMES_GAMEDAY, isActive: true },
    { crumb: CrumbsEnum.GAME, isActive: true, params: { gameId: this.$route.params.gameId } },
    { crumb: CrumbsEnum.CHANGE_GAME_FORMAT, isActive: false },
  ])

  async mounted() {
    await this.retrieveFormats()
  }

  async retrieveFormats() {
    this.loading = true
    this.gameFormats = await divisionClient.gameFormats(this.currentTeam.typeProgramID)
    this.loading = false
  }

  get formats() {
    const playerCount = this.currentTeam.players?.length ?? 0
    const inactiveCount = inactivePlayers(this.currentGameLineupWithRotation)?.length ?? 0
    const availablePlayers = playerCount - inactiveCount
    return this.gameFormats
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .filter((f) => {
        return availablePlayers >= gameFormatToPlayersPerSeg(f.typeGameFormatID)
      })
  }

  isSelected(typeGameFormatID: string) {
    const playersPerSegment = gameFormatToPlayersPerSeg(typeGameFormatID)
    return this.currentGameLineupWithRotation.playersPerSegment == playersPerSegment
  }

  save(typeGameFormatID: string) {
    const lineup = cloneDeep(this.currentGameLineupWithRotation)
    lineup.playersPerSegment = gameFormatToPlayersPerSeg(typeGameFormatID)
    this.upsertLineup({ lineup })
    this.recalculateLineup(this.currentGameLineupWithRotation)
    this.$router.back()
  }
}
