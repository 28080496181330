
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PlayerContactInfo } from '@/GeneratedTypes/ListInfo/PlayerContactInfo'
import { TeamCoachInfo } from '@/GeneratedTypes/ListInfo/TeamCoachInfo'
import { getEmptyPlayerContactInfo } from '@/modelHelpers/PlayerContactInfo'
import LabelInput from '@/elements/InputLabel.vue'
import { formatPhone } from '@/formatters/phoneFmt'
import { maxStringLength } from '@/formatters/maxStringLength'

@Component({
  components: {
    LabelInput,
  },
})
export default class Contact extends Vue {
  @Prop({ type: Object, default: getEmptyPlayerContactInfo, required: false })
  private readonly contact!: PlayerContactInfo | TeamCoachInfo

  private fmtPhone = formatPhone
  private maxStringLen = maxStringLength
}
