
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Loading from '@/elements/Loading.vue'
import LinkingListItem from '@/components/LinkingListItem.vue'
import * as teamStore from '@/store/team'
import * as resourceStore from '@/store/resources'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { Resource } from '@/models/Program/Resource'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    LinkingListItem,
    Loading,
  },
})
export default class CouchResourceList extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly title!: string

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  private readonly currentTeam!: DivisionTeamInfoExt

  @Getter(resourceStore.getterNames.resources, { namespace: resourceStore.namespace })
  private readonly resources!: Resource[]

  private get list() {
    // List of all resources for one category
    if (!this.resources) return [] as Resource[]
    const clone = cloneDeep(this.resources)
    return clone.filter((a) => a.category === this.$route.params.category)
  }
}
