
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import * as gameStore from '@/store/game'
import PlayerRankable from '@/components/PlayerRankable.vue'
import Star from '@/components/Star.vue'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import { DivisionGamePlayerInfo } from '@/GeneratedTypes/ListInfo/DivisionGamePlayerInfo'
import { GameLineupPositionPlayer } from '@/models/Lineup'
import { IsCheerMixin } from '@/common/IsCheerMixin'
import { CheerSquadRecognition } from '@/GeneratedTypes/CheerSquadRecognition'
import { getEmptyTeamPlayerInfo } from '@/modelHelpers/TeamPlayerInfo'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

interface DisplayablePlayer {
  id: number
  recognitionId: string
  comment: string | null
}

@Component({
  components: { PlayerRankable, Star, ConfirmationModal },
})
export default class GameDayPlayer extends Mixins(IsCheerMixin) {
  @Prop({ type: Object, default: getEmptyTeamPlayerInfo, required: false })
  private readonly player!: TeamPlayerInfo | GameLineupPositionPlayer

  @Prop({ type: Boolean, default: false, required: false })
  private readonly showComment!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  private readonly showRecognitionName!: boolean

  @Prop({ type: Boolean, default: true, required: false })
  private readonly showRosterRanking!: boolean

  @Prop({ type: Boolean, default: true, required: false })
  private readonly showPositionEdit!: boolean

  @Prop({ type: Boolean, default: true, required: false })
  private readonly showRemove!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  private readonly enableRecognition!: boolean

  @Getter(gameStore.getterNames.currentGameRecognitions, { namespace: gameStore.namespace })
  private gameRecognitions!: DivisionGamePlayerInfo[]

  @Getter(gameStore.getterNames.currentCheerGameRecognitions, { namespace: gameStore.namespace })
  private cheerGameRecognitions!: CheerSquadRecognition[]

  private confirmRemoval = false

  get playerRecognition(): DisplayablePlayer | null {
    if (!this.enableRecognition) return null
    if (this.isCheer) {
      return this.cheerPlayer
    } else {
      return this.sportPlayer
    }
  }

  get showRecognitionDetails() {
    return this.enableRecognition && (this.showRecognitionName || this.showComment)
  }

  get show(): boolean {
    if (!this.enableRecognition) return true
    if (this.enableRecognition && this.playerRecognition) return true
    return false
  }

  get sportPlayer(): DisplayablePlayer | null {
    // Sport and cheer players are different types so convert
    // a sport player to a universal type that can contain either (aka a DisplayablePlayer)
    if (!this.enableRecognition) return null
    const player = {
      id: this.player.individualID,
      recognitionId: '',
      comment: '',
    } as DisplayablePlayer
    const p = this.gameRecognitions.find((p) => p.playerIndividualID === this.player.individualID)
    if (p) {
      player.recognitionId = p.typePlayerRecognitionID ?? ''
      player.comment = p.recognitionNote ?? ''
    }
    return player
  }

  get cheerPlayer(): DisplayablePlayer | null {
    // Sport and cheer players are different types so convert
    // a cheerleader to a universal that can contain either (aka a DisplayablePlayer)
    if (!this.enableRecognition) return null
    const cheer = {
      id: this.player.individualID,
      recognitionId: '',
      comment: '',
    } as DisplayablePlayer
    const c = this.cheerGameRecognitions.find((p) => p.playerIndividualID === this.player.individualID)
    if (c) {
      cheer.recognitionId = c?.typePlayerRecognitionID ?? ''
      cheer.comment = c?.note ?? ''
    }
    return cheer
  }

  get gameId(): string {
    return this.$route.params.gameId
  }

  get to(): string {
    if (!this.playerRecognition) return ''
    return `${this.$route.matched[0].path}/game/${this.gameId}/recognition/${this.playerRecognition.id}`
  }

  private removePlayer(response: boolean) {
    if (response) {
      this.$emit('remove', this.player)
    }

    this.confirmRemoval = false
  }
}
