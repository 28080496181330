
import { Component, Mixins, Watch, Prop } from 'vue-property-decorator'
import { TextLikeEvents } from '@/elements/TextLikeWrapper/TextLikeEvents'
import { ValidationProvider } from 'vee-validate'
import uuid from 'uuid'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class InputlessStringValidator extends Mixins(TextLikeEvents) {
  @Prop({ type: String, default: '', required: false })
  private readonly value!: string

  @Prop({ type: String, default: () => uuid.v4(), required: false })
  public readonly id!: string

  @Prop({ type: String, default: '', required: false })
  public readonly name!: string

  @Prop({ type: String, default: '', required: false })
  private readonly VeeValidateRules!: string

  @Prop({ type: String, default: 'aggressive', required: false })
  private readonly veeValidateMode!: string

  internalValue: string = this.value
  private setInternalValue() {
    this.internalValue = this.value
  }

  @Watch('value')
  private onValueChanged() {
    this.setInternalValue()
  }

  mounted() {
    this.setInternalValue()
  }
}
