import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { getEmptyDivisionTeamInfo } from '@/modelHelpers/DivisionTeamInfo'

export function getEmptyDivisionTeamInfoExt(): DivisionTeamInfoExt {
  const ext = {
    partnerName: '',
    divisionContact: null,
    appointments: [],
    games: [],
    specialEvents: [],
    divisionGenderGradeRange: '',
  }

  const divisionTeamInfo = getEmptyDivisionTeamInfo()
  return { ...ext, ...divisionTeamInfo }
}
