
import { Component, Vue, Prop } from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'

export interface SelectStylableConfig {
  bgColor: string
  color: string
  placeholderColor: string
  highlightBgColor: string
  highlightColor: string
}

const defaultConfig: SelectStylableConfig = {
  bgColor: 'white',
  color: 'black',
  placeholderColor: '#adadad',
  highlightBgColor: 'lightgrey',
  highlightColor: 'black',
}
@Component({
  components: { Multiselect },
})
export default class SelectStylable extends Vue {
  @Prop({ required: false, type: Object, default: () => defaultConfig })
  private readonly config!: SelectStylableConfig
  private value: any = ''

  private options = [
    { name: 'Vue.js', language: 'JavaScript' },
    { name: 'Rails', language: 'Ruby' },
    { name: 'Sinatra', language: 'Ruby' },
    { name: 'Laravel', language: 'PHP' },
    { name: 'Phoenix', language: 'Elixir' },
  ]

  private get cssVars() {
    return {
      '--bg-color': this.config.bgColor,
      '--color': this.config.color,
      '--highlight-bg-color': this.config.highlightBgColor,
      '--highlight-color': this.config.highlightColor,
      '--placeholder-color': this.config.placeholderColor,
    }
  }
}
