import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import contentManagementClient from '@/clients/contentManagementClient'
import { ActionsImpl } from 'direct-vuex'
import { Resource } from '@/models/Program/Resource'

interface ResourceState {
  resources: Resource[] | null
  currentResourceType: string | null
}

const initState = (): ResourceState => {
  return {
    resources: [] as Resource[],
    currentResourceType: null,
  }
}

const resourceState = initState()

export enum getterNames {
  resources = 'resources',
}

const getterTree: GetterTree<ResourceState, RootState> = {
  [getterNames.resources]: (state) => state.resources,
}

export enum mutationNames {
  setResources = 'setResoruces',
  setCurrentResourceType = 'setCurrentResourceType',
  reset = 'reset',
}

const mutations: MutationTree<ResourceState> = {
  [mutationNames.setResources](state, { resources }: { resources: Resource[] | null }) {
    state.resources = resources
  },
  [mutationNames.setCurrentResourceType](state, { resource }: { resource: string | null }) {
    state.currentResourceType = resource
  },
  [mutationNames.reset](state) {
    Object.assign(state, initState())
  },
}

export enum actionNames {
  fetchCoachResource = 'fetchCoachResource',
  fetchCoachResourceByUPW = 'fetchCoachResourceByUPW',
}

const actions: ActionTree<ResourceState, RootState> & ActionsImpl = {
  async [actionNames.fetchCoachResource](
    { commit },
    { resource, programType, productYear, role, ruleLevel, week, devoTrack }: FetchResource
  ): Promise<boolean> {
    try {
      const result = await contentManagementClient.getCoachResources(
        resource,
        programType,
        productYear,
        role,
        ruleLevel,
        week,
        devoTrack
      )
      if (result) {
        commit(mutationNames.setResources, { resources: result })
        commit(mutationNames.setCurrentResourceType, { resource })
        return true
      }
    } catch (e) {
      commit(mutationNames.setResources, { resources: null })
      commit(mutationNames.setCurrentResourceType, { resource: null })
      throw e
    }

    return false
  },
  async [actionNames.fetchCoachResourceByUPW](
    { commit },
    { upw, typeProgramID, resource, role, ruleLevel, week }: FetchResourceByUPW
  ): Promise<boolean> {
    try {
      const result = await contentManagementClient.getCoachResourcesByUPW(
        upw,
        typeProgramID,
        resource,
        role,
        ruleLevel,
        week
      )
      if (result) {
        commit(mutationNames.setResources, { resources: result })
        commit(mutationNames.setCurrentResourceType, { resource })
        return true
      }
    } catch (e) {
      commit(mutationNames.setResources, { resources: null })
      commit(mutationNames.setCurrentResourceType, { resource: null })
      throw e
    }

    return false
  },
}

export const namespace = 'resources'

export const resources = {
  namespaced: true as true,
  state: resourceState,
  getters: getterTree,
  actions,
  mutations,
}

export interface FetchResource {
  resource: string
  programType: string
  productYear: string
  role: string
  ruleLevel: number
  week: number | null
  devoTrack: string
}

export interface FetchResourceByUPW {
  upw: string
  typeProgramID: string
  resource: string
  role: string
  ruleLevel: number
  week: number | null
}
