import { GameVolunteerInfo } from '@/GeneratedTypes/ListInfo/GameVolunteerInfo'

interface DisplayVolunteer extends GameVolunteerInfo {
  name: string | null
}
export interface DisplayableGame {
  gameStart: Date | null
  gameEnd: Date | null
  facilityName: string | null
  homeTeamName: string | null
  homeTeamId: number
  awayTeamName: string | null
  referees?: DisplayVolunteer[] | null | undefined
  coaches?: DisplayVolunteer[] | null | undefined
  divisionName?: string | null
}

export function getEmptyDisplableGame(): DisplayableGame {
  return {
    gameStart: null,
    gameEnd: null,
    facilityName: '',
    homeTeamName: '',
    homeTeamId: 0,
    awayTeamName: '',
    referees: [],
    coaches: [],
    divisionName: '',
  }
}
