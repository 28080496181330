
import { Component, Mixins, Prop } from 'vue-property-decorator'
import LinkingListItem from '@/components/LinkingListItem.vue'
import { IsCheerMixin } from '@/common/IsCheerMixin'
import { CurrentGameLineupWithRotationMixin } from '@/common/CurrentGameLineupWithRotationMixin'

interface DisplayableItem {
  id: number
  route: string
}

@Component({
  components: { LinkingListItem },
})
export default class GameDayGameCountList extends Mixins(IsCheerMixin, CurrentGameLineupWithRotationMixin) {
  @Prop({ type: Number, default: 5, required: false })
  private readonly gameCount!: number

  private get list() {
    if (this.isCheer) {
      return this.composeCheer()
    } else {
      return this.composeSport()
    }
  }

  private composeCheer(): DisplayableItem[] {
    const list: DisplayableItem[] = []
    for (let i = 0; i < this.gameCount; i++) {
      const id = i + 1
      list.push({
        id: id,
        route: `game/${id}/gamerecognition`,
      } as DisplayableItem)
    }
    return list
  }

  private composeSport(): DisplayableItem[] {
    const list: DisplayableItem[] = []
    for (let i = 0; i < this.gameCount; i++) {
      const id = i + 1
      list.push({
        id: id,
        route: `game/${id}`,
      } as DisplayableItem)
    }
    return list
  }
}
