
import TextInput from '@/elements/TextInput.vue'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component({
  components: {
    TextInput,
  },
})
export default class PasswordInput extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly required!: boolean

  @Prop({ type: String, default: '', required: false })
  private readonly placeholder!: string

  @Prop({ type: String, default: '', required: false })
  private readonly prependIcon!: string

  @Prop({ type: String, default: 'eye-slash', required: false })
  private readonly appendIcon!: string

  @Prop({ type: String, required: true })
  private readonly value!: string

  private internalValue: string = this.value
  localAppendIcon = ''
  inputType = 'password'

  private handleInput(e: Event) {
    this.checkAutoFillBug(e)
    this.$emit('input', this.internalValue)
  }

  private handleChange(e: Event) {
    this.checkAutoFillBug(e)
    this.$emit('change', this.internalValue)
  }

  private checkAutoFillBug(e: Event) {
    //taken from https://github.com/vuejs/vue/issues/7058 (scroll to post from tafelnl dated Dec 28, 2022)
    if (e.target instanceof HTMLInputElement && e.target.value) {
      this.internalValue = e.target.value
    }
  }

  eyeClicked() {
    this.inputType = this.localAppendIcon == 'eye-slash' ? 'text' : 'password'
    this.localAppendIcon = this.localAppendIcon == 'eye-slash' ? 'eye' : 'eye-slash'
  }
  @Watch('value')
  private onValueChanged() {
    this.internalValue = this.value
  }
  @Watch('appendIcon', { immediate: true })
  private iconChange() {
    this.localAppendIcon = this.appendIcon
  }
}
