export enum AlertStatusEnum {
  warning = 'warning',
  danger = 'danger',
  info = 'info',
  default = 'default',
}
export interface AlertOption {
  message: string
  icon: string
  status: string
}

const dflt: AlertOption = {
  message: 'There is no alert for this program.',
  icon: 'fas fa-bell-slash',
  status: 'default',
}

const past: AlertOption = {
  message: 'This message is no longer showing.',
  icon: 'fas fa-bell-slash',
  status: 'danger',
}

const warning: AlertOption = {
  message: 'This message is not active yet.',
  icon: 'fas fa-clock',
  status: 'warning',
}

const info: AlertOption = {
  message: 'This message is currently displaying.',
  icon: 'fas fa-bell',
  status: 'info',
}

export const TeamAlertOptions = new Map([
  ['default', dflt],
  ['warning', warning],
  ['info', info],
  ['danger', past],
])
