
import '@/css'
import Loading from '@/elements/Loading.vue'
import Toaster, { AlertTypeEnum } from '@/components/Toaster.vue'
import TheErrorRenderer from '@/singletons/TheErrorRenderer.vue'
import TheSidebar from '@/singletons/TheSidebar.vue'
import TheHeader from '@/singletons/TheHeader.vue'
import TheFooter from '@/singletons/TheFooter.vue'
import FullBody from '@/components/FullBody.vue'
import TheAuthWall from '@/singletons/TheAuthWall.vue'
import PwaInstallPrompt from '@/components/PwaInstallPrompt.vue'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { Mixins, Component, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { RouteMetaMixin } from '@/router/'
import { SWRefreshMixin } from '@/common/SWRefreshMixin'
import * as programStore from '@/store/programs'
import * as errors from '@/store/errors'
import * as auth from '@/store/authentication'
import * as root from '@/store/index'
import * as lineupStore from '@/store/lineup'
import * as upwardTypesStore from '@/store/upwardTypes'
import { convertToRestServiceResult, convertToVerificationDetails } from '@/services/restService'
import { UpwardExceptionResult, UpwardError } from '@/models/UpwardExceptionResult'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'
import { adminUsers } from '@/common/Authorization/AccountRoles'
import { logoutUser } from '@/common/Authentication/logoutUser'

@Component({
  components: {
    Loading,
    Toaster,
    FullBody,
    TheSidebar,
    TheFooter,
    TheHeader,
    TheErrorRenderer,
    PwaInstallPrompt,
    TheAuthWall,
  },
})
export default class App extends Mixins(RouteMetaMixin, SWRefreshMixin) {
  @Getter(auth.getterNames.isAuthenticated, { namespace: auth.namespace })
  private isAuthenticated!: boolean

  @Getter(auth.getterNames.refreshTime, { namespace: auth.namespace })
  private refreshTime!: Date | null

  @Getter(root.getterNames.now)
  private now!: Date

  @Getter(root.getterNames.userInactiveMS)
  private userInactiveMS!: number

  @Action(root.actionNames.startTimekeeping)
  private startTimekeeping!: () => void

  @Mutation(root.mutationNames.updateUserLastSeen)
  private updateUserLastSeen!: () => void

  @Action(auth.actionNames.tryLoadSavedToken, { namespace: auth.namespace })
  private tryLoadSavedToken!: () => void

  @Action(auth.actionNames.refreshToken, { namespace: auth.namespace })
  private refreshToken!: () => void

  @Mutation(lineupStore.mutationNames.clearOldLineups, { namespace: lineupStore.namespace })
  private clearOldLineups!: () => void

  @Mutation(lineupStore.mutationNames.clearAllLineups, { namespace: lineupStore.namespace })
  private clearAllLineups!: () => void

  @Getter(programStore.getterNames.programs, { namespace: programStore.namespace })
  private programs!: LeagueInfoCondensed[]

  @Getter(programStore.getterNames.currentProgramId, { namespace: programStore.namespace })
  private currentProgramId!: string

  @Getter(auth.getterNames.impersonationActive, { namespace: auth.namespace })
  private impersonationActive!: boolean

  @Mutation(programStore.mutationNames.setCurrentProgram, { namespace: programStore.namespace })
  private setCurrentProgram!: ({ upwId }: { upwId: string }) => void

  @Mutation(errors.mutationNames.addError, { namespace: errors.namespace })
  private addErrorToStore!: ({ item }: { item: UpwardExceptionResult }) => void

  @Mutation(errors.mutationNames.addValidationError, { namespace: errors.namespace })
  private addValidationErrorToStore!: ({ item }: { item: UpwardVerificationDetails }) => void

  @Action(upwardTypesStore.actionNames.fetchProgramTypes, { namespace: upwardTypesStore.namespace })
  private fetchProgramTypes!: ({ force }: { force: boolean }) => Promise<boolean>

  @Action(upwardTypesStore.actionNames.fetchLeagueTypes, { namespace: upwardTypesStore.namespace })
  private fetchLeagueTypes!: ({ force }: { force: boolean }) => Promise<boolean>

  @Action(upwardTypesStore.actionNames.fetchPositionTypes, { namespace: upwardTypesStore.namespace })
  private fetchPositionTypes!: ({ force }: { force: boolean }) => Promise<boolean>

  @Action(upwardTypesStore.actionNames.fetchGradeTypes, { namespace: upwardTypesStore.namespace })
  private fetchGradeTypes!: ({ force }: { force: boolean }) => Promise<boolean>

  @Action(lineupStore.actionNames.loadSubstitutionPreference, { namespace: lineupStore.namespace })
  private loadSubstitutionPreference!: () => void

  private alertTypeEnum = AlertTypeEnum
  private loading = true
  private userHasBeenSeenThisTimePeriod = false

  private get isLoading() {
    return this.loading || this.updateExists
  }

  private async mounted() {
    if (this.$route.query.token) {
      //The user is trying to login with a passthrough token from another app.
      //Clear any creditals they had from old authenications.
      logoutUser()
      this.loading = false
    } else {
      try {
        this.loading = true
        this.startTimekeeping()
        this.tryLoadSavedToken()
        this.watchForActivity()
      } finally {
        this.loading = false
      }
    }
  }

  private watchForActivity() {
    const onActivity = () => (this.userHasBeenSeenThisTimePeriod = true)

    document.addEventListener('mousemove', onActivity, false)
    document.addEventListener('mousedown', onActivity, false)
    document.addEventListener('keypress', onActivity, false)
    document.addEventListener('touchmove', onActivity, false)
  }

  private get isCurrentRoutePublic() {
    const currentRoute = this.currentRouteMeta
    if (currentRoute.isPublic) {
      return true
    }
    return false
  }

  private errorCaptured(err: any) {
    // Validation result object
    const validationResult = convertToVerificationDetails(err)

    if (err.name == 'QuotaExceededError') {
      // local storage has exceeded max capacity
      // clear out lineups
      this.clearAllLineups()
      return false // stop propogation
    }

    if (
      validationResult &&
      validationResult.data &&
      validationResult.data.brokenRules &&
      validationResult.data.brokenRules.length
    ) {
      this.addValidationErrorToStore({ item: validationResult.data! })
      return false // Stop propogation
    }

    // Rest service result object
    const genericResult = convertToRestServiceResult(err)

    if (genericResult !== null && genericResult.errorObject !== null) {
      this.addErrorToStore({ item: genericResult.errorObject })
      return false // Stop propogation
    }

    // Unknown
    this.addErrorToStore({
      item: {
        message: 'An unknown error occurred',
        errors: [
          {
            message: `${err}`,
          } as UpwardError,
        ],
      } as UpwardExceptionResult,
    })

    return true
  }

  @Watch('now')
  private onTimeChange() {
    if (this.isAuthenticated) {
      if (this.userHasBeenSeenThisTimePeriod) {
        this.updateUserLastSeen()
        this.userHasBeenSeenThisTimePeriod = false
      } else if (this.userInactiveMS >= 120 * 60 * 1000) {
        logoutUser()
        this.addErrorToStore({
          item: {
            message: 'You have been logged out because of inactivity',
          } as UpwardExceptionResult,
        })

        return
      }

      if (this.refreshTime && this.now >= this.refreshTime) {
        this.refreshToken()
      }
    }
  }

  private async loadAppWideData() {
    try {
      this.loading = true
      this.clearOldLineups() // remove old lineups from localstorage (via store and vuexpersist)
      this.loadSubstitutionPreference()
      await Promise.all([
        this.fetchProgramTypes({ force: false }),
        this.fetchLeagueTypes({ force: false }),
        this.fetchPositionTypes({ force: false }),
        this.fetchGradeTypes({ force: false }),
      ])
    } catch (error) {
      const genericResult = convertToRestServiceResult(error)
      if (genericResult && genericResult.errorObject) {
        this.addErrorToStore({ item: genericResult.errorObject })
      }
    } finally {
      this.loading = false
    }
  }

  @Watch('isAuthenticated')
  private async authenticationChange() {
    if (this.isAuthenticated) {
      this.loadAppWideData()
    }
    if (this.isAuthenticated) {
      //force admin users to impersonate
      if (this.$hasAtLeastOne(adminUsers) && !this.impersonationActive) {
        if (this.$route.name !== 'Impersonate') {
          await this.$router.push({ name: 'Impersonate' })
        }
      } else {
        if (this.$route.name !== 'Programs') {
          //force everyone else to pick a program
          if (this.$route.query.token) {
            //$router.replace used to smooths back button navigation
            //when authenticating via query parameter token
            this.$router.replace({ name: 'Programs' })
          } else {
            this.$router.push({ name: 'Programs' })
          }
        }
      }
    }
  }
}
