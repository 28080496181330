import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios'
import { UpwardExceptionResult } from '@/models/UpwardExceptionResult'
import jwtService from '@/services/jwtService'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'
import store from '@/store'

const conf: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_ROOT_API,
}

const axiosInstance: AxiosInstance = axios.create(conf)

axiosInstance.interceptors.request.use(
  (config) => {
    const token = jwtService.getTokenFromStorage()
    const url = config.url ? config.url : ''
    if (token) {
      if (
        url.endsWith('authorization/login/') ||
        url.endsWith('authorization/login') ||
        url.endsWith('authorization/passwordreset/') ||
        url.endsWith('authorization/passwordreset')
      ) {
        return config
      }
      config.headers.Authorization = 'Bearer ' + token.upwardJWToken
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

const setToken = (token: string | null | undefined) => {
  axios.defaults.headers.common = {
    Authorization: token ? `Bearer ${token}` : null,
  }
}

// Refresh token. Might use this after final authentication pattern is finished
// axiosInstance.interceptors.response.use(
//   response => {
//     return response
//   },
//   error => {
//     const originalRequest = error.config
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true
//       return authorizationClient.getOprGuestToken().then(res => {
//         if (res.status === 200) {
//           systemTokenService.setOprGuestToken(res.data)
//           axiosInstance.defaults.headers.Authorization = 'Bearer ' + systemTokenService.getOprGuestToken()
//           return axiosInstance(originalRequest)
//         }
//       })
//     }
//     return Promise.reject(error)
//   }
// )

// send back to login if token is expired
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401 && store.getters.authentication.isTokenExpired) {
      console.log('expired token detected...  clearing old token to force re-login')
      store.commit.authentication.reset()
    }
    return Promise.reject(error)
  }
)

export interface RestServiceResult<T> {
  errorObject: UpwardExceptionResult | null
  isSuccess: boolean
  status: number
  data: T | null
}

export function convertToRestServiceResult<T>(instance: any) {
  const implementsInterface =
    typeof instance === 'object' &&
    instance !== null &&
    'errorObject' in instance &&
    'isSuccess' in instance &&
    'data' in instance

  return implementsInterface ? (instance as RestServiceResult<T>) : null
}

export function convertToVerificationDetails(instance: any) {
  const implementsInterface =
    typeof instance === 'object' &&
    instance !== null &&
    'errorObject' in instance &&
    'isSuccess' in instance &&
    'data' in instance &&
    instance.data &&
    'brokenRules' in instance.data &&
    'model' in instance.data

  return implementsInterface ? (instance as RestServiceResult<UpwardVerificationDetails>) : null
}

const isUpwardInvalidResult = (instance: any) => {
  return (
    typeof instance === 'object' &&
    instance !== null &&
    'id' in instance &&
    'message' in instance &&
    'errors' in instance
  )
}

const getRestServiceResult = <T>(axiosResponse: AxiosResponse<any>) => {
  const isSuccessCode = Math.floor(axiosResponse.status / 100) === 2
  const isErrorObject = isUpwardInvalidResult(axiosResponse.data)

  return {
    data: isErrorObject ? null : (axiosResponse.data as T),
    isSuccess: isSuccessCode && !isErrorObject,
    status: axiosResponse.status,
    errorObject: isErrorObject
      ? axiosResponse.data
      : ({
          message: axiosResponse.statusText || 'Unknown error',
        } as UpwardExceptionResult),
  } as RestServiceResult<T>
}

const wrapApiCall = async <T>(callback: () => Promise<AxiosResponse<any>>) => {
  return await callback()
    .then((result) => {
      return getRestServiceResult<T>(result)
    })
    .catch((error) => {
      if (error.response) {
        throw getRestServiceResult<T>(error.response)
      }

      throw {
        data: null,
        isSuccess: false,
        status: 0,
        errorObject: {
          message: error,
          errors: null,
          id: null,
        },
      } as RestServiceResult<T>
    })
}

const post = async <T>(
  resource: string,
  data: object | null,
  config: AxiosRequestConfig | undefined = undefined
) => {
  return wrapApiCall<T>(() => axiosInstance.post(resource, data, config))
}

const put = async <T>(resource: string, data: object | null) => {
  return wrapApiCall<T>(() => axiosInstance.put(resource, data))
}

const get = async <T>(resource: string, params = {}) => {
  return wrapApiCall<T>(() => axiosInstance.get(resource, { params }))
}

const deleteMethod = async <T>(resource: string) => {
  return wrapApiCall<T>(() => axiosInstance.delete(resource))
}

const patch = async <T>(resource: string, data: object | null) => {
  return wrapApiCall<T>(() => axiosInstance.patch(resource, data))
}

export default {
  get,
  post,
  put,
  delete: deleteMethod,
  patch,
  setToken,
}
