
import { Component, Vue, Prop } from 'vue-property-decorator'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'

export const ProgramList = [
  { value: 'ALL', text: 'All' },
  { value: 'PLAYER', text: 'Players Only' },
  { value: 'CHEER', text: 'Cheer Only' },
]

@Component({
  components: { RadioGroupInput },
})
export default class MessageProgramSelector extends Vue {
  @Prop({ type: String, required: false })
  private readonly value!: string

  currentOption = ''

  list = ProgramList

  clear() {
    this.$emit('input', '')
    this.$emit('change', '')
  }

  emitValue(val: string) {
    if (val === this.list[0].value) {
      this.clear()
    }
    this.currentOption = val
    this.$emit('input', val)
    this.$emit('change', val)
  }
}
