
import InputLabel from '@/elements/InputLabel.vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
    InputLabel,
  },
})
export default class FileInput extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly required!: boolean

  @Prop({ type: String, default: '', required: false })
  private readonly placeholder!: string

  private fileName = ''
  private fileSize = 0

  private handleChange(ev: Event) {
    if (!ev || !ev.target) {
      return
    }

    const fileInput = ev.target as HTMLInputElement

    if (!fileInput || !fileInput.files) {
      return
    }

    const file = fileInput.files[0]

    if (!file) {
      this.fileName = ''
      this.fileSize = 0
    } else {
      this.fileName = file.name
      this.fileSize = file.size
    }

    this.$emit('change', file)
  }

  private remove() {
    ;(this.$refs.fileSelector as HTMLInputElement).value = ''
    this.fileName = ''
    this.fileSize = 0
    this.$emit('change', null)
  }

  private get fileSizeFormatted() {
    if (!this.fileSize) {
      return ''
    }

    if (this.fileSize / 1000000 >= 1) {
      return `${Math.round(this.fileSize / 1000000)}MB`
    }

    if (this.fileSize / 1000 >= 1) {
      return `${Math.round(this.fileSize / 1000)}KB`
    }

    return `${this.fileSize}B`
  }
}
