import { CheerSquadGameAppointmentInfo } from '@/GeneratedTypes/ListInfo/CheerSquadGameAppointmentInfo'
export function getEmptyCheerSquadGameAppointmentInfo(): CheerSquadGameAppointmentInfo {
  return {
    id: 0,
    leagueID: 0,
    typeProgramID: null,
    divisionID: 0,
    teamID: 0,
    gameStart: null,
    gameEnd: null,
    facilityID: 0,
    facilityName: null,
  }
}

export function getEmptyCheerSquadGameAppointmentInfoList(): CheerSquadGameAppointmentInfo[] {
  return [getEmptyCheerSquadGameAppointmentInfo()]
}
