import { Component, Prop, Vue } from 'vue-property-decorator'
import uuid from 'uuid'

/***
 * Serves as a reference for the canonical types supported by inputs with labels and validation.
 * reflect the following on the underlying input
 * - id - enables clicking on the label to proxy to the control
 * - name - enables description of a validation error
 * - enabled - enable/disable of the control
 *
 */
@Component
export class BaseInputWrapperProps extends Vue {
  @Prop({ type: String, default: '', required: false }) private readonly label!: string
  @Prop({ type: Boolean, default: false, required: false }) private readonly required!: boolean
  @Prop({ type: Boolean, default: true, required: false }) private readonly enabled!: boolean

  @Prop({ type: String, default: '', required: false })
  private readonly subText!: string
  @Prop({ type: String, default: () => uuid.v4(), required: false }) public readonly id!: string
  @Prop({ type: String, default: '', required: false }) public readonly name!: string
  @Prop({ type: String, default: '', required: false }) public readonly size!: string

  @Prop({ type: String, default: '', required: false }) private readonly subLabel!: string
  @Prop({ type: Boolean, default: false, required: false }) private readonly subLabelInline!: boolean
  @Prop({ type: String, default: '', required: false }) private readonly VeeValidateRules!: string
  @Prop({ type: String, default: 'aggressive', required: false }) private readonly veeValidateMode!: string
}
