
import InputLabel from '@/elements/InputLabel.vue'
import { Component, Mixins } from 'vue-property-decorator'
import { BaseInputWrapperProps } from '@/elements/BaseInputWrapper/BaseInputWrapperProps'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    InputLabel,
    ValidationProvider,
  },
})
export default class BaseInputWrapper extends Mixins(BaseInputWrapperProps) {}
