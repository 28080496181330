
import SelectInput from '@/elements/SelectInput.vue'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component({
  components: {
    SelectInput,
  },
})
export default class DayOfWeekSelect extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly required!: boolean

  @Prop({ required: true })
  private readonly value!: string | null

  @Prop({ type: String, default: '', required: false })
  private readonly blankItemText!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly showBlankItem!: boolean

  @Prop({ default: null, required: false })
  private readonly blankItemValue!: any

  @Prop({ type: String, default: '', required: false })
  private readonly VeeValidateRules!: string

  private internalValue = this.value

  private options = [
    { value: 'Sunday', text: 'Sunday' },
    { value: 'Monday', text: 'Monday' },
    { value: 'Tuesday', text: 'Tuesday' },
    { value: 'Wednesday', text: 'Wednesday' },
    { value: 'Thursday', text: 'Thursday' },
    { value: 'Friday', text: 'Friday' },
    { value: 'Saturday', text: 'Saturday' },
  ]

  private handleChange() {
    this.$emit('input', this.internalValue)
    this.$emit('change', this.internalValue)
  }

  @Watch('value')
  private onValueChanged() {
    this.internalValue = this.value
  }
}
