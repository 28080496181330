
import Logout from '@/components/Logout.vue'
import { Component, Mixins } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

import * as programStore from '@/store/programs'
import * as sidebar from '@/store/sidebar'
import * as authentication from '@/store/authentication'

import { MainMenuMixin } from '@/common/MainMenuMixin'
import { superUsers, adminUsers } from '@/common/Authorization/AccountRoles'
import { allVolunteerRoles, VolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import { ApplicationRoles } from '@/common/Authorization/ApplicationRoles'

import { MainMenuItemConfig } from '@/models/MenuItem'

import { maxStringLength } from '@/formatters/maxStringLength'

import Impersonating from '@/components/Impersonating.vue'
import SidebarItem from '@/components/SidebarItem.vue'

import PrimaryHeading from '@/elements/PrimaryHeading.vue'
import * as upwardTypes from '@/store/upwardTypes'
import { UpwardLeagueTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueTypeID'

@Component({
  components: {
    SidebarItem,
    Logout,
    Impersonating,
    PrimaryHeading,
  },
})
export default class TheSidebar extends Mixins(MainMenuMixin) {
  @Getter(sidebar.getterNames.isOpen, { namespace: sidebar.namespace })
  private isOpen!: boolean

  @Getter(authentication.getterNames.userData, { namespace: authentication.namespace })
  private userToken!: string | undefined

  @Getter(authentication.getterNames.impersonationAccountNumber, { namespace: authentication.namespace })
  private impersonationAccountNumber!: string

  @Getter(authentication.getterNames.impersonationActive, { namespace: authentication.namespace })
  private impersonationActive!: boolean

  @Getter(authentication.getterNames.accountName, { namespace: authentication.namespace })
  private accountName!: string

  @Getter(authentication.getterNames.firstAccountNumber, { namespace: authentication.namespace })
  private firstAccountNumber!: string

  @Getter(programStore.getterNames.currentProgramId, { namespace: programStore.namespace })
  private currentProgramId!: string | undefined

  @Mutation(sidebar.mutationNames.toggle, { namespace: sidebar.namespace })
  private toggleSidebar!: void

  @Getter(upwardTypes.getterNames.currentLeagueType, { namespace: upwardTypes.namespace })
  private currentLeagueType!: UpwardLeagueTypeID

  private allVolunteerRoles = allVolunteerRoles
  private maxStringLen = maxStringLength
  private superUsers = superUsers
  private adminUsers = adminUsers
  private impersonateLink = {
    text: 'Impersonate',
    icon: 'fas fa-mask',
    route: '/account/impersonate',
    description: '',
    name: 'impersonate',
  } as MainMenuItemConfig

  private get fullSiteLink() {
    const upwardUrl = process.env.VUE_APP_MYUPWARD
    const chit = `${this.userToken}.${this.impersonationAccountNumber}`
    if (this.userToken) {
      return `${upwardUrl}?token=${encodeURIComponent(chit)}&redirect=false`
    }
    return ''
  }

  private get show() {
    if (!this.currentLeagueType) return false

    if (!this.$hasAllOf([ApplicationRoles.USER_WITH_LEAGUE])) {
      return false
    }
    if (
      !this.currentLeagueType.isCamp &&
      this.$hasAllOf([VolunteerRoles.COACH]) &&
      !this.$hasAllOf([ApplicationRoles.COACH_WITH_TEAM])
    ) {
      return false
    }
    return true
  }
}
