
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ProgramOverview extends Vue {
  @Prop({ type: String, required: true })
  private readonly description!: string

  private get iconCssClass() {
    const defaultIcon = 'fa fa-running'

    if (!this.description) {
      return defaultIcon
    }

    if (this.description.toLowerCase().includes('cheer')) {
      return 'fa fa-bullhorn'
    }

    if (this.description.toLowerCase().includes('soccer')) {
      return 'fa fa-futbol'
    }

    if (this.description.toLowerCase().includes('basketball')) {
      return 'fa fa-basketball-ball'
    }

    if (this.description.toLowerCase().includes('baseball')) {
      return 'fa fa-baseball-ball'
    }

    if (this.description.toLowerCase().includes('football')) {
      return 'fa fa-football-ball'
    }

    if (this.description.toLowerCase().includes('volleyball')) {
      return 'fas fa-volleyball-ball'
    }

    return defaultIcon
  }
}
