import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'

export function getEmptyTeamPlayerInfo(): TeamPlayerInfo {
  return {
    leagueID: 0,
    typeProgramID: '',
    divisionID: 0,
    divisionName: '',
    teamID: 0,
    teamName: '',
    typePositionID: '',
    typeTapeDownReasonID: '',
    manualAssignment: false,
    draftNumber: 0,
    rosterRanking: '',
    evaluationScore: 0,
    positionScore: 0,
    athleticScore: 0,
    extraScore: 0,
    draftExceptions: null,
    contacts: [],
    individualID: 0,
    firstName: '',
    lastName: '',
    middleInitial: '',
    gender: '',
    grade: '',
    birthDate: null,
    churchName: '',
    addresses: null,
    phoneNumbers: null,
    emailAddresses: null,
    thirdPartyClientIDs: null,
    formattedNameFirstLast: '',
    formattedNameLastFirst: '',
    formattedNamePrivate: '',
    coachAssignedJerseyNumber: '',
  }
}

export function getEmptyTeamPlayerInfoList(): TeamPlayerInfo[] {
  return [getEmptyTeamPlayerInfo()]
}
