import { RouteConfig } from 'vue-router'
import ResourceList from '@/views/PracticeResources/ResourceList.vue'
import CoachResource from '@/views/PracticeResources/CoachResource.vue'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import { allowFirstRanking } from '@/plugins/Authorization/permissionChecks'

export default [
  {
    path: 'practiceplans',
    name: 'MinistryPracticePlans',
    meta: {
      title: 'Practice Plans',
      level: 1,
      childRoute: 'PracticePlan',
      childComponent: 'CoachResourceList',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: ResourceList,
  },
  {
    path: 'practiceplan/:resourceId',
    name: 'MinistryPracticePlan',
    meta: {
      title: 'MinistryPractice Plan',
      level: 2,
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: CoachResource,
  },
  {
    path: 'devotions',
    name: 'MinistryDevotions',
    meta: {
      title: 'Devotions',
      level: 1,
      childRoute: 'Devotion',
      childComponent: 'CoachResourceList',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: ResourceList,
  },
  {
    path: 'devotion/:resourceId',
    name: 'MinisrtyDevotion',
    meta: {
      title: 'Devotion',
      level: 2,
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: CoachResource,
  },
] as RouteConfig[]
