
import { VueConstructor } from 'vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import uuid from 'uuid'

/// <reference path="@/modules/vue-slide-up-down.d.ts" />
import SlideUpDown from 'vue-slide-up-down'

@Component({
  components: {
    SlideUpDown: SlideUpDown as unknown as VueConstructor,
  },
})
export default class AccordionItem extends Vue {
  @Inject('accordionData')
  private readonly accordionData!: { currentGuid: string }

  private readonly guid = uuid.v4()

  private toggle() {
    this.accordionData.currentGuid = this.isExpanded ? '' : this.guid
  }

  private get isExpanded() {
    return this.accordionData.currentGuid === this.guid
  }
}
