import { AlertInfo } from '@/GeneratedTypes/ListInfo/AlertInfo'
import { TeamPageAlertInfo } from '@/GeneratedTypes/ListInfo/TeamPageAlertInfo'

export function getEmptyAlertInfo(): AlertInfo {
  return {
    id: 0,
    typeAlertMessageID: null,
    typeAlertTargetID: null,
    typeAlertPriorityID: null,
    message: '',
    startDate: null,
    expireDate: null,
    expireViewCount: 0,
    registeredViewCount: 0,
    userCanHide: false,
    userDidHide: false,
  }
}

export function getEmptyAlertInfoList(): AlertInfo[] {
  return [getEmptyAlertInfo()]
}

export function getEmptyTeamPageAlertInfo(): TeamPageAlertInfo {
  return {
    id: 0,
    typeAlertMessageID: null,
    typeAlertTargetID: null,
    typeAlertPriorityID: null,
    message: '',
    startDate: null,
    expireDate: null,
    expireViewCount: 0,
    registeredViewCount: 0,
    userCanHide: false,
    userDidHide: false,
    typeProgramID: '',
    divisionID: 0,
    divisionName: null,
    teamID: 0,
    teamName: null,
    upwardTeamID: null,
  }
}

export function getEmptyTeamPageAlertInfoList(): TeamPageAlertInfo[] {
  return [getEmptyTeamPageAlertInfo()]
}
