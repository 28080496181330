import localStorageService from '@/services/localStorageService'
import jsonwebtoken, { JwtPayload } from 'jsonwebtoken'
import { AuthToken } from '@/models/Authorization/AuthToken'
import restService from '@/services/restService'
import { Credentials } from '@/models/Credentials'
import credentialsService from '@/services/credientialService'

const localStorageKey = 'auth-token'
const oldLocalStorageKey = 'old-auth-token'

/**
 * Get stored auth token.
 */
const getAuthToken = () => {
  return localStorageService.getParsed<AuthToken>(localStorageKey)
}

/**
 * Backup a token that is not already impersonating a user, otherwise no-op
 */
const backupToken = () => {
  const token = getAuthToken()
  if (token) {
    const credentials = getCredentialsFromToken(token)

    if (
      credentialsService.hasValidCredentials(credentials) &&
      !credentialsService.isImpersonating(credentials)
    ) {
      localStorageService.set(oldLocalStorageKey, token)
    }
  }
}

const getCredentialsFromNewAuthToken = (authToken: AuthToken | null) => {
  if (authToken === null) {
    return null
  }

  const credentials = getCredentialsFromToken(authToken)

  if (credentials === null) {
    return null
  }

  restService.setToken(authToken.upwardJWToken)
  localStorageService.set(localStorageKey, authToken)
  return credentials
}

const setHTTPHeaderAndLocalStorage = (authToken: AuthToken | null) => {
  if (!authToken) return null

  restService.setToken(authToken.upwardJWToken)
  localStorageService.set(localStorageKey, authToken)
}

const getCredentialsFromStoredAuthToken = () => {
  const authToken = localStorageService.getParsed<AuthToken>(localStorageKey)

  if (authToken !== null) {
    restService.setToken(authToken.upwardJWToken)
    return getCredentialsFromToken(authToken)
  }

  return null
}

const clearStoredAuthToken = () => {
  localStorageService.remove(localStorageKey)
  restService.setToken(null)
}

const getCsvFromPayload = (payload: any, propertyName: string) => {
  const raw = payload[propertyName]

  if (!raw || typeof raw !== 'string') {
    return null
  }

  return raw.split(',')
}

const getTokenFromStorage = () => {
  return localStorageService.getParsed(localStorageKey) as AuthToken
}

const retrieveOldToken = async () => {
  const oldtoken = localStorageService.getParsed<AuthToken>(oldLocalStorageKey)
  if (oldtoken) {
    return oldtoken
  }
  return getAuthToken()
}

const getCredentialsFromToken = (authToken: AuthToken | null) => {
  if (authToken === null || authToken.upwardJWToken === null) {
    return null
  }

  const decodedJwt = jsonwebtoken.decode(authToken.upwardJWToken, { complete: true })

  if (decodedJwt === null) {
    return null
  } else if (typeof decodedJwt === 'string') {
    return null
  }

  interface JwtPayload {
    'upw-account': string
    'upw-rootaccount': string
    'upw-rolesbyleague': string
    'upw-aname': string
    'upw-activities': string
    'upw-name': string
    'upw-userdata': string
    'upw-pwdexp': string
    'upw-totp': string
    exp: number
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': string
  }
  const jwtPayload = decodedJwt.payload as JwtPayload

  const claimAccountNumber = jwtPayload['upw-account']
  const rootAccountNumber = jwtPayload['upw-rootaccount']
  const rolesByLeague = jwtPayload['upw-rolesbyleague']

  /*
    Impersonation is active if account numbers above do not match
  */
  let rootIsImpersonating = false
  if (claimAccountNumber !== rootAccountNumber) {
    rootIsImpersonating = true
  }

  const accountNumbers: string[] = []

  if (claimAccountNumber) {
    accountNumbers.push(claimAccountNumber)
  }

  if (authToken.accountNumbers && authToken.accountNumbers.length) {
    accountNumbers.push(...authToken.accountNumbers)
  }

  return {
    accountNumbers,
    accountName: jwtPayload['upw-aname'],
    activities: jwtPayload['upw-activities'],
    fullName: jwtPayload['upw-name'],
    impersonatedAccount: rootIsImpersonating ? claimAccountNumber : '',
    impersonationActive: rootIsImpersonating,
    email: jwtPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
    userData: jwtPayload['upw-userdata'],
    roles: getCsvFromPayload(decodedJwt.payload, 'upw-roles'),
    expiration: new Date(jwtPayload.exp * 1000),
    daysUntilPasswordExpiration: Number(jwtPayload['upw-pwdexp']) || null,
    leagueRoles: JSON.parse(rolesByLeague),
    totpInfo: jwtPayload['upw-totp'],
  } as Credentials
}

export default {
  getCredentialsFromNewAuthToken,
  getTokenFromStorage,
  getCredentialsFromStoredAuthToken,
  clearStoredAuthToken,
  backupToken,
  setHTTPHeaderAndLocalStorage,
  retrieveOldToken,
}
