/* There is a requirment to store and manage game lineup information offline because
 * connectivity in gyms can be very poor. There are no lineup API endpoints, so service
 * workers for offline functionality is not a solution at this time.
 *
 * This module makes no API calls. It uses **vuex-persist** and localstorage
 * to manage and persist game lineups
 *
 * Any lineups in localstorage that are more than one year old are cleared
 * each time the application starts.
 */

import { GetterTree, MutationTree } from 'vuex'
import { defineActions } from 'direct-vuex'
import { RootState } from '@/store/rootState'
import { cloneDeep } from 'lodash'
import { GameLineupWithRotation, GameLineup, SubstitutionPreference } from '@/models/Lineup'
import localStorageService from '@/services/localStorageService'

interface LineupState {
  allLineups: GameLineup[]
  allLineupsWithRotation: GameLineupWithRotation[]
  substitutionPreference: SubstitutionPreference | null
}

const lineupState: LineupState = {
  allLineups: [] as GameLineup[],
  allLineupsWithRotation: [] as GameLineupWithRotation[],
  substitutionPreference: null,
}

export enum mutationNames {
  upsertLineup = 'upsertLineup',
  deleteLineup = 'deleteLineup',
  upsertLineupWithRotation = 'upsertLineupWithRotation',
  deleteLineupWithRotation = 'deleteLineupWithRotation',
  clearOldLineups = 'clearOldLineups',
  clearAllLineups = 'clearAllLineups',
  setSubstitutionPreference = 'setSubstitutionPreference',
}

const mutations: MutationTree<LineupState> = {
  [mutationNames.upsertLineup](state, { lineup }: { lineup: GameLineup }) {
    const clone = cloneDeep(state.allLineups)
    const filtered = clone.filter((l) => {
      const uniqueArrayId = `${l.gameId}${l.divisionId}${l.teamId}`
      const uniqueLineupId = `${lineup.gameId}${lineup.divisionId}${lineup.teamId}`
      return uniqueArrayId != uniqueLineupId
    })
    filtered.push(lineup)
    state.allLineups = filtered
  },
  [mutationNames.deleteLineup](state, { lineup }: { lineup: GameLineup }) {
    const clone = cloneDeep(state.allLineups)
    const filtered = clone.filter((l) => {
      const uniqueArrayId = `${l.gameId}${l.divisionId}${l.teamId}`
      const uniqueLineupId = `${lineup.gameId}${lineup.divisionId}${lineup.teamId}`
      return uniqueArrayId != uniqueLineupId
    })
    state.allLineups = filtered
  },
  [mutationNames.upsertLineupWithRotation](state, { lineup }: { lineup: GameLineupWithRotation }) {
    const clone = cloneDeep(state.allLineupsWithRotation)
    const filtered = clone.filter((l) => {
      const uniqueArrayId = `${l.gameId}${l.divisionId}${l.teamId}`
      const uniqueLineupId = `${lineup.gameId}${lineup.divisionId}${lineup.teamId}`
      return uniqueArrayId != uniqueLineupId
    })
    filtered.push(lineup)
    state.allLineupsWithRotation = filtered
  },
  [mutationNames.deleteLineupWithRotation](state, { lineup }: { lineup: GameLineupWithRotation }) {
    const clone = cloneDeep(state.allLineupsWithRotation)
    const filtered = clone.filter((l) => {
      const uniqueArrayId = `${l.gameId}${l.divisionId}${l.teamId}`
      const uniqueLineupId = `${lineup.gameId}${lineup.divisionId}${lineup.teamId}`
      return uniqueArrayId != uniqueLineupId
    })
    state.allLineupsWithRotation = filtered
  },
  [mutationNames.clearOldLineups](state) {
    const cleanLineupsWithRotation = state.allLineupsWithRotation.filter(removeYearOld)
    state.allLineupsWithRotation = cleanLineupsWithRotation

    const cleanLineups = state.allLineups.filter(removeYearOld)
    state.allLineups = cleanLineups
  },
  [mutationNames.clearAllLineups](state) {
    state.allLineupsWithRotation = []
    state.allLineups = []
  },
  [mutationNames.setSubstitutionPreference](state, { preference }: { preference: SubstitutionPreference }) {
    const preferenceString: string = JSON.stringify(preference)
    localStorageService.set(getterNames.substitutionPreference, preferenceString)
    state.substitutionPreference = preference
  },
}

function removeYearOld(l: GameLineupWithRotation | GameLineup) {
  const today = new Date()
  if (!l.createDate) return true
  const lineupCreateDt = new Date(l.createDate)
  //@ts-ignore
  const days = parseInt((today - lineupCreateDt) / (24 * 3600 * 1000))
  return days < 366
}

export enum getterNames {
  lineupsWithRotation = ' lineupsWithRotation',
  lineups = ' lineups',
  substitutionPreference = 'substitutionPreference',
}

const getterTree: GetterTree<LineupState, RootState> = {
  [getterNames.lineupsWithRotation]: (state) => state.allLineupsWithRotation,
  [getterNames.lineups]: (state) => state.allLineups,
  [getterNames.substitutionPreference]: (state) => {
    return state.substitutionPreference
  },
}

export enum actionNames {
  setSubstitutionPreference = 'setSubstitutionPreference',
  loadSubstitutionPreference = 'loadSubstitutionPreference',
}

const actions = defineActions({
  [actionNames.setSubstitutionPreference](
    { commit },
    { preference }: { preference: SubstitutionPreference }
  ): void {
    commit(mutationNames.setSubstitutionPreference, { preference })
  },
  [actionNames.loadSubstitutionPreference]({ commit }): void {
    let preference = localStorageService.getParsed<SubstitutionPreference>(getterNames.substitutionPreference)
    if (preference && preference.expiry < new Date()) {
      localStorageService.remove(getterNames.substitutionPreference)
      preference = null
    }
    commit(mutationNames.setSubstitutionPreference, { preference })
  },
})

export const namespace = 'lineup'

export const lineup = {
  namespaced: true as true,
  state: lineupState,
  getters: getterTree,
  mutations,
  actions,
}
