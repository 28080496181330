
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class PageStateToggle extends Vue {
  @Prop({ type: Array, default: [], required: false }) private readonly states!: string[]
  @Prop({ type: String, default: null, required: false }) private readonly value!: string | null

  private currentState = ''

  private mounted() {
    if (!this.value) this.$emit('input', this.states[0])
  }

  private toggleState(state: string) {
    this.$emit('input', state)
  }
}
