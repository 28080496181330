import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'

export function getEmptyLeaguePlayerInfoList(): LeaguePlayerInfo[] {
  return [getEmptyLeaguePlayerInfo()]
}

export function getEmptyLeaguePlayerInfo(): LeaguePlayerInfo {
  return {
    leagueID: 0,
    accountNumber: '',
    typeGradeID: '',
    medicalNotes: '',
    generalNotes: '',
    registrationTransactionID: 0,
    isImported: false,
    typeProgramID: '',
    yearsExperience: 0,
    adminNotes: '',
    pendingCoachLinkIndividualID: 0,
    pendingCoachLinkFirstName: '',
    pendingCoachLinkLastName: '',
    active: true,
    amountExpectedToPay: 0,
    amountPaid: 0,
    isPlayerSizingComplete: false,
    registrationCompletionInformation: '',
    evaluationScore: 0,
    positionScore: 0,
    athleticScore: 0,
    hasBrokenCarpoolLink: false,
    hasBrokenCoachLink: false,
    actualCoachLinkIndividualID: 0,
    actualCoachLinkFirstName: null,
    actualCoachLinkLastName: null,
    actualCarpoolLinkIndividualID: 0,
    actualCarpoolLinkFirstName: null,
    actualCarpoolLinkLastName: null,
    extraScore: 0,
    practiceNightExclusions: null,
    individualID: 0,
    firstName: '',
    lastName: '',
    middleInitial: '',
    gender: '',
    birthDate: null,
    churchName: '',
    addresses: null,
    phoneNumbers: null,
    emailAddresses: null,
    thirdPartyClientIDs: null,
    formattedNameFirstLast: '',
    formattedNameLastFirst: '',
    formattedNamePrivate: '',
  }
}
