import restService from '@/services/restService'
import { Resource } from '@/models/Program/Resource'
import { LegalResource } from '@/models/Legal/LegalResource'
import { PageContentDocument } from '@/models/PageContent/PageContentDocument'
import { CoachAPIResources } from '@/common/CoachAPIResources'
import { MarketingResource } from '@/models/Resources/MarketingResource'
import { GettingStartedResource } from '@/models/Resources/GettingStartedResource'

const baseUrl = 'ContentManagement/'

const getVolunteerResources = async (programType: string, productYear: string, role: string) => {
  const results = await restService.get<Resource[]>(`${baseUrl}volunteerresources`, {
    programType,
    productYear,
    role,
  })

  if (!results.isSuccess) {
    throw new Error('Error in contentManagementClient.getVolunteerResources')
  }
  return results.data || []
}

const getVolunteerResourcesByUPW = async (upw: string, typeProgramID: string, role: string) => {
  const results = await restService.get<Resource[]>(`${baseUrl}volunteerresources/${upw}`, {
    typeProgramID,
    role,
  })

  if (!results.isSuccess) {
    throw new Error('Error in contentManagementClient.getVolunteerResources')
  }
  return results.data || []
}

const getCheerResources = async (type: string, productYear: string, programType: string) => {
  const results = await restService.get<Resource[]>(`${baseUrl}cheerresources`, {
    type,
    productYear,
    programType,
  })

  if (!results.isSuccess) {
    throw new Error('Error in contentManagementClient.getCheerResources')
  }
  return results.data || []
}

const getCheerResourcesByUPW = async (upw: string, typeProgramID: string, type: string) => {
  const results = await restService.get<Resource[]>(`${baseUrl}cheerresources/${upw}`, {
    typeProgramID,
    type,
  })

  if (!results.isSuccess) {
    throw new Error('Error in contentManagementClient.getCheerResourcesByUPW')
  }
  return results.data || []
}

const getCoachResources = async (
  resource: string,
  programType: string,
  productYear: string,
  role: string,
  ruleLevel: number | null,
  week: number | null,
  devoTrack: string
) => {
  //ignore rulesLevel for 'Rule' resouces because the value is set to 0 in the db right now so nothing returns
  if (resource === CoachAPIResources.get('Rules')) ruleLevel = null
  const results = await restService.get<Resource[]>(`${baseUrl}volunteerresources/${resource}`, {
    programType,
    productYear,
    role,
    ruleLevel,
    week: week ? week : undefined,
    devoTrack,
  })

  if (!results.isSuccess) {
    throw new Error('Error in contentManagementClient.getCoachResources')
  }
  return results.data || []
}

const getCoachResourcesByUPW = async (
  upw: string,
  typeProgramID: string,
  resource: string,
  role: string,
  ruleLevel: number | null,
  week: number | null
) => {
  //ignore rulesLevel for 'Rule' resouces because the value is set to 0 in the db right now so nothing returns
  if (resource === CoachAPIResources.get('Rules')) ruleLevel = null
  const results = await restService.get<Resource[]>(`${baseUrl}volunteerresources/${resource}/${upw}`, {
    typeProgramID,
    role,
    ruleLevel,
    week: week ? week : undefined,
  })

  if (!results.isSuccess) {
    throw new Error('Error in contentManagementClient.getCoachResourcesByUPW')
  }
  return results.data || []
}

const getLegalResource = async (documentID: string, version: number) => {
  const results = await restService.get<LegalResource>(`${baseUrl}legalresource/${documentID}`, {
    version,
  })
  if (!results.isSuccess) {
    throw new Error('Error in contentManagementClient.getLegalResource')
  }
  return results.data
}

const getPageContentDocument = async (documentID: string) => {
  const result = await restService.get<PageContentDocument>(`${baseUrl}pagecontent/${documentID}`)
  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('Error in contentManagementClient.getPageContentDocument')
  }
}

const getMarketingResources = async (leagueTypeAbreviation: string) => {
  const result = await restService.get<MarketingResource[]>(`${baseUrl}volunteerResources/marketing`, {
    programType: leagueTypeAbreviation,
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('Error in contentManagementClient.getMarketingResources')
  }
}

const getGettingStartedResource = async (upwardLeagueID: string, programType: string, role: string) => {
  const results = await restService.get<GettingStartedResource>(
    `${baseUrl}gettingStarted/${upwardLeagueID}`,
    {
      programType,
      role,
    }
  )

  if (!results.isSuccess) {
    throw new Error('Error in contentManagementClient.getGettingStartedREsource')
  }
  return results.data
}

export default {
  getVolunteerResources,
  getLegalResource,
  getPageContentDocument,
  getCoachResources,
  getCheerResources,
  getMarketingResources,
  getGettingStartedResource,
  getCoachResourcesByUPW,
  getCheerResourcesByUPW,
  getVolunteerResourcesByUPW,
}
