
import { Component, Mixins } from 'vue-property-decorator'
import PwaInstallInfo from '@/components/PwaInstallInfo.vue'
import { PwaInstallMixin } from '@/common/PwaInstallMixin'

@Component({
  components: {
    PwaInstallInfo,
  },
})
export default class InstallOption extends Mixins(PwaInstallMixin) {
  private mounted() {
    this.installPrompt()
  }
}
