import * as authorization from '@/store/authorization'
import * as auth from '@/store/authentication'
import { cloneDeep } from 'lodash'

export function getUserRoles(that: Vue) {
  return getCurrentRoles(that)
}

export function getCurrentUserRole(that: Vue) {
  return getCurrentRole(that)
}

const getCurrentRole = (that: Vue) => {
  const currentRoleGetter = `${authorization.namespace}/${authorization.getterNames.currentRole}`
  const currentRole = cloneDeep(that.$store.getters[currentRoleGetter])
  return currentRole
}

const getCurrentRoles = (that: Vue): string[] => {
  const currentRoleGetter = `${authorization.namespace}/${authorization.getterNames.currentRole}`
  const applicationRoleGetter = `${authorization.namespace}/${authorization.getterNames.applicationRoles}`
  const accountRolesGetter = `${auth.namespace}/${auth.getterNames.accountRoles}`
  const currentRole = cloneDeep(that.$store.getters[currentRoleGetter]) //The roles being used by the current user. These are volunteer roles.
  const acctRoles = cloneDeep(that.$store.getters[accountRolesGetter]) //Account roles returned by the API.
  const appRoles = cloneDeep(that.$store.getters[applicationRoleGetter]) //Application Roles assigned to the user based on logic contained in the application.
  let allRoles: string[] = []
  if (currentRole) allRoles = [currentRole.roleId]
  if (acctRoles) allRoles = [...allRoles, ...acctRoles]
  if (appRoles) allRoles = [...allRoles, ...appRoles]
  return allRoles
}
