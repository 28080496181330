import { TeamPlayerInfo } from 'src/GeneratedTypes/ListInfo/TeamPlayerInfo'
import { getEmptyTeamPlayerInfo } from '@/modelHelpers/TeamPlayerInfo'
import { cloneDeep } from 'lodash'
import addDays from '@/utils/addDays'

interface GameLineupBase {
  gameId: number
  divisionId: number
  teamId: number
  upwardTypeId: string | null
  createDate: string | null
  isScheduledGame: boolean
  round: number
}

export interface GameLineup extends GameLineupBase {
  players: GameLineupPlayer[]
}

export interface GameLineupPlayer {
  player: TeamPlayerInfo
  foul: number
  starts: Start[]
}

export interface GameLineupWithRotation extends GameLineupBase {
  lineup: Lineup[] //player lineup
  startingSide: SidesEnum | null // only used for football
  completed: boolean
  currentSegment: number | null
  currentSide: string | null // only used for football
  playersPerSegment: number
}

export interface GameLineupPositionPlayer extends TeamPlayerInfo {
  positionText: string | null
}

export interface Lineup {
  segment: number
  side: SidesEnum | null // used for football
  players: GameLineupPositionPlayer[]
  inactivePlayers: TeamPlayerInfo[]
}

export enum SidesEnum {
  OFFFENSE = 'Offense',
  DEFENSE = 'Defense',
  BATTING = 'Batting',
  FIELDING = 'Fielding',
}

export function getEmptyGameLineupWithRotation(): GameLineupWithRotation {
  return {
    gameId: 0,
    divisionId: 0,
    teamId: 0,
    lineup: [] as Lineup[],
    startingSide: null,
    upwardTypeId: '',
    createDate: null,
    completed: false,
    currentSegment: null,
    currentSide: null,
    isScheduledGame: false,
    round: 0,
    playersPerSegment: 0,
  }
}

export function getEmptyGameLineup(): GameLineup {
  return {
    gameId: 0,
    divisionId: 0,
    teamId: 0,
    upwardTypeId: '',
    createDate: null,
    isScheduledGame: false,
    round: 0,
    players: [] as GameLineupPlayer[],
  }
}

export function getEmptyLineup(): Lineup {
  return {
    segment: 0,
    side: null,
    players: [] as GameLineupPositionPlayer[],
    inactivePlayers: [] as TeamPlayerInfo[],
  }
}

export function getEmptyVolleyballPlayer(): GameLineupPlayer {
  return {
    player: {} as TeamPlayerInfo,
    foul: 0,
    starts: cloneDeep(volleyballStarts),
  }
}

//Basketball level 4
export function getEmptyBasketballPlayer(): GameLineupPlayer {
  return {
    player: {} as TeamPlayerInfo,
    foul: 0,
    starts: cloneDeep(basketballStarts),
  }
}

export function getEmptyGameLineupPositionPlayer() {
  return { ...getEmptyTeamPlayerInfo(), positionText: '' }
}

const volleyballStarts: Start[] = [
  { id: 1, label: 'Game 1', started: false },
  { id: 2, label: 'Game 2', started: false },
  { id: 3, label: 'Game 3', started: false },
]

const basketballStarts: Start[] = [
  { id: 1, label: 'Qtr 1', started: false },
  { id: 2, label: 'Qtr 2', started: false },
  { id: 3, label: 'Qtr 3', started: false },
  { id: 4, label: 'Qtr 4', started: false },
]

interface Start {
  id: number
  label: string
  started: boolean
}
export enum SubstitutionType {
  Default = 'DEFAULT',
  Tranditional = 'TRADITIONAL',
}
export interface SubstitutionPreference {
  type: SubstitutionType
  expiry: Date
}
export class SubstitutionPreferenceImpl implements SubstitutionPreference {
  type: SubstitutionType
  expiry: Date
  constructor(type: SubstitutionType, expiry: Date) {
    this.type = type
    this.expiry = expiry
  }
  public static createNew(type: SubstitutionType, expiry: Date | number): SubstitutionPreferenceImpl {
    const exp = expiry instanceof Date ? expiry : addDays(new Date(), expiry)
    return new SubstitutionPreferenceImpl(type, exp)
  }
}
