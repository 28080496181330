//Type used to create a union of all leagueVolunteer and upwardAccount types
export interface RoleUnion {
  roleId: string
  roleType: RoleUnionTypes
  roleDescription: string
}

export enum RoleUnionTypes {
  VOLUNTEER = 'volunteer',
  ACCOUNT = 'account',
  APPLICATION = 'application',
  NONE = 'NONE',
}

export function getEmtpyRoleUnion(): RoleUnion {
  return {
    roleId: '',
    roleType: RoleUnionTypes.NONE,
    roleDescription: '',
  }
}

export function getEmtpyRoleUnionList(): RoleUnion[] {
  return [getEmtpyRoleUnion()]
}
