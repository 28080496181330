
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getEmptyDivisionGameInfo } from '@/modelHelpers/DivisionGameInfo'
import LabelInput from '@/elements/InputLabel.vue'
import DateCard from '@/components/DateCard.vue'
import moment from 'moment'
import { DisplayableGame } from '@/modelHelpers/DisplayableGame'
import { Getter } from 'vuex-class'
import * as programStore from '@/store/programs'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'

@Component({
  components: {
    LabelInput,
    DateCard,
  },
})
export default class Game extends Vue {
  @Prop({ type: Object, default: getEmptyDivisionGameInfo, required: false })
  private readonly game!: DisplayableGame

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  private time(dt: string) {
    return moment(dt).format('h:mmA')
  }
}
