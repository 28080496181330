
import { Getter, Action } from 'vuex-class'
import { Component, Vue, Mixins, Ref, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import InputlessStringValidator from '@/elements/InputlessStringValidator.vue'
import InputLabel from '@/elements/InputLabel.vue'
import Loading from '@/elements/Loading.vue'

import MessageContent from '@/components/MessageContent.vue'
import MessageRecipient from '@/components/MessageRecipient/MessageRecipient.vue'
import MessageFilters from '@/components/MessageFilters/MessageFilters.vue'
import MessagePreview from '@/components/MessagePreview.vue'
import ParentRecipientPicker from '@/components/ParentRecipientPicker.vue'
import CoachRecipientPicker from '@/components/CoachRecipientPicker.vue'
import CopyRecipientsToClipboard from '@/components/CopyPhoneRecipientsToClipboard.vue'
import { FilterOptions, getInitialFilterOptions } from '@/components/MessageFilters/data'
import { ProgramList } from '@/components/MessageRecipient/MessageProgramSelector.vue'

import { ViewStates } from '@/views/Message/data'

import { getEmptyPreviewMessage } from '@/modelHelpers/PreviewMessage'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import { EmailMessageImpl } from '@/models/Communication/EmailMessageImpl.ts'
import { getEmptyAttachment } from '@/modelHelpers/Attachment.ts'
import { getEmptyContactBuilder } from '@/models/Communication/ContactBuilder'
import { Attachment } from '@/GeneratedTypes/Communication/Attachment'
import { DivisionTeam } from '@/GeneratedTypes/DivisionTeam'
import { LeagueProgramInfo } from 'src/GeneratedTypes/ListInfo/LeagueProgramInfo'

import authorizationClient from '@/clients/authorizationClient'
import communicationsClient from '@/clients/communicationsClient'
import programClient from '@/clients/programsClient'

import * as programStore from '@/store/programs'
import * as messageStore from '@/store/message'
import * as auth from '@/store/authentication'
import * as authorization from '@/store/authorization'

import { iOSTestMixin } from '@/common/iOSTestMixin'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { RoleUnion } from '@/common/Authorization/RoleUnion'
import { VolunteerRoles, allVolunteerRoles, commissionerGroup } from '@/common/Authorization/VolunteerRoles'
import {
  communicationVolunteerRoles,
  bulkEmailerGroup,
  forbidBulkEmailerGroup,
  denyMessaging,
  localSMSClientGroup,
} from '@/common/Authorization/PermissionGroups'
import { allowFirstRanking, denyIfAnyOnly, allowIfAnyOnly } from '@/plugins/Authorization/permissionChecks'

type EditableInput = HTMLTextAreaElement | HTMLInputElement

@Component({
  components: {
    MessageContent,
    MessageRecipient,
    MessageFilters,
    MessagePreview,
    VeeValidateForm,
    Loading,
    ParentRecipientPicker,
    CoachRecipientPicker,
    InputLabel,
    InputlessStringValidator,
    CopyRecipientsToClipboard,
  },
})
export default class Message extends Mixins(iOSTestMixin) {
  @Ref('form') readonly form!: HTMLFormElement

  @Getter(programStore.getterNames.currentProgramId, { namespace: programStore.namespace })
  private currentProgramId!: string

  @Getter(messageStore.getterNames.previewMessage, { namespace: messageStore.namespace })
  private message!: PreviewMessage

  @Getter(auth.getterNames.email, { namespace: auth.namespace })
  private loggedInUserEmail!: string | null

  @Getter(auth.getterNames.fullName, { namespace: auth.namespace })
  private loggedInUserFullName!: string | null

  @Getter(authorization.getterNames.currentRole, { namespace: authorization.namespace })
  private readonly currentRole!: RoleUnion

  @Action(messageStore.actionNames.retrievePreviewMessage, { namespace: messageStore.namespace })
  private retrievePreviewMessage!: ({
    previewMessage,
  }: {
    previewMessage: PreviewMessage
  }) => Promise<PreviewMessage>

  private readonly viewStates = ViewStates
  private currentState = ViewStates.EDITING

  private leagueSettingsLoading = false
  private contactBuilderLoading = false
  private previewLoading = false
  private sendMessageLoading = false
  private clipboardFull = false
  private loadingClipboardData = false

  private contentMessagePreview = getEmptyPreviewMessage()
  private recipientMessagePreview = getEmptyPreviewMessage()
  private filterOptions = getInitialFilterOptions()
  private contactBuilder = getEmptyContactBuilder()
  private attachment: Attachment = getEmptyAttachment()
  private superUsers = superUsers
  private allVolunteerRoles = allVolunteerRoles
  private commissionerGroup = commissionerGroup
  private denyIfAnyOnly = denyIfAnyOnly
  private allowIfAnyOnly = allowIfAnyOnly
  private volunteerRoles = VolunteerRoles
  private denyMessaging = denyMessaging
  private bulkEmailerGroup = bulkEmailerGroup
  private forbidBulkEmailerGroup = forbidBulkEmailerGroup
  private recipientCount = 0
  private parentRecipients: string[] = []
  private coachRecipients: string[] = []
  private copyToClipboardString = ''
  private coachOnlyRoleAccessRanking = allowFirstRanking
  private programs: LeagueProgramInfo[] | null = [] as LeagueProgramInfo[]

  private async mounted() {
    this.loadData()
  }

  get messageType() {
    return this.$route.params.channel
  }

  private async loadData() {
    await this.retrievePrograms()
    await Promise.all([this.loadLeagueSettings(), this.loadContactBuilder()])
  }

  private async retrievePrograms() {
    if (!this.currentProgramId) return
    const result = await programClient.retrieve(this.currentProgramId)
    if (result?.myLeagueInfo?.programs) {
      this.programs = result.myLeagueInfo?.programs
    }
  }

  private get programTypeID(): string {
    if (!this.programs || this.recipientMessagePreview.typeProgramID == 'ALL') return ''
    const cheer: string = ProgramList[2].value
    if (this.recipientMessagePreview.typeProgramID == cheer) {
      const cheerProgram = this.programs.find((p) => p.typeProgramID?.includes(cheer))
      return cheerProgram?.typeProgramID ?? ''
    }

    //this.program will be SPORT
    const sportProgram = this.programs.find((p) => !p.typeProgramID?.includes(cheer))
    return sportProgram?.typeProgramID ?? ''
  }

  private resetAll() {
    this.contentMessagePreview = getEmptyPreviewMessage()
    this.recipientMessagePreview = getEmptyPreviewMessage()
    this.parentRecipients = []
    this.loadData()
  }

  private clipboardCopied() {
    this.clipboardFull = true
  }

  private get smsViaAPIRule() {
    const isSMS = this.messageType === 'SMS'
    return {
      allowIfAnyRoles: isSMS ? [...superUsers, VolunteerRoles.CHEER_DIRECTOR] : null,
      denyIfAnyRoles: isSMS ? localSMSClientGroup : null,
    }
  }

  private get smsViaLocalClientRule() {
    const isSMS = this.messageType === 'SMS'
    return {
      allowIfAnyRoles: isSMS
        ? [VolunteerRoles.COACH, VolunteerRoles.COACH_COMMISSIONER, VolunteerRoles.REFEREE_COMMISSIONER]
        : null,
      denyIfAnyRoles: isSMS ? [...superUsers, VolunteerRoles.CHEER_DIRECTOR] : null,
    }
  }

  private get showPreview(): boolean {
    // show preview button for all emails and SuperUser SMS
    if (this.messageType === 'Email') return true
    if (
      this.messageType === 'SMS' &&
      this.$allowIfAny(this.superUsers) &&
      !this.$denyIfAny([
        this.volunteerRoles.COACH,
        this.volunteerRoles.COACH_COMMISSIONER,
        this.volunteerRoles.REFEREE_COMMISSIONER,
      ])
    ) {
      return true
    }
    return false
  }
  private populateMessageObject(preview: PreviewMessage) {
    const message = new EmailMessageImpl()
    message.accountNumber = authorizationClient.getFirstAccount()
    message.upwardLeagueID = this.currentProgramId
    message.displayName = preview.displayName
    message.replyTo = preview.replyTo
    message.emailSubject = preview.emailSubject
    message.messageBody = preview.messageBody
    message.to = preview.to
    if (!this.emptyAttachment(this.attachment)) {
      message.attachments = [this.attachment]
    } else {
      message.attachments = null
    }
    return message
  }

  private emptyAttachment(a: Attachment) {
    return !a || (!a.data && !a.name && !a.type)
  }

  private async formIsValid() {
    const isValid = await this.form.reportValidity()
    if (!isValid) {
      this.moveTo('page_top')
    }
    return isValid
  }

  private async sendMessage() {
    if (await this.formIsValid()) {
      try {
        await this.postMessage()
      } finally {
        this.currentState = this.viewStates.CONFIRMING
      }
    }
  }

  private get smsPhoneListForCoaches() {
    let list = 'sms://'
    if (this.isIosOnBrowser()) {
      list = `${list}open?addresses=`
    }
    const body = encodeURIComponent(this.contentMessagePreview.messageBody)
    const parentRecipients = this.parentRecipients.map((r) => '+1' + r)
    const coachRecipients = this.coachRecipients.map((r) => '+1' + r)
    const recipients = [...parentRecipients, ...coachRecipients]
    list = `${list}${recipients}`
    list = `${list};?&body=${body}`

    return list
  }

  private async loadSMSRecipients() {
    await this.loadMessagePreview()
  }

  private get smsPhoneListForCommissioners() {
    //this.loadSMSRecipients() //this causes a spamming of the API.  Commenting out for now to just stop it.  Needs to be reviewed for downstream bugs.  Was added as part of PR 3119.

    if (!this.message) return
    let list = 'sms://'
    if (this.isIosOnBrowser()) {
      list = `${list}open?addresses=`
    }
    const body = encodeURIComponent(this.contentMessagePreview.messageBody)
    const commRecipients = this.message.to.map((r) => '+1' + r)
    const recipients = [...commRecipients]
    list = `${list}${recipients}`
    list = `${list};?&body=${body}`

    return list
  }

  private async sendMessageFromPreview() {
    try {
      await this.postMessage()
    } finally {
      this.currentState = this.viewStates.CONFIRMING
    }
  }

  private nextMessage() {
    this.currentState = this.viewStates.EDITING
    this.resetAll()
  }

  private async postMessage() {
    this.sendMessageLoading = true
    try {
      await this.loadMessagePreview()
      if (!this.message) return
      this.recipientCount = this.message.to.length
      const messageObject = this.populateMessageObject(this.message)
      const type = this.messageType === 'Email' ? 'sendemail' : 'sendsms'
      await communicationsClient.sendMessage(messageObject, type)
    } finally {
      this.sendMessageLoading = false
    }
  }

  private async onPreview() {
    if (await this.formIsValid()) {
      try {
        this.previewLoading = true
        await this.loadMessagePreview()
        this.currentState = this.viewStates.PREVIEWING
        this.moveTo('page_top')
      } finally {
        this.previewLoading = false
      }
    }
  }

  private moveTo(id: string) {
    Vue.nextTick(() => {
      const elem = document.getElementById(id)
      if (elem) elem.scrollIntoView()
    })
  }

  private backToEditing() {
    this.currentState = this.viewStates.EDITING
  }

  private async loadMessagePreview() {
    let recipients = getEmptyPreviewMessage()
    if (this.$allowIfAny([this.volunteerRoles.COACH])) {
      recipients.individualContactInfo = [...this.parentRecipients, ...this.coachRecipients]
    } else {
      recipients = cloneDeep(this.recipientMessagePreview)
    }

    const message = this.mergeMessagePreview(this.contentMessagePreview, recipients, this.filterOptions)

    await this.retrievePreviewMessage({ previewMessage: message })
  }

  private mergeMessagePreview(
    contentMessagePreview: PreviewMessage,
    recipientMessagePreview: PreviewMessage,
    filterOptions: FilterOptions
  ) {
    const previewMessage = getEmptyPreviewMessage()
    previewMessage.displayName = contentMessagePreview.displayName
    previewMessage.replyTo = contentMessagePreview.replyTo
    previewMessage.emailSubject = contentMessagePreview.emailSubject
    previewMessage.messageBody = contentMessagePreview.messageBody

    previewMessage.paymentStatus = recipientMessagePreview.paymentStatus
    previewMessage.evalStatus = recipientMessagePreview.evalStatus
    previewMessage.coachTeamStatus = recipientMessagePreview.coachTeamStatus
    previewMessage.approvalStatus = recipientMessagePreview.approvalStatus
    previewMessage.headCoachOnly = recipientMessagePreview.headCoachOnly
    previewMessage.selectedGroups = recipientMessagePreview.selectedGroups
    previewMessage.individualContactInfo = recipientMessagePreview.individualContactInfo
    previewMessage.typeProgramID = this.programTypeID

    previewMessage.startGrade = filterOptions.grades[0]
    previewMessage.endGrade = filterOptions.grades[filterOptions.grades.length - 1]
    previewMessage.practiceNight = filterOptions.practiceNight
    previewMessage.teams = this.teamIdArray(this.filterOptions.teams)

    previewMessage.upwardLeagueID = this.currentProgramId
    previewMessage.accountNumber = authorizationClient.getFirstAccount()
    previewMessage.communicationType = this.messageType.toLowerCase()

    return previewMessage
  }

  private teamIdArray(teamFilters: DivisionTeam[] | null) {
    if (!teamFilters) return []
    return teamFilters.reduce((teams: number[], team: DivisionTeam) => {
      if (team.teamID) {
        teams.push(team.teamID)
      }
      return teams
    }, [])
  }
  private async loadLeagueSettings() {
    const account = authorizationClient.getFirstAccount()
    const upwID = this.currentProgramId
    if (!upwID || !account) return

    this.leagueSettingsLoading = true
    try {
      const leagueSetting = await communicationsClient.retrieveLeagueSettings(upwID, account)
      if (leagueSetting) {
        this.contentMessagePreview.displayName = this.useLeagueReplySetting
          ? leagueSetting.emailDisplayName ?? ''
          : this.loggedInUserFullName ?? ''

        this.contentMessagePreview.replyTo = this.useLeagueReplySetting
          ? leagueSetting.emailReplyTo
          : this.loggedInUserEmail ?? ''
      }
    } finally {
      this.leagueSettingsLoading = false
    }
  }

  get useLeagueReplySetting(): boolean {
    if (this.$hasAtLeastOne(communicationVolunteerRoles) && !this.$hasAtLeastOne(superUsers)) {
      // This is a volunteer with communication permissions (not a super user who selected the coach role)
      return false
    } else if (this.$hasAtLeastOne(superUsers)) {
      // this is a super user
      return true
    } else {
      // user should not have access to communication
      throw Error('No permissions for this action')
    }
  }

  private get coachAndParentRecipientString() {
    this.clipboardFull = false
    const recipients = [...this.coachRecipients, ...this.parentRecipients]
    return recipients.join()
  }

  private async copyToClipboard() {
    this.loadingClipboardData = true
    if (this.$currentRoleIsOneOf(this.bulkEmailerGroup)) {
      //Bulk Emailers use groups so need to make API call to get phone numbers.
      await this.loadMessagePreview()
      //Safari requires a pure user gesture for clipboard API, so api call can't be in clipboard event.
      //therefore show data to user, then copy
      this.currentState = this.viewStates.CLIPBOARDING
    } else {
      //Emailing people individually. No additional API call needed. Just send to clipboard.
      //This will only work over https and localhost
      if (!navigator.clipboard)
        throw new Error('Cannot copy to clipboard. This is probably because you are not using https')
      await navigator.clipboard.writeText(this.coachAndParentRecipientString)
      this.clipboardFull = true
    }
    this.loadingClipboardData = false
  }

  get copyToClipboardClass() {
    if (!this.clipboardFull) return 'far fa-copy'
    return 'fas fa-copy'
  }

  private async loadContactBuilder() {
    const account = authorizationClient.getFirstAccount()
    const upwID = this.currentProgramId
    if (!upwID || !account) return

    if (this.$hasAtLeastOne([VolunteerRoles.CHEER_DIRECTOR])) {
      //Cheer Director can only email cheer coaches and parents
      this.recipientMessagePreview.typeProgramID = 'CHEER'
    }

    if (this.$hasAtLeastOne([VolunteerRoles.COACH_COMMISSIONER])) {
      //Coach Commissioner can only email sport coaches
      this.recipientMessagePreview.typeProgramID = 'PLAYER'
    }

    if (this.$hasAtLeastOne([VolunteerRoles.REFEREE_COMMISSIONER])) {
      //Referee Commissioner can only email referees
      this.recipientMessagePreview.typeProgramID = 'REFEREE'
    }

    this.contactBuilderLoading = true
    try {
      const results = await communicationsClient.getContactBuilder(
        upwID,
        account,
        this.recipientMessagePreview.paymentStatus ? this.recipientMessagePreview.paymentStatus : '',
        this.recipientMessagePreview.evalStatus ? this.recipientMessagePreview.evalStatus : '',
        this.recipientMessagePreview.coachTeamStatus ? this.recipientMessagePreview.coachTeamStatus : '',
        this.programTypeID,
        this.recipientMessagePreview.approvalStatus,
        this.recipientMessagePreview.headCoachOnly
      )
      if (results) {
        this.contactBuilder = results
      }
    } finally {
      this.contactBuilderLoading = false
    }
  }

  get hasCheer(): boolean {
    if (!this.programs) return false
    return this.programs.length > 1
  }
  @Watch('messageType')
  private async currentMessageType() {
    this.currentState = this.viewStates.EDITING
    this.resetAll()
  }

  @Watch('recipientMessagePreview.typeProgramID', { immediate: true })
  private typeProgramIDChanged() {
    this.filterOptions = getInitialFilterOptions()
  }
  @Watch('recipientMessagePreview', { immediate: true })
  private recipientsChanged() {
    //used for CoachComm to copy phonenumbers to clipboard
    if (this.messageType == 'SMS') {
      this.clipboardFull = false
    }
  }
}
