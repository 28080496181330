import { CheerSquadRecognition } from '@/GeneratedTypes/CheerSquadRecognition'

export function getEmptyCheerSquadRecognition(): CheerSquadRecognition {
  return {
    leagueID: 0,
    typeProgramID: '',
    divisionID: 0,
    teamID: 0,
    playerIndividualID: 0,
    cheerSquadGameAppointmentID: 0,
    typePlayerRecognitionID: null,
    note: null,
  }
}
