import { DivisionGamePlayerInfo } from '@/GeneratedTypes/ListInfo/DivisionGamePlayerInfo'

export function getEmptyDivisionGamePlayerInfo(): DivisionGamePlayerInfo {
  return {
    leagueID: 0,
    typeProgramID: '',
    divisionID: 0,
    gameID: 0,
    teamID: 0,
    playerIndividualID: 0,
    typePlayerRecognitionID: '',
    recognitionNote: '',
    isPlaying: true,
    playedQuarter1: false,
    playedQuarter2: false,
    playedQuarter3: false,
    playedQuarter4: false,
    foulCount: 0,
    typePositionID: '',
    rosterRanking: '',
    coachAssignedJerseyNumber: '',
  }
}
