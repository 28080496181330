
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Loading from '@/elements/Loading.vue'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import * as messageStore from '@/store/message'
@Component({
  components: {
    Loading,
  },
})
export default class MessagePreview extends Vue {
  @Getter(messageStore.getterNames.previewMessage, { namespace: messageStore.namespace })
  private message!: PreviewMessage

  private get phoneNumberString() {
    return this.phoneNumbers.join()
  }

  private get phoneNumbers() {
    return [...this.message.to, ...this.message.individualContactInfo]
  }

  private async copy() {
    if (!navigator.clipboard)
      throw new Error('Cannot copy to clipboard. This is probably because you are not using https.')

    await navigator.clipboard.writeText(this.phoneNumberString)
    this.$emit('copied')
    this.close()
  }

  private close() {
    this.$emit('close')
  }
}
