import restService from '@/services/restService'
import { EmailSMSAccount } from '@/GeneratedTypes/Communication/EmailSMSAccount'
import { EmailSMSLeagueSetting } from '@/GeneratedTypes/Communication/EmailSMSLeagueSetting'
import { EmailSMSLeagueMessageInfo } from '@/GeneratedTypes/Communication/EmailSMSLeagueMessage'
import { EmailMessage } from '@/GeneratedTypes/Communication/EmailMessage'
import { SMSMessage } from '@/GeneratedTypes/Communication/SMSMessage'
import { ContactBuilder } from '@/models/Communication/ContactBuilder'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'

const baseUrl = 'communications/'

enum MessageTypes {
  Email,
  Sms,
}

enum PreviewMessageTypes {
  Email = 'email',
  Sms = 'sms',
}

const retrieveProviderInfo = async (upwID: string, accountNumber: string) => {
  const results = await restService.get<EmailSMSAccount>(
    `${baseUrl}management/providerInfo/${accountNumber}/${upwID}`
  )

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in communicationsClient.retrieveProviderInfo')
  }
}

const retrieveMessagePreview = async (preview: PreviewMessage) => {
  const results = await restService.post<PreviewMessage>(`${baseUrl}messagepreview`, preview)

  if (!results.isSuccess) {
    throw new Error(`Error in retrieveMessagePreview`)
  }

  return results.data
}

const retrieveLeagueSettings = async (upwID: string, account: string) => {
  const providerInfo = await retrieveProviderInfo(upwID, account)
  if (providerInfo) {
    const results = await restService.get<EmailSMSLeagueSetting>(
      `${baseUrl}management/leagueSettings/${providerInfo.emailSmsAccountID}/${upwID}`
    )
    if (results.isSuccess) {
      return results.data
    } else {
      throw new Error('Error in communicationsClient.retrieveLeagueSettings')
    }
  } else {
    throw new Error('Cannot retreive providerInfo in communicationClient.retrieveLeagueSettings')
  }
}

const updateLeagueSettings = async (setting: EmailSMSLeagueSetting) => {
  const results = await restService.put<EmailSMSLeagueSetting>(`${baseUrl}management/leagueSettings`, setting)
  if (!results.isSuccess) {
    throw new Error('Error in communicationsClient.updateLeagueSettings')
  }
}

const retrieveMessages = async (
  upwID: string,
  emailSMSAccountID: number,
  messageType: MessageTypes,
  showArchived = false
) => {
  const messages = messageType == MessageTypes.Email ? 'emailmessages' : 'smsmessages'

  const results = await restService.get<EmailSMSLeagueMessageInfo[]>(
    `${baseUrl}${messages}/${emailSMSAccountID}/${upwID}?excludeArchive=${!showArchived}`
  )

  if (!results.isSuccess) {
    throw new Error('Error in communicationsClient.retrieveEmailMessages')
  }

  return results.data
}

const retrieveMessage = async (emailSmsMessageID: number, messageType: MessageTypes) => {
  const messages = messageType == MessageTypes.Email ? 'emailmessages' : 'smsmessages'

  const results = await restService.get<EmailSMSLeagueMessageInfo>(
    `${baseUrl}${messages}/${emailSmsMessageID}`
  )

  if (!results.isSuccess) {
    throw new Error('Error in communicationsClient.retrieveEmailMessages')
  }

  return results.data
}

const sendEmailMessage = async (message: EmailMessage) => {
  // REMOVE
  const results = await restService.post<EmailMessage>(`${baseUrl}sendemail`, message)

  if (!results.isSuccess) {
    throw new Error(`Error sending email message`)
  }

  return results.data
}

const sendSMSMessage = async (message: SMSMessage) => {
  // REMOVE
  const results = await restService.post<SMSMessage>(`${baseUrl}sendsms`, message)

  if (!results.isSuccess) {
    throw new Error(`Error sending sms message`)
  }

  return results.data
}

const sendMessage = async (message: EmailMessage, type: string) => {
  const results = await restService.post<EmailMessage | SMSMessage>(`${baseUrl}${type}`, message)

  if (!results.isSuccess) {
    throw new Error(`Error sending message`)
  }

  return results.data
}

const getContactBuilder = async (
  upwID: string,
  accountNumber: string,
  playerPaymentStatus = '',
  playerEvalStatus = '',
  coachTeamStatus = '',
  programTypeID = '',
  coachApprovalStatus = '',
  headCoachOnly = false
) => {
  const results = await restService.get<ContactBuilder>(
    `${baseUrl}contactbuilder/${accountNumber}/${upwID}/${programTypeID}`,
    {
      playerPaymentStatus,
      playerEvalStatus,
      coachTeamStatus,
      approvalStatus: coachApprovalStatus,
      headCoachOnly,
    }
  )

  if (!results.isSuccess) {
    throw new Error('Error retrieving contact builder: communicationsClient getContactBuilder')
  }

  return results.data
}

export default {
  MessageTypes,
  PreviewMessageTypes,
  retrieveProviderInfo,
  retrieveLeagueSettings,
  updateLeagueSettings,
  retrieveMessages,
  retrieveMessage,
  sendEmailMessage,
  sendMessage,
  sendSMSMessage,
  getContactBuilder,
  retrieveMessagePreview,
}
