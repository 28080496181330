import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import communicationsClient from '@/clients/communicationsClient'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import { getEmptyPreviewMessage } from '@/modelHelpers/PreviewMessage'
import { ActionsImpl } from 'direct-vuex'

interface MessageState {
  previewMessage: PreviewMessage
}

const MessageState: MessageState = {
  previewMessage: getEmptyPreviewMessage(),
}

export enum mutationNames {
  setPreviewMessage = 'setPreviewMessage',
}

const mutations: MutationTree<MessageState> = {
  [mutationNames.setPreviewMessage](state, { previewMessage }: { previewMessage: PreviewMessage }) {
    state.previewMessage = previewMessage
  },
}

export enum getterNames {
  previewMessage = 'previewMessage',
}

const getterTree: GetterTree<MessageState, RootState> = {
  [getterNames.previewMessage]: (state) => state.previewMessage,
}

export enum actionNames {
  retrievePreviewMessage = 'retrievePreviewMessage',
}

const actions: ActionTree<MessageState, RootState> & ActionsImpl = {
  async [actionNames.retrievePreviewMessage](
    { commit },
    { previewMessage }: { previewMessage: PreviewMessage }
  ): Promise<PreviewMessage | null> {
    const result = await communicationsClient.retrieveMessagePreview(previewMessage)
    if (result) {
      commit(mutationNames.setPreviewMessage, { previewMessage: result })
    }

    return result
  },
}

export const namespace = 'message'

export const message = {
  namespaced: true as true,
  state: MessageState,
  getters: getterTree,
  mutations,
  actions,
}
