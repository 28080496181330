import { RoleUnion, RoleUnionTypes } from '@/common/Authorization/RoleUnion'
import store from '@/store'

export enum VolunteerRoles {
  COACH = 'Coach',
  COACH_COMMISSIONER = 'CoachCommissioner',
  CHEER_DIRECTOR = 'CheerDirector',
  REFEREE = 'Referee',
  REFEREE_COMMISSIONER = 'RefereeCommissioner',
  HOST_COMMISSIONER = 'HostCommissioner',
  PRAYER_COMMISSIONER = 'PrayerCommissioner',
  ADVERTISING_COMMISSIONER = 'AdCommissioner',
  HALFTIME_COMMISSIONER = 'GamedayCommissioner',
  MINISTRY_COORDINATOR = 'MinistryCoordinator',
}

export const commissionerGroup = [
  VolunteerRoles.COACH_COMMISSIONER,
  VolunteerRoles.REFEREE_COMMISSIONER,
  VolunteerRoles.HOST_COMMISSIONER,
  VolunteerRoles.PRAYER_COMMISSIONER,
  VolunteerRoles.ADVERTISING_COMMISSIONER,
  VolunteerRoles.HALFTIME_COMMISSIONER,
  VolunteerRoles.MINISTRY_COORDINATOR,
]
interface VolunteerRoleMap {
  role: VolunteerRoles
  description: string
}

class refereeRoleMap implements VolunteerRoleMap {
  public get role() {
    return VolunteerRoles.REFEREE
  }

  public get description() {
    return store.state.programs.currentProgram?.refLabel ?? 'Referee'
  }
}

class refereeCommisionerRoleMap implements VolunteerRoleMap {
  public get role() {
    return VolunteerRoles.REFEREE_COMMISSIONER
  }

  public get description() {
    return `${store.state.programs.currentProgram?.refLabel ?? 'Referee'} Commissioner`
  }
}

//this really should be data driven
export const BuiltInVolunteerRoles: VolunteerRoleMap[] = [
  { role: VolunteerRoles.COACH, description: 'Coach' },
  { role: VolunteerRoles.COACH_COMMISSIONER, description: 'Coach Commissioner' },
  { role: VolunteerRoles.CHEER_DIRECTOR, description: 'Cheer Director' },
  new refereeRoleMap(),
  new refereeCommisionerRoleMap(),
  { role: VolunteerRoles.HOST_COMMISSIONER, description: 'Host Commissioner' },
  { role: VolunteerRoles.PRAYER_COMMISSIONER, description: 'Prayer Commissioner' },
  { role: VolunteerRoles.ADVERTISING_COMMISSIONER, description: 'Ad Commissioner' },
  { role: VolunteerRoles.HALFTIME_COMMISSIONER, description: 'Gameday Commissioner' },
  { role: VolunteerRoles.MINISTRY_COORDINATOR, description: 'Ministry Coordinator' },
]

export const coachGroup = [VolunteerRoles.COACH]

export const allVolunteerRoles: string[] = Object.values(VolunteerRoles)

export function allVolunteerRolesAsRoleUnion(hasCheer: boolean): RoleUnion[] {
  const roles: RoleUnion[] = []
  allVolunteerRoles.forEach((volRole: string) => {
    //If this league doesn't have a cheer program, don't add the cheer director
    if (!hasCheer && volRole == VolunteerRoles.CHEER_DIRECTOR) return

    //add role
    roles.push({
      roleId: volRole,
      roleType: RoleUnionTypes.VOLUNTEER,
      roleDescription: BuiltInVolunteerRoles.find((x) => x.role == volRole)?.description ?? volRole,
    })
  })
  return roles
}
