
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Loading from '@/elements/Loading.vue'
import * as DOMPurify from 'dompurify'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import * as messageStore from '@/store/message'
@Component({
  components: {
    Loading,
  },
})
export default class MessagePreview extends Vue {
  @Getter(messageStore.getterNames.previewMessage, { namespace: messageStore.namespace })
  private message!: PreviewMessage

  @Prop({ type: Boolean, default: false, required: false })
  private readonly sendingMessageInProgress!: boolean

  @Prop({ type: String, default: 'Email', required: false })
  private readonly messageType!: string

  @Prop({ type: String, default: '', required: false })
  private readonly attachmentFileName!: string

  private get sanitizedMessage() {
    const msg = this.message.messageBody ? this.message.messageBody : ''
    return DOMPurify.sanitize(msg, {
      ALLOWED_TAGS: [
        'address',
        'article',
        'aside',
        'footer',
        'header',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'hgroup',
        'main',
        'nav',
        'section',
        'blockquote',
        'dd',
        'div',
        'dl',
        'dt',
        'figcaption',
        'figure',
        'hr',
        'li',
        'main',
        'ol',
        'p',
        'pre',
        'ul',
        'a',
        'abbr',
        'b',
        'bdi',
        'bdo',
        'br',
        'cite',
        'code',
        'data',
        'dfn',
        'em',
        'i',
        'kbd',
        'mark',
        'q',
        'rb',
        'rp',
        'rt',
        'rtc',
        'ruby',
        's',
        'samp',
        'small',
        'span',
        'strong',
        'sub',
        'sup',
        'time',
        'u',
        'var',
        'wbr',
        'caption',
        'col',
        'colgroup',
        'table',
        'tbody',
        'td',
        'tfoot',
        'th',
        'thead',
        'tr',
      ],
    })
  }

  private send() {
    this.$emit('send')
  }

  private close() {
    this.$emit('close')
  }

  private get recipientCount() {
    return this.message.to.length
  }

  private get summaryMessage() {
    if (!this.message) return ''
    const plural = this.recipientCount === 1 ? '' : 's'

    return ` You will send the following <span class="font-weight-bold">${this.messageType}
    </span> to <span class="font-weight-bold">${this.recipientCount} </span>recipient${plural}.`
  }
}
