
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { getEmptyDivisionGameInfo } from '@/modelHelpers/DivisionGameInfo'
import LabelInput from '@/elements/InputLabel.vue'
import JerseyIcon from '@/components/JerseyIcon.vue'
import DateCard from '@/components/DateCard.vue'
import moment from 'moment'
import * as teamStore from '@/store/team'
import * as programStore from '@/store/programs'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { DisplayableGame } from '@/modelHelpers/DisplayableGame'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'

@Component({
  components: {
    LabelInput,
    JerseyIcon,
    DateCard,
  },
})
export default class Game extends Vue {
  @Prop({ type: Object, default: getEmptyDivisionGameInfo, required: false })
  private readonly game!: DisplayableGame

  @Prop({ type: Boolean, default: false, required: false })
  private readonly isCheer!: boolean

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  private currentTeam!: DivisionTeamInfoExt

  private get currentTeamId() {
    if (!this.$route.params.teamId) return null
    return this.$route.params.teamId
  }

  private get isHomeGame() {
    return this.game.homeTeamId === this.currentTeam.teamID
  }

  private get hexColor(): string {
    if (this.currentProgram.jerseyOptionSelected) {
      if (this.isHomeGame) return this.currentProgram.primaryColorHex ?? ''
      return this.currentProgram.secondaryColorHex ?? ''
    }
    return '#666'
  }

  private time(dt: string) {
    return moment(dt).format('h:mmA')
  }
}
