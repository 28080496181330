import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'
import { DivisionTeam } from '@/GeneratedTypes/DivisionTeam'

export interface SelectionGroups {
  Coaches: number
  Commissioners: number
  'Other Volunteers': number
  'Parents/Guardians': number
  Referees: number
}

export interface PracticeNights {
  typeDayID: string
}

export interface ContactBuilder {
  selectionGroups: SelectionGroups
  divisions: LeagueDivision[]
  divisionTeams: DivisionTeam[]
  grades: any
  practiceNights: PracticeNights
}

export function getEmptyContactBuilder(): ContactBuilder {
  return {
    selectionGroups: {
      Coaches: 0,
      Commissioners: 0,
      'Other Volunteers': 0,
      'Parents/Guardians': 0,
      Referees: 0,
    },
    divisions: [],
    divisionTeams: [],
    grades: {},
    practiceNights: { typeDayID: '' },
  }
}
