export enum AccountRoles {
  SUPER_ADMIN = 'SuperAdmin',
  DIRECTOR = 'Director',
  PARTNER_SUPPORT = 'PartnerSupport',
  SYS_AND_SUPPORT = 'SystemsAndSupport',
  UPWARD_STAFF = 'UpwardStaff',
}

export const superUsers: string[] = [
  AccountRoles.PARTNER_SUPPORT,
  AccountRoles.DIRECTOR,
  AccountRoles.SUPER_ADMIN,
  AccountRoles.SYS_AND_SUPPORT,
  AccountRoles.UPWARD_STAFF,
]

interface AccountRoleMap {
  role: AccountRoles
  description: string
}

//this really should be data driven
export const BuiltInAccountRoles: AccountRoleMap[] = [
  { role: AccountRoles.PARTNER_SUPPORT, description: 'Partner Support' },
  { role: AccountRoles.DIRECTOR, description: 'Director' },
  { role: AccountRoles.SUPER_ADMIN, description: 'Super Admin' },
  { role: AccountRoles.SYS_AND_SUPPORT, description: 'Systems And Support' },
  { role: AccountRoles.UPWARD_STAFF, description: 'Upward Staff' },
]

export const adminUsers: string[] = [
  AccountRoles.SUPER_ADMIN,
  AccountRoles.SYS_AND_SUPPORT,
  AccountRoles.UPWARD_STAFF,
]

export const allRoles: string[] = Object.values(AccountRoles)

export function convertSuperUserToDirector(roleId: string) {
  if (superUsers.includes(roleId)) {
    return AccountRoles.DIRECTOR
  }
  return roleId
}
