
import { Component, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import Loading from '@/elements/Loading.vue'
import { getCrumbs, CrumbsEnum, Crumb, CrumbBuilder } from '@/common/Crumbs'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import GameDayPlayer from '@/components/GameDayPlayer.vue'
import * as teamStore from '@/store/team'
import * as gameStore from '@/store/game'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import { cloneDeep } from 'lodash'
import { IsCheerMixin } from '@/common/IsCheerMixin'

@Component({
  components: {
    Loading,
    Breadcrumbs,
    GameDayPlayer,
  },
})
export default class CompleteGame extends Mixins(IsCheerMixin) {
  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  currentTeam!: DivisionTeamInfoExt

  @Getter(teamStore.getterNames.hasScheduledGames, { namespace: teamStore.namespace })
  private readonly hasScheduledGames!: boolean

  @Getter(teamStore.getterNames.hasScheduledAppointments, { namespace: teamStore.namespace })
  private readonly hasScheduledAppointments!: boolean

  @Action(gameStore.actionNames.fetchCurrentCheerGameRecognitions, { namespace: gameStore.namespace })
  private fetchCurrentCheerGameRecognitions!: ({
    upwId,
    programType,
    divisionId,
    teamId,
    aptId,
  }: {
    upwId: string | null
    programType: string | null
    divisionId: number
    teamId: number
    aptId: number
  }) => Promise<boolean>

  @Action(gameStore.actionNames.fetchCurrentGameRecognitions, { namespace: gameStore.namespace })
  private fetchCurrentGameRecognitions!: ({
    upwId,
    gameId,
    teamId,
  }: {
    upwId: string | null
    gameId: number
    teamId: number
  }) => Promise<boolean>

  private loading = false
  private breadcrumbs: Crumb[] = []

  private async mounted() {
    this.breadcrumbs = getCrumbs(this.makeBreadcrumbs())

    this.loading = true
    try {
      await this.loadGameRecognitions()
    } finally {
      this.loading = false
    }
  }

  private get enableRecognitions(): boolean {
    if (this.isCheer) return this.hasScheduledAppointments
    return this.hasScheduledGames
  }

  private async loadGameRecognitions() {
    if (!this.enableRecognitions) return
    if (!this.isCheer) {
      await this.fetchCurrentGameRecognitions({
        upwId: this.currentTeam.upwardLeagueID,
        gameId: this.gameId,
        teamId: this.currentTeam.teamID,
      })
    } else {
      await this.fetchCurrentCheerGameRecognitions({
        upwId: this.currentTeam.upwardLeagueID,
        programType: this.currentTeam.typeProgramID,
        divisionId: this.currentTeam.divisionID,
        teamId: this.currentTeam.teamID,
        aptId: this.gameId,
      })
    }
  }

  private get gameId(): number {
    return Number(this.$route.params.gameId)
  }

  private get players() {
    return this.currentTeam.players || ([] as TeamPlayerInfo[])
  }

  private get sortedPlayers() {
    const players: TeamPlayerInfo[] = cloneDeep(this.players)
    return players.sort((a, b) => {
      if (!a.formattedNameFirstLast || !b.formattedNameFirstLast) return 0
      if (a.formattedNameFirstLast < b.formattedNameFirstLast) return -1
      if (a.formattedNameFirstLast > b.formattedNameFirstLast) return 1
      return 0
    })
  }

  private makeBreadcrumbs(): CrumbBuilder[] {
    const crumbs = [] as CrumbBuilder[]
    crumbs.push({ crumb: CrumbsEnum.HOME, isActive: true })

    if (this.isCheer || this.isSelect) {
      crumbs.push({ crumb: CrumbsEnum.GAMES_STAR_DIST, isActive: true })
    } else {
      crumbs.push({ crumb: CrumbsEnum.GAMES_GAMEDAY, isActive: true })
      crumbs.push({ crumb: CrumbsEnum.GAME, isActive: true, params: { gameId: this.gameId } })
    }

    crumbs.push({ crumb: CrumbsEnum.COMPLETE_GAME, isActive: false })
    return crumbs
  }
}
