
import { defineComponent } from 'vue'
import store from '@/store'
export default defineComponent({
  name: 'UnrankedWarning',
  props: {},
  components: {},
  setup() {
    function click() {
      store.commit.game.setSkipPlayerRanking({ skipRanking: true })
    }
    return { click }
  },
})
