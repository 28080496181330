
import { Component, Mixins } from 'vue-property-decorator'
import Toaster, { AlertTypeEnum } from '@/components/Toaster.vue'
import PwaInstallInfo from '@/components/PwaInstallInfo.vue'
import { PwaInstallMixin } from '@/common/PwaInstallMixin'

@Component({
  components: {
    Toaster,
    PwaInstallInfo,
  },
})
export default class PwaInstallPrompt extends Mixins(PwaInstallMixin) {
  private alertTypeEnum = AlertTypeEnum

  private mounted() {
    this.installPrompt()
  }
}
