
import { Component, Vue, Prop } from 'vue-property-decorator'
import { LeagueSpecialEventInfo } from '@/GeneratedTypes/ListInfo/LeagueSpecialEventInfo'
import SpecialEvent from '@/components/SpecialEvent.vue'
import Toggle from '@/components/Toggle.vue'
import moment from 'moment'

@Component({
  components: {
    SpecialEvent,
    Toggle,
  },
})
export default class PracticeList extends Vue {
  @Prop({ type: Array, default: [], required: false })
  private readonly specialEvents!: LeagueSpecialEventInfo[]

  @Prop({ type: String, default: '', required: false })
  private readonly title!: string

  private pageStates = ['Upcoming', 'Past']
  private currentToggle: string | null = null

  private get filteredSpecialEvents() {
    return this.specialEvents.filter((evt) => {
      if (moment().isAfter(evt.eventStart)) {
        // past practice
        return this.currentToggle === 'Past'
      } else {
        //upcoming practice
        return this.currentToggle === 'Upcoming'
      }
    })
  }

  private get filteredSpecialEventCount() {
    if (!this.specialEvents) return 0
    return this.filteredSpecialEvents.length
  }
}
