import { RouteConfig } from 'vue-router'
import { superUsers } from '@/common/Authorization/AccountRoles'
import Message from '@/views/Message/Message.vue'
import TeamAlertList from '@/views/TeamAlertList.vue'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'

export default [
  {
    path: 'individual/:channel',
    name: 'Individual',
    meta: {
      guard: {
        allowIfAny: [
          VolunteerRoles.COACH,
          VolunteerRoles.CHEER_DIRECTOR,
          VolunteerRoles.COACH_COMMISSIONER,
          VolunteerRoles.REFEREE_COMMISSIONER,
          ...superUsers,
        ],
      },
    },
    component: Message,
  },
  {
    path: 'team',
    name: 'Team',
    meta: {
      title: 'Team',
      guard: { allowIfAny: [VolunteerRoles.COACH, ...superUsers] },
    },
    component: TeamAlertList,
  },
] as RouteConfig[]
