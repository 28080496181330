import { VueConstructor, ComponentOptions } from 'vue/types'
import VueRouter from 'vue-router/types'
import { AuthorizationOptions, Roles } from './types/types'
import { isPermitted, isDenied, PermissionChecks, defaultRanking } from './permissionChecks'

function AuthorizationPlugin(Vue: VueConstructor, options: AuthorizationOptions = {}): void {
  if (options.router) {
    const errorRoute = options.errorRoute ? options.errorRoute : '/'
    addRouterGuards(options.router, errorRoute)
  }

  Vue.component('role-access', {
    functional: true,
    props: {
      allowIfAny: {
        type: [Array, String],
        default: null,
      },
      allowIfAll: {
        type: [Array, String],
        default: null,
      },
      denyIfAny: {
        type: [Array, String],
        default: null,
      },
      denyIfAll: {
        type: [Array, String],
        default: null,
      },
      ranking: {
        type: Array,
        default: () => defaultRanking,
      },
    },
    render(h, { props, slots, ...rest }) {
      const { $getRoles } = rest.parent
      if (!$getRoles) {
        console.error(`[role-access]`, `role-access must be a descendant of a component with a roles options`)
      }

      const { allowIfAll, allowIfAny, denyIfAny, denyIfAll, ranking } = props
      const userRoles = $getRoles()

      if (show(userRoles, allowIfAll, allowIfAny, denyIfAny, denyIfAll, ranking)) {
        return slots().default
      } else {
        return h()
      }
    },
  })

  Vue.mixin({
    beforeCreate: authorizationInit,
  })

  function authorizationInit(this: Vue) {
    const options = this.$options as ComponentOptions<Vue>
    const rolesFunction = getPropFromSelfOrAcenstor('userRoles', options)
    const currentRoleFunction = getPropFromSelfOrAcenstor('currentRole', options)
    if (!rolesFunction || !currentRoleFunction) {
      return
    }

    Vue.prototype.$getRoles =
      typeof rolesFunction === 'function' ? rolesFunction.bind(this) : () => rolesFunction

    Vue.prototype.$currentRole =
      typeof currentRoleFunction === 'function' ? currentRoleFunction.bind(this) : () => currentRoleFunction

    Vue.prototype.$currentRoleIsOneOf = function currentRolePermission(roles: Roles) {
      const currentRole = Vue.prototype.$currentRole()
      if (Array.isArray(roles)) {
        return roles.filter((r) => r == currentRole.roleId).length > 0
      }

      return roles == currentRole.roleId
    }

    Vue.prototype.$allowIfAll = function permitsAll(roles: Roles) {
      return isPermitted(Vue.prototype.$getRoles(), roles, true)
    }

    Vue.prototype.$hasAllOf = function permitsAll(roles: Roles) {
      return isPermitted(Vue.prototype.$getRoles(), roles, true)
    }

    Vue.prototype.$allowIfAny = function permitsAll(roles: Roles) {
      return isPermitted(Vue.prototype.$getRoles(), roles, false)
    }

    Vue.prototype.$hasAtLeastOne = function permitsAll(roles: Roles) {
      return isPermitted(Vue.prototype.$getRoles(), roles, false)
    }

    Vue.prototype.$denyIfAll = function denyAll(roles: Roles) {
      return isDenied(Vue.prototype.$getRoles(), roles, true)
    }

    Vue.prototype.$denyIfAny = function denyAll(roles: Roles) {
      return isDenied(Vue.prototype.$getRoles(), roles, false)
    }
  }

  function addRouterGuards(router: VueRouter, errorRoute: string) {
    router.beforeResolve((to, from, next) => {
      const guard = to.meta && to.meta.guard
      if (!guard) {
        return next()
      }
      const { $getRoles } = Vue.prototype
      if (!$getRoles) {
        const { errorRoute } = options
        if (errorRoute) {
          return next(errorRoute)
        }
        throw new Error('You need an error route.')
      }
      const usersRoles = $getRoles()
      const { allowIfAll, allowIfAny, denyIfAny, denyIfAll, ranking } = guard
      const rank = ranking || defaultRanking

      if (show(usersRoles, allowIfAll, allowIfAny, denyIfAny, denyIfAll, rank)) {
        return next()
      } else {
        return next(errorRoute)
      }
    })
  }
}

// @ts-ignore
function getPropFromSelfOrAcenstor(prop: string, config: ComponentOptions): Function | null {
  if (config[prop]) {
    return config[prop]
  }
  if (config.parent) {
    return getPropFromSelfOrAcenstor(prop, config.parent)
  }
  return null
}

function show(
  userRoles: string[],
  allowIfAll: string | unknown[] | null,
  allowIfAny: string | unknown[] | null,
  denyIfAny: string | unknown[] | null,
  denyIfAll: string | unknown[] | null,
  ranking: unknown[] //Array of permission checks. Order of the array is the order of precedence
) {
  if (!allowIfAll && !allowIfAny && !denyIfAny && !denyIfAll) {
    return true
  }
  let show: boolean | null = null

  // Iterate over the ranking array. The first time
  // a check returns true, that check will take precedence
  // over all other checks.
  for (let i = 0; i < ranking.length; ++i) {
    const permissionCheck = ranking[i]

    //Deny If Any
    if (permissionCheck === PermissionChecks.DENY_IF_ANY) {
      if (denyIfAny) {
        if (isDenied(userRoles || [], denyIfAny as Roles, false)) {
          show = false
          break
        } else {
          show = true
        }
      }
    }

    // Allow If Any
    if (permissionCheck === PermissionChecks.ALLOW_IF_ANY) {
      if (allowIfAny) {
        if (isPermitted(userRoles || [], allowIfAny as Roles, false)) {
          show = true
          break
        } else {
          show = false
        }
      }
    }

    // Deny If All
    if (permissionCheck === PermissionChecks.DENY_IF_ALL) {
      if (denyIfAll) {
        if (isDenied(userRoles || [], denyIfAll as Roles, true)) {
          show = false
          break
        } else {
          show = true
        }
      }
    }

    //Allow If All
    if (permissionCheck === PermissionChecks.ALLOW_IF_ALL) {
      if (allowIfAll) {
        if (isPermitted(userRoles || [], allowIfAll as Roles, true)) {
          show = true
          break
        } else {
          show = false
        }
      }
    }
  }
  return show
}

export default AuthorizationPlugin
