
import BaseInputWrapper from '@/elements/BaseInputWrapper/BaseInputWrapper.vue'
import uuid from 'uuid'
import { Component, Watch, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    BaseInputWrapper,
  },
})
export default class RadioGroupInput extends Vue {
  private readonly guid = uuid.v4()

  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly required!: boolean

  @Prop({ type: Boolean, default: true, required: false })
  private readonly enabled!: boolean

  @Prop({ type: String, default: '', required: false })
  private readonly prependIcon!: string

  @Prop({ type: String, default: '', required: false })
  private readonly appendIcon!: string

  @Prop({ type: String, default: '', required: false })
  private readonly id!: string

  @Prop({ required: true })
  private readonly value!: any | null

  @Prop({ type: String, default: '', required: false })
  private readonly textPropertyName!: string

  @Prop({ type: String, default: '', required: false })
  private readonly valuePropertyName!: string

  @Prop({ type: String, default: '', required: false })
  private readonly itemClass!: string

  @Prop({ type: Array, required: true })
  private readonly itemsList!: []

  private internalValue: any | null = this.value

  private getOptionValue(item: any, index: number): string {
    if (this.valuePropertyName !== '') {
      return item[this.valuePropertyName]
    }
    if (this.textPropertyName !== '') {
      return item[this.textPropertyName]
    }
    return index.toString()
  }

  private getOptionText(item: any): string {
    if (this.textPropertyName !== '') {
      return item[this.textPropertyName]
    }
    return item.toString()
  }

  public get elementId() {
    return this.id || `radio-${this.guid}`
  }

  private handleChange() {
    this.$emit('input', this.internalValue)
    this.$emit('change', this.internalValue)
  }

  @Watch('value')
  private onValueChanged() {
    if (this.internalValue !== this.value) {
      this.internalValue = this.value
    }
  }
}
