import restService from '@/services/restService'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'

const baseUrl = 'programs/'

const retrieveAll = async (includeHistorical = false) => {
  const results = await restService.get<LeagueListItem[]>(`${baseUrl}`, { includeHistorical })
  if (results.isSuccess) {
    return results.data
  } else {
    Promise.reject('Error in programsClient.retrieveAll')
  }
}

const retrieveAllCondensed = async () => {
  const results = await restService.get<LeagueInfoCondensed[]>(`${baseUrl}condensedSportsApp`)
  if (results.isSuccess) {
    return results.data
  } else {
    Promise.reject('Error in programsClient.retrieveAllCondensed')
  }
}

const retrieve = async (leagueID: string) => {
  const results = await restService.get<LeagueListItem>(`${baseUrl}${leagueID}`)
  if (results.isSuccess) {
    return results.data
  } else {
    Promise.reject('Error in programsClient.retrieve')
  }
}

export default {
  retrieveAll,
  retrieve,
  retrieveAllCondensed,
}
