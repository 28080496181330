import { RouteConfig } from 'vue-router'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import { allowFirstRanking } from '@/plugins/Authorization/permissionChecks'
import GamePlay from '@/views/GameDay/GamePlay.vue'
import ReactivatePlayer from '@/views/GameDay/ReactivatePlayer.vue'
import ChangeGameFormat from '@/views/GameDay/ChangeGameFormat.vue'

export default [
  {
    path: 'game/:gameId',
    name: 'game',
    meta: {
      title: 'Game',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: GamePlay,
  },
  {
    path: 'game/:gameId/reactivate',
    name: 'reactivate',
    meta: {
      title: 'Reactivate',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: ReactivatePlayer,
  },
  {
    path: 'game/:gameId/format',
    name: 'format',
    meta: {
      title: 'Format',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: ChangeGameFormat,
  },
] as RouteConfig[]
