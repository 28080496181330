
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

export enum AlertTypeEnum {
  DANGER = 'danger',
  NOTICE = 'notice',
  HAPPYDAYS = 'happy-days',
}

@Component
export default class Toaster extends Vue {
  @Prop({ type: Boolean, required: true }) private readonly value!: boolean
  @Prop({ type: Boolean, required: false, default: true }) private readonly dismissable!: boolean
  @Prop({ type: String, required: false, default: AlertTypeEnum.DANGER })
  private readonly type!: AlertTypeEnum

  private dismiss() {
    this.$emit('input', false)
    this.$emit('change', false)
  }

  get typeClass() {
    switch (this.type) {
      case AlertTypeEnum.DANGER:
        return 'toast-danger'
      case AlertTypeEnum.NOTICE:
        return 'toast-notice'
      case AlertTypeEnum.HAPPYDAYS:
        return 'toast-happy-days'
    }
  }
  get classNames() {
    return `${this.value ? 'show d-block' : ''} ${this.typeClass}`
  }

  @Watch('value')
  private onValueChanged() {
    if (!this.value) {
      this.dismiss()
    }
  }
}
