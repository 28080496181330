import programsClient from '@/clients/programsClient'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import moment from 'moment'
import { defineGetters, defineActions, defineMutations } from 'direct-vuex'
import store from '@/store'
import { UpwardLeagueTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueTypeID'
interface ProgramState {
  programs: LeagueInfoCondensed[]
  currentProgram: LeagueInfoCondensed | null
  usersPrograms: LeagueInfoCondensed[]
}

const programState: ProgramState = {
  programs: [] as LeagueInfoCondensed[],
  currentProgram: null,
  usersPrograms: [] as LeagueInfoCondensed[],
}

export enum getterNames {
  programs = 'programs',
  currentProgramId = 'currentProgramId',
  currentProgram = 'currentProgram',
}

const getterTree = defineGetters<ProgramState>()({
  [getterNames.programs]: (state) => state.programs,
  [getterNames.currentProgramId]: (state) => state.currentProgram?.upwardLeagueID,
  [getterNames.currentProgram]: (state) => state.currentProgram,
})

export enum mutationNames {
  setPrograms = 'setPrograms',
  setCurrentProgram = 'setCurrentProgram',
  reset = 'reset',
}

const mutations = defineMutations<ProgramState>()({
  [mutationNames.setPrograms](state, { programs }: { programs: LeagueInfoCondensed[] }) {
    state.programs = programs
  },
  [mutationNames.setCurrentProgram](state, { upwId }: { upwId: string }) {
    const program = state.programs.find((x: LeagueInfoCondensed) => x.upwardLeagueID === upwId)
    const leagueTypeID = program?.typeLeagueID ?? ''
    const leagueType = store.getters.upwardTypes.leagueTypes.find(
      (t: UpwardLeagueTypeID) => t.upwardTypeID === leagueTypeID
    )
    store.commit.upwardTypes.setCurrentLeagueType({ item: leagueType })
    state.currentProgram = program || null
    if (program) {
      store.commit.upwardTypes.updateGradeDescriptionsFromLeague({ descriptions: program.gradeDescriptions })
    }
  },
  [mutationNames.reset](state) {
    state.programs = []
    state.currentProgram = null
  },
})

export enum actionNames {
  fetchPrograms = 'fetchPrograms',
}

const actions = defineActions({
  async [actionNames.fetchPrograms]({ commit }, { isCoach }: { isCoach: boolean }): Promise<boolean> {
    let result = await programsClient.retrieveAllCondensed()
    if (isCoach) {
      result = result?.filter((p) => moment().subtract(14, 'd').isBefore(p.seasonEndDate))
    }

    if (result) {
      commit(mutationNames.setPrograms, { programs: result })
      return true
    }

    return false
  },
})

export const namespace = 'programs'

export const programs = {
  namespaced: true as true,
  state: programState,
  getters: getterTree,
  actions,
  mutations,
}
