import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'

export function getEmptyLeagueInfoCondensedList(): LeagueInfoCondensed[] {
  return [getEmptyLeagueInfoCondensed()]
}

export function getEmptyLeagueInfoCondensed(): LeagueInfoCondensed {
  return {
    id: 0,
    typeProgramID: null,
    typeLeagueID: null,
    typeProductGroupID: null,
    programTypeDescription: null,
    iconLabel: null,
    leagueName: null,
    publicDisplayName: null,
    upwardLeagueID: null,
    registrationBeginDate: null,
    registrationEndDate: null,
    onlineRegistrationBeginDate: null,
    onlineRegistrationEndDate: null,
    firstEvalDate: null,
    firstPracticeDate: null,
    firstGameDate: null,
    seasonEndDate: null,
    lateDate: null,
    awardsOrderDate: null,
    typeSystemsAccessID: null,
    isPrivate: true,
    bailedReason: null,
    allowCarpoolLinks: true,
    allowCoachLinks: true,
    allowPracticeNightExclusions: true,
    isMailIn: true,
    isOPRLeague: true,
    otherRegistrationVendorName: null,
    leagueRegistrationURL: null,
    typeCountryID: null,
    expectedToPay: 0,
    totalPaid: 0,
    totalUnpaid: 0,
    donations: 0,
    isHistorical: true,
    productYear: null,
    seasonYear: null,
    schoolYear: null,
    participantCount: 0,
    participantSportCount: 0,
    participantCheerCount: 0,
    orderCount: 0,
    canDelete: true,
    canDeleteReason: null,
    lastPossibleGameDate: null,
    grades: null,
    upwardDevotionTrackTypeID: '',
    jerseyOptionSelected: false,
    primaryColorHex: null,
    secondaryColorHex: null,
    refLabel: 'Referee',
    isByAge: true,
    gradeDescriptions: [],
  }
}
