
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class InputLabel extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: String, default: '', required: false })
  private readonly inputId!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly required!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  private readonly showHelp!: boolean

  @Prop({ type: String, required: false })
  private readonly subLabel!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly subLabelInline!: boolean

  @Prop({ type: String, default: 'text-uppercase', required: false })
  private readonly labelClass!: boolean
}
