
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class LinkingListItem extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly route!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly disable!: boolean
}
