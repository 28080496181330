
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import * as teamStore from '@/store/team'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'

@Component
export default class TeamPageWidget extends Vue {
  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  private currentTeam!: DivisionTeamInfoExt
}
