import restService from '@/services/restService'
import { AuthUser } from '@/GeneratedTypes/Authorize/AuthUser'

const url = 'users/'

const retrieve = async (userName: string) => {
  const results = await restService.get<AuthUser>(`${url}${userName}`)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in user.retrieve')
  }
}

export default {
  retrieve,
}
