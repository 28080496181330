
import { computed, defineComponent, ref, watch } from 'vue'
import SearchInput from '@/elements/SearchInput.vue'
import partnersClient, { PartnerSearchResultsType } from '@/clients/partnerClient'
import Loading from '@/elements/Loading.vue'
import { ImpersonationPayload } from '@/clients/authorizationClient'

interface SearchResults {
  name: string
  city: string
  accountNumber: number
}

export default defineComponent({
  components: { SearchInput, Loading },
  setup(p, ctx) {
    // set to an error if there is one.
    const error = ref('')
    //clear an error in 20 seconds automatically
    watch(
      () => error.value,
      (a) => {
        if (a) {
          setTimeout(() => (error.value = ''), 20000)
        }
      }
    )
    const searchTerm = ref('')
    const loading = ref(false)
    const results = ref<PartnerSearchResultsType[]>([])
    const searchResults = computed(() => {
      return results.value.map((x) => {
        const splitResult = x.name?.match(/(.*)-(.*)?$/) ?? []
        let name = x.name
        let city = 'n/a'
        if (splitResult.length == 3) {
          name = splitResult[1]
          city = splitResult[2]
        }
        return { name, city, accountNumber: x.accountNumber } as SearchResults
      })
    })

    watch(
      () => searchTerm.value,
      async () => {
        if (searchTerm.value.length >= 3) {
          try {
            loading.value = true
            results.value = (await partnersClient.searchPartners(searchTerm.value)) ?? []
            if (!results.value?.length) {
              error.value = 'No results returned'
            }
          } catch (e) {
            error.value =
              e?.message || e?.errorObject?.message || 'An unexpected error occurred searching for partners'
            loading.value = false
            throw e
          } finally {
            loading.value = false
          }
        }
      }
    )

    function impersonate(accountNumber: string) {
      ctx.emit('impersonate', { accountNumber } as ImpersonationPayload)
    }

    return { searchTerm, loading, searchResults, impersonate, error }
  },
})
