
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { LeaguePlayerInfoExt } from '@/models/LeaguePlayerInfoExt'
import { getEmptyLeaguePlayerInfoExt } from '@/modelHelpers/LeaguePlayerInfoExt.ts'
import PrimaryHeading from '@/elements/PrimaryHeading.vue'
import PlayerInformation from '@/components/PlayerInformation.vue'
import PlayerTeam from '@/components/PlayerTeamInformation.vue'
import ContactList from '@/components/ContactList.vue'
import Loading from '@/elements/Loading.vue'
import participantClient from '@/clients/participantClient'
import teamClient from '@/clients/teamClient'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import { VolunteerRoles, allVolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import * as programStore from '@/store/programs'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { getEmptyDivisionTeamInfoList } from '@/modelHelpers/DivisionTeamInfo'
import { superUsers } from '@/common/Authorization/AccountRoles'

@Component({
  components: {
    PrimaryHeading,
    Loading,
    ContactList,
    PlayerInformation,
    Breadcrumbs,
    PlayerTeam,
  },
})
export default class Player extends Vue {
  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  private isLoading = false
  private allVolunteerRoles = allVolunteerRoles
  private superUsers = superUsers
  private participant: LeaguePlayerInfoExt = getEmptyLeaguePlayerInfoExt()
  private teams: DivisionTeamInfo[] = getEmptyDivisionTeamInfoList()
  private pageCrumbs = [
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.SEARCH, isActive: true },
    { crumb: CrumbsEnum.MANAGE_TEAM, isActive: true },
    { crumb: CrumbsEnum.PLAYER, isActive: false },
  ]
  private breadcrumbs = [] as Crumb[]

  private async mounted() {
    this.isLoading = true
    try {
      await Promise.all([this.loadParticipant(), this.loadTeam()])
    } finally {
      this.isLoading = false
    }
    this.setBreadcrumbs()
  }

  private setBreadcrumbs() {
    if (this.$allowIfAny([VolunteerRoles.COACH])) {
      //coach -> remove search
      this.pageCrumbs = this.pageCrumbs.filter((p) => p.crumb != CrumbsEnum.SEARCH)
    } else {
      //super user -> remove manageTeam
      this.pageCrumbs = this.pageCrumbs.filter((p) => p.crumb != CrumbsEnum.MANAGE_TEAM)
    }
    if (!this.pageCrumbs) return
    this.breadcrumbs = getCrumbs(this.pageCrumbs)
  }

  private async loadTeam() {
    if (!this.currentProgram || !this.currentProgram.upwardLeagueID) return
    this.teams = await teamClient.retrieveByIndividualId(
      this.currentProgram.upwardLeagueID,
      this.$route.params.individualId
    )
  }

  private async loadParticipant() {
    if (!this.currentProgram || !this.currentProgram.upwardLeagueID) return
    this.participant = await participantClient.retrieveLeaguePlayerInfoExt(
      this.currentProgram.upwardLeagueID,
      this.$route.params.individualId
    )
  }
}
