
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class AnchorListItem extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly route!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly disable!: boolean

  @Prop({ type: String, default: '_self', required: false })
  private readonly target!: string
}
