import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { LeagueType, MainMenuItemConfig, mainMenuItemConfigEmpty } from '@/models/MenuItem'
import * as upwardTypesStore from '@/store/upwardTypes'
import { UpwardLeagueTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueTypeID'

@Component
export class ExcludedLeagueTypesMixin extends Vue {
  @Prop({ type: Object, default: mainMenuItemConfigEmpty, required: false })
  readonly config!: MainMenuItemConfig
  @Getter(upwardTypesStore.getterNames.currentLeagueType, { namespace: upwardTypesStore.namespace })
  protected readonly currentLeagueType!: UpwardLeagueTypeID
  protected get leagueTypeExcluded(): boolean {
    const excludedTypes = this.config.excludedLeagueTypes ?? []
    const excludeCamp = excludedTypes.includes(LeagueType.CAMP) && this.currentLeagueType.isCamp
    const excludeClinic = excludedTypes.includes(LeagueType.CLINIC) && this.currentLeagueType.isClinic
    const excludeStandard =
      excludedTypes.includes(LeagueType.STANDARD) &&
      !(this.currentLeagueType.isCamp || this.currentLeagueType.isClinic)
    return excludeCamp || excludeClinic || excludeStandard
  }
}
