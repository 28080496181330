
import { Component, Vue, Prop } from 'vue-property-decorator'

import InputLabel from '@/elements/InputLabel.vue'
import PracticeList from '@/components/PracticeList.vue'

import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { getEmptyDivisionTeamInfoList } from '@/modelHelpers/DivisionTeamInfo'

@Component({
  components: {
    InputLabel,
    PracticeList,
  },
})
export default class PlayerTeamInformation extends Vue {
  @Prop({ type: Array, default: getEmptyDivisionTeamInfoList, required: false })
  private readonly teams!: DivisionTeamInfo[]
}
