import { PlayerContactInfo } from '@/GeneratedTypes/ListInfo/PlayerContactInfo'
export function getEmptyPlayerContactInfo(): PlayerContactInfo {
  return {
    leagueID: 0,
    playerIndividualID: 0,
    typeRelationshipID: '',
    isEmergencyContact: false,
    priority: 0,
    individualID: 0,
    firstName: '',
    lastName: '',
    middleInitial: '',
    gender: '',
    birthDate: null,
    churchName: '',
    addresses: null,
    phoneNumbers: null,
    emailAddresses: null,
    thirdPartyClientIDs: null,
    formattedNameFirstLast: '',
    formattedNameLastFirst: '',
    formattedNamePrivate: '',
  }
}

export function getEmptyPlayerContactInfoList(): PlayerContactInfo[] {
  return [getEmptyPlayerContactInfo()]
}
