import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'

export function getEmptyDivisionGameInfo(): DivisionGameInfo {
  return {
    leagueID: 0,
    typeProgramID: '',
    divisionID: 0,
    gameID: 0,
    roundNumber: 0,
    homeTeamID: 0,
    awayTeamID: 0,
    facilityID: 0,
    gameStart: null,
    gameEnd: null,
    serverGameStart: null,
    serverGameEnd: null,
    volunteers: null,
    divisionName: '',
    homeTeamName: '',
    awayTeamName: '',
    facilityName: '',
  }
}

export function getEmptyDivisionGameInfoList(): DivisionGameInfo[] {
  return [getEmptyDivisionGameInfo()]
}
