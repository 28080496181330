
import { Vue, Component } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { getEmptyDivisionTeamInfoExt } from '@/modelHelpers/DivisionTeamInfoExt.ts'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import PrimaryHeading from '@/elements/PrimaryHeading.vue'
import InputLabel from '@/elements/InputLabel.vue'
import Loading from '@/elements/Loading.vue'
import PageStateToggle from '@/components/PageStateToggle.vue'
import teamClient from '@/clients/teamClient'
import ContactList from '@/components/ContactList.vue'
import PlayerList from '@/components/PlayerList.vue'
import PracticeList from '@/components/PracticeList.vue'
import GameList from '@/components/GameList.vue'
import * as programStore from '@/store/programs'
import * as teamStore from '@/store/team'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    PrimaryHeading,
    InputLabel,
    Loading,
    ContactList,
    PageStateToggle,
    PlayerList,
    PracticeList,
    GameList,
    Breadcrumbs,
  },
})
export default class Team extends Vue {
  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  @Mutation(teamStore.mutationNames.setCurrentTeam, { namespace: teamStore.namespace })
  private setCurrentTeam!: ({ team }: { team: DivisionTeamInfoExt }) => void

  private isLoading = false
  private team: DivisionTeamInfoExt = getEmptyDivisionTeamInfoExt()
  private pageStates = ['Coaches', 'Roster', 'Games', 'Practices']
  private currentToggle: string | null = null
  private breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.SEARCH, isActive: true },
    { crumb: CrumbsEnum.TEAM, isActive: false },
  ])

  private async mounted() {
    await this.loadTeam()
  }

  private async loadTeam() {
    if (!this.currentProgram || !this.currentProgram.upwardLeagueID) return

    this.isLoading = true
    try {
      this.team = await teamClient.retrieveTeam(
        this.currentProgram.upwardLeagueID,
        this.$route.params.programId,
        Number(this.$route.params.divisionId),
        Number(this.$route.params.teamId)
      )
    } finally {
      this.setCurrentTeam({ team: cloneDeep(this.team) })
      this.isLoading = false
    }
  }

  private get divisionName() {
    if (this.team.games.length > 0) return this.team.games[0].divisionName ?? ''
  }
}
