import Vue from 'vue'
import store from './store'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import Authorization from '@/plugins/Authorization/authorization'
import { getUserRoles, getCurrentUserRole } from '@/services/roles'

import LogRocket from 'logrocket'

if (process.env.VUE_APP_LOG_ROCKET_KEY) {
  LogRocket.init(process.env.VUE_APP_LOG_ROCKET_KEY, {
    network: {
      requestSanitizer: (request) => {
        // if the url contains 'authorization api calls'
        if (request.url.toLowerCase().indexOf('api/authorization') !== -1) {
          // ignore the request response pair
          //return null
          //temporarily log the request with no body to track oddball issue
          request.body = undefined
        }

        // otherwise log the request normally
        return request
      },
    },
  })
}

Vue.config.productionTip = false
Vue.prototype.$deferredPrompt = null
Vue.use(Authorization, { router, errorRoute: '/403' })

new Vue({
  router,
  store: store.original,
  currentRole() {
    return getCurrentUserRole(this)
  },
  userRoles() {
    return getUserRoles(this)
  },
  render: (h) => h(App),
}).$mount('#app')
