
import { Component, Vue, Prop } from 'vue-property-decorator'
import StylableSelectInput, { SelectConfig } from '@/elements/StylableSelectInput.vue'
import { RoleUnion, getEmtpyRoleUnionList, getEmtpyRoleUnion } from '@/common/Authorization/RoleUnion'

@Component({
  components: {
    StylableSelectInput,
  },
})
export default class RoleSelect extends Vue {
  @Prop({ type: Array, default: getEmtpyRoleUnionList, required: false })
  private readonly roles!: RoleUnion[]

  @Prop({ required: false, type: Object, default: getEmtpyRoleUnion })
  private readonly value!: RoleUnion

  private selectConfig: SelectConfig = {
    bgColor: '#3e3e3e',
    color: 'white',
    borderColor: '#3e3e3e',
    placeholderColor: 'white',
    highlightBgColor: '#3e3e3e',
    highlightColor: 'white',
  }
}
