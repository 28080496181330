
import uuid from 'uuid'

import InputLabel from '@/elements/InputLabel.vue'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { VueConstructor } from 'vue'

/// <reference path="@/modules/vuejs-datepicker.d.ts" />
import Datepicker from 'vuejs-datepicker'
import { getDateWithTime } from '@/utils/time/time'

@Component({
  components: {
    InputLabel,
    ValidationProvider,
    Datepicker: Datepicker as unknown as VueConstructor,
  },
})
export default class DateInput extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly VeeValidateRules!: string

  @Prop({ type: String, default: 'aggressive', required: false })
  private readonly veeValidateMode!: string

  @Prop({ type: String, default: '', required: false })
  private readonly validationProviderName!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly required!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  private readonly strictMode!: boolean

  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: String, default: '', required: false })
  private readonly placeholder!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly showHelp!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly displayCalendarTop!: boolean

  // prop: time
  // format = HH:mm:ss
  // The date component is really just for setting dates, but it carries with it the time the user
  // set the date. If you need the time to be something else, like the begining or end of the day,
  // use the time prop.
  @Prop({ type: String, default: '', required: false })
  private readonly time!: string

  @Prop({
    default: () => {
      const d = new Date()
      d.setDate(d.getDate() - 90)
      return d
    },
    required: false,
  })
  private readonly minDate!: Date | null

  @Prop({
    default: () => {
      const d = new Date()
      d.setDate(d.getDate() + 730)
      return d
    },
    required: false,
  })
  private readonly maxDate!: Date | null

  @Prop({ required: true })
  private readonly value!: Date | string | null

  private internalValue: Date | null = null
  private inputId = ''
  private once = false

  private created() {
    this.setInternalValue()
    this.inputId = 'datepicker-' + uuid.v4()
  }

  private get disabledDates() {
    const minDate = this.minDate

    if (minDate) {
      minDate.setDate(minDate.getDate() - 1)
    }

    return {
      to: minDate,
      from: this.maxDate,
    }
  }

  private onIconClick() {
    const input = (this.$refs.datepicker as Vue).$el.querySelectorAll('input')[0]
    input.click()
    input.focus()
  }

  private setInternalValue() {
    if (!this.value) {
      this.internalValue = null
    } else if (typeof this.value === 'string') {
      this.internalValue = new Date(this.value)
    } else {
      this.internalValue = this.value
    }
  }

  private handleChange() {
    if (this.time && this.internalValue) {
      this.internalValue = this.setTime(this.internalValue)
    }
    this.$emit('input', this.internalValue)
  }

  private setTime(date: Date) {
    if (this.time && date) {
      const parts = this.time.split(':')
      return getDateWithTime(date, Number(parts[0]), Number(parts[1]), Number(parts[2]))
    }
    return date
  }

  @Watch('value', { immediate: true })
  private onValueChanged() {
    this.setInternalValue()
    if (this.time && !this.once) {
      this.handleChange()
      this.once = true
    }
  }
}
