import { memoize } from 'lodash'
/**
 * Converts a time string into component parts [0]=hr [1]=min [2]=sec
 */
export function timeStringSplit(time: string) {
  let arr: number[] = []
  if (time) {
    arr = time.split(/[:\s]/).map(function (item) {
      return parseInt(item)
    })
    return arr
  }
  return [NaN, NaN, NaN]
}

/**
 * Converts a time to milliseconds past midnight.
 *
 * @param {string} time - looks like "8:00" or "12:00:11" etc
 * @return {number} ms since midnight or NaN if not a time
 */
export const timeStringToMilliseconds = memoize(function (time: string) {
  let secs = 0
  let arr: number[] = []
  if (time) {
    arr = timeStringSplit(time)
    secs = (arr[0] * 3600 + arr[1] * 60 + (arr[2] === undefined ? 0 : arr[2])) * 1000

    return secs
  }
  return NaN
})

/**
 * Will return a time string padded for sorting.
 */
export function millisecondsToTimeString(ms: number) {
  const d = new Date(ms)
  return dateToTimeString(d)
}

export function dateToTimeString(d: Date) {
  return `${d.getUTCHours().toString().padStart(2, '0')}:${d.getUTCMinutes().toString().padStart(2, '0')}`
}

/**
 * Looks at a string and returns if it looks valid or not.
 * @param time
 */
export function timeStringLooksValid(time: string | Date | undefined | null) {
  if (time) {
    if (time instanceof Date) {
      return true
    }
    return !isNaN(timeStringSplit(time)[0])
  }
  return false
}

/**
 * Creates a new date from the existing date with the time specified from the parameters.
 * @param hours
 * @param minutes
 * @param seconds
 */
export function getDateWithTime(
  date: string | Date | null,
  hours: number,
  minutes: number,
  seconds: number
): Date | null {
  if (!date) {
    return null
  }
  if (date instanceof Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds)
  }
  date = new Date(date)
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds)
}

/**
 * Creates a new date from the existing date with the time specified from the parameters.
 * @param date
 */
export function convertDateTimeToUTC(date: string | Date | null): Date | null {
  if (!date) {
    return null
  }
  if (date instanceof Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
  }
  date = new Date(date)
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
}

export function convertDateTimeToLocalTime(date: string | Date | null): Date | null {
  if (!date) {
    return null
  }
  if (date instanceof Date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  }
  date = new Date(date)
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}
