
import { Component, Vue } from 'vue-property-decorator'
import * as auth from '@/store/authentication'
import * as authorization from '@/store/authorization'
import * as programs from '@/store/programs'
import * as team from '@/store/team'
import { Mutation } from 'vuex-class'
import { logoutUser } from '@/common/Authentication/logoutUser'

@Component
export default class Logout extends Vue {
  @Mutation(auth.mutationNames.reset, { namespace: auth.namespace })
  private authReset!: () => void

  @Mutation(programs.mutationNames.reset, { namespace: programs.namespace })
  private programReset!: () => void

  @Mutation(authorization.mutationNames.reset, { namespace: authorization.namespace })
  private authorizationReset!: () => void

  @Mutation(team.mutationNames.reset, { namespace: team.namespace })
  private teamReset!: () => void

  private logout() {
    logoutUser()
    if (this.$route.name !== 'Root') this.$router.push('/')
  }
}
