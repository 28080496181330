
import { Vue, Component } from 'vue-property-decorator'
import Loading from '@/elements/Loading.vue'
import AnchorListItem from '@/components/AnchorListItem.vue'
import * as cheerResourceStore from '@/store/cheerResources'
import * as programStore from '@/store/programs'
import * as upwardTypesStore from '@/store/upwardTypes'
import * as teamStore from '@/store/team'
import { Action, Getter } from 'vuex-class'
import { FetchCheerResourceByUPW } from '@/store/cheerResources'
import { CheerAPIResources } from '@/common/CheerAPIResources'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { CheerResources } from '@/models/Resources/CheerResources'

@Component({
  components: {
    Loading,
    AnchorListItem,
  },
})
export default class CheerResourceList extends Vue {
  @Getter(upwardTypesStore.getterNames.programTypes, { namespace: upwardTypesStore.namespace })
  private readonly programTypes!: UpwardProgramTypeID[]

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  private currentTeam!: DivisionTeamInfoExt

  @Action(cheerResourceStore.actionNames.fetchCheerResourceByUPW, { namespace: cheerResourceStore.namespace })
  private fetchCheerResourceByUPW!: ({
    upw,
    typeProgramID,
    type,
  }: FetchCheerResourceByUPW) => Promise<boolean>

  @Getter(cheerResourceStore.getterNames.cheerResources, { namespace: cheerResourceStore.namespace })
  private readonly resources!: CheerResources[]

  private isLoading = false

  private async mounted() {
    await this.loadResource()
  }

  private async loadResource() {
    const resource = CheerAPIResources.get(this.$route.meta?.title)
    this.isLoading = true
    try {
      await this.fetchCheerResourceByUPW({
        upw: this.currentProgram.upwardLeagueID ?? '',
        typeProgramID: this.currentProgram.typeProgramID ?? '',
        type: resource ?? '',
      })
    } finally {
      this.isLoading = false
    }
  }

  private currentLeagueAbbreviation(): string {
    const programType = this.programTypes.find((p) => p.upwardTypeID === this.currentTeam.typeProgramID)
    if (!programType) throw Error('Cannot retrieve currentLeagueAbbreviation')
    return programType && programType.gpProgramAbbreviation ? programType.gpProgramAbbreviation : ''
  }

  get hasResources(): boolean {
    return this.resources.length > 0
  }
}
