import { superUsers } from './AccountRoles'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'

export const forbidSettingProgramForEmail = [
  VolunteerRoles.CHEER_DIRECTOR,
  VolunteerRoles.COACH_COMMISSIONER,
  VolunteerRoles.REFEREE_COMMISSIONER,
]

export const communicationVolunteerRoles = [
  VolunteerRoles.COACH,
  VolunteerRoles.CHEER_DIRECTOR,
  VolunteerRoles.COACH_COMMISSIONER,
  VolunteerRoles.REFEREE_COMMISSIONER,
]

export const localSMSClientGroup = [
  VolunteerRoles.COACH,
  VolunteerRoles.REFEREE_COMMISSIONER,
  VolunteerRoles.HOST_COMMISSIONER,
  VolunteerRoles.PRAYER_COMMISSIONER,
  VolunteerRoles.ADVERTISING_COMMISSIONER,
  VolunteerRoles.HALFTIME_COMMISSIONER,
  VolunteerRoles.MINISTRY_COORDINATOR,
  VolunteerRoles.REFEREE,
  VolunteerRoles.COACH_COMMISSIONER,
]

export const bulkEmailerGroup = [
  ...superUsers,
  VolunteerRoles.CHEER_DIRECTOR,
  VolunteerRoles.COACH_COMMISSIONER,
  VolunteerRoles.REFEREE_COMMISSIONER,
]

export const forbidBulkEmailerGroup = [
  VolunteerRoles.COACH,
  VolunteerRoles.HOST_COMMISSIONER,
  VolunteerRoles.PRAYER_COMMISSIONER,
  VolunteerRoles.ADVERTISING_COMMISSIONER,
  VolunteerRoles.HALFTIME_COMMISSIONER,
  VolunteerRoles.MINISTRY_COORDINATOR,
  VolunteerRoles.REFEREE,
]

export const denyMessaging = [
  VolunteerRoles.HOST_COMMISSIONER,
  VolunteerRoles.PRAYER_COMMISSIONER,
  VolunteerRoles.ADVERTISING_COMMISSIONER,
  VolunteerRoles.HALFTIME_COMMISSIONER,
  VolunteerRoles.MINISTRY_COORDINATOR,
  VolunteerRoles.REFEREE,
]
