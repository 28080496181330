
import { Vue, Component } from 'vue-property-decorator'
import CoachResourceList from '@/components/CoachResourceList.vue'
import CoachResourceCategoryList from '@/components/CoachResourceCategoryList.vue'
import Loading from '@/elements/Loading.vue'
import * as resources from '@/store/resources'
import * as programStore from '@/store/programs'
import * as autho from '@/store/authorization'
import * as upwardTypesStore from '@/store/upwardTypes'
import * as teamStore from '@/store/team'
import { Action, Getter } from 'vuex-class'
import { FetchResourceByUPW } from '@/store/resources'
import { CoachAPIResources } from '@/common/CoachAPIResources'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { RoleUnion } from '@/common/Authorization/RoleUnion'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'

@Component({
  components: {
    CoachResourceList,
    CoachResourceCategoryList,
    Loading,
  },
})
export default class PracticePlans extends Vue {
  @Getter(upwardTypesStore.getterNames.programTypes, { namespace: upwardTypesStore.namespace })
  private readonly programTypes!: UpwardProgramTypeID[]

  @Getter(autho.getterNames.currentRole, { namespace: autho.namespace })
  private readonly currentRole!: RoleUnion

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  private currentTeam!: DivisionTeamInfoExt

  @Action(resources.actionNames.fetchCoachResourceByUPW, { namespace: resources.namespace })
  private fetchCoachResourceByUPW!: ({
    upw,
    typeProgramID,
    resource,
    role,
    ruleLevel,
    week,
  }: FetchResourceByUPW) => Promise<boolean>

  private isLoading = false

  private async mounted() {
    await this.loadResource()
  }

  private async loadResource() {
    const resource = CoachAPIResources.get(this.$route.meta?.title)
    this.isLoading = true
    try {
      await this.fetchCoachResourceByUPW({
        upw: this.currentProgram.upwardLeagueID ?? '',
        typeProgramID: this.currentTeam.typeProgramID ?? '',
        resource: resource ?? '',
        role: this.currentRole.roleId ?? '',
        ruleLevel: this.currentTeam.ruleLevel,
        week: null,
      })
    } finally {
      this.isLoading = false
    }
  }
}
