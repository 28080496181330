
import { Component, Vue, Prop } from 'vue-property-decorator'
import BackLink from '@/elements/BackLink.vue'

@Component({
  components: {
    BackLink,
  },
})
export default class PrimaryHeading extends Vue {
  @Prop({ type: Boolean, default: false, required: false }) private readonly showBackLink!: boolean
  @Prop({ type: String, default: null, required: false }) private readonly title!: string
}
