// Used to detect when a service worker recognizes a change
// in the application, then reloads the app

import { Component, Vue } from 'vue-property-decorator'
@Component
export class SWRefreshMixin extends Vue {
  private refreshing = false
  private registration = null
  protected updateExists = false

  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    if (navigator && navigator.serviceWorker) {
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.log('controllerchange triggered')
        if (this.refreshing) return
        this.refreshing = true
        // Here the actual reload of the page occurs
        window.location.reload()
      })
    }
  }

  // Store the SW registration so we can send it a message
  // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
  // To alert the user there is an update they need to refresh for
  protected updateAvailable(event: any) {
    console.log('updateAvailable')
    this.registration = event.detail
    this.updateExists = true
  }

  // Called when the user accepts the update
  protected refreshApp() {
    console.log('refreshApp')
    this.updateExists = false
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    //@ts-ignore
    if (!this.registration || !this.registration.waiting) return
    // send message to SW to skip the waiting and activate the new SW
    //@ts-ignore
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
  }
}
