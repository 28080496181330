import { Commit } from 'vuex'
import { defineGetters, defineActions, defineMutations } from 'direct-vuex'
import jwtService from '@/services/jwtService'
import { Credentials } from '@/models/Credentials'
import authorizationClient, { ImpersonationPayload } from '@/clients/authorizationClient'
import userClient from '@/clients/userClient'
import { AuthToken } from '@/models/Authorization/AuthToken'
import { RestServiceResult } from '@/services/restService'
import { LockedAccountException } from '@/common/Authentication/LockedAccountException'
import { TwoFactorAuthRequiredException } from '@/common/Authentication/TwoFactorAuthRequiredException'
import { AuthUser } from '@/GeneratedTypes/Authorize/AuthUser'
import { ProfilePayload } from '@/models/Authorization/ProfilePayload'
import dayjs from 'dayjs'
interface AuthState extends Credentials {
  timeSet: Date | null
  userProfile: AuthUser | null
  isMultiAccountUser: boolean | null
}

const authState: AuthState = {
  accountNumbers: [],
  email: null,
  fullName: null,
  expiration: null,
  timeSet: null,
  accountName: null,
  activities: null,
  roles: null,
  userData: '',
  impersonatedAccount: '',
  impersonationActive: false,
  daysUntilPasswordExpiration: null,
  leagueRoles: [],
  userProfile: null,
  isMultiAccountUser: null,
  totpInfo: 'GOOD',
}

export enum getterNames {
  isAuthenticated = 'isAuthenticated',
  fullName = 'fullName',
  accountName = 'accountName',
  firstAccountNumber = 'firstAccountNumber',
  email = 'email',
  refreshTime = 'refreshTime',
  daysUntilPasswordExpiration = 'daysUntilPasswordExpiration',
  accountNumbers = 'accountNumbers',
  leagueRoles = 'leagueRoles',
  accountRoles = 'accountRoles',
  leagueRolesByProgram = 'leagueRolesByProgram',
  impersonationAccountNumber = 'impersonationAccountNumber',
  userData = 'userData',
  impersonationActive = 'impersonationActive',
  userProfile = 'userProfile',
  isMultiAccountUser = 'isMultiAccountUser',
  totpInfo = 'totpInfo',
  isTokenExpired = 'isTokenExpired',
}

const getterTree = defineGetters<AuthState>()({
  [getterNames.isAuthenticated]: (state, getters, rootState) => {
    if (state.totpInfo != 'GOOD') return false

    if (state.timeSet === null || state.expiration === null) {
      return false
    }

    if (state.accountNumbers && state.accountNumbers.length > 1) {
      // if a user is connected to more that one account, they are not fully authenticated
      // until they have picked just one.
      return false
    }

    const now = rootState.now
    return now <= state.expiration
  },
  [getterNames.fullName]: (state) => {
    return state.fullName
  },
  [getterNames.userProfile]: (state) => {
    return state.userProfile
  },
  [getterNames.userData]: (state) => {
    return state.userData
  },
  [getterNames.accountNumbers]: (state) => {
    return state.accountNumbers
  },
  [getterNames.isMultiAccountUser]: (state) => {
    return state.isMultiAccountUser
  },
  [getterNames.firstAccountNumber]: (state) => {
    if (state.accountNumbers && state.accountNumbers.length > 0) {
      return state.accountNumbers[0]
    }
    return ''
  },
  [getterNames.email]: (state) => {
    if (state.email) {
      return state.email
    }
    return ''
  },
  [getterNames.accountName]: (state) => {
    return state.accountName
  },
  [getterNames.refreshTime]: (state) => {
    if (state.timeSet === null || state.expiration === null) {
      return null
    }

    const msPerMinute = 60 * 1000
    return new Date(state.expiration.getTime() - 10 * msPerMinute) //give us a 10 minute buffer in case there is a time difference to the server
  },
  [getterNames.daysUntilPasswordExpiration]: (state) => {
    return state.daysUntilPasswordExpiration
  },
  [getterNames.leagueRoles]: (state) => {
    return state.leagueRoles
  },
  [getterNames.accountRoles]: (state) => {
    return state.roles
  },
  [getterNames.leagueRolesByProgram]: (state) => (upwId: string) => {
    const roles = state.leagueRoles.filter((item) => item.UpwardLeagueID === upwId)
    if (roles) {
      return roles
    } else {
      return []
    }
  },
  [getterNames.impersonationAccountNumber]: (state) => {
    return state.impersonatedAccount
  },
  [getterNames.impersonationActive]: (state) => {
    return state.impersonationActive
  },
  totpInfo: (state) => {
    return state.totpInfo
  },
  [getterNames.isTokenExpired]: (state) => {
    return dayjs().isAfter(dayjs(state.expiration))
  },
})

export enum mutationNames {
  setCurrentCredentials = 'setCurrentCredentials',
  reset = 'reset',
  setUserProfile = 'setUserProfile',
  setIsMultiAccountUser = 'setIsMultiAccountUser',
}

const mutations = defineMutations<AuthState>()({
  [mutationNames.setCurrentCredentials](state, { credentials }: { credentials: Credentials }) {
    if (credentials) {
      Object.assign(state, { ...state, ...credentials })
      state.timeSet = new Date()
    }
  },

  [mutationNames.setUserProfile](state, { userProfile }: { userProfile: AuthUser }) {
    state.userProfile = userProfile
  },

  [mutationNames.setIsMultiAccountUser](
    state,
    { isMultiAccountUser }: { isMultiAccountUser: boolean | null }
  ) {
    state.isMultiAccountUser = isMultiAccountUser
  },

  [mutationNames.reset](state) {
    jwtService.clearStoredAuthToken()
    state.accountNumbers = []
    state.email = null
    state.fullName = null
    state.expiration = null
    state.timeSet = null
    state.accountName = null
    state.activities = null
    state.roles = null
    state.leagueRoles = []
    state.userData = ''
    state.isMultiAccountUser = null
  },
})

export enum actionNames {
  login = 'login',
  refreshToken = 'refreshToken',
  tryLoadSavedToken = 'tryLoadSavedToken',
  passwordChange = 'passwordChange',
  loginByTokenWithImpersonate = 'loginByTokenWithImpersonate',
  impersonate = 'impersonate',
  unimpersonate = 'unimpersonate',
  fetchProfile = 'fetchProfile',
  updateProfile = 'updateProfile',
  validateTOTP = 'validateTOTP',
}

const actions = defineActions({
  /**
   * User login.
   * Initially account number is passed in as null. If the user is affiliated
   * with many acccounts, the login is executed a second time passing in the
   * accountNumber the account the user selected
   */
  async [actionNames.login](
    { commit },
    { email, password, accountNumber }
  ): Promise<RestServiceResult<AuthToken>> {
    const restResult = await authorizationClient.login(email, password, accountNumber)

    if (restResult.isSuccess) {
      const credentials = jwtService.getCredentialsFromNewAuthToken(restResult.data)
      const numberOfAccounts = restResult.data?.accountNumbers?.length ?? 0

      if (!accountNumber && numberOfAccounts > 1) {
        commit(mutationNames.setIsMultiAccountUser, { isMultiAccountUser: true })
      }
      commit(mutationNames.setCurrentCredentials, { credentials })
    }

    return restResult
  },

  async validateTOTP({ commit }, totpCode: string): Promise<AuthToken> {
    const restResult = await authorizationClient.validateTOTP(totpCode)
    const credentials = jwtService.getCredentialsFromNewAuthToken(restResult)
    commit(mutationNames.setCurrentCredentials, { credentials })

    return restResult
  },

  async [actionNames.fetchProfile]({ commit }, { email }: { email: string }): Promise<AuthUser | null> {
    const userProfile = await userClient.retrieve(email)
    if (userProfile) {
      commit(mutationNames.setUserProfile, { userProfile })
    }
    return userProfile
  },

  async [actionNames.loginByTokenWithImpersonate]({ commit }, { chit }: { chit: string }) {
    const [token, impersonatingAccountNumber] = chit.split('.')
    const who = { userName: undefined, accountNumber: impersonatingAccountNumber } as ImpersonationPayload
    let usersAuthToken = null

    // clear any persisted login information
    commit(mutationNames.reset)

    try {
      //login with the user's token
      usersAuthToken = await authorizationClient.tokenLogin(token)
    } catch (e) {
      // logout
      commit(mutationNames.reset)
      const err = e as RestServiceResult<void>
      if (err.status === 401 && err.errorObject?.message) {
        throw new LockedAccountException('This account is locked. Please reset your password')
      }
    }

    const credentials = jwtService.getCredentialsFromNewAuthToken(usersAuthToken)
    if (credentials?.totpInfo != 'GOOD') {
      commit(mutationNames.setCurrentCredentials, { credentials })
      const ex = new TwoFactorAuthRequiredException('2FA Required for this login')
      ex.accountToImpersonateAfterAuth = impersonatingAccountNumber
      throw ex
    }

    if (usersAuthToken && impersonatingAccountNumber) {
      //Impersonate and persist credentials
      passthoughImpersonation(usersAuthToken, who, commit)
    } else if (usersAuthToken) {
      //persist credentials
      commit(mutationNames.setCurrentCredentials, { credentials })
    }
  },

  async [actionNames.passwordChange]({ commit, state }, { password, newPassword }): Promise<boolean> {
    const email = state.email

    if (!email) {
      return false
    }

    const restResult = await authorizationClient.passwordChange(email, password, newPassword)

    if (restResult.isSuccess) {
      commit(mutationNames.reset)
      return true
    }
    return false
  },

  async [actionNames.refreshToken]({ commit }): Promise<boolean> {
    const restResult = await authorizationClient.refreshToken()

    if (restResult.isSuccess) {
      const credentials = jwtService.getCredentialsFromNewAuthToken(restResult.data)
      commit(mutationNames.setCurrentCredentials, { credentials })
      return true
    }

    return false
  },
  async [actionNames.tryLoadSavedToken]({ commit }): Promise<boolean> {
    const credentials = jwtService.getCredentialsFromStoredAuthToken()

    if (credentials) {
      commit(mutationNames.setCurrentCredentials, { credentials })
      return true
    }

    return false
  },

  async [actionNames.impersonate]({ commit }, who: { userName?: string; accountNumber: string }) {
    const restResult = await authorizationClient.impersonate(who)
    jwtService.backupToken()
    const credentials = jwtService.getCredentialsFromNewAuthToken(restResult)
    commit(mutationNames.setCurrentCredentials, { credentials })
  },

  async [actionNames.unimpersonate]({ commit }) {
    const restResult = await jwtService.retrieveOldToken()
    if (restResult) {
      const credentials = jwtService.getCredentialsFromNewAuthToken(restResult)

      commit(mutationNames.setCurrentCredentials, { credentials })
    }
  },
  async [actionNames.updateProfile](
    { commit, state },
    { payload }: { payload: ProfilePayload }
  ): Promise<{ incompleteToken: boolean }> {
    payload.username = state.email

    if (!payload.username) {
      throw new Error('Missing username')
    }

    const restResult = await authorizationClient.updateProfile(payload)

    if (restResult.isSuccess) {
      const credentials = jwtService.getCredentialsFromNewAuthToken(restResult.data)
      const hasMultipleAccounts = (restResult.data?.accountNumbers?.length ?? 0) > 1
      if (hasMultipleAccounts) {
        return { incompleteToken: true }
      } else {
        commit(mutationNames.setCurrentCredentials, { credentials })
        return { incompleteToken: false }
      }
    }
    throw new Error('Update Profile Failed')
  },
})

const passthoughImpersonation = async (
  usersAuthToken: AuthToken,
  who: ImpersonationPayload,
  commit: Commit
) => {
  jwtService.setHTTPHeaderAndLocalStorage(usersAuthToken)

  //Attempt to impersonate. If the user's permissions are insufficient to impersonate, this will fail.
  try {
    const impersonatedAuthToken = await authorizationClient.impersonate(who)
    if (impersonatedAuthToken) {
      const credentials = jwtService.getCredentialsFromNewAuthToken(impersonatedAuthToken)
      commit(mutationNames.setCurrentCredentials, { credentials })
    }
  } catch (e) {
    // logout
    commit(mutationNames.reset)
  }
}

export const namespace = 'authentication'

export const authentication = {
  namespaced: true as true,
  state: authState,
  getters: getterTree,
  actions,
  mutations,
}
