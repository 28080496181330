
import { Component, Mixins, Prop } from 'vue-property-decorator'
import moment from 'moment'
import { getEmptyDivisionGameInfoList } from '@/modelHelpers/DivisionGameInfo'
import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'
import Game from '@/components/Game.vue'
import Toggle from '@/components/Toggle.vue'
import { IsCheerMixin } from '@/common/IsCheerMixin'
import { CheerSquadGameAppointmentInfo } from '@/GeneratedTypes/ListInfo/CheerSquadGameAppointmentInfo'
import { getEmptyCheerSquadGameAppointmentInfoList } from '@/modelHelpers/CheerSquadGameAppointmentInfo'
import { DisplayableGame, getEmptyDisplableGame } from '@/modelHelpers/DisplayableGame'

@Component({
  components: {
    Game,
    Toggle,
  },
})
export default class GameList extends Mixins(IsCheerMixin) {
  @Prop({ type: Array, default: getEmptyDivisionGameInfoList, required: false })
  private readonly games!: DivisionGameInfo[]

  @Prop({ type: Array, default: getEmptyCheerSquadGameAppointmentInfoList, required: false })
  private readonly appointments!: CheerSquadGameAppointmentInfo[]

  @Prop({ type: String, default: '', required: false })
  private readonly title!: string

  private pageStates = ['Upcoming', 'Past']
  private currentToggle: string | null = null

  private get gameList(): DisplayableGame[] {
    if (this.isCheer) return this.composeCheerGames
    return this.composeSportGames
  }

  private get composeCheerGames(): DisplayableGame[] {
    return this.appointments.map((a) => {
      const game = getEmptyDisplableGame()
      game.gameStart = a.gameStart
      game.gameEnd = a.gameEnd
      game.facilityName = a.facilityName
      return game
    })
  }

  private get composeSportGames(): DisplayableGame[] {
    if (!this.games) return [getEmptyDisplableGame()]
    return this.games.map((g) => {
      const game = getEmptyDisplableGame()
      game.gameStart = g.gameStart
      game.gameEnd = g.gameEnd
      game.facilityName = g.facilityName
      game.homeTeamName = g.homeTeamName
      game.awayTeamName = g.awayTeamName
      game.homeTeamId = g.homeTeamID
      return game
    })
  }

  private get scheduledGames() {
    return this.gameList.filter((game) => {
      return !!game.gameStart
    })
  }

  private get scheduledGamesCount() {
    return this.gameList.length
  }

  private get sortedGames(): DisplayableGame[] {
    //sort games by gameStart
    return this.gameList.sort((a, b) => {
      return moment.utc(a.gameStart).diff(moment.utc(b.gameStart))
    })
  }

  private get filteredGames(): DisplayableGame[] {
    return this.sortedGames.filter((game) => {
      if (!game.gameStart) return false
      if (this.isAfterNextDay(game.gameStart)) {
        // past game
        return this.currentToggle === 'Past'
      } else {
        //upcoming game
        return this.currentToggle === 'Upcoming'
      }
    })
  }
  private isAfterNextDay(date: Date): boolean {
    const nextDay = new Date(date)
    nextDay.setDate(nextDay.getDate() + 1)
    nextDay.setHours(0, 0, 0, 0)
    return moment().isAfter(nextDay)
  }
  private get filteredGamesCount() {
    if (!this.gameList) return 0
    return this.filteredGames.length
  }
}
