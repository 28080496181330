
import { Component, Vue, Prop } from 'vue-property-decorator'
import { AlertStatusEnum } from './data'

@Component({})
export default class Alert extends Vue {
  @Prop({ type: String, default: () => AlertStatusEnum.default, required: false })
  private readonly status!: string

  private get bootstrapClass() {
    switch (this.status) {
      case AlertStatusEnum.warning:
        return 'alert-warning'
      case AlertStatusEnum.danger:
        return 'alert-danger'
      case AlertStatusEnum.info:
        return 'alert-info'
      case AlertStatusEnum.default:
        return 'alert-secondary'
    }
  }
}
