
import { defineComponent, ref, computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import PasswordInput from '@/elements/PasswordInput.vue'
import Loading from '@/elements/Loading.vue'

import store from '@/store'

export default defineComponent({
  name: 'managePassword',
  props: {},
  components: { PasswordInput, Loading },
  setup() {
    const updatePassword = store.dispatch.authentication.passwordChange
    const router = useRouter()

    const currentPassword = ref('')
    const newPassword = ref('')
    const confirmNewPassword = ref('')
    const showConfirmPasswordError = ref(false)
    const loading = ref(false)

    async function submitPasswordChange() {
      showConfirmPasswordError.value = false
      loading.value = true

      try {
        const success = await updatePassword({
          password: currentPassword.value,
          newPassword: newPassword.value,
        })
        if (success) {
          newPassword.value = ''
          confirmNewPassword.value = ''
          currentPassword.value = ''
          router.push('/')
        }
      } finally {
        loading.value = false
      }
    }

    const saveIsDisabled = computed(() => {
      return !(
        !loading.value &&
        currentPassword.value &&
        newPassword.value &&
        confirmNewPassword.value &&
        newPassword.value === confirmNewPassword.value
      )
    })

    function onNewPasswordsChange() {
      showConfirmPasswordError.value =
        !!newPassword.value && !!confirmNewPassword.value && newPassword.value !== confirmNewPassword.value
    }

    return {
      currentPassword,
      newPassword,
      confirmNewPassword,
      showConfirmPasswordError,
      loading,
      saveIsDisabled,
      submitPasswordChange,
      onNewPasswordsChange,
    }
  },
})
