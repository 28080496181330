
import { Component, Vue, Provide } from 'vue-property-decorator'

@Component
export default class Accordion extends Vue {
  @Provide('accordionData')
  private accordionData = {
    currentGuid: '',
  }
}
