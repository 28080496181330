export enum ProgramEnum {
  BASKETBALL = 'BASKETBALL',
  VOLLEYBALL = 'VOLLEYBALL',
  FOOTBALL = 'FOOTBALL',
  SOCCER = 'SOCCER',
  BASKETBALCHEERLEADING = 'BASKETBALCHEERLEADING',
  FOOTBALLCHEERLEADING = 'FOOTBALLCHEERLEADING',
  BASEBALL = 'BASEBALL',
  SOFTBALL = 'SOFTBALL',
  MSSSELECT = 'UPWARDSELECTMSS',
}

export function isFootball(program: string | null | undefined) {
  return program === ProgramEnum.FOOTBALL
}

export function isBaseballOrSoftball(program: string | null | undefined) {
  return program === ProgramEnum.BASEBALL || program === ProgramEnum.SOFTBALL
}
