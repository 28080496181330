import { RouteConfig } from 'vue-router'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import { allowFirstRanking } from '@/plugins/Authorization/permissionChecks'
import GameDayGames from '@/views/GameDay/GameDayGames.vue'
import Recognition from '@/views/GameDay/Recognition.vue'
import GameRecognition from '@/views/GameDay/GameRecognition.vue'

export default [
  {
    path: 'games',
    meta: {
      title: 'Games',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: GameDayGames,
  },
  {
    path: 'game/:gameId/recognition/:individualId',
    meta: {
      title: 'Recognition',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: Recognition,
  },
  {
    path: 'game/:gameId/gamerecognition',
    name: 'gameRecognition',
    meta: {
      title: 'Game Recognition',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: GameRecognition,
  },
] as RouteConfig[]
