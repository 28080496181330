
import { Component, Vue, Prop } from 'vue-property-decorator'
import LabelInput from '@/elements/InputLabel.vue'
import moment from 'moment'

@Component({
  components: {
    LabelInput,
  },
})
export default class DateCard extends Vue {
  @Prop({ type: String, default: null, required: false })
  private readonly cardDate!: string

  @Prop({ type: String, default: null, required: false })
  private readonly time!: string

  private get dateInfo() {
    const game: DateInfo = {
      day: '',
      time: '',
      month: '',
    }

    if (this.cardDate) {
      const dt = moment(this.cardDate)
      game.day = dt.format('DD')
      game.month = dt.format('MMM')
      game.time = dt.format('h:mmA')
    }
    return game
  }
}
interface DateInfo {
  day: string
  month: string
  time: string
}
