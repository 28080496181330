import { RouteConfig } from 'vue-router'
import ResourceList from '@/views/PracticeResources/ResourceList.vue'
import CoachSkillsList from '@/components/CoachSkillsList.vue'
import CoachResource from '@/views/PracticeResources/CoachResource.vue'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import { allowFirstRanking } from '@/plugins/Authorization/permissionChecks'

export default [
  {
    path: 'PracticePlans',
    name: 'PracticePlans',
    meta: {
      title: 'Practice Plans',
      level: 1,
      childRoute: 'PracticePlan',
      childComponent: 'CoachResourceList',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: ResourceList,
  },
  {
    path: 'PracticePlan/:resourceId',
    name: 'PracticePlan',
    meta: {
      title: 'Practice Plan',
      level: 2,
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: CoachResource,
  },
  {
    path: 'Devotions',
    name: 'Devotions',
    meta: {
      title: 'Devotions',
      level: 1,
      childRoute: 'Devotion',
      childComponent: 'CoachResourceList',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: ResourceList,
  },
  {
    path: 'Devotion/:resourceId',
    name: 'Devotion',
    meta: {
      title: 'Devotion',
      level: 2,
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: CoachResource,
  },
  {
    path: 'SkillsCategories',
    name: 'SkillsCategories',
    meta: {
      title: 'Skills & Drills',
      level: 1,
      childRoute: 'SkillsList',
      childComponent: 'CoachResourceCategoryList',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: ResourceList,
  },
  {
    path: 'SkillsList/:category',
    name: 'Skills',
    meta: {
      title: 'Skills & Drills',
      level: 2,
      childRoute: '/PracticeResources/Skill',
      childComponent: 'CoachSkillsList',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: CoachSkillsList,
  },
  {
    path: 'Skill/:resourceId',
    name: 'Skill',
    meta: {
      title: 'Skill',
      level: 3,
      childRoute: '',
      childComponent: '',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: CoachResource,
  },
  {
    path: 'Rules',
    name: 'Rules',
    meta: {
      title: 'Rules',
      level: 1,
      childRoute: 'Rule',
      childComponent: 'CoachResourceList',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: ResourceList,
  },
  {
    path: 'Rule/:resourceId',
    name: 'Rule',
    meta: {
      title: 'Rule',
      level: 2,
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: CoachResource,
  },
] as RouteConfig[]
