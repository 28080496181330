
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
import { Bold, Italic, Underline, BulletList, ListItem, OrderedList, Heading, Link } from 'tiptap-extensions'
import { defineComponent, onMounted, onUnmounted, ref, watch, computed } from 'vue'
import { ValidationProvider } from 'vee-validate'
import { ProviderInstance } from 'vee-validate/dist/types/types'
import InputLabel from '@/elements/InputLabel.vue'
import { TextLikeWrapperProps } from '@/elements/TextLikeWrapper/TextLikeWrapperProps'
import LetterList from '../LetterList'
import Modal from '@/components/Modal.vue'
import DivWrapper from '@/elements/DivWrapper.vue'

export default defineComponent({
  mixins: [TextLikeWrapperProps],
  name: 'HtmlEditor2',
  props: {
    value: { type: String, required: true },
    maxLength: { type: Number, required: false, default: null },
  },
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble,
    ValidationProvider,
    InputLabel,
    Modal,
    DivWrapper,
  },
  setup(props, { emit }) {
    const openModal = ref<boolean | number>(false)
    const parentComponent = computed(() => (openModal.value ? Modal : DivWrapper))
    const internalValue = ref('')
    const charactersRemaining = ref('')
    const editorElement = ref<any | null>(null)
    const menuBubbleElement = ref<any | null>(null)
    const menuBarElement = ref<any | null>(null)
    const provider = ref<ProviderInstance | null>(null)
    const editorAreaElement = computed(() => editorElement.value?.querySelector('.ProseMirror'))
    const linkInputElement = computed(() => menuBubbleElement.value?.$el.querySelector('.menububble__input'))
    const linkUrl = ref(null)
    const linkMenuIsActive = ref(false)
    watch(
      () => props.value,
      (v) => {
        try {
          const isSame = v === editor?.value?.getHTML()
          if (isSame) {
            return
          }
          const limit = !props.maxLength ? 0 : props.maxLength
          charactersRemaining.value = (limit - (props.value?.length ?? 0)).toString()
          internalValue.value = v
          editor?.value?.setContent(v)
        } catch (err) {
          console.error(err)
        }
      },
      { immediate: true }
    )
    const menuBubbleIsActive = computed(() => menuBubbleElement?.value?.menu?.isActive ?? false)
    function showLinkMenu(attrs: any) {
      linkUrl.value = attrs.href
      linkMenuIsActive.value = menuBubbleIsActive.value
      linkInputElement?.value?.focus()
    }
    function hideLinkMenu() {
      linkUrl.value = null
      linkMenuIsActive.value = false
    }
    function setLinkUrl(command: any, url: any) {
      command({ href: url })
      hideLinkMenu()
    }
    function onInit() {
      if (!internalValue.value) return
      setTimeout(async () => {
        const validator = provider.value as ProviderInstance
        await validator.validate(internalValue.value)
      })
    }
    async function onUpdate({ getHTML }: { getHTML: () => string }) {
      // we can get the text minus html tags
      const text = editorAreaElement.value?.textContent?.trim()
      // having the text only allows us to determine if the control is truly empty
      const isEmpty = text?.length === 0 ?? true
      const limit = !props.maxLength ? 0 : props.maxLength
      const validator = provider.value as ProviderInstance
      if (isEmpty) {
        charactersRemaining.value = `${limit.toString()}`
        // if it's truly empty, validate and emit an empty string
        // to avoid an empty <p></p> tag from causing our
        // charactersRemaining from displaying an inaccurate
        // value. I wish tiptap would do this automatically.
        await validator.validate('')
        emit('input', '')
      } else {
        const html = getHTML()?.trim()
        charactersRemaining.value = `${(limit - html.length).toString()}`
        await validator.validate(html)
        emit('input', html)
      }
    }
    const focusEditor = () => {
      ;(editor!.value! as any).focus()
    }
    const editor = ref<Editor | null>(null)
    onMounted(() => {
      editor.value = new Editor({
        extensions: [
          new Bold(),
          new Italic(),
          new Underline(),
          new BulletList(),
          new ListItem(),
          new OrderedList(),
          new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
          new Link(),
          new LetterList(),
        ],
        content: internalValue.value,
        onInit,
        onUpdate,
      })
      /* This is a work-around to trigger the 
      re-draw of the component to enable 
      the bubble menu functionality. */
      openModal.value = 0
    })
    onUnmounted(() => editor.value?.destroy())
    return {
      editor,
      internalValue,
      editorElement,
      charactersRemaining,
      provider,
      showLinkMenu,
      hideLinkMenu,
      setLinkUrl,
      linkMenuIsActive,
      linkUrl,
      menuBubbleElement,
      menuBubbleIsActive,
      openModal,
      parentComponent,
      menuBarElement,
      focusEditor,
    }
  },
})
