export interface GettingStartedResource {
  title: string
  programType: string[]
  role: string[]
  devotionTrack: string[]
  productGroupIDs: string[]
  content: string | null
  documentContent: string
}

export function getEmptyGettingStartedResource(): GettingStartedResource {
  return {
    title: '',
    programType: [],
    role: [],
    devotionTrack: [],
    productGroupIDs: [],
    content: null,
    documentContent: '',
  }
}
