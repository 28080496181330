
import { defineComponent, ref, watch, computed } from 'vue'
import TextInput from '@/elements/TextInput.vue'
import VerficationCode, { ProfileCredentials } from '@/views/Account/VerificationCode.vue'
import Loading from '@/elements/Loading.vue'
import store from '@/store'
import { logoutUser } from '@/common/Authentication/logoutUser'

export default defineComponent({
  name: 'Profile',
  props: {},
  components: { TextInput, VerficationCode, Loading },
  setup() {
    const redirect = ref(false)
    const fullName = computed(() => store.getters.authentication.fullName)
    const email = computed(() => store.getters.authentication.email)
    const isNewEmail = computed(() => localEmail.value !== email.value)
    const isNewFullName = computed(() => localFullName.value !== fullName.value)
    const daysUntilPasswordExpiration = computed(
      () => store.getters.authentication.daysUntilPasswordExpiration ?? 0
    )
    const localFullName = ref('')
    const localEmail = ref('')
    const loading = ref(false)

    async function save() {
      if (isNewEmail.value || isNewFullName.value) {
        verify.value = isNewEmail.value
        await updateProfile()
      }
    }

    async function updateProfile() {
      loading.value = true
      try {
        const r = await store.dispatch.authentication.updateProfile({
          payload: {
            newUsername: isNewEmail.value ? localEmail.value : null,
            newFullName: isNewFullName.value ? localFullName.value : null,
          },
        })

        if (r?.incompleteToken) {
          redirect.value = true
          setTimeout(logoutUser, 6000)
        }
      } finally {
        loading.value = false
      }
    }

    const credentials = computed(() => {
      return {
        newEmail: localEmail.value,
        newFullName: localFullName.value,
      } as ProfileCredentials
    })

    const canSave = computed(() => {
      return localEmail.value && localFullName.value
    })

    const verify = ref(false)
    function cancelVerification() {
      verify.value = false
    }

    const showDaysUntilExpiration = computed(() => {
      return daysUntilPasswordExpiration.value !== null && daysUntilPasswordExpiration.value < 10
    })

    const warning = computed(() => {
      if (daysUntilPasswordExpiration.value >= 2) {
        return `Your password expires in ${daysUntilPasswordExpiration.value} days.`
      } else if (daysUntilPasswordExpiration.value === 1) {
        return 'Your password expires tomorrow. Please change it soon.'
      } else {
        return 'Your password is expired. Please change it.'
      }
    })

    watch(
      () => fullName.value,
      (val) => {
        localFullName.value = val ?? ''
      },
      { immediate: true }
    )

    watch(
      () => email.value,
      (val) => {
        localEmail.value = val
      },
      { immediate: true }
    )

    return {
      localFullName,
      localEmail,
      canSave,
      credentials,
      verify,
      showDaysUntilExpiration,
      warning,
      loading,
      save,
      cancelVerification,
      redirect,
    }
  },
})
