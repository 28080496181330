
import { Component, Vue, Prop } from 'vue-property-decorator'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { getEmptyLeagueInfoCondensedList } from '@/modelHelpers/LeagueInfoCondensed'
import StylableSelectInput, { SelectConfig } from '@/elements/StylableSelectInput.vue'

@Component({
  components: {
    StylableSelectInput,
  },
})
export default class ProgramSelect extends Vue {
  @Prop({ type: Array, default: getEmptyLeagueInfoCondensedList, required: false })
  private readonly programs!: LeagueInfoCondensed[]

  @Prop({ required: true, type: Object })
  private readonly value!: LeagueInfoCondensed

  private selectConfig: SelectConfig = {
    bgColor: '#3e3e3e',
    color: 'white',
    borderColor: '#3e3e3e',
    placeholderColor: 'white',
    highlightBgColor: '#3e3e3e',
    highlightColor: 'white',
  }
}
