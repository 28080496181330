import restService from '@/services/restService'
import { TeamPageAlertInfo } from '@/GeneratedTypes/ListInfo/TeamPageAlertInfo'
import { getEmptyTeamPageAlertInfoList } from '@/modelHelpers/AlertInfo'

const alertUrl = 'alerts/'
const teamsUrl = `${alertUrl}teams/`

const retrieve = async (upwId: string) => {
  try {
    const results = await restService.get<TeamPageAlertInfo[]>(`${teamsUrl}byLeague/${upwId}`)
    if (results.isSuccess) {
      return results.data
    } else {
      throw new Error('Error in alertClient.retrieve')
    }
  } catch (e) {
    if (e.status === 404) {
      // no alert exists for this program/programType. Return empty alert.
      return getEmptyTeamPageAlertInfoList()
    } else {
      throw e
    }
  }
}

const save = async (upwId: string, alert: TeamPageAlertInfo) => {
  const results = await restService.post<TeamPageAlertInfo>(`${teamsUrl}${upwId}`, alert)

  if (!results.isSuccess) {
    throw new Error(`Error in alertClient.save`)
  }
  return results.data
}

const remove = async (alertId: number) => {
  const results = await restService.delete<void>(`${alertUrl}${alertId}`)

  if (!results.isSuccess) {
    throw new Error(`Error in alertClient.remove`)
  }
  return results.isSuccess
}

export default {
  retrieve,
  save,
  remove,
}
