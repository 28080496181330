
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import moment from 'moment'

import Loading from '@/elements/Loading.vue'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import GameReferee from '@/components/GameReferee.vue'
import Toggle from '@/components/Toggle.vue'

import { AuthUser } from '@/GeneratedTypes/Authorize/AuthUser'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'
import { DisplayableGame } from '@/modelHelpers/DisplayableGame'

import { RolesEnum } from '@/modelHelpers/LeagueVolunteer'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'

import * as authStore from '@/store/authentication'
import * as programStore from '@/store/programs'

import gameClient from '@/clients/gameClient'
import volunteerClient from '@/clients/volunteerClient'
import { LeagueVolunteerInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerInfo'

@Component({
  components: {
    Loading,
    Breadcrumbs,
    GameReferee,
    Toggle,
  },
})
export default class Coach extends Vue {
  @Action(authStore.actionNames.fetchProfile, { namespace: authStore.namespace })
  private fetchProfile!: ({ email }: { email: string }) => Promise<AuthUser>

  @Getter(authStore.getterNames.userProfile, { namespace: authStore.namespace })
  private userProfile!: AuthUser

  @Getter(authStore.getterNames.email, { namespace: authStore.namespace })
  private email!: string

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.REFEREE_SCHEDULE, isActive: false },
  ])

  loading = false
  leagueGames = [] as DivisionGameInfo[]
  leagueVolunteers = [] as LeagueVolunteerInfo[]
  pageStates = ['Upcoming', 'Past']
  currentToggle: string | null = null

  async mounted() {
    this.loading = true
    await this.fetchProfile({ email: this.email })

    const [volunteers, games] = await Promise.all([
      volunteerClient.retrieveByRoles(this.currentProgram.upwardLeagueID ?? '', [RolesEnum.REFEREE]),
      gameClient.retrieveByLeague(this.currentProgram.upwardLeagueID),
    ])
    this.leagueVolunteers = volunteers
    this.leagueGames = games
    this.loading = false
  }

  get refereesIndividualID() {
    if (!this.userProfile || !this.userProfile.roles) return null
    const ids = this.userProfile.roles
      .filter(
        (r) => r.upwardLeagueID == this.currentProgram.upwardLeagueID && r.roleID == VolunteerRoles.REFEREE
      )
      .map((x) => x.individualID)

    return ids.length ? ids[0] : null
  }

  get title() {
    const ref = this.leagueVolunteers.find((v) => v.individualID == this.refereesIndividualID)
    return ref ? `for ${ref.formattedNameFirstLast}` : 'All Games'
  }

  get refereesGames(): DisplayableGame[] {
    return this.leagueGames
      .filter((g) => {
        return !this.refereesIndividualID
          ? true // this is a super user or referee commissioner acting as a ref. Show for entire leage
          : g.volunteers?.some((v) => v.volunteerIndividualID == this.refereesIndividualID)
      })
      .map((g) => {
        return {
          homeTeamName: g.homeTeamName,
          awayTeamName: g.awayTeamName,
          homeTeamId: g.homeTeamID,
          gameStart: g.gameStart,
          gameEnd: g.gameEnd,
          referees: g.volunteers
            ?.filter((v) => v.roleID == RolesEnum.REFEREE)
            .map((v) => {
              return {
                ...v,
                name: this.name(v.volunteerIndividualID),
              }
            }),
          divisionName: g.divisionName,
          facilityName: g.facilityName,
        }
      })
      .sort((a, b) => {
        return moment.utc(a.gameStart).diff(moment.utc(b.gameStart))
      })

    return []
  }

  get filteredGames() {
    return this.refereesGames.filter((game) => {
      if (!game.gameStart) return false
      if (moment().isAfter(game.gameStart)) {
        // past game
        return this.currentToggle === 'Past'
      } else {
        //upcoming game
        return this.currentToggle === 'Upcoming'
      }
    })
  }

  name(individualId: number) {
    const volunteer = this.leagueVolunteers.find((l) => l.individualID == individualId)
    return volunteer ? volunteer.formattedNameFirstLast : ''
  }
}
