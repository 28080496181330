import restService from '@/services/restService'
import { UpwardLeagueTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueTypeID'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { UpwardPlayerRecognitionTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPlayerRecognitionTypeID'
import { UpwardPositionTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPositionTypeID'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'

const baseUrl = 'upwardTypes/'

const retrieveLeagueTypes = async () => {
  return await restService.get<UpwardLeagueTypeID[]>(`${baseUrl}league`)
}

const retrieveProgramTypes = async () => {
  return await restService.get<UpwardProgramTypeID[]>(`${baseUrl}program`)
}

const retrieveRecognitionTypes = async (program: string) => {
  return await restService.get<UpwardPlayerRecognitionTypeID>(`${baseUrl}playerRecognitionTypes/${program}`)
}

const retrievePositionTypes = async () => {
  return await restService.get<UpwardPositionTypeID[]>(`${baseUrl}positions`)
}

const retrieveGradeTypes = async () => {
  return await restService.get<UpwardGradeTypeID[]>(`${baseUrl}gradeTypes`)
}

export default {
  retrieveLeagueTypes,
  retrieveProgramTypes,
  retrieveRecognitionTypes,
  retrievePositionTypes,
  retrieveGradeTypes,
}
