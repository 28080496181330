// Misc. route information used in multiple places, for example, breadcrumbs.

import { MenuItemConfig } from '@/models/MenuItem'
const home: MenuItemConfig = {
  text: 'Home',
  route: '/home',
  name: 'Home',
}

const profile: MenuItemConfig = {
  text: 'Profile',
  route: 'profile',
  name: 'Profile',
}

const account: MenuItemConfig = {
  text: 'Account',
  route: 'account',
  name: 'Account',
}

const password: MenuItemConfig = {
  text: 'Password',
  route: 'password',
  name: 'password',
}

const search: MenuItemConfig = {
  text: 'Search',
  route: '/search',
  name: 'search',
}

const player: MenuItemConfig = {
  text: 'Player',
  route: '/player/:individualId',
  name: 'player',
}

const coach: MenuItemConfig = {
  text: 'Coach',
  route: '/coach/:individualId',
  name: 'coach',
}

const team: MenuItemConfig = {
  text: 'Team',
  route: '/team/:programId/:divisionId/:teamId',
  name: 'team',
}

const gettingStarted: MenuItemConfig = {
  text: 'Getting Started',
  route: '/start',
  name: 'start',
}

export enum RouterItemsEnum {
  HOME = 'Home',
  SEARCH = 'Search',
  PLAYER = 'Player',
  COACH = 'Coach',
  TEAM = 'Team',
  START = 'Getting Started',
  PROFILE = 'Profile',
  PASSWORD = 'Password',
  ACCOUNT = 'Account',
}

export const RouterItems: Record<RouterItemsEnum, MenuItemConfig> = {
  [RouterItemsEnum.HOME]: home,
  [RouterItemsEnum.SEARCH]: search,
  [RouterItemsEnum.PLAYER]: player,
  [RouterItemsEnum.COACH]: coach,
  [RouterItemsEnum.TEAM]: team,
  [RouterItemsEnum.START]: gettingStarted,
  [RouterItemsEnum.PROFILE]: profile,
  [RouterItemsEnum.PASSWORD]: password,
  [RouterItemsEnum.ACCOUNT]: account,
}
