
import BaseInputWrapper from '@/elements/BaseInputWrapper/BaseInputWrapper.vue'
import uuid from 'uuid'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    BaseInputWrapper,
    Multiselect,
  },
})
export default class MultiSelectInput extends Vue {
  private readonly guid = uuid.v4()

  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly required!: boolean

  @Prop({ type: Boolean, default: true, required: false })
  private readonly enabled!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  private readonly showPointer!: boolean

  @Prop({ type: String, default: '', required: false })
  private readonly prependIcon!: string

  @Prop({ type: String, default: '', required: false })
  private readonly appendIcon!: string

  @Prop({ type: String, default: '', required: false })
  private readonly id!: string

  @Prop({ required: true })
  private readonly value!: any | null

  @Prop({ type: String, default: '', required: false })
  private readonly textPropertyName!: string

  @Prop({ type: String, default: '', required: false })
  private readonly valuePropertyName!: string

  @Prop({ type: String, default: '', required: false })
  private readonly placeholder!: string

  @Prop({ type: String, default: '', required: false })
  private readonly tagPlaceholder!: string

  @Prop({ type: Array, required: true })
  private readonly itemsList!: []

  private internalValue: any = []

  private emitInput(value: any) {
    this.$emit('input', value)
    this.$emit('change', value)
  }

  private mounted() {
    this.internalValue = this.value
  }

  public get elementId() {
    return this.id || `multiselect-${this.guid}`
  }
  @Watch('value')
  private valueChange(newVal: any | null) {
    if (this.internalValue.join() != newVal.join()) this.internalValue = cloneDeep(newVal)
  }
}
