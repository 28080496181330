import restService from '@/services/restService'
import { DivisionGamePlayerInfo } from '@/GeneratedTypes/ListInfo/DivisionGamePlayerInfo'
import { CheerSquadRecognition } from '@/GeneratedTypes/CheerSquadRecognition'
import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'
import { TypeProgramPlayerRecognitionInfo } from '@/GeneratedTypes/ListInfo/TypeProgramPlayerRecognitionInfo'

const baseUrl = 'games/'

const retreiveGameRecognitions = async (
  upwId: string | null,
  gameId: number,
  teamId: number
): Promise<DivisionGamePlayerInfo[]> => {
  const result = await restService.get<DivisionGamePlayerInfo[]>(
    `${baseUrl}playerGameDay/listByGame/${upwId}/${gameId}/${teamId}`
  )
  if (result.isSuccess) {
    return result.data ?? ([] as DivisionGamePlayerInfo[])
  } else {
    return Promise.reject(result.errorObject)
  }
}

const retrieveByLeague = async (upwId: string | null): Promise<DivisionGameInfo[]> => {
  const result = await restService.get<DivisionGameInfo[]>(`${baseUrl}${upwId}`)
  if (result.isSuccess) {
    return result.data ?? ([] as DivisionGameInfo[])
  } else {
    return Promise.reject(result.errorObject)
  }
}

const retreivePlayerRecognitions = async (
  upwId: string | null,
  individualId: number,
  teamId: number
): Promise<DivisionGamePlayerInfo[]> => {
  const result = await restService.get<DivisionGamePlayerInfo[]>(
    `${baseUrl}playerGameDay/listByPlayer/${upwId}/${teamId}/${individualId}`
  )
  if (result.isSuccess) {
    return result.data ?? ([] as DivisionGamePlayerInfo[])
  } else {
    return Promise.reject(result.errorObject)
  }
}

const retreiveCheerleaderRecognitions = async (
  upwId: string | null,
  programType: string | null,
  divisionId: number,
  teamId: number,
  individualId: number
): Promise<CheerSquadRecognition[]> => {
  const result = await restService.get<CheerSquadRecognition[]>(
    `${baseUrl}cheerRecognition/listByPlayer/${upwId}/${programType}/${divisionId}/${teamId}/${individualId}`
  )
  if (result.isSuccess) {
    return result.data ?? ([] as CheerSquadRecognition[])
  } else {
    return Promise.reject(result.errorObject)
  }
}

const retreiveCheerGameRecognitions = async (
  upwId: string | null,
  programType: string | null,
  divisionId: number,
  teamId: number,
  aptId: number
): Promise<CheerSquadRecognition[]> => {
  const result = await restService.get<CheerSquadRecognition[]>(
    `${baseUrl}cheerRecognition/listByAppointment/${upwId}/${programType}/${divisionId}/${teamId}/${aptId}`
  )
  if (result.isSuccess) {
    return result.data ?? ([] as CheerSquadRecognition[])
  } else {
    return Promise.reject(result.errorObject)
  }
}

const updateSportRecognition = async (upwId: string | null, dgp: DivisionGamePlayerInfo) => {
  return await restService.put<DivisionGamePlayerInfo>(
    `${baseUrl}playerGameDay/${upwId}/${dgp.gameID}/${dgp.teamID}/${dgp.playerIndividualID}`,
    dgp
  )
}

export const updateCheerRecognition = async (upwId: string | null, csr: CheerSquadRecognition) => {
  return await restService.put<DivisionGamePlayerInfo>(
    `${baseUrl}cheerRecognition/${upwId}/${csr.typeProgramID}/${csr.divisionID}/${csr.teamID}/${csr.playerIndividualID}/${csr.cheerSquadGameAppointmentID}`,
    csr
  )
}

const retrievePlayerRecognitionTypes = async (
  typeProgramID: string | null
): Promise<TypeProgramPlayerRecognitionInfo[]> => {
  const result = await restService.get<TypeProgramPlayerRecognitionInfo[]>(
    `${baseUrl}playerRecognitionList/${typeProgramID}`
  )
  if (result.isSuccess) {
    return result.data ?? ([] as TypeProgramPlayerRecognitionInfo[])
  } else {
    return Promise.reject(result.errorObject)
  }
}

export default {
  retreiveGameRecognitions,
  retreiveCheerGameRecognitions,
  retreiveCheerleaderRecognitions,
  updateSportRecognition,
  updateCheerRecognition,
  retreivePlayerRecognitions,
  retrieveByLeague,
  retrievePlayerRecognitionTypes,
}
