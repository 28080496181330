/*
Application Roles are assigned to the user based on logic contained in the application.
*/

export enum ApplicationRoles {
  COACH_WITH_TEAM = 'CoachWithTeam',
  USER_WITH_LEAGUE = 'UserWithLeague',
}

export const allApplicationRoles: string[] = Object.values(ApplicationRoles)
