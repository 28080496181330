import { VolunteerRoles, allVolunteerRoles, coachGroup } from '@/common/Authorization/VolunteerRoles'
import { denyMessaging } from '@/common/Authorization/PermissionGroups'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { MainMenuItemConfig, DisplayLocation, SportOrCheer, LeagueType } from '@/models/MenuItem'
import store from '@/store'

const home: MainMenuItemConfig = {
  text: 'Home',
  icon: 'fas fa-home',
  route: '/home',
  description: '',
  name: 'home',
  displayLocation: [DisplayLocation.SIDEBAR],
}

const account: MainMenuItemConfig = {
  text: 'Account',
  icon: 'far fa-id-card',
  route: '/account/profile',
  description: '',
  name: 'account',
  displayLocation: [DisplayLocation.SIDEBAR],
}

const communication: MainMenuItemConfig = {
  text: 'Communication',
  icon: 'fa fa-paper-plane',
  route: '/communication',
  description: 'Text and email communication tools',
  name: 'communication',
  denyIfAnyRoles: [...denyMessaging, VolunteerRoles.COACH],
  allowIfAnyRoles: [
    ...superUsers,
    VolunteerRoles.CHEER_DIRECTOR,
    VolunteerRoles.COACH_COMMISSIONER,
    VolunteerRoles.REFEREE_COMMISSIONER,
  ],
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
}
const coachCommunication: MainMenuItemConfig = {
  text: 'Communication',
  icon: 'fa fa-paper-plane',
  route: '/communication',
  description: 'Text and email communication tools',
  name: 'communication',
  //denyIfAnyRoles: [...denyMessaging, ...communication.allowIfAnyRoles!],
  allowIfAnyRoles: [VolunteerRoles.COACH],
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
  excludedLeagueTypes: [LeagueType.CAMP],
}

const resources: MainMenuItemConfig = {
  text: 'Resources',
  icon: 'fa fa-book',
  route: '/resources',
  name: 'resources',
  description: 'Use these tools to guide you through the season',
  allowIfAnyRoles: [...superUsers, ...allVolunteerRoles],
  denyIfAnyRoles: [VolunteerRoles.COACH],
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
  excludedLeagueTypes: [LeagueType.CAMP],
}
const coachResources: MainMenuItemConfig = {
  text: 'Resources',
  icon: 'fa fa-book',
  route: '/resources',
  name: 'resources',
  description: 'Use these tools to guide you through the season',
  allowIfAnyRoles: [...superUsers, ...allVolunteerRoles],
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
  excludedLeagueTypes: [LeagueType.STANDARD],
}

const practiceResources: MainMenuItemConfig = {
  text: 'Practice Resources',
  icon: 'fa fa-tasks',
  route: '/PracticeResources',
  name: 'PracticeResources',
  description: 'Practice plans, devotions, skills & drills, and rules',
  allowIfAnyRoles: [VolunteerRoles.COACH],
  sportOrCheer: SportOrCheer.SPORT_ONLY,
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
  excludedLeagueTypes: [LeagueType.CAMP],
}

const cheerPracticeResources: MainMenuItemConfig = {
  text: 'Practice Resources',
  icon: 'fa fa-music',
  route: '/cheerresources',
  name: 'CheerPracticeResources',
  description: 'Cheers, dances, chants, and tunes',
  allowIfAnyRoles: [VolunteerRoles.COACH],
  sportOrCheer: SportOrCheer.CHEER_ONLY,
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
}

const selectPracticeResources: MainMenuItemConfig = {
  text: 'Practice Resources',
  icon: 'fa fa-tasks',
  route: '/selectResources',
  name: 'SelectResources',
  description: 'Devotions',
  allowIfAnyRoles: [VolunteerRoles.COACH],
  sportOrCheer: SportOrCheer.SELECT_ONLY,
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
  excludedLeagueTypes: [LeagueType.CAMP],
}

const ministryResources: MainMenuItemConfig = {
  text: 'Ministry Resources',
  icon: 'fa fa-bible',
  route: '/ministryresources',
  name: 'MinistryResources',
  description: 'Practice plans and devotions',
  allowIfAnyRoles: [VolunteerRoles.COACH],
  sportOrCheer: SportOrCheer.CHEER_ONLY,
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
}

const gameDay: MainMenuItemConfig = {
  text: 'Game Day',
  icon: 'fas fa-clipboard-list',
  route: '/gameday',
  name: 'gameDay',
  defaultChildRoute: 'games',
  description: 'Manage game day roster, substitutions and star distribution',
  allowIfAnyRoles: [...coachGroup],
  sportOrCheer: SportOrCheer.SPORT_ONLY,
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
  excludedLeagueTypes: [LeagueType.CAMP],
}

const starDistribution: MainMenuItemConfig = {
  text: 'Star Distribution',
  icon: 'fas fa-star',
  route: '/startdistribution',
  name: 'startDistribution',
  defaultChildRoute: 'games',
  description: 'Assign recognition stars during a game',
  allowIfAnyRoles: [...coachGroup],
  sportOrCheer: SportOrCheer.CHEER_ONLY,
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
}

const selectStarDistribution: MainMenuItemConfig = {
  text: 'Star Distribution',
  icon: 'fas fa-star',
  route: '/startdistribution',
  name: 'startDistribution',
  defaultChildRoute: 'games',
  description: 'Assign recognition stars during a game',
  allowIfAnyRoles: [...coachGroup],
  sportOrCheer: SportOrCheer.SELECT_ONLY,
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
}

const manageTeam: MainMenuItemConfig = {
  text: 'Team',
  icon: 'fas fa-chalkboard-teacher',
  route: '/ManageTeam',
  name: 'manageTeam',
  description: 'Manage your roster, view schedule, see team details',
  allowIfAnyRoles: [...coachGroup],
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
  excludedLeagueTypes: [LeagueType.CAMP],
}

const install: MainMenuItemConfig = {
  text: 'Install',
  icon: 'fas fa-plus-square',
  route: '/install',
  name: 'install',
  description: 'Install a shortcut icon on your home screen or desktop',
  displayLocation: [DisplayLocation.SIDEBAR],
}

const search: MainMenuItemConfig = {
  text: 'Search',
  icon: 'fas fa-search',
  route: '/search',
  name: 'search',
  description: 'Search for players, coaches or teams by name',
  allowIfAnyRoles: superUsers,
  denyIfAnyRoles: allVolunteerRoles,
  displayLocation: [DisplayLocation.SIDEBAR],
}

const gettingStarted: MainMenuItemConfig = {
  text: 'Getting Started',
  icon: 'fas fa-door-open',
  route: '/start',
  name: 'start',
  description: 'Prepare for your role this season',
  displayLocation: [DisplayLocation.HOME, DisplayLocation.SIDEBAR],
}

class refereeSchedule implements MainMenuItemConfig {
  public get text() {
    return `${store.state.programs.currentProgram?.refLabel} Schedule`
  }
  public get icon() {
    return 'fas fa-calendar-day'
  }
  public get route() {
    return '/referee_schedule'
  }
  public get name() {
    return 'referee'
  }
  public get description() {
    return `View the ${store.state.programs.currentProgram?.refLabel.toLocaleLowerCase()} schedule for this league`
  }
  public get displayLocation() {
    return [DisplayLocation.HOME, DisplayLocation.SIDEBAR]
  }
  public get allowIfAnyRoles() {
    return [VolunteerRoles.REFEREE, VolunteerRoles.REFEREE_COMMISSIONER]
  }
  public get excludedLeagueTypes() {
    return [LeagueType.CAMP]
  }
}

export enum MainMenuEnum {
  MANAGE_TEAM = 'ManageTeam',
  RESOURCES = 'Resources',
  PRACTICE_RESOURCES = 'practiceResources',
  COACH_RESOURCES = 'coachResources',
  COMMUNICATION = 'Communication',
  COACH_COMMUNICATION = 'CoachCommunication',
  GAMEDAY = 'GameDay',
  INSTALL = 'Install',
  SEARCH = 'Search',
  STARTED = 'Starting',
  HOME = 'Home',
  STAR_DISTRIBUTION = 'StartDistribution',
  SELECT_STAR_DISTRIBUTION = 'SelectStartDistribution',
  MINISTRY_RESOURCES = 'MinistryResources',
  CHEER_PRACTICE_RESOURCES = 'CheerPracticeResources',
  SELECT_PRACTICE_RESOURCES = 'SelectPracticeResources',
  REFEREE_SCHEDULE = 'RefereeSchedule',
  ACCOUNT = 'Account',
}

export const MainMenu: Record<MainMenuEnum, MainMenuItemConfig> = {
  [MainMenuEnum.HOME]: home,
  [MainMenuEnum.ACCOUNT]: account,
  [MainMenuEnum.STARTED]: gettingStarted,
  [MainMenuEnum.MANAGE_TEAM]: manageTeam,
  [MainMenuEnum.PRACTICE_RESOURCES]: practiceResources,
  [MainMenuEnum.COACH_RESOURCES]: coachResources,
  [MainMenuEnum.MINISTRY_RESOURCES]: ministryResources,
  [MainMenuEnum.CHEER_PRACTICE_RESOURCES]: cheerPracticeResources,
  [MainMenuEnum.SELECT_PRACTICE_RESOURCES]: selectPracticeResources,
  [MainMenuEnum.SEARCH]: search,
  [MainMenuEnum.RESOURCES]: resources,
  [MainMenuEnum.COMMUNICATION]: communication,
  [MainMenuEnum.COACH_COMMUNICATION]: coachCommunication,
  [MainMenuEnum.GAMEDAY]: gameDay,
  [MainMenuEnum.INSTALL]: install,
  [MainMenuEnum.STAR_DISTRIBUTION]: starDistribution,
  [MainMenuEnum.SELECT_STAR_DISTRIBUTION]: selectStarDistribution,
  [MainMenuEnum.REFEREE_SCHEDULE]: new refereeSchedule(),
}
