
import InputLabel from '@/elements/InputLabel.vue'
import { Component, Mixins, Watch, Prop } from 'vue-property-decorator'
import TextLikeWrapper from '@/elements/TextLikeWrapper/TextLikeWrapper.vue'
import { TextLikeWrapperProps } from '@/elements/TextLikeWrapper/TextLikeWrapperProps'

@Component({
  components: {
    InputLabel,
    TextLikeWrapper,
  },
})
export default class MemoInput extends Mixins(TextLikeWrapperProps) {
  @Prop({ type: Number, default: null, required: false })
  private readonly rows!: number | null

  @Prop({ type: String, default: '', required: false })
  private readonly placeholder!: string

  @Prop({ type: String, required: true })
  private readonly value!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly isReadOnly!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  private readonly haltInput!: boolean

  private internalValue: string = this.value

  private handleInput() {
    this.$emit('input', this.internalValue)
  }

  private handleChange() {
    this.$emit('change', this.internalValue)
  }

  private handleBlur() {
    this.$emit('blur', this.internalValue)
  }

  private canInput(event: KeyboardEvent) {
    if (this.haltInput && event.key != 'Backspace') {
      event.preventDefault()
    }
    return
  }

  @Watch('value')
  private onValueChanged() {
    this.internalValue = this.value
  }
}
