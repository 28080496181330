import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'

export function getEmptyDivisionTeamInfo(): DivisionTeamInfo {
  return {
    leagueID: 0,
    typeProgramID: null,
    leagueDirectorPartnerID: 0,
    divisionID: 0,
    divisionName: null,
    teamID: 0,
    teamName: null,
    upwardTeamID: null,
    typeColorID: null,
    teamPageUrl: null,
    currentPracticeNumber: 0,
    currentGameID: 0,
    currentGameOffenseFirst: true,
    overrideTypeGameFormatID: null,
    practices: null,
    coaches: null,
    players: null,
    coachCount: 0,
    playerCount: 0,
    headCoachName: null,
    upwardLeagueID: null,
    hideTeamPageRoster: false,
    hideCoachEmailOnTeamPage: false,
    hideCoachPhoneOnTeamPage: false,
    hideAssistantCoachEmailOnTeamPage: false,
    hideAssistantCoachPhoneOnTeamPage: false,
    hideScheduleOnTeamPage: false,
    hideHeadCoachLabel: false,
    typePracticePlanID: null,
    typeGameFormatID: null,
    overrideNumberOfSegments: 0,
    typeLeagueScoringID: null,
    showStandingsTeamPage: false,
    useLevelFourSub: false,
    ruleLevel: 0,
  }
}

export function getEmptyDivisionTeamInfoList(): DivisionTeamInfo[] {
  return [getEmptyDivisionTeamInfo()]
}
