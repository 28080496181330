
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { FilterOptions, getInitialFilterOptions } from '@/components/MessageFilters/data.ts'
import { FilterByOptions } from '@/components/MessageRecipient/data.ts'
import { ContactBuilder, getEmptyContactBuilder } from '@/models/Communication/ContactBuilder'
import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'
import { DivisionTeam } from '@/GeneratedTypes/DivisionTeam'
import { cloneDeep } from 'lodash'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import Loading from '@/elements/Loading.vue'
import SwitchSlot from '@/elements/SwitchSlot.vue'
import SelectInput from '@/elements/SelectInput.vue'
import DayOfWeekSelect from '@/elements/DayOfWeekSelect.vue'
import MultiselectInput from '@/elements/MultiselectInput.vue'
import GradeDropdownInput from '@/elements/GradeDropdownInput.vue'
import { denyIfAnyOnly } from '@/plugins/Authorization/permissionChecks'

@Component({
  components: {
    Loading,
    SwitchSlot,
    SelectInput,
    DayOfWeekSelect,
    MultiselectInput,
    GradeDropdownInput,
  },
})
export default class MessageFilters extends Vue {
  @Prop({ type: Object, default: getInitialFilterOptions, required: false })
  private readonly value!: FilterOptions

  @Prop({ type: Object, default: getEmptyContactBuilder, required: false })
  private readonly contactBuilder!: ContactBuilder

  @Prop({ type: Boolean, default: false, required: false })
  private readonly loading!: boolean

  private readonly filterByOptions = FilterByOptions
  private localFilterOptions = getInitialFilterOptions()
  private divisions: Array<LeagueDivision> = []
  private teamDivisions: Array<LeagueDivision> = []
  private teams: Array<DivisionTeam> = []
  private selectedTeams: DivisionTeam[] = []
  private filterBy = this.filterByOptions[0].value
  private readonly volunteerRoles = VolunteerRoles
  private denyIfAnyOnly = denyIfAnyOnly
  private mounted() {
    this.initAllData()
  }

  private initAllData() {
    this.setDivisions()
    this.setTeams()
    this.setTeamDivisionDefault()
    this.setGrades()
  }

  private setGrades() {
    this.localFilterOptions.grades = this.value.grades
  }

  private setDivisions() {
    if (this.contactBuilder) {
      this.divisions = this.contactBuilder.divisions
    }
  }

  private onDivisionChange() {
    // in the API. filtering by division occures by sending the list of teams in the division
    // so get all the teamID and set this.localFilterOptions.teams
    if (this.localFilterOptions.divisionId) {
      const teams = this.teams.filter((team) => team.divisionID === this.localFilterOptions.divisionId)
      this.localFilterOptions.teams = teams.reduce((teams: DivisionTeam[], team: DivisionTeam) => {
        teams.push(team)
        return teams
      }, [])
      this.localFilterOptions.teams = teams
      this.emitFilterOptionsChange()
    }
  }

  private onMultiselectChange() {
    this.localFilterOptions.teams = this.selectedTeams
    this.emitFilterOptionsChange()
  }

  private setTeams() {
    if (this.contactBuilder) {
      this.teams = cloneDeep(this.contactBuilder.divisionTeams)
      this.teamDivisions = cloneDeep(this.contactBuilder.divisions)
    }
  }

  private get getSelectableTeams() {
    if (this.localFilterOptions.teamDivisionId) {
      this.localFilterOptions.teams = []
      if (this.localFilterOptions.teamDivisionId === -1) {
        return this.teams
      } else {
        const newTeams = this.teams.filter((team) => {
          return team.divisionID === this.localFilterOptions.teamDivisionId
        })
        return newTeams
      }
    }
    return []
  }

  private emitFilterOptionsChange() {
    if (this.localFilterOptions.filterBy === 'team') {
      this.localFilterOptions.teams = this.selectedTeams
    }
    this.$emit('change', this.localFilterOptions)
    this.$emit('input', this.localFilterOptions)
  }

  private filterOptionChanged(selection: string) {
    this.clearFilters()
    if (selection === 'team') this.setTeamDivisionDefault()
    this.emitFilterOptionsChange()
  }

  private setTeamDivisionDefault() {
    if (this.teamDivisions.length) {
      this.localFilterOptions.teamDivisionId = this.teamDivisions[0].divisionID
      this.localFilterOptions.teams = []
    }
  }
  private clearFilters() {
    this.localFilterOptions.teams = []
    this.selectedTeams = []
    this.localFilterOptions.practiceNight = null
    this.localFilterOptions.divisionId = -1
    this.localFilterOptions.teamDivisionId = -1
  }

  @Watch('contactBuilder', { deep: true })
  private async contactBuilderChanged() {
    this.initAllData()
  }

  @Watch('value', { deep: true, immediate: true })
  private syncToLocalValue() {
    this.localFilterOptions = cloneDeep(this.value)

    if (this.value.teams) {
      this.selectedTeams = this.value.teams
    }
  }
}
