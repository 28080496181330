import restService from '@/services/restService'
import { AccountInfo } from '@/GeneratedTypes/ListInfo/AccountInfo'

const baseUrl = 'partners/'

export interface PartnerSearchResultsType {
  /*** name of church hyphenated with location ***/
  name: string
  accountNumber: number
  // ... other stuff returned, but don't need it yet
}

/**
 * Searches the list of partners by a prefix term.
 * @param term
 */
const searchPartners = async (term: string) => {
  const x = await restService.post<PartnerSearchResultsType[]>(`partners/search`, {
    searchFilter: term,
    useStartsWith: true,
  })
  if (x.isSuccess) {
    return x.data
  }

  throw new Error('A problem occurred searching partners.')
}

const retreivePartnerInfo = async (accountId: string): Promise<AccountInfo | null> => {
  const result = await restService.get<AccountInfo>(`${baseUrl}${accountId}/info`)
  return result.data
}

const retreivePartners = async (accountNumber: string[]): Promise<AccountInfo[] | null> => {
  const result = await restService.post<AccountInfo[]>(`${baseUrl}`, accountNumber)
  return result.data
}

export default {
  retreivePartnerInfo,
  retreivePartners,
  searchPartners,
}
