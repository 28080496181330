
import { Component, Watch, Prop, Mixins } from 'vue-property-decorator'
import TextLikeWrapper from '@/elements/TextLikeWrapper/TextLikeWrapper.vue'
import { TextLikeWrapperProps } from '@/elements/TextLikeWrapper/TextLikeWrapperProps'
import { TextLikeEvents } from '@/elements/TextLikeWrapper/TextLikeEvents'
import Loading from '@/elements/Loading.vue'

@Component({
  components: {
    TextLikeWrapper,
    Loading,
  },
})
export default class TextInput extends Mixins(TextLikeWrapperProps, TextLikeEvents) {
  @Prop({ type: String, default: '', required: false })
  private readonly tabIndex!: string

  @Prop({ type: String, default: 'text', required: false })
  private readonly inputType!: string

  @Prop({ required: false })
  private readonly maxlength!: number

  @Prop({ type: String, default: '', required: false })
  private readonly placeholder!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly underlineOnly!: boolean

  @Prop({ type: String, default: '', required: false })
  private readonly value!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly loadingData!: string

  internalValue: string | number = this.value

  private setInternalValue() {
    this.internalValue = this.value
  }

  @Watch('value')
  private onValueChanged() {
    this.setInternalValue()
  }

  mounted() {
    this.setInternalValue()
  }
}
