
import { Vue, Component } from 'vue-property-decorator'
import PageStateToggle from '@/components/PageStateToggle.vue'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'

@Component({
  components: {
    PageStateToggle,
    Breadcrumbs,
  },
})
export default class Communication extends Vue {
  private pageStates: string[] = []
  private currentToggle: string | null = null
  private breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.COMMUNICATION, isActive: false },
  ])

  private mounted() {
    this.pageStates = ['Email', 'SMS']
    if (this.$hasAtLeastOne(superUsers) || this.$hasAllOf([VolunteerRoles.COACH])) {
      this.pageStates.push('Team Alert')
    }
    this.currentToggle = this.pageStates[0]
    this.stateChanged(this.currentToggle)
  }

  private async stateChanged(state: string | null) {
    let path = `/communication/individual/${this.currentToggle}`
    if (state === 'Team Alert') path = '/communication/team'
    this.$router.push(path)
  }
}
