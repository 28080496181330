import { DivisionGamePlayer } from '@/GeneratedTypes/DivisionGamePlayer'
import gameClient from '@/clients/gameClient'
import { CheerSquadRecognition } from '@/GeneratedTypes/CheerSquadRecognition'
import { defineGetters, defineActions, defineMutations } from 'direct-vuex'

interface GameState {
  currentGameRecognitions: DivisionGamePlayer[]
  currentCheerRecognitions: CheerSquadRecognition[]
  skipPlayerRanking: boolean
}

const gameState: GameState = {
  currentGameRecognitions: [] as DivisionGamePlayer[],
  currentCheerRecognitions: [] as CheerSquadRecognition[],
  skipPlayerRanking: false,
}

export enum mutationNames {
  setCurrentGameRecognitions = 'setCurrentGameRecognitions',
  setCurrentCheerGameRecognitions = 'setCurrentCheerGameRecognitions',
  setSkipPlayerRanking = 'setSkipPlayerRanking',
}

const mutations = defineMutations<GameState>()({
  [mutationNames.setCurrentGameRecognitions](
    state,
    { recognitions }: { recognitions: DivisionGamePlayer[] }
  ) {
    state.currentGameRecognitions = recognitions
  },
  [mutationNames.setCurrentCheerGameRecognitions](
    state,
    { recognitions }: { recognitions: CheerSquadRecognition[] }
  ) {
    state.currentCheerRecognitions = recognitions
  },
  [mutationNames.setSkipPlayerRanking](state, { skipRanking }: { skipRanking: boolean }) {
    state.skipPlayerRanking = skipRanking
  },
})

export enum getterNames {
  currentGameRecognitions = ' currentGameRecognitions',
  currentCheerGameRecognitions = ' currentCheerGameRecognitions',
  skipPlayerRanking = 'skipPlayerRanking',
}

const getterTree = defineGetters<GameState>()({
  [getterNames.currentGameRecognitions]: (state) => state.currentGameRecognitions,
  [getterNames.currentCheerGameRecognitions]: (state) => state.currentCheerRecognitions,
  [getterNames.skipPlayerRanking]: (state) => state.skipPlayerRanking,
})

export enum actionNames {
  fetchCurrentGameRecognitions = 'fetchCurrentGameRecognitions',
  fetchCurrentCheerGameRecognitions = 'fetchCurrentCheerGameRecognitions',
}

const actions = defineActions({
  async [actionNames.fetchCurrentGameRecognitions](
    { commit },
    { upwId, gameId, teamId }: { upwId: string | null; gameId: number; teamId: number }
  ): Promise<boolean> {
    const result = await gameClient.retreiveGameRecognitions(upwId, gameId, teamId)

    if (result) {
      commit(mutationNames.setCurrentGameRecognitions, { recognitions: result })
      return true
    }

    return false
  },
  async [actionNames.fetchCurrentCheerGameRecognitions](
    { commit },
    {
      upwId,
      programType,
      divisionId,
      teamId,
      aptId,
    }: { upwId: string | null; programType: string | null; divisionId: number; teamId: number; aptId: number }
  ): Promise<boolean> {
    const result = await gameClient.retreiveCheerGameRecognitions(
      upwId,
      programType,
      divisionId,
      teamId,
      aptId
    )

    if (result) {
      commit(mutationNames.setCurrentCheerGameRecognitions, { recognitions: result })
      return true
    }

    return false
  },
})

export const namespace = 'game'

export const game = {
  namespaced: true as true,
  state: gameState,
  actions: actions,
  getters: getterTree,
  mutations,
}
