
interface CoachDetail {
  id: number
  name: string | null
  touchPoints: TouchPoint[]
}

interface TouchPoint {
  id: number
  touchPoint: string
  selected: boolean
  type: string //email, mobile, home etc
}

import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { cloneDeep } from 'lodash'

import * as teamStore from '@/store/team'
import * as authStore from '@/store/authentication'

import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { TeamCoachInfo } from 'src/GeneratedTypes/ListInfo/TeamCoachInfo'

import ListItem from '@/components/ListItem.vue'
import Listing from '@/components/Listing.vue'

import InputLabel from '@/elements/InputLabel.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'

import { formatPhone } from '@/formatters/phoneFmt'
import { maxStringLength } from '@/formatters/maxStringLength'

@Component({
  components: {
    ListItem,
    Listing,
    CheckboxInput,
    InputLabel,
  },
})
export default class CoachRecipientPicker extends Vue {
  @Prop({ type: Array, default: [], required: false })
  private readonly value!: string[]

  @Prop({ type: String, default: 'Email', required: false })
  private readonly messageType!: string

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  private currentTeam!: DivisionTeamInfoExt

  @Getter(authStore.getterNames.fullName, { namespace: authStore.namespace })
  private readonly fullName!: string

  private localValue: string[] = []
  private coaches: CoachDetail[] | null = [] as CoachDetail[]

  private mounted() {
    this.initialize()
  }

  private initialize() {
    this.localValue = this.value
    this.coaches = this.coachList()
    this.setSelected()
  }

  private get recipientString() {
    return this.localValue.join()
  }

  private coachList(): CoachDetail[] {
    const coaches = cloneDeep(this.currentTeam.coaches)
    if (!coaches) return []
    if (this.messageType == 'SMS') return this.smsList(coaches)
    if (this.messageType == 'Email') return this.emailList(coaches)
    return []
  }

  emailList(coaches: TeamCoachInfo[]): CoachDetail[] {
    return coaches.map((c) => {
      return {
        name: c.formattedNameFirstLast,
        id: c.individualID,
        touchPoints: c.emailAddresses?.map((e) => {
          return {
            id: e.emailID,
            touchPoint: e.emailAddress,
            selected: false,
          } as TouchPoint
        }),
      } as CoachDetail
    })
  }

  smsList(coaches: TeamCoachInfo[]): CoachDetail[] {
    // Coach phone numbers. Filtered for only MOBILE number
    // and to remove logged in user.
    const list = coaches.map((c) => {
      const mobiles = c.phoneNumbers
        ?.filter((p) => p.typePhoneID === 'MOBILE')
        .map((m) => {
          return {
            id: m.phoneID,
            selected: false,
            touchPoint: m.phoneNumber,
          } as TouchPoint
        })

      return {
        name: c.formattedNameFirstLast,
        id: c.individualID,
        touchPoints: mobiles ? mobiles : null,
      } as CoachDetail
    })

    return list.filter((c) => c.touchPoints?.length > 0).filter((c) => c.name !== this.fullName)
  }

  private fmtContactPoint(contact: string) {
    if (this.messageType === 'SMS') {
      return formatPhone(contact)
    }
    return maxStringLength(contact, 25)
  }

  private setAll(val: boolean) {
    if (!this.coaches) return
    this.coaches.forEach((c) => {
      c.touchPoints.forEach((t) => {
        t.selected = val
      })
    })
  }

  private setSelected() {
    if (!this.coaches) return
    this.coaches.forEach((a) => {
      if (!a.touchPoints) return
      a.touchPoints.forEach((c) => {
        if (!c.touchPoint) return
        if (this.localValue.includes(c.touchPoint)) {
          c.selected = true
        }
      })
    })
  }

  @Watch('coaches', { deep: true })
  private emitSelectionChange() {
    const recipients: string[] = []
    if (!this.coaches) return

    this.coaches.forEach((a) => {
      if (!a.touchPoints) return
      a.touchPoints.forEach((b) => {
        if (!b.selected) return
        recipients.push(b.touchPoint)
      })
    })
    this.$emit('input', recipients)
  }

  @Watch('value')
  private valueChanged() {
    this.localValue = this.value
    this.setSelected()
  }

  @Watch('messageType')
  private messageTypeChanged() {
    this.initialize()
  }
}
