import { Component, Mixins, Prop } from 'vue-property-decorator'
import { SportOrCheer, MainMenuItemConfig, mainMenuItemConfigEmpty } from '@/models/MenuItem'
import { IsCheerMixin } from '@/common/IsCheerMixin'

@Component
export class MenuItemValidProgramMixin extends Mixins(IsCheerMixin) {
  @Prop({ type: Object, default: mainMenuItemConfigEmpty, required: false })
  readonly config!: MainMenuItemConfig

  get validProgramType(): boolean {
    if (!this.config.sportOrCheer) return true //if there is no sportOrCheer then always display
    if (this.isSelect) {
      return this.config.sportOrCheer === SportOrCheer.SELECT_ONLY
    } else {
      if (this.isCheer && this.config.sportOrCheer === SportOrCheer.CHEER_ONLY) return true
      if (!this.isCheer && this.config.sportOrCheer === SportOrCheer.SPORT_ONLY) return true
    }
    return false
  }
}
