
import TextInput from '@/elements/TextInput.vue'
import { Component, Vue, Watch, Prop, Emit } from 'vue-property-decorator'

@Component({
  components: {
    TextInput,
  },
})
export default class SearchInput extends Vue {
  @Prop({ type: String, required: true })
  private readonly value!: string

  @Prop({ type: String, required: false, default: '' })
  private readonly searchButtonText!: string

  @Prop({ type: String, required: false, default: '' })
  private readonly label!: string

  @Prop({ type: Boolean, required: false, default: false })
  private readonly allowBlankInput!: boolean

  private internalValue = ''

  @Emit()
  input() {
    return this.internalValue
  }
  @Emit()
  searchClick() {
    return this.internalValue
  }

  @Emit()
  change() {
    //trigger change if we don't have a search button
    return this.internalValue
  }

  private handleInput() {
    //trigger only if we don't have a search button
    if (!this.searchButtonText || (this.searchButtonText && this.allowBlankInput)) {
      this.input()
    }
  }

  private handleButtonInput() {
    this.searchClick()
    this.input()
  }

  private handleChange() {
    if (!this.searchButtonText) {
      this.change()
    }
  }

  @Watch('value', { immediate: true })
  private onValueChanged() {
    this.internalValue = this.value
  }
}
