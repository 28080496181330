import { cloneDeep } from 'lodash'
import { TeamCoachInfo } from 'src/GeneratedTypes/ListInfo/TeamCoachInfo'
import { TeamCoach } from 'src/GeneratedTypes/TeamCoach'
import { DivisionTeam } from '@/GeneratedTypes/DivisionTeam'
import { TeamPlayer } from 'src/GeneratedTypes/TeamPlayer'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import { DraftException } from 'src/GeneratedTypes/DraftException'
import { DivisionTeamPracticeAppointment } from 'src/GeneratedTypes/DivisionTeamPracticeAppointment'
import { DivisionTeamPracticeAppointmentInfo } from 'src/GeneratedTypes//ListInfo/DivisionTeamPracticeAppointmentInfo'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'

export function toDivisionTeam(teamIn: DivisionTeamInfoExt): DivisionTeam {
  const clone = cloneDeep(teamIn) as any
  delete clone.leagueDirectorPartnerID
  delete clone.divisionName
  delete clone.teamPageUrl
  delete clone.coachCount
  delete clone.playerCount
  delete clone.headCoachName
  delete clone.games
  delete clone.partnerName
  delete clone.divisionContact
  delete clone.games
  delete clone.appointments
  delete clone.divisionGenderGradeRange
  clone.coaches = toTeamCoach(clone.coaches)
  clone.players = toTeamPlayer(clone.players)
  clone.appointments = toDivisionTeamPracticeAppointment(clone.appointments)
  return clone as DivisionTeam
}

export function toTeamCoach(coaches: TeamCoachInfo[]): TeamCoach[] | null {
  if (!coaches) return null
  const clone = cloneDeep(coaches)
  return clone.map((coach: TeamCoachInfo) => {
    return {
      coachIndividualID: coach.individualID,
      headCoach: coach.headCoach,
    }
  })
}

export function toTeamPlayer(players: TeamPlayerInfo[]): TeamPlayer[] | null {
  if (!players) return null
  const clone = cloneDeep(players)
  return clone.map((player: TeamPlayerInfo) => {
    return {
      playerIndividualID: player.individualID,
      typePositionID: player.typePositionID,
      typeTapeDownReasonID: player.typeTapeDownReasonID,
      manualAssignment: player.manualAssignment,
      draftNumber: player.draftNumber,
      rosterRanking: player.rosterRanking,
      coachAssignedJerseyNumber: player.coachAssignedJerseyNumber,
      draftExceptions: player.draftExceptions as DraftException[],
    }
  })
}

export function toDivisionTeamPracticeAppointment(
  apts: DivisionTeamPracticeAppointmentInfo[]
): DivisionTeamPracticeAppointment | null {
  if (!apts) return null
  const clone = cloneDeep(apts) as any
  delete clone.leagueID
  delete clone.typeProgramID
  delete clone.divisionID
  delete clone.teamID
  delete clone.facilityName
  return clone as DivisionTeamPracticeAppointment
}
