
import { defineComponent, ref, computed, PropType } from 'vue'
import { useRouter } from 'vue-router/composables'

import TextInput from '@/elements/TextInput.vue'
import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'

import store from '@/store'
import { logoutUser } from '@/common/Authentication/logoutUser'

export interface ProfileCredentials {
  newEmail: string
  newFullName: string
}

export default defineComponent({
  name: 'EmailVerification',
  props: { credentials: { type: Object as PropType<ProfileCredentials>, required: true, default: '' } },
  components: { TextInput, Loading, Modal },
  setup(props, { emit }) {
    const updateProfile = store.dispatch.authentication.updateProfile
    const router = useRouter()

    const code = ref('')
    const error = ref('')
    const loading = ref(false)
    const verificationComplete = ref(false)
    const canSave = computed(() => code.value.length >= 10)
    const isMultiAccountUser = computed(() => store.getters.authentication.isMultiAccountUser)
    const show = ref(false)
    function cancel() {
      emit('cancel')
    }

    async function verify() {
      await saveProfile()
    }

    async function saveProfile() {
      loading.value = true
      try {
        const r = await updateProfile({
          payload: {
            newUsername: props.credentials.newEmail,
            usernameChangeCode: code.value,
          },
        })
        verificationComplete.value = true
        if (r.incompleteToken) {
          setTimeout(() => {
            logoutUser()
            router.push('/')
          }, 6000)
        }
      } finally {
        loading.value = false
      }
    }

    return { code, verify, canSave, error, cancel, verificationComplete, loading, isMultiAccountUser, show }
  },
})
