
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getEmptyDivisionTeamPracticeAppointmentInfo } from '@/modelHelpers/DivisionTeamPracticeAppointmentInfo'
import { DivisionTeamPracticeAppointmentInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamPracticeAppointmentInfo'
import LabelInput from '@/elements/InputLabel.vue'
import DateCard from '@/components/DateCard.vue'
import moment from 'moment'

@Component({
  components: {
    LabelInput,
    DateCard,
  },
})
export default class Practice extends Vue {
  @Prop({ type: Object, default: getEmptyDivisionTeamPracticeAppointmentInfo, required: false })
  private readonly practice!: DivisionTeamPracticeAppointmentInfo

  private time(dt: string) {
    return moment(dt).format('h:mmA')
  }
}
