
import Toaster from '@/components/Toaster.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Mutation, Getter } from 'vuex-class'
import { getterNames, mutationNames, namespace } from '@/store/errors'
import {
  UpwardExceptionResultWithTimestamp,
  UpwardValidationDetailsWithTimestamp,
} from '@/models/error_timestamp_types'

@Component({
  components: {
    Toaster,
  },
})
export default class TheErrorRenderer extends Vue {
  @Getter(getterNames.currentError, { namespace })
  private currentError!: UpwardExceptionResultWithTimestamp | null

  @Getter(getterNames.allErrors, { namespace })
  private allErrors!: UpwardExceptionResultWithTimestamp[]

  @Mutation(mutationNames.clearCurrentError, { namespace })
  private clearCurrentError!: () => void

  @Getter(getterNames.currentValidationError, { namespace })
  private currentValidationError!: UpwardValidationDetailsWithTimestamp | null

  @Getter(getterNames.allValidationErrors, { namespace })
  private allValidationErrors!: UpwardValidationDetailsWithTimestamp[]

  @Mutation(mutationNames.clearCurrentValidationError, { namespace })
  private clearCurrentValidationError!: () => void

  @Mutation(mutationNames.clearAll, { namespace })
  private clearAll!: () => void

  private alertIsVisible = !!this.currentError

  private highWaterTimeStamp = new Date()
  private get highestTimeStamp() {
    if (this.currentError && this.currentError.timestamp > this.highWaterTimeStamp) {
      this.highWaterTimeStamp = this.currentError.timestamp
    }
    if (this.currentValidationError && this.currentValidationError.timestamp > this.highWaterTimeStamp) {
      this.highWaterTimeStamp = this.currentValidationError.timestamp
    }
    return this.highWaterTimeStamp
  }

  /**
   * Grab the error with the newest timestamp as "current"
   */
  private get currentItem() {
    if (this.currentError && this.currentValidationError) {
      if (this.currentError.timestamp > this.currentValidationError.timestamp) {
        return this.currentError
      }
      return this.currentValidationError
    }
    return this.currentError || this.currentValidationError
  }

  private get itemCount() {
    return this.allErrors.length + this.allValidationErrors.length
  }

  @Watch('$route')
  private onRouteChange() {
    this.clearAll()
  }

  @Watch('currentItem')
  private onCurrentItemChange() {
    this.alertIsVisible = !!this.currentItem
  }

  private onAlertIsVisibleChange() {
    if (!this.alertIsVisible && this.currentItem) {
      this.clearCurrentValidationError()
      this.clearCurrentError()
    }
  }
}
