
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import PrimaryHeading from '@/elements/PrimaryHeading.vue'
import PageStateToggle from '@/components/PageStateToggle.vue'
import PracticeList from '@/components/PracticeList.vue'
import SpecialEventList from '@/components/SpecialEventList.vue'
import GameList from '@/components/GameList.vue'
import PlayerList from '@/components/PlayerList.vue'
import * as teamStore from '@/store/team'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'

@Component({
  components: {
    PrimaryHeading,
    PlayerList,
    PageStateToggle,
    PracticeList,
    GameList,
    SpecialEventList,
    Breadcrumbs,
  },
})
export default class TeamManage extends Vue {
  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  private currentTeam!: DivisionTeamInfoExt

  private currentToggle: string | null = null
  private breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.TEAM, isActive: false },
  ])

  private get pageStates() {
    if (this.currentTeam?.specialEvents?.length ?? 0 > 0) {
      return ['Roster', 'Games', 'Practices', 'Special Events']
    } else {
      return ['Roster', 'Games', 'Practices']
    }
  }

  private get divisionName() {
    if (this.currentTeam && this.currentTeam.divisionName) return this.currentTeam.divisionName
  }
}
