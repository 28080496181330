import { Component, Vue } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import * as upwardTypesStore from '@/store/upwardTypes'
import { Getter } from 'vuex-class'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import * as teamStore from '@/store/team'

@Component
export class IsCheerMixin extends Vue {
  @Getter(upwardTypesStore.getterNames.programTypes, { namespace: upwardTypesStore.namespace })
  protected readonly allProgramTypes!: UpwardProgramTypeID[]

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  protected readonly currentTeam!: DivisionTeamInfoExt

  protected get isCheer(): boolean {
    if (!this.currentTeam) return false //if there is no team, then it can't be cheer
    const allprograms = cloneDeep(this.allProgramTypes)
    const program = allprograms.find((p) => p.upwardTypeID === this.currentTeam.typeProgramID)
    return program ? program.isCheer : false
  }

  protected get isSelect(): boolean {
    if (!this.currentTeam) return false //if there is no team, then it can't be cheer
    return this.currentTeam.typeProgramID === 'UPWARDSELECTMSS'
  }
}
