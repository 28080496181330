
import { Component, Vue, Prop } from 'vue-property-decorator'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { ProgramEnum } from '@/common/programEnum'
import moment from 'moment'

@Component
export default class ListPaging extends Vue {
  @Prop({ type: Number, default: 0, required: false })
  private readonly maxLength!: number

  @Prop({ type: Number, default: 0, required: false })
  private readonly value!: number

  @Prop({ type: Object, required: false })
  private readonly programType!: UpwardProgramTypeID

  @Prop({ type: Number, default: 0, required: true })
  private readonly numberOfSegments!: number

  private current = 1

  mounted() {
    this.current = this.value
  }

  private next() {
    const next = this.current + 1
    if (next <= this.maxLength) {
      this.current = next
      this.$emit('input', this.current)
    } else {
      this.$emit('upperLimit')
    }
  }
  private previous() {
    const previous = this.current - 1
    if (previous >= 1) {
      this.current = previous
      this.$emit('input', this.current)
    } else {
      this.$emit('lowerLimit')
    }
  }

  private get segmentTitle() {
    if (!this.numberOfSegments || !this.programType.upwardTypeID) return ''

    if (
      this.programType.upwardTypeID == ProgramEnum.BASEBALL ||
      this.programType.upwardTypeID == ProgramEnum.SOFTBALL
    ) {
      return `${moment.localeData().ordinal(this.current)} Inning`
    }

    let half = 0
    if (this.programType.upwardTypeID === ProgramEnum.FOOTBALL) half = 2
    if (this.programType.upwardTypeID !== ProgramEnum.FOOTBALL) half = this.numberOfSegments / 2

    if (this.current <= half) {
      return 'First Half'
    }
    if (this.current > half) {
      return 'Second Half'
    }
  }

  private get segmentSubTitle() {
    if (this.programType.upwardTypeID == ProgramEnum.FOOTBALL) {
      return this.footballQuarterMap.get(this.current)
    }

    if (
      this.programType.upwardTypeID == ProgramEnum.BASEBALL ||
      this.programType.upwardTypeID == ProgramEnum.SOFTBALL
    ) {
      return ``
    }

    return `current segment: ${this.current}`
  }

  footballQuarterMap = new Map([
    [1, 'First Quarter'],
    [2, 'Second Quarter'],
    [3, 'Third Quarter'],
    [4, 'Fourth Quarter'],
  ])
}
