import { Credentials } from '@/models/Credentials'

/**
 * Returns the primary account number (really just indirect the logic for getting primary account number) or null if none
 * @param credentials
 *
 */
function getPrimaryAccountFromCredentials(credentials: Credentials | null) {
  return credentials?.accountNumbers ? credentials.accountNumbers[0] : null
}

/**
 * Returns if the credentials are valid.
 * @param credentials
 */
const hasValidCredentials = (credentials: Credentials | null) => {
  return !!credentials
}

/**
 * Returns if the credentials are impersonating a user.
 * @param credentials
 * @return boolean
 */
const isImpersonating = (credentials: Credentials | null) => {
  return credentials && credentials.impersonatedAccount && credentials.impersonationActive
}

export default { getPrimaryAccountFromCredentials, hasValidCredentials, isImpersonating }
