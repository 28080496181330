export interface MenuItemConfig {
  text: string
  route: string
  name: string // route name
  defaultChildRoute?: string
  denyIfAnyRoles?: string[] | null
  allowIfAnyRoles?: string[] | null
  denyIfAllRoles?: string[] | null
  allowIfAllRoles?: string[] | null
  ranking?: string[] | null
}

export interface MainMenuItemConfig extends MenuItemConfig {
  description: string
  icon: string
  displayLocation: DisplayLocation[]
  sportOrCheer?: SportOrCheer | null //leave bank or null to display for both sport and cheer
  excludedLeagueTypes?: LeagueType[] | null
}

export function mainMenuItemConfigEmpty(): MainMenuItemConfig {
  return {
    text: '',
    icon: '',
    route: '',
    description: '',
    name: '',
    defaultChildRoute: '',
    allowIfAnyRoles: null,
    displayLocation: [],
    sportOrCheer: null,
  }
}

export enum DisplayLocation {
  HOME = 'Home',
  SIDEBAR = 'Sidebar',
}

export enum SportOrCheer {
  SPORT_ONLY = 'sportOnly',
  CHEER_ONLY = 'cheerOnly',
  SELECT_ONLY = 'selectOnly',
}

export enum LeagueType {
  CLINIC = 'CLINIC',
  CAMP = 'CAMP',
  CONNECT = 'CONNECT',
  STANDARD = 'STANDARD',
}
