
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { mainMenuItemConfigEmpty, MainMenuItemConfig, DisplayLocation } from '@/models/MenuItem'

import { MenuItemValidProgramMixin } from '@/common/MenuItemValidProgramMixin'
import { ExcludedLeagueTypesMixin } from '@/common/ExcludedLeagueTypesMixin'
import { adminUsers } from '@/common/Authorization/AccountRoles'

import * as authentication from '@/store/authentication'

@Component
export default class SidebarItem extends Mixins(MenuItemValidProgramMixin, ExcludedLeagueTypesMixin) {
  @Prop({ type: Object, default: mainMenuItemConfigEmpty, required: false })
  readonly config!: MainMenuItemConfig

  @Getter(authentication.getterNames.impersonationActive, { namespace: authentication.namespace })
  private impersonationActive!: boolean

  get validLocation(): boolean {
    if (!this.config.displayLocation) return true
    return this.config.displayLocation.includes(DisplayLocation.SIDEBAR)
  }

  get show() {
    //If this is an admin user make sure impersonation is active
    const impersonationIsInValidState = this.$hasAtLeastOne(adminUsers) ? this.impersonationActive : true

    return (
      this.validLocation && this.validProgramType && impersonationIsInValidState && !this.leagueTypeExcluded
    )
  }
}
