
import { Vue, Component } from 'vue-property-decorator'
import ProgramList from '@/components/ProgramList.vue'
import Loading from '@/elements/Loading.vue'
import InputLabel from '@/elements/InputLabel.vue'
import * as programStore from '@/store/programs'
import * as authorizeStore from '@/store/authorization'
import * as authentication from '@/store/authentication'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { Getter, Mutation, Action } from 'vuex-class'
import { ApplicationRoles } from '@/common/Authorization/ApplicationRoles'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { VolunteerRole } from '@/models/VolunteerRole'

@Component({
  components: {
    ProgramList,
    InputLabel,
    Loading,
  },
})
export default class Programs extends Vue {
  @Getter(programStore.getterNames.programs, { namespace: programStore.namespace })
  private programs!: LeagueInfoCondensed[]

  @Getter(authentication.getterNames.userData, { namespace: authentication.namespace })
  private userToken!: string | undefined

  @Getter(authentication.getterNames.accountRoles, { namespace: authentication.namespace })
  private accountRole!: string[] | undefined

  @Getter(authentication.getterNames.leagueRoles, { namespace: authentication.namespace })
  private leagueRole!: VolunteerRole[] | undefined

  @Mutation(programStore.mutationNames.setCurrentProgram, { namespace: programStore.namespace })
  private setCurrentProgram!: ({ upwId }: { upwId: string }) => void

  @Action(programStore.actionNames.fetchPrograms, { namespace: programStore.namespace })
  private fetchPrograms!: ({ isCoach }: { isCoach: boolean }) => Promise<boolean>

  @Mutation(authorizeStore.mutationNames.addApplicationRole, { namespace: authorizeStore.namespace })
  private addApplicationRole!: ({ role }: { role: ApplicationRoles }) => void

  @Mutation(authorizeStore.mutationNames.removeApplicationRole, { namespace: authorizeStore.namespace })
  private removeApplicationRole!: ({ role }: { role: ApplicationRoles }) => void

  private loading = true
  private applicationRoles = ApplicationRoles

  private async mounted() {
    const isCoach = this.leagueRole?.some((r) => r.RoleID == VolunteerRoles.COACH) ?? false
    const isSuperUser = this.accountRole?.some((r) => superUsers.join().includes(r)) ?? false
    await this.fetchPrograms({ isCoach: isCoach && !isSuperUser })
    this.setApplicationRoles()

    if (this.programs.length === 1 && this.programs[0].upwardLeagueID) {
      this.setCurrentProgram({ upwId: this.programs[0].upwardLeagueID })
      this.$router.push({ name: 'Home' })
    }
    this.loading = false
  }

  private setApplicationRoles() {
    const role = this.applicationRoles.USER_WITH_LEAGUE
    if (this.programs.length) {
      //used to guard routes
      this.addApplicationRole({ role: role })
    } else {
      this.removeApplicationRole({ role: role })
    }
  }

  private go(upwId: string) {
    this.setCurrentProgram({ upwId })
    this.$router.push({ name: 'Home' })
  }

  private get isLoading() {
    //loading or redirecting for case with 1 program
    return this.loading === true || this.programs.length === 1
  }

  private get hasProgram() {
    return this.programs.length > 0
  }
}
