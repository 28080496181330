import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import * as teamStore from '@/store/team'
import * as lineupStore from '@/store/lineup'
import { GameLineup, getEmptyGameLineup } from '@/models/Lineup'
@Component
export class CurrentGameLineupMixin extends Vue {
  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  protected readonly currentTeam!: DivisionTeamInfoExt

  @Getter(lineupStore.getterNames.lineups, { namespace: lineupStore.namespace })
  protected readonly allLineups!: GameLineup[]

  @Mutation(lineupStore.mutationNames.upsertLineup, { namespace: lineupStore.namespace })
  protected upsertLineup!: ({ lineup }: { lineup: GameLineup }) => void

  @Mutation(lineupStore.mutationNames.deleteLineup, { namespace: lineupStore.namespace })
  protected deleteLineup!: ({ lineup }: { lineup: GameLineup }) => void

  protected get currentGameLineup() {
    const lineup = this.maintainDataModel()
    return lineup ? lineup : getEmptyGameLineup()
  }

  private maintainDataModel(): GameLineup | undefined {
    //From time to time the JSON structure of a gameLineup may need to change.
    //Each time a gameLineup is retieved, make sure it is up to date.

    //All lineups more than a year old are automatically deleted,
    // so any check more than a year old can be removed.
    let dirty = false
    const divisionId = this.currentTeam.divisionID
    const teamId = this.currentTeam.teamID
    const lineup = this.allLineups.find((l) => {
      return l.gameId === this.gameId && l.teamId === teamId && l.divisionId === divisionId
    })
    if (!lineup) return

    //** CHECKED ***/
    if (lineup.hasOwnProperty('currentSegment')) {
      //this is not a valid GameLineup, remove it so the lineup will regenerate
      //05/20/2020
      dirty = true
      this.deleteLineup({ lineup })
    }

    //** RETURN CLEAN GameLineup **/
    if (dirty) {
      return this.allLineups.find((l) => {
        return l.gameId === this.gameId && l.teamId === teamId && l.divisionId === divisionId
      })
    }
    return lineup
  }

  protected currentGameLineupById(gameId: number) {
    const divisionId = this.currentTeam.divisionID
    const teamId = this.currentTeam.teamID
    const lineup = this.allLineups.find((l) => {
      return l.gameId === gameId && l.teamId === teamId && l.divisionId === divisionId
    })
    return lineup ? lineup : getEmptyGameLineup()
  }

  protected get gameId(): number {
    if (!this.$route.params.gameId) throw Error('Missing gameId on Route')
    return Number(this.$route.params.gameId)
  }
}
