
import { Component, Vue, Prop } from 'vue-property-decorator'
import { GridColumn, TemplateType } from '@/common/GridColumn'
import SmartGridRow from './SmartGridRow.vue'
import SmartTable from 'vuejs-smart-table'

Vue.use(SmartTable)
Vue.component('smart-tr', SmartGridRow)

@Component({
  components: {
    SmartTable,
    SmartGridRow,
  },
  methods: {
    getColumnComponent: (data: TemplateType) => {
      const c = data()
      return c?.template
    },
  },
})
export default class VueSmartGrid extends Vue {
  @Prop({ type: Array, required: true })
  private readonly columns!: GridColumn[]

  @Prop({ type: Array, required: true })
  private readonly gridData!: []

  @Prop({ type: Boolean, required: false, default: true })
  private readonly showHeader!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  private readonly showEditButton!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  private readonly showDeleteButton!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  private readonly showDeleteButtonHeader!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  private readonly showEditButtonHeader!: boolean

  @Prop({ type: String, required: false, default: 'Edit' })
  private readonly editButtonInnerHtml!: string

  @Prop({ type: String, required: false, default: 'Delete' })
  private readonly deleteButtonInnerHtml!: string

  @Prop({ type: String, required: false, default: 'btn btn-primary' })
  private readonly deleteButtonClasses!: string

  @Prop({ type: String, required: false, default: 'btn btn-primary' })
  private readonly editButtonClasses!: string

  @Prop({ type: Boolean, required: false, default: false })
  private readonly customSelection!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  private readonly tableStriped!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  private readonly allowRowSelection!: boolean

  @Prop({ type: Number, required: false, default: 0 })
  private readonly pageSize!: number

  @Prop({ type: Number, required: false, default: 0 })
  private readonly maxPageLinks!: number

  @Prop({ type: Boolean, required: false, default: false })
  private readonly boundaryLinks!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  private readonly directionLinks!: boolean

  selectedRows: any[] = []
  selectedRow: any
  currentTr: any
  private currentPage = 1
  private totalPages = 0

  //@todo better job of defining the return, by inspection it is row.row as a Record<string, unknown>.
  private updateSelections(row: any) {
    /**
     * @todo ideally emit either the row or a normalized row +
     * some event info that might be helpful.
     * e.g. {rowNum: number; event: MouseEvent; data: Record<string,unknown>} */
    this.$emit('selectionChanged', row)
  }

  public selectRow(row: any) {
    ;(this.$refs.table as Vue & { selectRow: (row: any) => void }).selectRow(row)
  }

  private handleEditClicked(row: any) {
    this.$emit('onEditRow', row)
  }

  private handleDeleteClicked(row: any) {
    this.$emit('onDeleteRow', row)
  }

  /** Added to surpress VueJS runtime error, but not sure what Rob's plan is for this. */
  private notifyRowClicked(e: MouseEvent) {
    e.stopPropagation()
    e.preventDefault()
    this.$emit('rowClicked', {}) //supresses an error on test. Not sure Robert's intent
  }
}
