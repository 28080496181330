
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import * as authStore from '@/store/authentication'

import PartnerSearchPanel from '@/views/Account/Impersonate/PartnerSearchPanel.vue'
import Impersonating from '@/components/Impersonating.vue'
import PrimaryHeading from '@/elements/PrimaryHeading.vue'
import Loading from '@/elements/Loading.vue'

@Component({
  components: {
    PartnerSearchPanel,
    Impersonating,
    PrimaryHeading,
    Loading,
  },
})
export default class Impersonate extends Vue {
  @Action(authStore.actionNames.impersonate, { namespace: authStore.namespace })
  private impersonate!: (who: { userName?: string; accountNumber: string }) => Promise<void>

  @Getter(authStore.getterNames.impersonationActive, { namespace: authStore.namespace })
  private impersonationActive!: boolean

  private loading = false

  private async goImpersonate(who: { userName?: string; accountNumber: string }) {
    this.loading = true
    await this.impersonate(who)
    this.$router.replace({ name: 'Programs' })
    this.loading = false
  }
}
