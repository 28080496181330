import { VolunteerSearchInfo } from '@/GeneratedTypes/ListInfo/VolunteerSearchInfo'

export function getEmptyVolunteerSearchInfo(): VolunteerSearchInfo {
  return {
    individualID: 0,
    firstName: null,
    lastName: null,
    middleInitial: null,
    gender: null,
    birthDate: null,
    individualCreateDate: null,
    leagueID: 0,
    leagueName: null,
    upwardLeagueID: null,
    firstAddress: null,
    firstEmailAddress: null,
    accountNumber: '',
    teams: [],
  }
}
