import { Component, Emit, Vue } from 'vue-property-decorator'

/**
 * interface tells TS to expect an internalValue
 * internalValue must be public on the class in the mixin, but it can be a property or a member
 */
export interface TextLikeEvents {
  internalValue: unknown
}

/**
 * Serves to standardize the event model on a text-like element.
 * - defines handleInput / input
 * - defines handleBlur / blur
 * - defines handleChange / change
 *
 * define internalValue to reflect the object/scalar you want returned to the parent.
 */
@Component
export class TextLikeEvents extends Vue {
  @Emit()
  change(e: any) {
    this.internalValue = e.target.value
    return this.internalValue
  }
  @Emit()
  blur() {
    return this.internalValue
  }

  @Emit()
  input(e: any) {
    this.internalValue = e.target.value
    return this.internalValue
  }
  handleInput(e: any) {
    this.input(e)
  }

  handleBlur() {
    this.blur()
  }

  handleChange(e: any) {
    this.change(e)
  }
}
