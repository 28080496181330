import { DivisionTeam } from '@/GeneratedTypes/DivisionTeam'
import * as upwardTypesStore from '@/store/upwardTypes'

export interface FilterOptions {
  filterBy: string
  divisionId: number
  teamDivisionId: number
  teams: DivisionTeam[] | null
  practiceNight: string | null
  grades: string[]
}

export function getInitialFilterOptions() {
  return {
    filterBy: 'all',
    divisionId: -1,
    teamDivisionId: -1,
    teams: null,
    practiceNight: null,
    grades: upwardTypesStore.upwardTypes.state.gradeTypes.map((x) => x.upwardTypeID!),
  } as FilterOptions
}
