import { TeamPlayerInfo } from 'src/GeneratedTypes/ListInfo/TeamPlayerInfo'

import { cloneDeep } from 'lodash'
export function rankPlayers<Type extends TeamPlayerInfo>(players: Array<Type>): Array<Type> {
  if (!players) return [] as Type[]
  const clone = cloneDeep(players)
  return useRosterRanking(clone) ? clone.sort(rankByRosterRank) : clone.sort(rankByEvalScore)
}
function rankByRosterRank(a: TeamPlayerInfo, b: TeamPlayerInfo) {
  const aRank = a.rosterRanking || 'Z'
  const bRank = b.rosterRanking || 'Z'
  if (aRank < bRank) return -1
  if (aRank > bRank) return 1
  return 0
}
function rankByEvalScore(a: TeamPlayerInfo, b: TeamPlayerInfo) {
  const aRank = a.evaluationScore
  const bRank = b.evaluationScore
  if (aRank < bRank) return 1
  if (aRank > bRank) return -1
  return 0
}
export function useRosterRanking(players: TeamPlayerInfo[]) {
  const arr = players ?? []
  return !arr.every((i) => !i.rosterRanking)
}

export function setRosterRanking(players: TeamPlayerInfo[]) {
  players.forEach((p: TeamPlayerInfo, i: number) => (p.rosterRanking = numberToLetters(i)))
  return players
}

function numberToLetters(num: number) {
  let letters = ''
  while (num >= 0) {
    letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
    num = Math.floor(num / 26) - 1
  }
  return letters
}
