
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class JerseyIcon extends Vue {
  @Prop({ type: Number, default: 24, required: false }) private readonly size!: number
  @Prop({ type: String, default: 'blue', required: false }) private readonly hexColor!: string
  @Prop({ type: Boolean, default: false, required: false }) private readonly fill!: boolean

  get isColorWhite() {
    return this.hexColor == '#FFFFFF'
  }

  private get path() {
    return 'M16,21H8A1,1 0 0,1 7,20V12.07L5.7,13.12C5.31,13.5 4.68,13.5 4.29,13.12L1.46,10.29C1.07,9.9 1.07,9.27 1.46,8.88L7.34,3H9C9,4.1 10.34,5 12,5C13.66,5 15,4.1 15,3H16.66L22.54,8.88C22.93,9.27 22.93,9.9 22.54,10.29L19.71,13.12C19.32,13.5 18.69,13.5 18.3,13.12L17,12.07V20A1,1 0 0,1 16,21'
  }
}
