
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { LeaguePlayerInfoExt } from '@/models/LeaguePlayerInfoExt'
import { getEmptyLeaguePlayerInfoExt } from '@/modelHelpers/LeaguePlayerInfoExt'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import InputLabel from '@/elements/InputLabel.vue'
import moment from 'moment'
import * as programStore from '@/store/programs'

@Component({
  components: {
    InputLabel,
  },
})
export default class PlayerInformation extends Vue {
  @Prop({ type: Object, default: getEmptyLeaguePlayerInfoExt, required: false })
  private readonly participant!: LeaguePlayerInfoExt

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  private mmt = moment
}
