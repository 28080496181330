import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import contentManagementClient from '@/clients/contentManagementClient'
import { ActionsImpl } from 'direct-vuex'
import { Resource } from '@/models/Program/Resource'

interface CheerResourceState {
  cheerResources: Resource[] | null
  currentResourceType: string | null
}

const initState = (): CheerResourceState => {
  return {
    cheerResources: [] as Resource[],
    currentResourceType: null,
  }
}

const cheerResourceState = initState()

export enum getterNames {
  cheerResources = 'cheerResources',
}

const getterTree: GetterTree<CheerResourceState, RootState> = {
  [getterNames.cheerResources]: (state) => state.cheerResources,
}

export enum mutationNames {
  setResources = 'setResoruces',
  setCurrentResourceType = 'setCurrentResourceType',
  reset = 'reset',
}

const mutations: MutationTree<CheerResourceState> = {
  [mutationNames.setResources](state, { resources }: { resources: Resource[] | null }) {
    state.cheerResources = resources
  },
  [mutationNames.setCurrentResourceType](state, { resource }: { resource: string | null }) {
    state.currentResourceType = resource
  },
  [mutationNames.reset](state) {
    Object.assign(state, initState())
  },
}

export enum actionNames {
  fetchCoachResource = 'fetchCheerResource',
  fetchCheerResourceByUPW = 'fetchCheerResourceByUPW',
}

const actions: ActionTree<CheerResourceState, RootState> & ActionsImpl = {
  async [actionNames.fetchCoachResource](
    { commit },
    { type, productYear, programType }: FetchCheerResource
  ): Promise<boolean> {
    try {
      const result = await contentManagementClient.getCheerResources(type, productYear, programType)
      if (result) {
        commit(mutationNames.setResources, { resources: result })
        commit(mutationNames.setCurrentResourceType, { type })
        return true
      }
    } catch (e) {
      commit(mutationNames.setResources, { resources: null })
      commit(mutationNames.setCurrentResourceType, { resource: null })
      throw e
    }

    return false
  },
  async [actionNames.fetchCheerResourceByUPW](
    { commit },
    { upw, typeProgramID, type }: FetchCheerResourceByUPW
  ): Promise<boolean> {
    try {
      const result = await contentManagementClient.getCheerResourcesByUPW(upw, typeProgramID, type)
      if (result) {
        commit(mutationNames.setResources, { resources: result })
        commit(mutationNames.setCurrentResourceType, { type })
        return true
      }
    } catch (e) {
      commit(mutationNames.setResources, { resources: null })
      commit(mutationNames.setCurrentResourceType, { resource: null })
      throw e
    }

    return false
  },
}

export const namespace = 'cheerResources'

export const cheerResources = {
  namespaced: true as true,
  state: cheerResourceState,
  getters: getterTree,
  actions,
  mutations,
}

export interface FetchCheerResource {
  type: string
  productYear: string
  programType: string
}

export interface FetchCheerResourceByUPW {
  upw: string
  typeProgramID: string
  type: string
}
