
import { Component, Mixins } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'
import * as lineupStore from '@/store/lineup'
import { activatePlayer, inactivePlayers } from '@/services/lineupService'
import { GameLineupWithRotation } from '@/models/Lineup'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import { RecalculateLineupMixin } from '@/common/RecalculateLineupMixin'
import { CurrentGameLineupWithRotationMixin } from '@/common/CurrentGameLineupWithRotationMixin'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'

@Component({
  components: { Breadcrumbs },
})
export default class ReactivatePlayer extends Mixins(
  RecalculateLineupMixin,
  CurrentGameLineupWithRotationMixin
) {
  @Mutation(lineupStore.mutationNames.upsertLineupWithRotation, { namespace: lineupStore.namespace })
  upsertLineup!: ({ lineup }: { lineup: GameLineupWithRotation }) => void

  private breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.GAMES_GAMEDAY, isActive: true },
    { crumb: CrumbsEnum.GAME, isActive: true, params: { gameId: this.$route.params.gameId } },
    { crumb: CrumbsEnum.REACTIVATE_PLAYER, isActive: false },
  ])

  private reactivate(id: number) {
    if (!this.currentGameLineupWithRotation) return
    const lineup = activatePlayer(this.currentGameLineupWithRotation, id)
    if (!lineup) return
    this.upsertLineup({ lineup })
    this.recalculateLineup(this.currentGameLineupWithRotation)
    this.$router.back()
  }

  private get inactivePlayers(): TeamPlayerInfo[] | undefined {
    return inactivePlayers(this.currentGameLineupWithRotation)
  }
}
