
import { Component, Vue, Prop } from 'vue-property-decorator'

//maps recognitionID to file name
export const starsMap = new Map([
  ['CHRISTLIKENESS', 'star-white.png'],
  ['EFFORT', 'star-blue.png'],
  ['SPORTSMANSHIP', 'star-gold.png'],
  ['OFFENSE', 'star-grey.png'],
  ['DEFENSE', 'star-red.png'],
  ['SERVING', 'star-grey.png'],
  ['HITTING', 'star-red.png'],
  ['CHEERS', 'star-grey.png'],
  ['LEADERSHIP', 'star-red.png'],
  ['SPIRIT', 'star-gold.png'],
  ['BLANK', 'star-blank.png'],
  ['SKILLS', 'star-grey.png'],
  ['TEAMWORK', 'star-red.png'],
])

@Component
export default class Star extends Vue {
  @Prop({ type: String, default: 'BLANK', required: false })
  private readonly recognitionId!: string

  private get starImg(): string {
    const file = starsMap.get(this.recognitionId)
    return file ? file : 'star-blank.png'
  }
}
