import restService from '@/services/restService'
import { PlayerSearchInfo } from '@/GeneratedTypes/ListInfo/PlayerSearchInfo'
import { PlayerSearchArgs } from '@/GeneratedTypes/PlayerSearchArgs'
import { LeaguePlayerInfoExt } from '@/models/LeaguePlayerInfoExt'

const baseUrl = 'participants/'

const search = async (upwId: string): Promise<PlayerSearchInfo[]> => {
  const params: PlayerSearchArgs = {
    currentLeagueID: 0,
    currentUpwardLeagueID: upwId,
    firstName: null,
    lastName: null,
    historyLimit: null,
    includeCurrentLeague: true,
    includePastLeagues: false,
    useStartsWith: true,
    accountNumber: null,
  }
  const endpointURI = `${baseUrl}search`

  const x = await restService.post<PlayerSearchInfo[]>(endpointURI, params)
  if (x.isSuccess) {
    return x.data ?? []
  } else {
    return Promise.reject(x.errorObject)
  }
}

const retrieveLeaguePlayerInfoExt = async (
  upwId: string,
  individualId: string
): Promise<LeaguePlayerInfoExt> => {
  const result = await restService.get<LeaguePlayerInfoExt>(`${baseUrl}${upwId}/${individualId}/info`)
  if (result.isSuccess && result.data) {
    return result.data
  } else {
    return Promise.reject(result.errorObject)
  }
}

export default {
  search,
  retrieveLeaguePlayerInfoExt,
}
