
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Crumb } from '@/common/Crumbs'
import { Location } from 'vue-router'

@Component
export default class Breadcrumbs extends Vue {
  @Prop({ type: Array, default: [], required: false }) private readonly crumbs!: Crumb[]

  private go(routeName: string, routeParams: Record<string, string>, isActive: boolean) {
    if (!isActive) return
    if (!routeName || routeName === this.$route.name) {
      throw 'invalid route provided in Breadcrumb'
    }
    const routeObj: Location = { name: routeName }
    if (routeParams) {
      routeObj.params = routeParams
    }

    this.$router.push(routeObj)
  }
}
