import { Component, Vue } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import * as upwardTypesStore from '@/store/upwardTypes'
import { Getter, Mutation } from 'vuex-class'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { adjustLineup } from '@/services/lineupService'
import * as teamStore from '@/store/team'
import * as lineupStore from '@/store/lineup'
import { GameLineupWithRotation } from '@/models/Lineup'

@Component
export class RecalculateLineupMixin extends Vue {
  @Getter(upwardTypesStore.getterNames.programTypes, { namespace: upwardTypesStore.namespace })
  protected readonly allProgramTypes!: UpwardProgramTypeID[]

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  protected readonly currentTeam!: DivisionTeamInfoExt

  @Mutation(lineupStore.mutationNames.upsertLineupWithRotation, { namespace: lineupStore.namespace })
  upsertLineup!: ({ lineup }: { lineup: GameLineupWithRotation }) => void

  protected recalculateLineup(GameLineup: GameLineupWithRotation) {
    const currentLineup = cloneDeep(GameLineup)
    const program = this.allProgramTypes.find((p) => p.upwardTypeID === this.currentTeam.typeProgramID)
    const lineup = adjustLineup(currentLineup, this.currentTeam.players, program)
    this.upsertLineup({ lineup })
  }
}
