
import Alert from '@/components/Alert.vue'
import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import PasswordInput from '@/elements/PasswordInput.vue'
import PartnerList from '@/components/PartnerList.vue'
import { adminUsers } from '@/common/Authorization/AccountRoles'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { namespace, actionNames, getterNames } from '@/store/authentication'
import partnerClient from '@/clients/partnerClient'
import localStorageService from '@/services/localStorageService'
import { RestServiceResult } from '@/services/restService'
import { AuthToken } from '@/models/Authorization/AuthToken'
import { AccountInfo } from '@/GeneratedTypes/ListInfo/AccountInfo'
import { LockedAccountException } from '@/common/Authentication/LockedAccountException'
import { TwoFactorAuthRequiredException } from '@/common/Authentication/TwoFactorAuthRequiredException'
import store from '@/store'
import * as authStore from '@/store/authentication'

const localStorageEmailKey = 'authwall-remembered-email'
const rememberedEmail = localStorageService.getRaw(localStorageEmailKey)

@Component({
  components: {
    Alert,
    TextInput,
    PasswordInput,
    Loading,
    PartnerList,
  },
})
export default class Login extends Vue {
  @Action(actionNames.login, { namespace })
  private loginAction!: ({
    email,
    password,
    accountNumber,
  }: {
    email: string
    password: string
    accountNumber: string | null
  }) => Promise<RestServiceResult<AuthToken>>

  @Action(actionNames.loginByTokenWithImpersonate, { namespace })
  private loginByTokenWithImpersonate!: ({ chit }: { chit: string }) => Promise<boolean>

  @Action(authStore.actionNames.impersonate, { namespace: authStore.namespace })
  private impersonate!: (who: { userName?: string; accountNumber: string }) => Promise<void>

  @Getter(getterNames.daysUntilPasswordExpiration, { namespace })
  private daysUntilPasswordExpiration!: number | null

  @Getter(getterNames.totpInfo, { namespace: namespace })
  private totpInfo!: string

  private validateTOTPAction = store.dispatch.authentication.validateTOTP

  private email: string = rememberedEmail || ''
  private password = ''
  private doRememberEmail = !!rememberedEmail
  private isLoading = true
  private alertIsVisible = false
  private currentYear = new Date().getFullYear()
  private partners = [] as AccountInfo[]
  private hasMultipleAccounts = false
  private show2FAPrompt = false
  private totpCode = ''
  private errorMessage = ''
  private accountToImpersonateAfter2FA = ''
  private lastValidTotp: string | null = null
  private isReplaying = false

  async mounted() {
    this.isLoading = true
    this.validateTOTPAction = store.dispatch.authentication.validateTOTP
    if (this.$route.query.token) {
      try {
        await this.loginByTokenWithImpersonate({ chit: this.$route.query.token as string })
      } catch (e) {
        if (e instanceof LockedAccountException) {
          await this.$router.push('/locked-account')
        }
        if (e instanceof TwoFactorAuthRequiredException) {
          const tfaEx = e as TwoFactorAuthRequiredException
          this.$nextTick(() => {
            this.isLoading = false
            this.accountToImpersonateAfter2FA = tfaEx.accountToImpersonateAfterAuth
            this.show2FAPrompt = true
          })
        }
      }
    } else {
      this.isLoading = false
    }
  }

  private async login(accountNo: string | null = '', replayTOTPCode: string | null) {
    this.isLoading = true

    try {
      const result = await this.loginAction({
        email: this.email,
        password: this.password,
        accountNumber: accountNo,
      })

      if (result.isSuccess) {
        if (this.doRememberEmail) {
          localStorageService.set(localStorageEmailKey, this.email)
        }

        if (this.totpInfo == 'GOOD') {
          if (this.daysUntilPasswordExpiration === 0) {
            this.$router.push('/account/security')
          }

          if (this.$allowIfAny(adminUsers)) {
            this.$router.push('/account/impersonate')
          } else {
            this.multiAccountCheck(result.data)
          }
        } else {
          if (this.totpInfo == 'PROMPT') {
            if (replayTOTPCode != null) {
              this.totpCode = replayTOTPCode
              this.isReplaying = true
              this.validateTOTP()
            } else {
              //show totp prompt
              this.show2FAPrompt = true
            }
          }
        }
      }
    } catch (err) {
      if (err.status === 403) {
        this.$router.push({ path: '/expired-password', query: { email: this.email } })
      } else if (err.status === 401 && err.errorObject?.message == 'locked') {
        this.$router.push({ path: '/locked-account' })
      } else {
        this.errorMessage = 'User or password combination not valid.'
        this.alertIsVisible = true
      }
    } finally {
      this.isLoading = false
    }
  }

  private async validateTOTP() {
    if (this.isLoading && !this.isReplaying) {
      return
    }

    this.isLoading = true
    this.isReplaying = false

    try {
      const result = await this.validateTOTPAction(this.totpCode)

      if (this.totpInfo == 'GOOD') {
        if (this.daysUntilPasswordExpiration === 0) {
          this.$router.push('/account/security')
        }

        if (this.accountToImpersonateAfter2FA) {
          const who = { userName: undefined, accountNumber: this.accountToImpersonateAfter2FA }
          await this.impersonate(who)
          this.$router.replace({ name: 'Programs' })
        } else {
          if (this.$allowIfAny(adminUsers)) {
            this.$router.push('/account/impersonate')
          } else {
            this.multiAccountCheck(result)
          }
        }
      } else {
        if (this.totpInfo == 'PROMPT' && !this.show2FAPrompt) {
          this.totpCode = ''
          this.show2FAPrompt = true
        } else {
          throw new Error('Verification Code invalid.  Try again.')
        }
      }
    } catch (err) {
      // deal with authentication issues.
      this.isLoading = false
      if (err.status === 403) {
        this.$router.push({ path: '/expired-password', query: { email: this.email } })
      } else if (err.status === 401 && err.errorObject?.message == 'locked') {
        this.$router.push({ path: '/locked-account' })
      } else {
        this.errorMessage = 'Verification Code invalid.'
        this.alertIsVisible = true
      }
    }

    this.isLoading = false
  }

  private async multiAccountCheck(token: AuthToken | null) {
    if (token && token.accountNumbers && token.accountNumbers.length > 1) {
      const p = await partnerClient.retreivePartners(token.accountNumbers)
      if (p) {
        this.lastValidTotp = this.totpCode
        this.hasMultipleAccounts = true
        this.partners = p
        this.show2FAPrompt = false
      }
    }
  }

  private loginWithPartner(p: AccountInfo) {
    this.login(p.accountNumber, this.lastValidTotp)
  }

  @Watch('show2FAPrompt')
  private onshow2FAPromptChanged() {
    if (this.show2FAPrompt) {
      this.$nextTick(() => {
        const verficatioCode = this.$refs.verificationCodeReturning as TextInput
        if (verficatioCode) {
          //if called from onMounted, this will be null, so just skip
          const verficatioCodeTextbox = verficatioCode.$refs.inputField as HTMLInputElement
          verficatioCodeTextbox.focus()
        }
      })
    }
  }
}
