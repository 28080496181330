import { RouteConfig } from 'vue-router'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import { allowFirstRanking } from '@/plugins/Authorization/permissionChecks'
import GameDayGames from '@/views/GameDay/GameDayGames.vue'

export default function (type: string) {
  return [
    {
      path: 'games',
      name: `games_${type}`,
      meta: {
        title: 'Games',
        guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
      },
      component: GameDayGames,
    },
  ] as RouteConfig[]
}
