
import CheckboxInput from '@/elements/CheckboxInput.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {
    CheckboxInput,
  },
})
export default class SwitchSlot extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: Boolean, required: false, default: false })
  private readonly value!: boolean

  private internalValue = this.value

  @Watch('internalValue')
  private onInternalValueChange() {
    this.$emit('input', this.internalValue)
    this.$emit('change', this.internalValue)
  }

  @Watch('value')
  private onValueChange() {
    this.internalValue = this.value
  }
}
