import { RouterItems, RouterItemsEnum } from '@/common/RouterItems'
import { MainMenu, MainMenuEnum } from '@/common/MainMenuItems'
import gameDayRoutes from '@/router/gameDay'
import gamesListRoute from '@/router/game'
import startDistributionRoutes from '@/router/startDistribution'
import store from '@/store'

export interface Crumb {
  routeName?: string
  label: string
  active: boolean
  params?: Record<string, any>
}

const home: Crumb = {
  label: RouterItems[RouterItemsEnum.HOME].text,
  routeName: RouterItems[RouterItemsEnum.HOME].name,
  active: true,
}

const account: Crumb = {
  label: RouterItems[RouterItemsEnum.ACCOUNT].text,
  routeName: RouterItems[RouterItemsEnum.ACCOUNT].name,
  active: false,
}

const search: Crumb = {
  label: RouterItems[RouterItemsEnum.SEARCH].text,
  routeName: RouterItems[RouterItemsEnum.SEARCH].name,
  active: true,
}

const communication: Crumb = {
  label: MainMenu[MainMenuEnum.COMMUNICATION].text,
  routeName: MainMenu[MainMenuEnum.COMMUNICATION].name,
  active: true,
}

const player: Crumb = {
  label: RouterItems[RouterItemsEnum.PLAYER].text,
  routeName: RouterItems[RouterItemsEnum.PLAYER].name,
  active: true,
}

const coach: Crumb = {
  label: RouterItems[RouterItemsEnum.COACH].text,
  routeName: RouterItems[RouterItemsEnum.COACH].name,
  active: true,
}

const team: Crumb = {
  label: RouterItems[RouterItemsEnum.TEAM].text,
  routeName: RouterItems[RouterItemsEnum.TEAM].name,
  active: true,
}

const manageTeam: Crumb = {
  label: MainMenu[MainMenuEnum.MANAGE_TEAM].text,
  routeName: MainMenu[MainMenuEnum.MANAGE_TEAM].name,
  active: true,
}

const resources: Crumb = {
  label: MainMenu[MainMenuEnum.RESOURCES].text,
  routeName: MainMenu[MainMenuEnum.RESOURCES].name,
  active: true,
}

const practiceResources: Crumb = {
  label: MainMenu[MainMenuEnum.RESOURCES].text,
  routeName: MainMenu[MainMenuEnum.RESOURCES].name,
  active: true,
}

const cheerResources: Crumb = {
  label: MainMenu[MainMenuEnum.CHEER_PRACTICE_RESOURCES].text,
  routeName: MainMenu[MainMenuEnum.CHEER_PRACTICE_RESOURCES].name,
  active: true,
}

const ministryResources: Crumb = {
  label: MainMenu[MainMenuEnum.MINISTRY_RESOURCES].text,
  routeName: MainMenu[MainMenuEnum.MINISTRY_RESOURCES].name,
  active: true,
}

class refereeSchedule implements Crumb {
  private _active = true
  public get label() {
    return `${store.state.programs.currentProgram?.refLabel} Schedule`
  }
  public get routeName() {
    return MainMenu[MainMenuEnum.REFEREE_SCHEDULE].name
  }
  public get active() {
    return this._active
  }
  public set active(value: boolean) {
    this._active = value
  }
}

const gameRoute = gameDayRoutes.find((r) => r.name === 'game')
const game: Crumb = {
  label: gameRoute?.meta?.title ?? '',
  routeName: gameRoute?.name,
  active: true,
}

const gamesGameDayRoute = gamesListRoute('gameday').find((r) => r.name === 'games_gameday')
const gamesGameDay: Crumb = {
  label: gamesGameDayRoute?.meta?.title ?? '',
  routeName: gamesGameDayRoute?.name,
  active: true,
}
const gamesStartDistributionRoute = gamesListRoute('startdistribution').find(
  (r) => r.name === 'games_startdistribution'
)
const gamesStarDistribution: Crumb = {
  label: gamesStartDistributionRoute?.meta?.title ?? '',
  routeName: gamesStartDistributionRoute?.name,
  active: true,
}

const completeGame: Crumb = {
  label: 'Game Recognitions',
  routeName: '',
  active: true,
}

const gameRecognitionRoute = startDistributionRoutes.find((r) => r.name === 'gameRecognition')
const gameRecognition: Crumb = {
  label: gameRecognitionRoute?.meta?.title,
  routeName: gameRecognitionRoute?.name,
  active: true,
}

const playerReactivateRoute = gameDayRoutes.find((r) => r.name === 'reactivate')
const playerReactivate: Crumb = {
  label: playerReactivateRoute?.meta?.title,
  routeName: playerReactivateRoute?.name,
  active: true,
}

const formatRoute = gameDayRoutes.find((r) => r.name === 'format')
const format: Crumb = {
  label: formatRoute?.meta?.title,
  routeName: playerReactivateRoute?.name,
  active: true,
}

export enum CrumbsEnum {
  HOME = 'Home',
  SEARCH = 'Search',
  COMMUNICATION = 'Communication',
  PLAYER = 'Player',
  COACH = 'Coach',
  TEAM = 'Team',
  RESOURCES = 'Resources',
  PRACTICE_RESOURCES = 'PracticeResources',
  MINISTRY_RESOURCES = 'MinistryResources',
  CHEER_RESOURCES = 'CheerResources',
  MANAGE_TEAM = 'ManageTeam',
  GAMES_GAMEDAY = 'gamesGameday',
  GAMES_STAR_DIST = 'gamesStarDist',
  GAME = 'game',
  COMPLETE_GAME = 'completeGame',
  RECOGNITION = 'gameRecognition',
  REACTIVATE_PLAYER = 'playerReactivate',
  CHANGE_GAME_FORMAT = 'changeGameFormat',
  REFEREE_SCHEDULE = 'refereeSchedule',
  ACCOUNT = 'account',
}

export const Crumbs: Record<CrumbsEnum, Crumb> = {
  [CrumbsEnum.HOME]: home,
  [CrumbsEnum.SEARCH]: search,
  [CrumbsEnum.COMMUNICATION]: communication,
  [CrumbsEnum.PLAYER]: player,
  [CrumbsEnum.COACH]: coach,
  [CrumbsEnum.TEAM]: team,
  [CrumbsEnum.RESOURCES]: resources,
  [CrumbsEnum.PRACTICE_RESOURCES]: practiceResources,
  [CrumbsEnum.MINISTRY_RESOURCES]: ministryResources,
  [CrumbsEnum.CHEER_RESOURCES]: cheerResources,
  [CrumbsEnum.MANAGE_TEAM]: manageTeam,
  [CrumbsEnum.GAME]: game,
  [CrumbsEnum.GAMES_GAMEDAY]: gamesGameDay,
  [CrumbsEnum.GAMES_STAR_DIST]: gamesStarDistribution,
  [CrumbsEnum.COMPLETE_GAME]: completeGame,
  [CrumbsEnum.RECOGNITION]: gameRecognition,
  [CrumbsEnum.REACTIVATE_PLAYER]: playerReactivate,
  [CrumbsEnum.CHANGE_GAME_FORMAT]: format,
  [CrumbsEnum.REFEREE_SCHEDULE]: new refereeSchedule(),
  [CrumbsEnum.ACCOUNT]: account,
}

export interface CrumbBuilder {
  crumb: CrumbsEnum
  isActive: boolean
  params?: Record<string, any>
}

export function getCrumbs(items: CrumbBuilder[]) {
  const crumbs: Crumb[] = []
  items.forEach((item: CrumbBuilder) => {
    const crumb = Crumbs[item.crumb]
    crumb.active = item.isActive
    if (item.params) {
      crumb.params = item.params
    }
    crumbs.push(crumb)
  })
  return crumbs
}
