
import { Component, Mixins } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import * as teamStore from '@/store/team'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import LinkingListItem from '@/components/LinkingListItem.vue'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import UnrankedWarning from '@/views/GameDay/UnrankedWarning.vue'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import GameDayGameList from '@/views/GameDay/GameDayGameList.vue'
import GameDayGameCountList from '@/views/GameDay/GameDayGameCountList.vue'
import * as upwardTypesStore from '@/store/upwardTypes'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import divisionClient from '@/clients/divisionsClient'
import Loading from '@/elements/Loading.vue'
import { IsCheerMixin } from '@/common/IsCheerMixin'
import { cloneDeep } from 'lodash'
import store from '@/store'
import { rankPlayers, useRosterRanking, setRosterRanking } from '@/common/players'

@Component({
  components: {
    LinkingListItem,
    Breadcrumbs,
    GameDayGameList,
    GameDayGameCountList,
    Loading,
    UnrankedWarning,
  },
})
export default class GameDayGames extends Mixins(IsCheerMixin) {
  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  readonly currentTeam!: DivisionTeamInfoExt

  @Getter(upwardTypesStore.getterNames.programTypes, { namespace: upwardTypesStore.namespace })
  readonly allProgramTypes!: UpwardProgramTypeID[]

  @Getter(teamStore.getterNames.hasScheduledGames, { namespace: teamStore.namespace })
  private readonly hasScheduledGames!: boolean

  @Getter(teamStore.getterNames.hasScheduledAppointments, { namespace: teamStore.namespace })
  private readonly hasScheduledAppointments!: boolean

  @Mutation(teamStore.mutationNames.setCurrentTeamPlayers, { namespace: teamStore.namespace })
  private setPlayers!: ({ players }: { players: TeamPlayerInfo[] }) => void

  private gameCount = 0
  private divisionName = ''
  private loading = false
  private breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.GAMES_GAMEDAY, isActive: false },
  ])

  private async mounted() {
    if (!this.hasScheduledGames) {
      //if there are no scheduled games, retreive the division
      // so that division.gameCount can me used to generate games
      await this.loadDivision()
    }

    if (this.currentTeam.players) {
      const players = cloneDeep(this.currentTeam.players)
      if (!useRosterRanking(players)) {
        this.setPlayers({ players: setRosterRanking(rankPlayers(players)) })
      }
    }
  }

  get currentPlayers() {
    return this.currentTeam.players
  }

  get unrankedPlayers() {
    if (store.getters.game.skipPlayerRanking) return false
    const players = cloneDeep(this.currentTeam.players)
    return players ? players.some((p) => p.rosterRanking == '' || !p.rosterRanking) : false
  }

  private async loadDivision() {
    try {
      this.loading = true
      const team = this.currentTeam
      const division = await divisionClient.retrieve(team.upwardLeagueID, team.typeProgramID, team.divisionID)
      if (division) {
        this.gameCount = division.gameCount
        this.divisionName = division.divisionName
      }
    } finally {
      this.loading = false
    }
  }

  private get component() {
    if (this.isCheer && this.hasScheduledAppointments) return 'GameDayGameList'
    if (!this.isCheer && this.hasScheduledGames) return 'GameDayGameList'
    return 'GameDayGameCountList'
  }

  private get noGames(): boolean {
    return !this.loading && !this.hasScheduledGames && !this.hasScheduledAppointments && !this.gameCount
  }
}
