
import { Component, Vue, Prop } from 'vue-property-decorator'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import ProgramIcon from '@/components/ProgramIcon.vue'

@Component({
  components: {
    ProgramIcon,
  },
})
export default class ProgramList extends Vue {
  @Prop({ type: Array, required: true })
  private readonly programs!: LeagueInfoCondensed[]
}
