
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { PreviewMessage } from '@/models/Communication/PreviewMessage.ts'
import { getEmptyPreviewMessage } from '@/modelHelpers/PreviewMessage.ts'
import { getEmptyAttachment } from '@/modelHelpers/Attachment.ts'
import { Attachment } from '@/GeneratedTypes/Communication/Attachment'
import TeamPageWidget from '@/components/TeamPageWidget.vue'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import { allowFirstRanking } from '@/plugins/Authorization/permissionChecks'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { cloneDeep } from 'lodash'
import Count from '@/utils/smsCount.ts'
import InputLabel from '@/elements/InputLabel.vue'
import TextInput from '@/elements/TextInput.vue'
import FileInput from '@/elements/FileInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'
import Loading from '@/elements/Loading.vue'
import { denyIfAnyOnly } from '@/plugins/Authorization/permissionChecks'

@Component({
  components: {
    InputLabel,
    TextInput,
    FileInput,
    MemoInput,
    HtmlEditor,
    Loading,
    TeamPageWidget,
  },
})
export default class MessageContent extends Vue {
  @Prop({ type: String, default: 'Email', required: false })
  private readonly messageType!: string

  @Prop({ type: Object, default: getEmptyPreviewMessage, required: false })
  private readonly value!: PreviewMessage

  @Prop({ type: Boolean, default: false, required: false })
  private readonly loading!: boolean

  private localMessagePreview = getEmptyPreviewMessage()
  private attachmentObject: Attachment = getEmptyAttachment()
  private volunteerRoles = VolunteerRoles
  private allowFirstRanking = allowFirstRanking
  private denyIfAnyOnly = denyIfAnyOnly
  private superUsers = superUsers
  private denyThem = [
    ...this.superUsers,
    this.volunteerRoles.COACH_COMMISSIONER,
    this.volunteerRoles.CHEER_DIRECTOR,
    this.volunteerRoles.REFEREE_COMMISSIONER,
  ]

  private emitChange() {
    this.$emit('change', this.localMessagePreview)
    this.$emit('input', this.localMessagePreview)
  }

  private handleAttachmentChange(file: File) {
    if (file) {
      // Capture file name and file type in attachmentObject
      this.attachmentObject.name = file.name
      this.attachmentObject.type = file.type

      // Encode attachment as Base64
      const reader = new FileReader()
      reader.onload = this.onFileLoad // onFileLoad sets attachmentObject.data
      reader.readAsDataURL(file)
    } else {
      // Clear local data if user clicks 'remove' in <file-input> component
      this.attachmentObject = getEmptyAttachment()
      this.$emit('fileAttached', '')
    }
  }

  private addToMessageBody(teamPageUrl: string) {
    this.localMessagePreview.messageBody = `${this.localMessagePreview.messageBody} ${teamPageUrl}`
    this.emitChange()
  }

  private onFileLoad(ev: ProgressEvent<FileReader>) {
    if (!ev || !ev.target || !ev.target.result) {
      // this.attachmentObject.data = ''
      this.$emit('fileAttached', '')
      return
    }
    // Capture Base64 encoding and remove extranious data before the comma
    const result = ev.target.result.toString().split(',')
    if (result.length >= 2) {
      this.attachmentObject.data = result[1]
      this.$emit('fileAttached', this.attachmentObject)
    } else {
      throw 'Error encoding email attachment'
    }
  }
  private get charactersRemaining() {
    return Count(this.localMessagePreview.messageBody)
  }

  private get maxCharactersReached() {
    return (
      this.charactersRemaining.messages > 3 ||
      (this.charactersRemaining.messages === 3 && this.charactersRemaining.remaining === 0)
    )
  }

  @Watch('value', { deep: true, immediate: true })
  private syncToLocalValue() {
    this.localMessagePreview = cloneDeep(this.value)
  }
}
