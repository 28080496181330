import restService from '@/services/restService'
import { RolesEnum } from '@/modelHelpers/LeagueVolunteer'
import { UpwardLeagueIDType } from '@/modelHelpers/League'
import { VolunteerSearchInfo } from '@/GeneratedTypes/ListInfo/VolunteerSearchInfo'
import { VolunteerSearchArgs } from '@/GeneratedTypes/VolunteerSearchArgs'
import { LeagueVolunteerInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerInfo'

const baseUrl = 'volunteers/'

interface VolunteerClientSearchArgs {
  leagueID: UpwardLeagueIDType
  first: string
  last: string
  orSearch: boolean
  role: RolesEnum[]
}

function getEmptyVolunteerSearchArgs(): VolunteerSearchArgs {
  return {
    currentUpwardLeagueID: '',
    currentLeagueID: 0,
    firstName: null,
    lastName: null,
    historyLimit: null,
    includeCurrentLeague: true,
    includePastLeagues: false,
    roleID: RolesEnum.COACH,
    useStartsWith: true,
    accountNumber: null,
  }
}

const search = async (upwId: string, roleId: RolesEnum): Promise<VolunteerSearchInfo[]> => {
  const params = getEmptyVolunteerSearchArgs()
  params.currentUpwardLeagueID = upwId
  params.roleID = roleId
  const result = await restService.post<VolunteerSearchInfo[]>(`${baseUrl}search`, params)
  if (result.isSuccess) {
    return result.data ?? []
  } else {
    throw new Error('Problem volunteerClient.search')
  }
}

const retrieveByRoles = async (upwId: string, roleIds: number[]): Promise<LeagueVolunteerInfo[]> => {
  const result = await restService.get<LeagueVolunteerInfo[]>(`${baseUrl}${upwId}/Roles?roleIDs=${roleIds}`)
  if (result.isSuccess) {
    return result.data ?? []
  } else {
    throw new Error('Problem volunteerClient.search')
  }
}

const retrieveVolunteer = async (upwId: string, individualId: string): Promise<VolunteerSearchInfo> => {
  const result = await restService.get<VolunteerSearchInfo>(`${baseUrl}${upwId}/${individualId}`)
  if (result.isSuccess && result.data) {
    return result.data
  } else {
    return Promise.reject(result.errorObject)
  }
}

export default {
  search,
  retrieveVolunteer,
  retrieveByRoles,
}
