import { Roles } from './types/types'

export function isPermitted(usersRoles: Array<string>, roles: Roles, all: boolean) {
  if (!roles && !all) return true
  if (!usersRoles && roles) return false
  if (!roles) roles = []
  roles = Array.isArray(roles) ? roles : roles.trim().split(',')
  const intersection = getIntersection(usersRoles, roles)
  return all ? intersection.length >= roles.length : intersection.length > 0
}

export function isDenied(usersRoles: Array<string>, roles: Roles, all: boolean) {
  if (!roles) return false
  if (!usersRoles && roles) return true
  const intersection = getIntersection(usersRoles, roles)
  return all ? intersection.length >= roles.length : intersection.length > 0
}

function getIntersection(usersRoles: Array<string>, roles: Roles) {
  if (!roles) return []
  roles = Array.isArray(roles) ? roles : roles.trim().split(',')
  const intersection = roles.reduce((intersect: Array<string>, perm: string) => {
    if (!usersRoles.map((s: string) => s.trim()).includes(perm.trim())) {
      return intersect
    }
    if (!intersect.includes(perm.trim())) {
      intersect.push(perm)
    }
    return intersect
  }, [])
  return intersection
}

export enum PermissionChecks {
  DENY_IF_ALL = 'denyIfAll',
  ALLOW_IF_ALL = 'allowIfAll',
  DENY_IF_ANY = 'denyIfAny',
  ALLOW_IF_ANY = 'allowIfAny',
}

export const defaultRanking = [
  PermissionChecks.DENY_IF_ANY,
  PermissionChecks.ALLOW_IF_ANY,
  PermissionChecks.DENY_IF_ALL,
  PermissionChecks.ALLOW_IF_ALL,
]

export const allowFirstRanking = [
  PermissionChecks.ALLOW_IF_ANY,
  PermissionChecks.DENY_IF_ANY,
  PermissionChecks.ALLOW_IF_ALL,
  PermissionChecks.DENY_IF_ALL,
]

export const ChecksOrderA = [
  PermissionChecks.ALLOW_IF_ALL,
  PermissionChecks.DENY_IF_ANY,
  PermissionChecks.DENY_IF_ALL,
  PermissionChecks.ALLOW_IF_ANY,
]

export const denyIfAnyOnly = [PermissionChecks.DENY_IF_ANY]
export const allowIfAnyOnly = [PermissionChecks.ALLOW_IF_ANY]
