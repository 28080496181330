import { Attachment } from '@/GeneratedTypes/Communication/Attachment'
import { EmailMessage } from '@/GeneratedTypes/Communication/EmailMessage'

export class EmailMessageImpl implements EmailMessage {
  accountNumber!: string | null
  upwardLeagueID!: string | null
  displayName!: string | null
  replyTo!: string | null
  emailSubject!: string | null
  messageBody!: string | null
  to!: string[] | null
  attachments!: Attachment[] | null
}
