import restService from '@/services/restService'
import { RestServiceResult } from '@/services/restService'
import { AuthToken } from '@/models/Authorization/AuthToken'
import { Credentials } from '@/models/Authorization/Credentials'
import { RequiredString } from '@/models/RequiredString'
import { AxiosRequestConfig } from 'axios'
import jwtService from '@/services/jwtService'
import { ProfilePayload } from '@/models/Authorization/ProfilePayload'
import { LockedAccountException } from '@/common/Authentication/LockedAccountException'
import { GeneralError } from '@/common/GeneralError'

const baseUrl = 'authorization/'
const appId = 'Streamline'

class AuthClientException extends GeneralError {
  name = 'Auth API Exception'
}

export interface ImpersonationPayload {
  userName?: string
  accountNumber: string
}

const login = async (username: string, password: string, accountNumber = '') => {
  const credentials = {
    username,
    password,
    appID: appId,
    accountNumber: accountNumber,
    newPassword: null,
  } as Credentials

  return await restService.post<AuthToken>(`${baseUrl}login/`, credentials)
}

const refreshToken = async () => {
  return await restService.post<AuthToken>(`${baseUrl}tokenrefresh/`, null)
}

const impersonate = async ({ userName, accountNumber }: ImpersonationPayload) => {
  if (!userName && !accountNumber) {
    throw new Error('When impersonating an account or username must be present.')
  }

  const payload = {
    username: userName ? userName : 'default',
    password: 'default',
    appID: 'default',
    accountNumber: accountNumber,
  }

  const r = await restService.post<AuthToken>(`${baseUrl}impersonate`, payload)
  if (r.isSuccess) {
    return r.data
  }

  throw new Error(`Invalid impersonation attempt ${r.errorObject?.message}`)
}

const passwordChange = async (username: string, password: string, newPassword: string) => {
  const credentials = {
    username,
    password,
    appID: appId,
    accountNumber: '',
    newPassword,
  } as Credentials

  return await restService.post<null>(`${baseUrl}passwordchange/`, credentials)
}

const getSystemToken = async () => {
  jwtService.clearStoredAuthToken()
  return await restService.get<AuthToken>(`${baseUrl}systemtoken/`)
}

const getPasswordResetLink = async (username: string) => {
  const data = {
    value: username,
  } as RequiredString

  const systemToken = await getSystemToken()
  return await restService.post<void>(`${baseUrl}passwordreset`, data, {
    headers: {
      Authorization: `Bearer ${systemToken.data!.upwardJWToken}`,
    },
  } as AxiosRequestConfig)
}

const resetPassword = async (code: string, newPassword: string) => {
  const data = {
    value: newPassword,
  } as RequiredString

  const systemToken = await getSystemToken()
  return await restService.post<AuthToken>(`${baseUrl}passwordreset/${code}`, data, {
    headers: {
      Authorization: `Bearer ${systemToken.data!.upwardJWToken}`,
    },
  } as AxiosRequestConfig)
}

const getFirstAccount = () => {
  const credentials = jwtService.getCredentialsFromStoredAuthToken()
  if (credentials && credentials.accountNumbers?.length) {
    return credentials.accountNumbers[0]
  } else {
    return ''
  }
}

/***
 * Token might come on the URL and this will turn it into a login credential.
 * @param token
 * @return AuthToken
 */
const tokenLogin = async (token: string) => {
  const payload = {
    userToken: token,
  }

  const t = await restService.post<AuthToken>(`${baseUrl}loginbytoken`, payload)
  if (t.isSuccess) {
    return t.data
  }
  throw new Error('User or password combination not found.')
}

const updateProfile = async (p: ProfilePayload) => {
  const data: ProfilePayload = {
    username: p.hasOwnProperty('username') ? p.username : '',
    appID: appId,
  }

  if (p.hasOwnProperty('newUsername') && p.newUsername) {
    data['newUsername'] = p.newUsername
    data['usernameChangeCode'] =
      p.hasOwnProperty('usernameChangeCode') && p.usernameChangeCode ? p.usernameChangeCode : ''
  }

  if ((p.hasOwnProperty('newFullName'), p.newFullName)) {
    data['newFullName'] = p.newFullName
  }

  return restService.post<AuthToken>('authorization/updateprofile', data)
}

const validateTOTP = async (totpCode: string) => {
  try {
    const t = await restService.post<AuthToken>(`${baseUrl}validateTOTP/`, { value: totpCode })
    if (t.status == 200) {
      return t.data as AuthToken
    }
  } catch (e) {
    const t = e as RestServiceResult<void>

    if (t.status === 401 && t.errorObject?.message == 'locked') {
      throw new LockedAccountException('This account is locked. Please reset your password')
    }
  }

  throw new AuthClientException('validating totp')
}

export default {
  login,
  refreshToken,
  passwordChange,
  getPasswordResetLink,
  resetPassword,
  getFirstAccount,
  tokenLogin,
  impersonate,
  updateProfile,
  validateTOTP,
}
