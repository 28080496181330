import { Prop, Emit, Mixins, Component } from 'vue-property-decorator'
import { BaseInputWrapperProps } from '@/elements/BaseInputWrapper/BaseInputWrapperProps'

/***
 * List of properties supported by the text like wrapper, expected to be passed through, and
 * shared where necessary.
 * reflect the following on the underlying input (from BaseInputWrapperProps)
 * - id - enables clicking on the label to proxy to the control
 * - name - enables description of a validation error
 * - enabled - enable/disable of the control
 */
@Component
export class TextLikeWrapperProps extends Mixins(BaseInputWrapperProps) {
  @Prop({ type: String, default: '', required: false })
  readonly prependIcon!: string

  @Prop({ type: String, default: '', required: false })
  readonly appendIcon!: string

  @Emit()
  iconClick() {
    return true
  }
}
