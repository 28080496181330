
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getEmptyDivisionTeamPracticeAppointmentInfoList } from '@/modelHelpers/DivisionTeamPracticeAppointmentInfo'
import { DivisionTeamPracticeAppointmentInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamPracticeAppointmentInfo'
import Practice from '@/components/Practice.vue'
import Toggle from '@/components/Toggle.vue'
import moment from 'moment'

@Component({
  components: {
    Practice,
    Toggle,
  },
})
export default class PracticeList extends Vue {
  @Prop({ type: Array, default: getEmptyDivisionTeamPracticeAppointmentInfoList, required: false })
  private readonly practices!: DivisionTeamPracticeAppointmentInfo[]

  @Prop({ type: String, default: '', required: false })
  private readonly title!: string

  private pageStates = ['Upcoming', 'Past']
  private currentToggle: string | null = null

  private get scheduledPractices() {
    if (!this.practices) return
    return this.practices.filter((practice) => {
      return !!practice.practiceStart
    })
  }

  private get scheduledPracticesCount() {
    if (!this.scheduledPractices) return 0
    return this.scheduledPractices.length
  }

  private sortPractices(practices: DivisionTeamPracticeAppointmentInfo[]) {
    //sort practices by practiceStart
    return practices.sort(
      (a: DivisionTeamPracticeAppointmentInfo, b: DivisionTeamPracticeAppointmentInfo) => {
        return moment.utc(a.practiceStart).diff(moment.utc(b.practiceStart))
      }
    )
  }

  private get filteredPractices() {
    if (!this.scheduledPractices) return getEmptyDivisionTeamPracticeAppointmentInfoList()
    const filtered = this.scheduledPractices.filter((practice: DivisionTeamPracticeAppointmentInfo) => {
      if (!practice.practiceStart) return false
      if (moment().isAfter(practice.practiceStart)) {
        // past practice
        return this.currentToggle === 'Past'
      } else {
        //upcoming practice
        return this.currentToggle === 'Upcoming'
      }
    })
    return this.sortPractices(filtered)
  }

  private get filteredPracticesCount() {
    if (!this.practices) return 0
    return this.filteredPractices.length
  }
}
