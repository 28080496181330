
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { VolunteerSearchInfo } from '@/GeneratedTypes/ListInfo/VolunteerSearchInfo'
import { getEmptyVolunteerSearchInfo } from '@/modelHelpers/VolunteerSearchInfo'
import PrimaryHeading from '@/elements/PrimaryHeading.vue'
import PlayerInformation from '@/components/PlayerInformation.vue'
import Loading from '@/elements/Loading.vue'
import volunteerClient from '@/clients/volunteerClient'
import * as programStore from '@/store/programs'
import { formatPhone } from '@/formatters/phoneFmt'
import { maxStringLength } from '@/formatters/maxStringLength'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'

@Component({
  components: {
    PrimaryHeading,
    Loading,
    PlayerInformation,
    Breadcrumbs,
  },
})
export default class Coach extends Vue {
  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  private isLoading = false
  private fmtPhone = formatPhone
  private maxStringLen = maxStringLength
  private coach: VolunteerSearchInfo = getEmptyVolunteerSearchInfo()
  private breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.SEARCH, isActive: true },
    { crumb: CrumbsEnum.COACH, isActive: false },
  ])

  private async mounted() {
    await this.loadCoach()
  }

  private async loadCoach() {
    if (!this.currentProgram || !this.currentProgram.upwardLeagueID) return

    this.isLoading = true
    try {
      this.coach = await volunteerClient.retrieveVolunteer(
        this.currentProgram.upwardLeagueID,
        this.$route.params.individualId
      )
    } finally {
      this.isLoading = false
    }
  }
  private get name() {
    return `${this.coach.firstName} ${this.coach.lastName}`
  }
}
