
import { Component, Ref, Vue } from 'vue-property-decorator'
import { ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { isPhoneValid } from '@/utils/phoneValidation.ts'

/***
 * Add new validation rules here, or move the library to @/lib/components/VeeValidateForm if it overflows.
 */
extend('required', { ...required, message: 'required' })
extend('required_one_recipient', { ...required, message: 'Please add at least one recipient' })
extend('email', { ...email, message: 'Invalid email format' })
extend('email-list', { ...email, message: 'At least one email is invalid' })
extend('exclude-semi-colon', (value) => {
  if (value.indexOf(';') === -1) {
    return true
  }
  return 'semi-colons are not allowed'
})
extend('prohibitPastDate', (value) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const submittedDate = new Date(value)
  if (today <= submittedDate) {
    return true
  }
  return 'Date in the past is not allowed'
})
extend('northamerican_phone', {
  // Simple validation for North American phone numbers.
  // Accepts 555-555-5555, (555)555-5555, (555) 555-5555, 555 555 5555, 5555555555, 1 555 555 5555
  // Supports comma delimited string of phone numbers.
  // If you pass 'multiple' as an argument, you get a message suitable for delimited list of phone numbers
  validate: (value: string) => {
    let values: string[] = []
    let valid = true
    values = value.split(' ').join('').split(',')
    values.forEach((val: string) => {
      if (val && !isPhoneValid(val)) {
        valid = false
        return
      }
    })
    return valid
  },
  message: (value, args) => {
    if (args && args[0] === 'multiple') {
      return 'One of the phone numbers is invalid'
    } else {
      return 'Invalid phone number'
    }
  },
})

extend('beginDateLessThanEndDate', {
  params: ['target'],
  // @ts-ignore
  validate(value, { target }) {
    /* 
    // usage:
    // on the start date field add the property: validation-provider-name="beginDate"
    // on the end date add the property: vee-validate-rules="beginDateLessThanEndDate@beginDate" 
    */
    const beginDate = new Date(target).setHours(0, 0, 0, 0)
    const endDate = new Date(value).setHours(0, 0, 0, 0)
    if (beginDate > endDate) {
      return false
    } else {
      return true
    }
  },
  message: 'Start date must be earlier than end date.',
})

@Component({
  components: {
    ValidationObserver,
  },
})
export default class VeeValidateForm extends Vue {
  @Ref('observer')
  private observer!: InstanceType<typeof ValidationObserver>

  private save() {
    this.$emit('submit')
  }

  /**
   * Works like DOM form (except it is a promise)
   * @return {Promise<boolean>} form status.
   */
  public async checkValidity(): Promise<boolean> {
    return await this.observer.validate({ silent: true })
  }

  /**
   * Works like form reportValidity (except its a promise)
   * displays validation errors and sets the focus to the first.
   * @return {Promise<boolean>} form status.
   */
  public async reportValidity(): Promise<boolean> {
    return await this.observer.validate()
  }
}
