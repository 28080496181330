import { Component, Vue } from 'vue-property-decorator'

@Component
export class iOSTestMixin extends Vue {
  protected isIosOnBrowser() {
    return this.isIos() && !this.isInStandaloneMode()
  }

  private isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent)
  }
  private isInStandaloneMode() {
    //@ts-ignore
    return 'standalone' in window.navigator && window.navigator.standalone
  }
}
