import { RouteConfig } from 'vue-router'
import CheerResourceList from '@/views/PracticeResources/CheerResourceList.vue'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import { allowFirstRanking } from '@/plugins/Authorization/permissionChecks'

export default [
  {
    path: 'cheers',
    name: 'Cheers',
    meta: {
      title: 'Cheers',
      level: 1,
      childRoute: 'cheer',
      childComponent: 'CoachResourceList',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: CheerResourceList,
  },
  {
    path: 'dances',
    name: 'Dances',
    meta: {
      title: 'Dances',
      level: 1,
      childRoute: 'dance',
      childComponent: 'CoachResourceList',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: CheerResourceList,
  },
  {
    path: 'chants',
    name: 'Chants',
    meta: {
      title: 'Chants',
      level: 1,
      childRoute: 'chant',
      childComponent: 'CoachResourceList',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: CheerResourceList,
  },
  {
    path: 'tunes',
    name: 'Tunes',
    meta: {
      title: 'Tunes',
      level: 1,
      childRoute: 'tune',
      childComponent: 'CoachResourceList',
      guard: { allowIfAny: [VolunteerRoles.COACH], denyIfAny: superUsers, ranking: allowFirstRanking },
    },
    component: CheerResourceList,
  },
] as RouteConfig[]
