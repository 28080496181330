import { ResourceItem } from '@/models/Program/ResourceItem'
import axios from 'axios'

const url = `${process.env.VUE_APP_USAGE_API}/resourceusage`

const postResourceUsage = async (resourceItem: ResourceItem) => {
  await axios.post(url, resourceItem)
}

export default {
  postResourceUsage,
}
