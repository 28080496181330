
import SelectInput from '@/elements/SelectInput.vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { Getter } from 'vuex-class'
import * as programStore from '@/store/programs'
import * as upwardTypesStore from '@/store/upwardTypes'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import { cloneDeep } from 'lodash'

extend('minLessThanOrEqualToMaxGrade', {
  params: ['field', 'target'],
  // @ts-ignore
  validate(value, { field, target }) {
    const valueSortOrder =
      upwardTypesStore.upwardTypes.state.gradeTypes.find((x) => x.upwardTypeID == value)?.sortOrder ?? -1
    const targetSortOrder =
      upwardTypesStore.upwardTypes.state.gradeTypes.find((x) => x.upwardTypeID == target)?.sortOrder ?? -1

    if (field === 'min') {
      return valueSortOrder <= targetSortOrder
    } else if (field === 'max') {
      return valueSortOrder >= targetSortOrder
    }
  },
  message: 'The min grade has to be less than max grade.',
})

@Component({
  components: {
    SelectInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class GradeDropdownSelect extends Vue {
  @Prop({ type: Array, required: true })
  private readonly value!: string[]

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  @Getter(upwardTypesStore.getterNames.gradeTypes, { namespace: upwardTypesStore.namespace })
  readonly allGradeTypes!: UpwardGradeTypeID[]

  private grades: string[] = []

  private internalValueMax = ''
  private internalValueMin = ''

  private mounted() {
    this.grades = cloneDeep(this.allGradeTypes)
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((x) => x.upwardTypeID!)

    this.internalValueMax = this.allGradeTypes[this.allGradeTypes.length - 1].upwardTypeID!
    this.internalValueMin = this.allGradeTypes[0].upwardTypeID!
  }

  private get selectedGrades() {
    const start = this.grades.indexOf(this.internalValueMin)
    const end = this.grades.indexOf(this.internalValueMax) + 1
    return this.grades.slice(start, end)
  }

  private get minLabel() {
    let retval = ''
    const typeID = upwardTypesStore.upwardTypes.state.gradeTypes.find(
      (x) => x.upwardTypeID == this.internalValueMin
    )
    if (typeID) {
      retval = this.currentProgram.isByAge ? typeID.ageByCutoff.toString() : typeID.description!
    }
    return retval
  }

  private get maxLabel() {
    let retval = ''
    const typeID = upwardTypesStore.upwardTypes.state.gradeTypes.find(
      (x) => x.upwardTypeID == this.internalValueMax
    )
    if (typeID) {
      retval = this.currentProgram.isByAge ? typeID.ageByCutoff.toString() : typeID.description!
    }
    return retval
  }

  private get labelText() {
    return this.currentProgram.isByAge ? 'Ages' : 'Grades'
  }

  private handleChange() {
    this.$emit('input', this.selectedGrades)
    this.$emit('change', this.selectedGrades)
  }

  @Watch('value')
  private onValueChanged() {
    if (this.value && this.value.length > 0) {
      this.internalValueMin = this.value[0]
      this.internalValueMax = this.value[this.value.length - 1]
    }
  }
}
