import { RoleUnion } from '@/common/Authorization/RoleUnion'
import { ApplicationRoles } from '@/common/Authorization/ApplicationRoles'
import { defineGetters, defineActions, defineMutations } from 'direct-vuex'

interface AuthorizationState {
  currentRole: RoleUnion | null
  applicationRoles: string[] //Roles assigned to a user based on data retrieve by this application
}

const initState = (): AuthorizationState => {
  return {
    currentRole: null,
    applicationRoles: [] as string[],
  }
}
const authorizationState = initState()

export enum getterNames {
  currentRole = 'currentRole',
  applicationRoles = 'applicationRoles',
}

const getterTree = defineGetters<AuthorizationState>()({
  [getterNames.currentRole]: (state): RoleUnion | null => {
    return state.currentRole
  },
  [getterNames.applicationRoles]: (state): string[] => {
    return state.applicationRoles
  },
})

export enum mutationNames {
  setCurrentRole = 'setCurrentRole',
  addApplicationRole = 'addApplicationRole',
  removeApplicationRole = 'removeApplicationRole',
  resetApplicationRoles = 'resetApplicationRoles',
  reset = 'reset',
}

const mutations = defineMutations<AuthorizationState>()({
  [mutationNames.setCurrentRole](state, { currentLeagueRole }: { currentLeagueRole: RoleUnion | null }) {
    state.currentRole = currentLeagueRole
  },
  [mutationNames.reset](state) {
    Object.assign(state, initState())
  },
  [mutationNames.resetApplicationRoles](state) {
    state.applicationRoles = [] as string[]
  },
  [mutationNames.addApplicationRole](state, { role }: { role: ApplicationRoles }) {
    const roles = [...state.applicationRoles]
    roles.push(role)

    state.applicationRoles = [...new Set(roles)]
  },
  [mutationNames.removeApplicationRole](state, { role }: { role: ApplicationRoles }) {
    const roles = state.applicationRoles?.filter((r) => r != role)
    state.applicationRoles = roles ? roles : state.applicationRoles
  },
})

export enum actionNames {}

const actions = defineActions({})

export const namespace = 'authorization'

export const authorization = {
  namespaced: true as true,
  state: authorizationState,
  getters: getterTree,
  actions,
  mutations,
}
