import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import { ActionsImpl } from 'direct-vuex'

export enum sidebarStatus {
  open = 'open',
  closed = 'closed',
}

interface SidebarState {
  isOpen: boolean
}

const sidebarState: SidebarState = {
  isOpen: false,
}

export enum getterNames {
  isOpen = 'isOpen',
}

const getters: GetterTree<SidebarState, RootState> = {
  [getterNames.isOpen]: (state) => state.isOpen,
}

export enum mutationNames {
  open = 'open',
  close = 'close',
  toggle = 'toggle',
}

const mutations: MutationTree<SidebarState> = {
  [mutationNames.open](state) {
    state.isOpen = true
  },
  [mutationNames.close](state) {
    state.isOpen = false
  },
  [mutationNames.toggle](state) {
    state.isOpen = !state.isOpen
  },
}
const actions: ActionTree<SidebarState, RootState> & ActionsImpl = {}

export const namespace = 'sidebar'

export const sidebar = {
  namespaced: true as true,
  state: sidebarState,
  getters,
  actions,
  mutations,
}
