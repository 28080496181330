
import { Component, Vue, Prop } from 'vue-property-decorator'
import InputLabel from '@/elements/InputLabel.vue'

@Component({
  components: { InputLabel },
})
export default class Incrementor extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: Number, default: 0, required: false })
  private readonly value!: number

  @Prop({ type: Number, default: 0, required: false })
  private readonly min!: number

  @Prop({ type: Number, required: false })
  private readonly max!: number

  increment() {
    let v = this.value
    v++
    if (!this.isValid(v)) return
    this.emit(v)
  }

  decrement() {
    let v = this.value
    v--
    if (!this.isValid(v)) return
    this.emit(v)
  }

  handleChange(e: any) {
    this.emit(Number(e.target.value))
  }

  emit(v: number) {
    this.$emit('input', v)
  }

  isValid(v: number): boolean {
    if (!this.max && v >= this.min) return true
    return v <= this.max && v >= this.min
  }
}
