
import { Mixins, Component, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { getEmptyLeagueInfoCondensed } from '@/modelHelpers/LeagueInfoCondensed'
import * as sidebar from '@/store/sidebar'
import * as programStore from '@/store/programs'
import * as auth from '@/store/authentication'
import * as authorization from '@/store/authorization'
import * as search from '@/store/search'
import * as teamStore from '@/store/team'
import * as resourcesStore from '@/store/resources'
import ProgramSelect from '@/components/ProgramSelect.vue'
import RoleSelect from '@/components/RoleSelect.vue'
import { VolunteerRole } from '@/models/VolunteerRole'
import { RoleUnion, getEmtpyRoleUnion } from '@/common/Authorization/RoleUnion'
import { RoleMixin } from '@/common/Authorization/RoleMixin'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { ApplicationRoles } from '@/common/Authorization/ApplicationRoles'

@Component({
  components: {
    ProgramSelect,
    RoleSelect,
  },
})
export default class TheHeader extends Mixins(RoleMixin) {
  @Mutation(sidebar.mutationNames.toggle, { namespace: sidebar.namespace })
  private toggleSidebar!: void

  @Getter(programStore.getterNames.programs, { namespace: programStore.namespace })
  private readonly programs!: LeagueInfoCondensed[]

  @Mutation(programStore.mutationNames.setCurrentProgram, { namespace: programStore.namespace })
  private setCurrentProgram!: ({ upwId }: { upwId: string }) => void

  @Mutation(teamStore.mutationNames.setCurrentTeam, { namespace: teamStore.namespace })
  private setCurrentTeam!: ({ team }: { team: DivisionTeamInfoExt | null }) => void

  @Mutation(resourcesStore.mutationNames.reset, { namespace: resourcesStore.namespace })
  private resetResources!: () => void

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  @Getter(auth.getterNames.fullName, { namespace: auth.namespace })
  private readonly fullName!: string

  @Getter(auth.getterNames.leagueRolesByProgram, { namespace: auth.namespace })
  private readonly leagueRolesByProgram!: (id: string) => VolunteerRole[]

  @Getter(auth.getterNames.accountRoles, { namespace: auth.namespace })
  private readonly accountRoles!: string[]

  @Mutation(authorization.mutationNames.setCurrentRole, { namespace: authorization.namespace })
  private setCurrentRole!: ({ currentLeagueRole }: { currentLeagueRole: RoleUnion }) => void

  @Mutation(authorization.mutationNames.removeApplicationRole, { namespace: authorization.namespace })
  private removeApplicationRole!: ({ role }: { role: ApplicationRoles }) => void

  @Getter(authorization.getterNames.currentRole, { namespace: authorization.namespace })
  private readonly currentRole!: () => RoleUnion

  @Mutation(search.mutationNames.reset, { namespace: search.namespace })
  private searchReset!: () => void

  private selectedProgram: LeagueInfoCondensed = getEmptyLeagueInfoCondensed()
  private selectedRole: RoleUnion = getEmtpyRoleUnion()

  private setRole() {
    if (!this.roles) return
    this.selectedRole = this.roles[0]
    this.roleChanged()
  }

  private get roles() {
    //returns the union of volunteer roles and account roles as RoleUnion type
    const id = this.selectedProgram.upwardLeagueID || ''
    const roles = this.roleUnion(this.leagueRolesByProgram(id), this.accountRoles, this.hasCheer)
    return roles
  }

  private get hasCheer() {
    const res = this.currentProgram.typeLeagueID?.includes('CHEER')
    return res || false
  }
  private get showPrograms() {
    return this.currentProgram && this.$route.name !== 'Programs'
  }

  private get showRoles() {
    return this.currentRole && this.$route.name !== 'Programs'
  }

  private programChanged() {
    if (!this.selectedProgram) return
    const id = this.selectedProgram.upwardLeagueID || ''
    this.setCurrentProgram({ upwId: id })
    this.setRole()
    this.searchReset()
    this.resetTeams()
    this.resetResources()
    this.goHome()
  }

  private resetTeams() {
    this.setCurrentTeam({ team: null })
    this.removeApplicationRole({ role: ApplicationRoles.COACH_WITH_TEAM })
  }

  private roleChanged() {
    this.setCurrentRole({ currentLeagueRole: this.selectedRole })
    this.goHome()
    this.resetResources()
    this.resetTeams()
  }

  private goHome() {
    if (this.$route.name !== 'Home') this.$router.push({ name: 'Home' })
  }

  @Watch('currentProgram')
  private currentProgramChanged() {
    this.selectedProgram = this.currentProgram
    this.programChanged()
  }
}
