
import { Vue, Component } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import * as authorization from '@/store/authorization'
import * as auth from '@/store/authentication'
import * as programStore from '@/store/programs'
import * as upwardTypesStore from '@/store/upwardTypes'

import contentManagementClient from '@/clients/contentManagementClient'
import resourceUsageClient from '@/clients/resourceUsageClient'

import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import Loading from '@/elements/Loading.vue'
import Accordion from '@/components/Accordion.vue'
import AccordionItem from '@/components/AccordionItem.vue'

import { RoleUnion } from '@/common/Authorization/RoleUnion'
import { convertSuperUserToDirector } from '@/common/Authorization/AccountRoles'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'

import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { ResourceItem } from '@/models/Program/ResourceItem'
import { Resource } from '@/models/Program/Resource'
import { MarketingResource } from '@/models/Resources/MarketingResource'
import { UpwardLeagueTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueTypeID.ts'

interface Taxonomy {
  resourceID: string
  title: string
  expirationDate: string
  shortDescriptionContent: string | null
  leagueDirectoryTaxonomy?: string[] | null
}

@Component({
  components: {
    Breadcrumbs,
    Loading,
    Accordion,
    AccordionItem,
  },
})
export default class Resources extends Vue {
  @Getter(authorization.getterNames.currentRole, { namespace: authorization.namespace })
  private readonly currentRole!: RoleUnion

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private readonly currentProgram!: LeagueInfoCondensed

  @Getter(auth.getterNames.email, { namespace: auth.namespace })
  private readonly email!: string

  @Getter(auth.getterNames.firstAccountNumber, { namespace: auth.namespace })
  private readonly firstAccountNumber!: string

  @Action(upwardTypesStore.actionNames.fetchLeagueTypes, { namespace: upwardTypesStore.namespace })
  private fetchLeagueTypes!: ({ force }: { force: boolean }) => Promise<boolean>

  @Getter(upwardTypesStore.getterNames.leagueTypes, { namespace: upwardTypesStore.namespace })
  private readonly leagueTypes!: UpwardLeagueTypeID[]

  private loading = false
  private resources: Resource[] = []
  private marketingResources: MarketingResource[] = []
  private breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.RESOURCES, isActive: false },
  ])

  private async mounted() {
    try {
      this.loading = true
      await this.fetchLeagueTypes({ force: false })
      await this.fetchResources()
    } finally {
      this.loading = false
    }
  }

  private async fetchResources() {
    this.resources = []
    this.marketingResources = []

    if (!this.currentRole || !this.currentRole.roleId || !this.currentProgram) {
      return
    }

    if (this.currentRole.roleId === VolunteerRoles.ADVERTISING_COMMISSIONER) {
      await this.fetchMarketingResources()
    } else {
      await this.fetchVolunteerResources()
    }
  }

  private async fetchMarketingResources() {
    const resourcesResult = await contentManagementClient.getMarketingResources(
      this.currentLeagueAbbreviation()
    )
    const now = new Date()
    if (!resourcesResult) return
    this.marketingResources = resourcesResult.filter(
      (r) =>
        r.expirationDate == null ||
        r.expirationDate === '0001-01-01T00:00:00' ||
        new Date(r.expirationDate) > now
    )
  }

  private async fetchVolunteerResources() {
    const resourcesResult = await contentManagementClient.getVolunteerResourcesByUPW(
      this.currentProgram.upwardLeagueID ?? '',
      this.currentProgram.typeProgramID || '',
      convertSuperUserToDirector(this.currentRole.roleId) || ''
    )
    const now = new Date()
    if (!resourcesResult) return
    this.resources = resourcesResult
      .filter((r) => r.expirationDate === '0001-01-01T00:00:00' || new Date(r.expirationDate) > now)
      .filter(this.devoTrackFilter)
  }

  private devoTrackFilter(r: Resource) {
    if (!r.devotionTrack) {
      return true
    }
    return r.devotionTrack == this.currentProgram.upwardDevotionTrackTypeID
  }

  private volunteerTaxonomies() {
    const taxonomies: { [key: string]: Taxonomy[] } = {}
    for (const resource of this.resources) {
      if (resource.leagueDirectoryTaxonomy && Array.isArray(resource.leagueDirectoryTaxonomy)) {
        for (const taxonomyTitle of resource.leagueDirectoryTaxonomy) {
          if (!taxonomies[taxonomyTitle]) {
            taxonomies[taxonomyTitle] = []
          }
          taxonomies[taxonomyTitle].push(resource)
        }
      } else if (resource.title && !taxonomies[resource.title]) {
        taxonomies[resource.title] = []
        taxonomies[resource.title].push(resource)
      }
    }
    return taxonomies
  }

  private marketingTaxonomies() {
    const taxonomies: { [key: string]: Taxonomy[] } = {}
    for (const resource of this.marketingResources) {
      const taxonomyTitle = resource.title
      if (!taxonomies[taxonomyTitle]) {
        taxonomies[taxonomyTitle] = []
      }

      taxonomies[taxonomyTitle].push(resource)
    }
    return taxonomies
  }

  private get taxonomies() {
    if (this.currentRole.roleId == VolunteerRoles.ADVERTISING_COMMISSIONER) {
      return this.marketingTaxonomies()
    }
    return this.volunteerTaxonomies()
  }

  private currentLeagueAbbreviation(): string {
    const leagueType = this.leagueTypes.find(
      (t: UpwardLeagueTypeID) => t.upwardTypeID === this.currentProgram.typeLeagueID
    )
    return leagueType && leagueType.abbreviation ? leagueType.abbreviation : ''
  }

  private downloadResource(resource: Resource) {
    if (!resource) {
      return
    }

    const resourceItem: ResourceItem = {
      accountNumber: this.firstAccountNumber,
      resourceID: resource.resourceID,
      resourceTitle: resource.title,
      programType: this.currentLeagueAbbreviation(),
      userID: this.email,
      role: this.currentRole.roleId || '',
      pageAccessedFrom: window.location.href,
    }

    resourceUsageClient.postResourceUsage(resourceItem)

    if (resource.mediaUrl) {
      window.open(resource.mediaUrl, '_blank')
    }

    if (resource.resourceUrl) {
      window.open(resource.resourceUrl, '_blank')
    }
  }
  private get taxonomyTitles() {
    return Object.keys(this.taxonomies).sort()
  }
}
