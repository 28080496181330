
import { AccountInfo } from '@/GeneratedTypes/ListInfo/AccountInfo'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class PartnerList extends Vue {
  @Prop({ type: Array, required: true })
  private readonly partners!: AccountInfo[]

  private selectPartner(partner: AccountInfo) {
    this.$emit('selected', partner)
  }
}
