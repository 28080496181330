
import InputLabel from '@/elements/InputLabel.vue'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import uuid from 'uuid'

@Component({
  components: {
    InputLabel,
  },
})
export default class CheckboxInput extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly label!: string
  @Prop({ type: Boolean, default: true, required: false })
  private readonly inline!: boolean
  @Prop({ type: Boolean, default: false, required: false })
  private readonly required!: boolean
  @Prop({ type: Boolean, default: false, required: false })
  private readonly value!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  private readonly enabled!: boolean

  private readonly guid = uuid.v4()

  private internalValue: boolean = this.value

  private handleChange() {
    this.$emit('input', this.internalValue)
    this.$emit('change', this.internalValue)
  }

  @Watch('value')
  private onValueChanged() {
    this.internalValue = this.value
  }

  public get elementId() {
    return `checkbox-${this.guid}`
  }
}
