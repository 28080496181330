import { Component, Mixins } from 'vue-property-decorator'
import moment from 'moment'
import localStorageService from '@/services/localStorageService'
import { iOSTestMixin } from '@/common/iOSTestMixin'

@Component
export class PwaInstallMixin extends Mixins(iOSTestMixin) {
  protected localStorageKey = 'install-reminder'
  protected showInstallPrompt = false
  protected reminderDelay = 15
  protected deferredPrompt: Event | null = null

  protected installPrompt() {
    //SETUP PWA INSTALL ON ANDROID
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.deferredPrompt = e
      // Stash the event so it can be triggered later.
      if (this.timeToPrompt()) {
        this.showInstallPrompt = true
      }
    })

    //SETUP PWA INSTALL ON ANDROID
    window.addEventListener('appinstalled', () => {
      this.showInstallPrompt = false
    })

    //START: SETUP PWA INSTALL ON iSO
    if (this.isIosOnBrowser()) {
      this.showIosInstall()
    }
  }

  protected async dismissInstall(toasterChange: boolean) {
    // if toasterChange is false, then toaster was
    // dismissed without install
    if (!toasterChange) {
      localStorageService.set(this.localStorageKey, new Date())
      this.showInstallPrompt = false
    }
  }

  protected async install() {
    // BeforeInstallPromptEvent is a non-standard Web API without an official stable type.
    // Skip TypeScript check
    // @ts-ignore
    const result = await this.deferredPrompt.prompt()
    if (result === 'dismissed') {
      localStorageService.set(this.localStorageKey, new Date())
    }
    this.showInstallPrompt = false
  }

  private timeToPrompt() {
    const dismissDate = localStorageService.getParsed<Date>(this.localStorageKey)
    if (!dismissDate) return true

    const threshold = moment(dismissDate).add(15, 'day')
    return moment().isAfter(threshold)
  }

  private showIosInstall() {
    // Only show banner once for iOS because there
    // is no way to see if app is already installed
    if (this.timeToPrompt()) {
      this.showInstallPrompt = true
    }
    const HundredYearsFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 100))
    localStorageService.set(this.localStorageKey, HundredYearsFromNow)
  }
}
