var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"views-base-css"},[_c('div',{staticClass:"container-fluid"},[(!_vm.loading)?_c('div',{staticClass:"row"},[(!_vm.currentRole)?_c('div',{staticClass:"col"},[_vm._v("Please select a role to view Team Alerts.")]):[_c('div',{staticClass:"col-12"},[_c('accordion',{staticClass:"mt-2"},_vm._l((_vm.taxonomies),function(taxonomy){return _c('accordion-item',{key:taxonomy.alertID},[_c('template',{slot:"header"},[_c('div',{staticClass:"p-2",class:taxonomy.alertID == -1
                      ? 'btn-dark'
                      : taxonomy.isActive
                      ? 'bg-active'
                      : taxonomy.isPast
                      ? 'bg-past'
                      : 'bg-future'},[_c('h5',{staticClass:"mb-1 truncate",domProps:{"innerHTML":_vm._s(taxonomy.title)}}),(taxonomy.alertID > 0)?_c('h6',{staticClass:"mb-0"},[_c('i',{staticClass:"pl-2"},[_vm._v(_vm._s(taxonomy.subTitle))])]):_vm._e(),(taxonomy.alertID > 0)?_c('p',{staticClass:"small text-nuted mb-0"},[_c('i',{staticClass:"pl-2"},[_vm._v(_vm._s(taxonomy.visibility))])]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('team-alert',{attrs:{"alert":taxonomy.alertInfo},on:{"saved":_vm.onAlertSaved}})],1)],2)}),1)],1)]],2):_vm._e()]),(_vm.loading)?_c('div',{staticClass:"center-spinner-on-view"},[_c('loading')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }