import { GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store/rootState'

export interface SearchState {
  searchTerms: string
}

const searchState: SearchState = {
  searchTerms: '',
}

export enum getterNames {
  searchTerms = 'searchTerms',
}

const getters: GetterTree<SearchState, RootState> = {
  [getterNames.searchTerms]: (state) => state.searchTerms,
}

export enum mutationNames {
  setSearchTerms = 'setSearchTerms',
  reset = 'reset',
}

const mutations: MutationTree<SearchState> = {
  [mutationNames.setSearchTerms](state, { searchTerms }: { searchTerms: string }) {
    state.searchTerms = searchTerms
  },
  [mutationNames.reset](state) {
    state.searchTerms = ''
  },
}

export const namespace = 'search'

export const search = {
  namespaced: true as true,
  state: searchState,
  getters,
  mutations,
}
