import { Vue, Component } from 'vue-property-decorator'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import Communication from '@/views/Communication.vue'
import Player from '@/views/Player.vue'
import Team from '@/views/Team.vue'
import TeamManage from '@/views/TeamManage.vue'
import Coach from '@/views/Coach.vue'
import InstallOption from '@/views/InstallOption.vue'
import DefaultError from '@/views/DefaultError.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import Resources from '@/views/Resources.vue'
import PracticeResources from '@/views/PracticeResources/PracticeResources.vue'
import MinistryResources from '@/views/PracticeResources/MinistryResources.vue'
import CheerResources from '@/views/PracticeResources/CheerResources.vue'
import SelectResources from '@/views/PracticeResources/SelectResources.vue'
import Search from '@/views/Search.vue'
import Programs from '@/views/Programs.vue'
import GettingStarted from '@/views/GettingStarted.vue'
import GameDay from '@/views/GameDay.vue'
import Impersonate from '@/views/Account/Impersonate/Impersonate.vue'
import RefereeSchedule from '@/views/RefereeSchedule.vue'
import Profile from '@/views/Account/Profile.vue'
import Password from '@/views/Account/Password.vue'
import Account from '@/views/Account/Account.vue'

import { MainMenu, MainMenuEnum } from '@/common/MainMenuItems'
import { RouterItems, RouterItemsEnum } from '@/common/RouterItems'
import { superUsers } from '@/common/Authorization/AccountRoles'

import practiceResourcesRoutes from '@/router/practiceResources'
import ministryResourcesRoutes from '@/router/ministryResources'
import cheerResourcesRoutes from '@/router/cheerResources'
import selectResourcesRoutes from '@/router/selectResources'
import gameRoute from '@/router/game'
import gameDayRoutes from '@/router/gameDay'
import communicationRoutes from '@/router/communication'
import startDistributionRoutes from '@/router/startDistribution'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'

Vue.use(Router)

export interface RouteMeta {
  isPublic: boolean
  isHome: boolean
}

@Component
export class RouteMetaMixin extends Vue {
  // This allows components while in the context of nested routes to see the aggregate meta,
  // which should just be the root level route.
  protected get currentRouteMeta() {
    return {
      isPublic: this.$route.matched.some((r) => (r.meta as RouteMeta).isPublic),
    } as RouteMeta
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Root',
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/expired-password',
      name: 'ExpiredPassword',
      component: ForgotPassword,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/locked-account',
      name: 'LockedAccount',
      component: ForgotPassword,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/403',
      name: 'error',
      component: DefaultError,
    },
    {
      path: '/programs',
      name: 'Programs',
      component: Programs,
    },
    {
      path: '/account',
      name: 'Account',
      children: [
        {
          path: RouterItems[RouterItemsEnum.PROFILE].route,
          name: RouterItems[RouterItemsEnum.PROFILE].name,
          component: Profile,
        },
        {
          path: RouterItems[RouterItemsEnum.PASSWORD].route,
          name: RouterItems[RouterItemsEnum.PASSWORD].name,
          component: Password,
        },
      ],
      component: Account,
    },
    {
      path: '/account/impersonate',
      name: 'Impersonate',
      component: Impersonate,
    },

    {
      path: RouterItems[RouterItemsEnum.HOME].route,
      name: RouterItems[RouterItemsEnum.HOME].name,
      component: Home,
    },
    {
      path: RouterItems[RouterItemsEnum.SEARCH].route,
      name: RouterItems[RouterItemsEnum.SEARCH].name,
      component: Search,
    },
    {
      path: MainMenu[MainMenuEnum.INSTALL].route,
      name: MainMenu[MainMenuEnum.INSTALL].name,
      meta: {
        guard: { allowIfAny: MainMenu[MainMenuEnum.INSTALL].allowIfAnyRoles },
      },
      component: InstallOption,
    },
    {
      path: MainMenu[MainMenuEnum.COMMUNICATION].route,
      name: MainMenu[MainMenuEnum.COMMUNICATION].name,
      children: communicationRoutes,
      meta: {
        guard: {
          allowIfAny: [...MainMenu[MainMenuEnum.COMMUNICATION].allowIfAnyRoles!, VolunteerRoles.COACH],
        },
      },
      component: Communication,
    },
    {
      path: MainMenu[MainMenuEnum.RESOURCES].route,
      name: MainMenu[MainMenuEnum.RESOURCES].name,
      meta: {
        guard: { allowIfAny: MainMenu[MainMenuEnum.RESOURCES].allowIfAnyRoles },
      },
      component: Resources,
    },
    {
      path: MainMenu[MainMenuEnum.PRACTICE_RESOURCES].route,
      name: MainMenu[MainMenuEnum.PRACTICE_RESOURCES].name,
      children: practiceResourcesRoutes,
      meta: {
        guard: { allowIfAny: MainMenu[MainMenuEnum.PRACTICE_RESOURCES].allowIfAnyRoles },
      },
      component: PracticeResources,
    },
    {
      path: MainMenu[MainMenuEnum.MINISTRY_RESOURCES].route,
      name: MainMenu[MainMenuEnum.MINISTRY_RESOURCES].name,
      children: ministryResourcesRoutes,
      meta: {
        guard: { allowIfAny: MainMenu[MainMenuEnum.MINISTRY_RESOURCES].allowIfAnyRoles },
      },
      component: MinistryResources,
    },
    {
      path: MainMenu[MainMenuEnum.CHEER_PRACTICE_RESOURCES].route,
      name: MainMenu[MainMenuEnum.CHEER_PRACTICE_RESOURCES].name,
      children: cheerResourcesRoutes,
      meta: {
        guard: { allowIfAny: MainMenu[MainMenuEnum.CHEER_PRACTICE_RESOURCES].allowIfAnyRoles },
      },
      component: CheerResources,
    },
    {
      path: MainMenu[MainMenuEnum.SELECT_PRACTICE_RESOURCES].route,
      name: MainMenu[MainMenuEnum.SELECT_PRACTICE_RESOURCES].name,
      children: selectResourcesRoutes,
      meta: {
        guard: { allowIfAny: MainMenu[MainMenuEnum.SELECT_PRACTICE_RESOURCES].allowIfAnyRoles },
      },
      component: SelectResources,
    },
    {
      path: MainMenu[MainMenuEnum.REFEREE_SCHEDULE].route,
      name: MainMenu[MainMenuEnum.REFEREE_SCHEDULE].name,
      meta: {
        guard: { allowIfAny: MainMenu[MainMenuEnum.REFEREE_SCHEDULE].allowIfAnyRoles },
      },
      component: RefereeSchedule,
    },
    {
      path: RouterItems[RouterItemsEnum.PLAYER].route,
      name: RouterItems[RouterItemsEnum.PLAYER].name,
      meta: {
        guard: { allowIfAny: [...superUsers, VolunteerRoles.COACH] },
      },
      component: Player,
    },
    {
      path: RouterItems[RouterItemsEnum.COACH].route,
      name: RouterItems[RouterItemsEnum.COACH].name,
      meta: {
        guard: { allowIfAny: superUsers },
      },
      component: Coach,
    },
    {
      path: MainMenu[MainMenuEnum.MANAGE_TEAM].route,
      name: MainMenu[MainMenuEnum.MANAGE_TEAM].name,
      meta: {
        guard: { allowIfAny: MainMenu[MainMenuEnum.MANAGE_TEAM].allowIfAnyRoles },
      },
      component: TeamManage,
    },
    {
      path: RouterItems[RouterItemsEnum.TEAM].route,
      name: RouterItems[RouterItemsEnum.TEAM].name,
      meta: {
        guard: { allowIfAny: superUsers },
      },
      component: Team,
    },
    {
      path: MainMenu[MainMenuEnum.GAMEDAY].route,
      name: MainMenu[MainMenuEnum.GAMEDAY].name,
      redirect: `${MainMenu[MainMenuEnum.GAMEDAY].route}/${MainMenu[MainMenuEnum.GAMEDAY].defaultChildRoute}`,
      children: [...gameRoute('gameday'), ...gameDayRoutes, ...startDistributionRoutes],
      meta: {
        guard: { allowIfAny: MainMenu[MainMenuEnum.GAMEDAY].allowIfAnyRoles },
      },
      component: GameDay,
    },
    {
      path: MainMenu[MainMenuEnum.STAR_DISTRIBUTION].route,
      name: MainMenu[MainMenuEnum.STAR_DISTRIBUTION].name,
      redirect: `${MainMenu[MainMenuEnum.STAR_DISTRIBUTION].route}/${
        MainMenu[MainMenuEnum.STAR_DISTRIBUTION].defaultChildRoute
      }`,
      children: [...gameRoute('startdistribution'), ...startDistributionRoutes],
      meta: {
        guard: { allowIfAny: MainMenu[MainMenuEnum.STAR_DISTRIBUTION].allowIfAnyRoles },
      },
      component: GameDay,
    },
    {
      path: RouterItems[RouterItemsEnum.START].route,
      name: RouterItems[RouterItemsEnum.START].name,
      component: GettingStarted,
    },
  ],
})

export default router
