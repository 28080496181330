
interface PlayerDetail {
  playerName: string | null
  contacts: PlayerContact[] | null
}

interface PlayerContact {
  parentName: string | null
  relationship: string | null
  touchPoints: TouchPoint[] | null
}

interface TouchPoint {
  id: number
  touchPoint: string | null
  type: string | null
  selected: boolean
}

import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import * as teamStore from '@/store/team'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { cloneDeep } from 'lodash'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import ListItem from '@/components/ListItem.vue'
import Listing from '@/components/Listing.vue'
import InputLabel from '@/elements/InputLabel.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import { IndividualEmailInfo } from '@/GeneratedTypes/ListInfo/IndividualEmailInfo'
import { IndividualPhoneInfo } from '@/GeneratedTypes/ListInfo/IndividualPhoneInfo'
import { PlayerContactInfo } from '@/GeneratedTypes/ListInfo/PlayerContactInfo'
import InputlessStringValidator from '@/elements/InputlessStringValidator.vue'
import { formatPhone } from '@/formatters/phoneFmt'
import { maxStringLength } from '@/formatters/maxStringLength'

@Component({
  components: {
    ListItem,
    Listing,
    CheckboxInput,
    InputLabel,
    InputlessStringValidator,
  },
})
export default class ParentRecipientPicker extends Vue {
  @Prop({ type: Array, default: [], required: false })
  private readonly value!: string[]

  @Prop({ type: String, default: 'Email', required: false })
  private readonly messageType!: string

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  private currentTeam!: DivisionTeamInfoExt

  private players: PlayerDetail[] | null = [] as PlayerDetail[]
  private localValue: string[] = []

  private mounted() {
    this.initialize()
  }

  private initialize() {
    this.localValue = this.value
    this.players = this.emailList()
    this.setSelected()
  }

  private emailList(): PlayerDetail[] {
    const playerClone = cloneDeep(this.currentTeam.players)
    if (!playerClone) return [] as PlayerDetail[]
    const list = playerClone.map((a: TeamPlayerInfo) => {
      if (!a.contacts) return null
      const playerContacts = a.contacts.map((b: PlayerContactInfo) => {
        const emails = this.convertToContacts(b)
        if (!emails) return null
        return this.convertToPlayerContact(b, emails)
      })
      return this.convertToPlayerDetail(a, playerContacts)
    })
    return list as PlayerDetail[]
  }

  private convertToContacts(b: PlayerContactInfo) {
    if (this.messageType === 'Email') {
      if (!b.emailAddresses) return []
      return b.emailAddresses.map((c: IndividualEmailInfo) => this.convertEmailToTouchPoint(c))
    }

    if (this.messageType === 'SMS') {
      if (!b.phoneNumbers) return []
      return b.phoneNumbers
        .filter((e: IndividualPhoneInfo) => e.typePhoneID === 'MOBILE')
        .map((c: IndividualPhoneInfo) => this.convertPhoneToTouchPoint(c))
    }
    return []
  }

  private convertEmailToTouchPoint(e: IndividualEmailInfo): TouchPoint | null {
    return {
      id: e.emailID,
      touchPoint: e.emailAddress,
      type: (e.typeEmailID ?? '').toLowerCase(),
      selected: false,
    }
  }

  private fmtContactPoint(contact: string) {
    if (this.messageType === 'SMS') {
      return formatPhone(contact)
    }
    return maxStringLength(contact, 25)
  }
  private convertPhoneToTouchPoint(e: IndividualPhoneInfo): TouchPoint | null {
    return {
      id: e.phoneID,
      touchPoint: e.phoneNumber,
      type: (e.typePhoneID ?? '').toLowerCase(),
      selected: false,
    }
  }

  private setAll(val: boolean) {
    if (!this.players) return
    this.players.forEach((a) => {
      if (!a.contacts) return
      a.contacts.forEach((b) => {
        if (!b.touchPoints) return
        b.touchPoints?.forEach((c) => (c.selected = val))
      })
    })
  }

  private convertToPlayerDetail(t: TeamPlayerInfo, contacts: (PlayerContact | null)[]): PlayerDetail {
    return {
      playerName: t.formattedNameFirstLast,
      contacts: contacts,
    } as PlayerDetail
  }

  private convertToPlayerContact(
    p: PlayerContactInfo,
    touchPoints: (TouchPoint | null)[]
  ): PlayerContact | null {
    return {
      parentName: p.formattedNameFirstLast,
      relationship: p.typeRelationshipID?.toLowerCase(),
      touchPoints,
    } as PlayerContact
  }

  @Watch('players', { deep: true })
  private emitSelectionChange() {
    const recipients: string[] = []
    if (!this.players) return

    this.players.forEach((a) => {
      if (!a.contacts) return
      a.contacts.forEach((b) => {
        if (!b.touchPoints) return
        b.touchPoints.forEach((c) => {
          if (!c.touchPoint || !c.selected) return
          recipients.push(c.touchPoint)
        })
      })
    })
    this.$emit('input', recipients)
  }

  private setSelected() {
    if (!this.players) return
    this.players.forEach((a) => {
      if (!a.contacts) return
      a.contacts.forEach((b) => {
        if (!b.touchPoints) return
        b.touchPoints.forEach((c) => {
          if (!c.touchPoint) return
          if (this.localValue.includes(c.touchPoint)) {
            c.selected = true
          }
        })
      })
    })
  }

  @Watch('value')
  private valueChanged() {
    this.localValue = this.value
    this.setSelected()
  }

  @Watch('messageType')
  private messageTypeChanged() {
    this.initialize()
  }
}
