
import { Component, Mixins, Prop } from 'vue-property-decorator'
import LinkingListItem from '@/components/LinkingListItem.vue'
import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'
import { CheerSquadGameAppointmentInfo } from '@/GeneratedTypes/ListInfo/CheerSquadGameAppointmentInfo'
import { IsCheerMixin } from '@/common/IsCheerMixin'
import { cloneDeep } from 'lodash'
import { CurrentGameLineupWithRotationMixin } from '@/common/CurrentGameLineupWithRotationMixin'

interface listItem {
  id: number
  round: number | null
  route: string
}

@Component({
  components: { LinkingListItem },
})
export default class GameDayGameList extends Mixins(IsCheerMixin, CurrentGameLineupWithRotationMixin) {
  @Prop({ type: Array, required: false })
  private readonly games!: DivisionGameInfo[]

  @Prop({ type: Array, required: false })
  private readonly appointments!: CheerSquadGameAppointmentInfo[]

  private get list() {
    if (this.isCheer) {
      return this.composeCheer()
    } else {
      return this.composeSport()
    }
  }

  private composeCheer(): listItem[] {
    return this.appointments.map((a, i) => {
      return {
        id: a.id,
        round: i + 1,
        route: `game/${a.id}/gamerecognition`,
      }
    })
  }

  private composeSport(): listItem[] {
    const games: DivisionGameInfo[] = cloneDeep(this.games)
    return games
      .sort((a, b) => a.roundNumber - b.roundNumber)
      .map((a) => {
        return {
          id: a.gameID,
          round: a.roundNumber,
          route: `game/${a.gameID}${this.isSelect ? '/gamerecognition' : ''}`,
        }
      })
  }
}
