import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import * as teamStore from '@/store/team'
import * as lineupStore from '@/store/lineup'
import { GameLineupWithRotation, getEmptyGameLineupWithRotation } from '@/models/Lineup'

@Component
export class CurrentGameLineupWithRotationMixin extends Vue {
  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  protected readonly currentTeam!: DivisionTeamInfoExt

  @Getter(lineupStore.getterNames.lineupsWithRotation, { namespace: lineupStore.namespace })
  protected readonly allLineups!: GameLineupWithRotation[]

  @Mutation(lineupStore.mutationNames.upsertLineupWithRotation, { namespace: lineupStore.namespace })
  protected upsertLineup!: ({ lineup }: { lineup: GameLineupWithRotation }) => void

  @Mutation(lineupStore.mutationNames.deleteLineupWithRotation, { namespace: lineupStore.namespace })
  protected deleteLineupWithRotation!: ({ lineup }: { lineup: GameLineupWithRotation }) => void

  protected get currentGameLineupWithRotation() {
    const lineup = this.maintainDataModel()
    return lineup ? lineup : getEmptyGameLineupWithRotation()
  }

  private maintainDataModel(): GameLineupWithRotation | undefined {
    //From time to time the JSON structure of a gameLineupWithRotation may need to change.
    //Each time a gameLineup is retieved, make sure it is up to date.

    //All lineups more than a year old are automatically deleted,
    // so any check more than a year old can be removed.
    const divisionId = this.currentTeam.divisionID
    const teamId = this.currentTeam.teamID
    const lineup = this.allLineups.find((l) => {
      return l.gameId === this.gameId && l.teamId === teamId && l.divisionId === divisionId
    })

    let dirty = false
    if (!lineup) return

    /*** CHECKS ***/
    if (
      lineup.lineup.length > 0 &&
      lineup.lineup[0].players.length > 0 &&
      !lineup.lineup[0].players[0].hasOwnProperty('positionText')
    ) {
      //this is not a valid GameLineupWithRotation, remove it so the lineup will regenerate
      //#4576
      dirty = true
      this.deleteLineupWithRotation({ lineup })
    }

    //** Return clean GameLineupWithRotation ***/
    if (dirty) {
      return this.allLineups.find((l) => {
        return l.gameId === this.gameId && l.teamId === teamId && l.divisionId === divisionId
      })
    }
    return lineup
  }

  protected currentGameLineupById(gameId: number) {
    const divisionId = this.currentTeam.divisionID
    const teamId = this.currentTeam.teamID
    const lineup = this.allLineups.find((l) => {
      return l.gameId === gameId && l.teamId === teamId && l.divisionId === divisionId
    })
    return lineup ? lineup : getEmptyGameLineupWithRotation()
  }

  protected get gameId(): number {
    if (!this.$route.params.gameId) throw Error('Missing gameId on Route')
    return Number(this.$route.params.gameId)
  }
}
