
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { SidesEnum } from '@/models/Lineup'

@Component
export default class StartingSideToggle extends Vue {
  @Prop({ required: false, type: String, default: null })
  private readonly value!: string

  private selectedValue: string | null = null
  private editable = false
  private name = 'pick_a_side'
  private options = [
    { text: 'Starting Offense', value: SidesEnum.OFFFENSE },
    { text: 'Starting Defense', value: SidesEnum.DEFENSE },
  ]

  private mounted() {
    this.selectedValue = this.value
  }

  private get showChoices(): boolean {
    return !this.selectedValue || this.editable === true
  }

  @Watch('selectedValue')
  private selectedValueChanged() {
    this.$emit('input', this.selectedValue)
  }
  @Watch('value')
  private valueChanged() {
    this.selectedValue = this.value
  }
}
