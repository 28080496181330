import { DivisionTeamPracticeAppointmentInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamPracticeAppointmentInfo'

export function getEmptyDivisionTeamPracticeAppointmentInfo(): DivisionTeamPracticeAppointmentInfo {
  return {
    id: 0,
    leagueID: 0,
    typeProgramID: '',
    divisionID: 0,
    teamID: 0,
    practiceStart: null,
    practiceEnd: null,
    facilityID: 0,
    facilityName: null,
  }
}

export function getEmptyDivisionTeamPracticeAppointmentInfoList(): DivisionTeamPracticeAppointmentInfo[] {
  return [getEmptyDivisionTeamPracticeAppointmentInfo()]
}
