
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import * as resourceStore from '@/store/resources'
import { Resource } from '@/models/Program/Resource'

@Component
export default class CoachResource extends Vue {
  @Getter(resourceStore.getterNames.resources, { namespace: resourceStore.namespace })
  private readonly resources!: Resource[]

  private get resource(): string {
    const resource = this.resources.find((r) => r.resourceID === this.$route.params.resourceId)
    return resource?.htmlCode ?? ''
  }
}
