
import InputLabel from '@/elements/InputLabel.vue'
import ButtonGroup from '@/elements/ButtonGroup.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import uuid from 'uuid'

@Component({
  components: {
    InputLabel,
    ButtonGroup,
    SelectInput,
  },
})
export default class SwitchSlot extends Vue {
  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: String, default: 'radio', required: false })
  private readonly mode!: string

  @Prop({ type: Boolean, default: true, required: false })
  private readonly showBorder!: boolean

  @Prop({ required: false, default: '' })
  private readonly value!: string

  @Prop({ type: Array, required: true })
  private readonly options!: Array<{ text: string; value: string | string[] }>

  @Prop({ type: Boolean, default: true, required: false })
  private readonly enabled!: boolean

  private selectedValue = this.value || (this.options.length ? this.options[0].value : null)
  private readonly guid = uuid.v4()
  private readonly name = `switchslot-${this.guid}`

  private get slotNames() {
    if (!this.selectedValue) {
      return []
    }

    if (!Array.isArray(this.selectedValue)) {
      return [this.selectedValue]
    }

    return this.selectedValue
  }

  @Watch('selectedValue')
  private onSelectedValueChange() {
    this.$emit('input', this.selectedValue)
    this.$emit('change', this.selectedValue)
  }

  @Watch('value')
  private onValueChange() {
    this.selectedValue = this.value || (this.options.length ? this.options[0].value : null)
  }
}
