
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'

@Component({
  components: {
    Modal,
  },
})
export default class ConfirmationModal extends Vue {
  @Prop({ type: String, required: true })
  private readonly title!: string

  @Prop({ type: Boolean, required: false, default: false })
  private readonly show!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  private readonly isSmall!: boolean

  private modalIsVisible = false

  private mounted() {
    this.modalIsVisible = this.show
  }

  private onYes() {
    this.$emit('onResponse', true)
  }

  private onNo() {
    this.$emit('onResponse', false)
  }
  @Watch('show')
  private onProgramChange(newValue: boolean) {
    this.modalIsVisible = newValue
  }
}
