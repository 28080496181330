
import { Component, Vue, Prop } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'

enum xOffsetEnum {
  RIGHT,
  LEFT,
  CENTER,
}

enum yOffsetEnum {
  ABOVE,
  BELOW,
}

@Component({
  directives: { onClickaway },
})
export default class SimpleMenu extends Vue {
  @Prop({ type: Boolean, default: false, required: false })
  private readonly display!: boolean

  @Prop({ type: Number, default: xOffsetEnum.LEFT, required: false })
  private readonly xOffset!: xOffsetEnum

  @Prop({ type: Number, default: yOffsetEnum.BELOW, required: false })
  private readonly yOffset!: yOffsetEnum

  private left: number | string = 'inherit'
  private top: number | string = 'inherit'
  private right: number | string = ' inherit'
  private bottom: number | string = 'inherit'
  private show = false

  private get style() {
    return {
      top: this.top,
      left: this.left,
      right: this.right,
      bottom: this.bottom,
    }
  }

  private close() {
    this.show = false
  }

  private open() {
    const parent = this.$refs.menu as HTMLElement
    const parentHeight = parent.clientHeight * 0.65
    const parentWidth = parent.clientWidth * 0.65
    if (this.yOffset === yOffsetEnum.BELOW) {
      this.top = `${parentHeight}px`
    }
    if (this.yOffset === yOffsetEnum.ABOVE) {
      this.bottom = `${parentHeight}px`
    }
    if (this.xOffset === xOffsetEnum.RIGHT) {
      this.left = `${parentWidth}px`
    }
    if (this.xOffset === xOffsetEnum.LEFT) {
      this.right = `${parentWidth}px`
    }
    const elem = this.$refs.menuContent as HTMLElement

    Vue.nextTick(() => elem.focus())
    this.show = !this.show
  }
}
