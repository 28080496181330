import { Component, Mixins } from 'vue-property-decorator'
import { MainMenu } from '@/common/MainMenuItems'
import { iOSTestMixin } from '@/common/iOSTestMixin'

@Component
export class MainMenuMixin extends Mixins(iOSTestMixin) {
  private get menu() {
    const menu: any = {}
    if (!this.isIosOnBrowser()) {
      Object.entries(MainMenu).forEach(([key, value]) => {
        //if it's not an iphone, don't include install menu item
        if (value.text != 'Install') {
          menu[key] = value
        }
      })
    } else {
      return MainMenu
    }
    return menu
  }
}
