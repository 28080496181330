
import { defineComponent, ref } from 'vue'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import PageStateToggle from '@/components/PageStateToggle.vue'
import { useRouter, useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'Account',
  props: {},
  components: { Breadcrumbs, PageStateToggle },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const pageStates = ['Profile', 'Password']
    const currentToggle = ref(pageStates[0])
    const breadcrumbs: Crumb[] = getCrumbs([
      { crumb: CrumbsEnum.HOME, isActive: true },
      { crumb: CrumbsEnum.ACCOUNT, isActive: false },
    ])

    async function stateChanged() {
      if (!route.path.includes(currentToggle.value.toLowerCase())) {
        const path = `/account/${currentToggle.value.toLowerCase()}`
        router.push(path)
      }
    }

    return { breadcrumbs, currentToggle, pageStates, stateChanged }
  },
})
