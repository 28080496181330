import { GetterTree, MutationTree, ActionTree, Commit } from 'vuex'
import { RootState } from '@/store/rootState'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import divisionsClient from '@/clients/divisionsClient'
import { ActionsImpl } from 'direct-vuex'

interface DivisionsState {
  items: LeagueDivisionInfo[]
}

const divisionsState: DivisionsState = {
  items: [] as LeagueDivisionInfo[],
}

export enum getterNames {
  allItems = 'allItems',
}

const getterTree: GetterTree<DivisionsState, RootState> = {
  [getterNames.allItems]: (state) => state.items,
}

export enum mutationNames {
  setAll = 'setAll',
}

const mutations: MutationTree<DivisionsState> = {
  [mutationNames.setAll](state, items) {
    state.items = items
  },
}

export enum actionNames {
  fetchAll = 'fetchAll',
}

const actions: ActionTree<DivisionsState, RootState> & ActionsImpl = {
  async [actionNames.fetchAll](
    { commit }: { commit: Commit },
    { upwardLeagueId }: { upwardLeagueId: string }
  ): Promise<boolean> {
    const result = await divisionsClient.retrieveAll(upwardLeagueId)

    if (result) {
      commit(mutationNames.setAll, result)
      return true
    }

    return false
  },
}

export const namespace = 'divisions'

export const divisions = {
  namespaced: true as true,
  state: divisionsState,
  getters: getterTree,
  actions,
  mutations,
}
