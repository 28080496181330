import { LeagueSpecialEventInfo } from '@/GeneratedTypes/ListInfo/LeagueSpecialEventInfo'

export function getEmptyLeagueSpecialEventInfo(): LeagueSpecialEventInfo {
  return {
    leagueID: 0,
    eventID: 0,
    typeProgramID: 'NONE',
    divisionID: 0,
    teamID: 0,
    facilityID: 0,
    eventStart: null,
    eventEnd: null,
    eventLabel: '',
    eventDescription: '',
    divisionAccountNumber: '',
    divisionName: '',
    teamName: '',
    upwardTeamID: '',
    typeColorID: '',
    facilityAccountNumber: '',
    facilityName: '',
    street1: '',
    street2: '',
    subdivision1: '',
    subdivision2: '',
    postalCode: '',
    typeCountryID: '',
  }
}
