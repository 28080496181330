
import { Component, Mixins, Watch } from 'vue-property-decorator'
import StylableSelectInput, { SelectConfig } from '@/elements/StylableSelectInput.vue'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import * as teamStore from '@/store/team'
import * as programStore from '@/store/programs'
import * as authorizeStore from '@/store/authorization'
import { Action, Getter, Mutation } from 'vuex-class'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { cloneDeep } from 'lodash'
import Loading from '@/elements/Loading.vue'
import { ApplicationRoles } from '@/common/Authorization/ApplicationRoles'
import { IsCheerMixin } from '@/common/IsCheerMixin'

@Component({
  components: {
    Loading,
    StylableSelectInput,
  },
})
export default class CurrentTeamSelect extends Mixins(IsCheerMixin) {
  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  @Mutation(teamStore.mutationNames.setCurrentTeam, { namespace: teamStore.namespace })
  private setCurrentTeam!: ({ team }: { team: DivisionTeamInfoExt | null }) => void

  @Mutation(authorizeStore.mutationNames.addApplicationRole, { namespace: authorizeStore.namespace })
  private addApplicationRole!: ({ role }: { role: ApplicationRoles }) => void

  @Mutation(authorizeStore.mutationNames.removeApplicationRole, { namespace: authorizeStore.namespace })
  private removeApplicationRole!: ({ role }: { role: ApplicationRoles }) => void

  @Action(teamStore.actionNames.fetchLeagueTeams, { namespace: teamStore.namespace })
  private fetchLeagueTeams!: ({ upwId, force }: { upwId: string | null; force: boolean }) => Promise<boolean>

  @Action(teamStore.actionNames.fetchTeamsForACoach, { namespace: teamStore.namespace })
  private fetchTeamsForACoach!: ({ force }: { force: boolean }) => Promise<boolean>

  @Action(teamStore.actionNames.fetchAndSetCurrentTeam, { namespace: teamStore.namespace })
  private fetchAndSetCurrentTeam!: ({
    upwId,
    programTypeId,
    divisionId,
    teamId,
  }: {
    upwId: string
    programTypeId: string
    divisionId: number
    teamId: number
  }) => Promise<boolean>

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  protected readonly currentTeam!: DivisionTeamInfoExt

  @Getter(teamStore.getterNames.teamsForCurrentUser, { namespace: teamStore.namespace })
  private teamsForCurrentUser!: DivisionTeamInfoExt[]

  private selectedTeam: DivisionTeamInfoExt | null = null

  private isLoading = false
  private isLoadingTeam = false
  private teams = [] as DivisionTeamInfoExt[]
  private selectConfig: SelectConfig = {
    bgColor: 'white',
    color: 'black',
    borderColor: '#3e3e3e',
    placeholderColor: 'white',
    highlightBgColor: '#3e3e3e',
    highlightColor: 'white',
  }

  private async mounted() {
    await this.loadTeamList()
  }

  private async setDefaultTeamID() {
    if (this.currentTeam && this.currentTeam.upwardTeamID) {
      this.selectedTeam = cloneDeep(this.currentTeam)
    } else {
      if (this.teams.length > 0) {
        this.selectedTeam = this.teams[0]
      }
    }
    await this.setCurrentTeamInStore()
  }

  private async setCurrentTeamInStore() {
    const teamID = this.selectedTeam ? this.selectedTeam.upwardTeamID : null
    const teamExistsInList = this.teams.find((t) => t.upwardTeamID === teamID)

    if (!this.teams || !teamExistsInList) {
      // current team might no longer be in the list if the program has changed in TheHeader
      this.setCurrentTeam({ team: null })
      return
    }

    if (!this.currentTeam || this.currentTeam.upwardTeamID != teamID) {
      try {
        this.isLoadingTeam = true
        await this.fetchAndSetCompleteTeamData()
      } finally {
        this.isLoadingTeam = false
      }
    }
  }

  private async fetchAndSetCompleteTeamData() {
    // The payload returned from fetchTeamsByProgram or fetchTeamsForACoach does
    // not contain all the information needed for a team, so fetch complete team info
    const hasTeam = await this.fetchTeam()
    this.setApplicationRoles(hasTeam)
  }

  async fetchTeam() {
    const team = this.selectedTeam
    return await this.fetchAndSetCurrentTeam({
      upwId: team?.upwardLeagueID ?? '',
      programTypeId: team?.typeProgramID ?? '',
      divisionId: team?.divisionID ?? 0,
      teamId: team?.teamID ?? 0,
    })
  }

  private setApplicationRoles(hasTeam: boolean) {
    if (hasTeam) this.addApplicationRole({ role: ApplicationRoles.COACH_WITH_TEAM })
    if (!hasTeam) this.removeApplicationRole({ role: ApplicationRoles.COACH_WITH_TEAM })
  }

  private setTeam(item: DivisionTeamInfoExt) {
    this.selectedTeam = item
    this.setCurrentTeamInStore()
  }

  private setTeams() {
    // if the user is a coach; fetchTeamsForACoach. It returns all coach teams regardless of program, so filter accordingly
    // It the user is a director pretending to be a coach; fetchTeamsByProgram which is for one program, so all teams will always return from filtering operation
    if (!this.currentProgram) return [] as DivisionTeamInfoExt[]
    const list = cloneDeep(this.teamsForCurrentUser)
    this.teams = list
      .filter((t: DivisionTeamInfoExt) => t.upwardLeagueID === this.currentProgram.upwardLeagueID)
      .sort((a, b) =>
        (a.teamName ?? '') === (b.teamName ?? '') ? 0 : (a.teamName ?? '') < (b.teamName ?? '') ? -1 : 1
      )
  }

  private async loadTeamList() {
    if (this.$allowIfAny(superUsers)) {
      await this.loadTeamsForSuperUsers()
    } else {
      await this.loadTeamsForCoaches()
    }
    await this.setTeams()
    await this.setDefaultTeamID()
  }

  private async loadTeamsForSuperUsers() {
    this.isLoading = this.forceReload
    try {
      await this.fetchLeagueTeams({
        upwId: this.currentProgram.upwardLeagueID,
        force: this.forceReload,
      })
    } finally {
      this.isLoading = false
    }
  }

  private async loadTeamsForCoaches() {
    this.isLoading = this.forceReload
    try {
      if (this.forceReload) {
        // fetchTeamsForACoach are for all programs so only call once
        await this.fetchTeamsForACoach({ force: this.forceReload })
      }
    } finally {
      this.isLoading = false
    }
  }

  private get forceReload(): boolean {
    //force reload if this is not a superUser, and teams have never been loaded
    if (!this.$allowIfAny(superUsers)) {
      return this.teamsForCurrentUser.length === 0
    }

    //force reload if the currentProgram changed, and this is a superUser
    if (this.currentProgram && this.currentTeam) {
      return this.currentProgram.upwardLeagueID !== this.currentTeam.upwardLeagueID
    }
    return true
  }

  @Watch('currentProgram')
  private currentProgramChanged() {
    this.loadTeamList()
    this.setTeams()
    this.setCurrentTeamInStore()
  }

  @Watch('isLoadingTeam')
  private isLoadingTeamChange() {
    this.$emit('teamLoading', this.isLoadingTeam)
  }

  @Watch('isLoading')
  private isLoadingChange() {
    this.$emit('teamLoading', this.isLoading)
  }
}
