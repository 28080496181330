
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator'
import focustrap, { FocusTrap } from 'focus-trap'
import tabbable from 'tabbable'

@Component
export default class Modal extends Vue {
  @Ref('dialog') dialog!: HTMLDialogElement
  @Ref('innerDialog') innerDialog!: HTMLDialogElement
  @Prop({ type: Boolean, required: true }) private readonly value!: boolean
  @Prop({ type: String, required: true }) private readonly title!: string
  @Prop({ type: Boolean, required: false, default: false }) private readonly isSmall!: boolean
  @Prop({ type: Boolean, required: false, default: true }) private readonly showClose!: boolean

  @Prop({ type: String, required: false, default: 'bg-dark text-white' })
  private readonly headerClass!: string

  @Prop({ type: String, required: false, default: '' })
  private readonly headerIcon!: string
  @Prop({ type: String, required: false, default: 'fa fa-times' })
  private readonly closeIconClass!: string
  private focustrap: FocusTrap | null = null

  private hide() {
    this.$emit('input', false)
    this.$emit('change', false)
  }

  private get isVisible() {
    return this.value
  }

  /***
   * Works to establish a focus trap. If this library is garbage we should
   * backup to b-modal on bootstrap-vue
   * see rules for tabbable and focus-trap
   * @param b {boolean} show status.
   */
  @Watch('value')
  async changeVisible(b: boolean) {
    this.$nextTick(() => {
      if (b) {
        if (!this.focustrap) {
          const ifc = tabbable(this.dialog.querySelector('.modal-body') ?? this.dialog)
          this.focustrap = focustrap(this.dialog, {
            initialFocus: (ifc?.[0] as HTMLElement | null) ?? this.dialog,
          })
        }

        this.focustrap.activate({})
      }
      if (!b && this.focustrap) {
        this.focustrap.deactivate({})
        this.focustrap = null
      }
    })
  }
  beforeDestroy() {
    if (this.focustrap) {
      this.focustrap.deactivate({})
      this.focustrap = null
    }
  }
}
