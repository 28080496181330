import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import * as teamStore from '@/store/team'
import * as gameStore from '@/store/game'

@Component
export class CurrentGameLineupCommonMixin extends Vue {
  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  protected readonly currentTeam!: DivisionTeamInfoExt

  @Action(gameStore.actionNames.fetchCurrentGameRecognitions, { namespace: gameStore.namespace })
  private fetchCurrentGameRecognitions!: ({
    upwId,
    gameId,
    teamId,
  }: {
    upwId: string | null
    gameId: number
    teamId: number
  }) => Promise<boolean>

  async fetchRecognitions(gameId: number) {
    // Only load recogntions if games are scheduled. If they are
    // generated from the division.gameCount property, there is no recognition functionality
    const upwId = this.currentTeam.upwardLeagueID
    const teamId = this.currentTeam.teamID
    await this.fetchCurrentGameRecognitions({ upwId, teamId, gameId: gameId })
  }
}
