
import BaseInputWrapper from '@/elements/BaseInputWrapper/BaseInputWrapper.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {
    BaseInputWrapper,
  },
})
export default class ButtonGroup extends Vue {
  @Prop({ type: Array, required: true })
  private readonly options!: Array<{ text: string; slotName: string; value: string }>

  @Prop({ required: true })
  private readonly value!: string | null

  private internalValue: string | null = null

  private select(value: any) {
    this.internalValue = value
    this.onInternalValueChange()
  }

  private created() {
    this.setInternalValue()
  }

  private setInternalValue() {
    this.internalValue = this.value ? this.value : this.options.length ? this.options[0].value : null
  }

  @Watch('value')
  private onValueChange() {
    this.setInternalValue()
  }

  @Watch('internalValue')
  private onInternalValueChange() {
    this.$emit('input', this.internalValue)
    this.$emit('change', this.internalValue)
  }
}
