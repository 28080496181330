
import { Component, Watch, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import * as teamStore from '@/store/team'
import * as gameStore from '@/store/game'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import gameClient from '@/clients/gameClient'
import { TypeProgramPlayerRecognitionInfo } from '@/GeneratedTypes/ListInfo/TypeProgramPlayerRecognitionInfo'
import Loading from '@/elements/Loading.vue'
import MemoInput from '@/elements/MemoInput.vue'
import { getEmptyDivisionGamePlayerInfo } from '@/modelHelpers/DivisionGamePlayer'
import { getEmptyCheerSquadRecognition } from '@/modelHelpers/CheerSquadRecognition'
import Star from '@/components/Star.vue'
import { DivisionGamePlayerInfo } from '@/GeneratedTypes/ListInfo/DivisionGamePlayerInfo'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import { IsCheerMixin } from '@/common/IsCheerMixin'
import { CheerSquadRecognition } from '@/GeneratedTypes/CheerSquadRecognition'
import teamClient from '@/clients/teamClient'

interface DisplayableRecognition {
  individualId: number
  recognitionId: string | null
  comment: string | null
  gameOrAptId: number
}

@Component({
  components: {
    Loading,
    MemoInput,
    Star,
    Breadcrumbs,
  },
})
export default class Recognition extends Mixins(IsCheerMixin) {
  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  protected currentTeam!: DivisionTeamInfoExt

  @Getter(gameStore.getterNames.currentGameRecognitions, { namespace: gameStore.namespace })
  private gameRecognitions!: DivisionGamePlayerInfo[]

  @Getter(gameStore.getterNames.currentCheerGameRecognitions, { namespace: gameStore.namespace })
  private cheerGameRecognitions!: CheerSquadRecognition[]

  @Action(teamStore.actionNames.fetchAndSetCurrentTeam, { namespace: teamStore.namespace })
  private fetchAndSetCurrentTeam!: ({
    upwId,
    programTypeId,
    divisionId,
    teamId,
  }: {
    upwId: string
    programTypeId: string
    divisionId: number
    teamId: number
  }) => Promise<boolean>

  private isLoading = false
  private saving = false
  private isloadingPlayerHistory = false
  private selectedRecognitionId: string | null = ''
  private comment = ''
  private recognitionHistory = [] as DisplayableRecognition[]
  private recognitionTypes = [] as TypeProgramPlayerRecognitionInfo[]
  private localCrumbs = [
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.GAMES_GAMEDAY, isActive: true },
    { crumb: CrumbsEnum.GAME, isActive: true, params: { gameId: this.gameId } },
    { crumb: CrumbsEnum.RECOGNITION, isActive: false },
  ]

  private breadcrumbs: Crumb[] = []

  private async mounted() {
    await Promise.all([this.retreiveStars(), this.retreivePlayerRecognitionHistory()])
    this.setRecognitionForThisPlayerAndThisGame()
    this.configBreadcrumbForCheer()
  }

  private configBreadcrumbForCheer() {
    if (this.isCheer || this.isSelect) {
      this.localCrumbs = this.localCrumbs.filter((c) => c.crumb != CrumbsEnum.GAME)
    }
    this.breadcrumbs = getCrumbs(this.localCrumbs)
  }

  private setRecognitionForThisPlayerAndThisGame() {
    if (this.playerRecognition) {
      this.selectedRecognitionId = this.playerRecognition.recognitionId ?? ''
      this.comment = this.playerRecognition.comment ?? ''
    }
  }

  private get playerRecognition(): DisplayableRecognition | null {
    const star = this.teamRecognitionList.find((g) => g.individualId === this.individualId)
    return star ? star : null
  }

  private get teamRecognitionList(): DisplayableRecognition[] {
    if (this.isCheer) {
      return this.composeDisplayableFromCheer
    } else {
      return this.composeDisplayableFromPlayer
    }
  }

  private get composeDisplayableFromCheer(): DisplayableRecognition[] {
    return this.cheerGameRecognitions.map((c) => {
      return {
        individualId: c.playerIndividualID,
        recognitionId: c.typePlayerRecognitionID,
        comment: c.note,
        gameOrAptId: c.cheerSquadGameAppointmentID,
      } as DisplayableRecognition
    })
  }

  private get composeDisplayableFromPlayer(): DisplayableRecognition[] {
    return this.gameRecognitions.map((c) => {
      return {
        individualId: c.playerIndividualID,
        recognitionId: c.typePlayerRecognitionID,
        comment: c.recognitionNote,
        gameOrAptId: c.gameID,
      } as DisplayableRecognition
    })
  }

  private get individualId(): number {
    return Number(this.$route.params.individualId)
  }

  private get gameId(): number {
    return Number(this.$route.params.gameId)
  }

  private removeStar() {
    this.selectedRecognitionId = ''
    this.comment = ''
  }

  private starClicked(recognitionId: string) {
    this.selectedRecognitionId = recognitionId
    this.comment = ''
  }

  private async save() {
    try {
      this.saving = true

      if (this.isCheer) {
        await this.saveCheer()
      } else {
        await this.savePlayer()
      }

      this.$router.back()
    } finally {
      this.saving = false
    }
  }

  private async savePlayer() {
    const player = this.composePlayer()
    await gameClient.updateSportRecognition(this.currentTeam.upwardLeagueID, player)
  }

  private async saveCheer() {
    const cheer = this.composeCheer()
    await gameClient.updateCheerRecognition(this.currentTeam.upwardLeagueID, cheer)
  }

  private composePlayer(): DivisionGamePlayerInfo {
    const player = getEmptyDivisionGamePlayerInfo()
    player.leagueID = this.currentTeam.leagueID
    player.typeProgramID = this.currentTeam.typeProgramID ?? ''
    player.divisionID = this.currentTeam.divisionID
    player.teamID = this.currentTeam.teamID
    player.recognitionNote = this.comment
    player.gameID = this.gameId
    player.playerIndividualID = this.individualId
    player.typePlayerRecognitionID = this.selectedRecognitionId
    return player
  }

  private composeCheer() {
    const cheer = getEmptyCheerSquadRecognition()
    cheer.leagueID = this.currentTeam.leagueID
    cheer.typeProgramID = this.currentTeam.typeProgramID ?? ''
    cheer.divisionID = this.currentTeam.divisionID
    cheer.teamID = this.currentTeam.teamID
    cheer.playerIndividualID = Number(this.$route.params.individualId)
    cheer.typePlayerRecognitionID = this.selectedRecognitionId
    cheer.note = this.comment
    cheer.cheerSquadGameAppointmentID = Number(this.$route.params.gameId)
    return cheer
  }

  private async retreiveStars() {
    if (!this.currentTeam.typeProgramID) return
    this.isLoading = true
    try {
      this.recognitionTypes = await gameClient.retrievePlayerRecognitionTypes(this.currentTeam.typeProgramID)
    } finally {
      this.isLoading = false
    }
  }

  private async retreivePlayerRecognitionHistory() {
    if (!this.currentTeam.typeProgramID) return
    this.isloadingPlayerHistory = true
    await this.setCurrentTeam()
    try {
      if (!this.isCheer) {
        this.recognitionHistory = await this.loadPlayerHistory()
      } else {
        this.recognitionHistory = await this.loadCheerHistory()
      }
    } finally {
      this.isloadingPlayerHistory = false
    }
  }

  private async setCurrentTeam() {
    if (this.currentTeam.currentGameID !== this.gameId) {
      this.isLoading = true
      try {
        await teamClient.setCurrentGame(this.currentTeam, this.gameId)
        await this.fetchTeam()
      } finally {
        this.isLoading = false
      }
    }
  }

  async fetchTeam() {
    const team = this.currentTeam
    return await this.fetchAndSetCurrentTeam({
      upwId: team?.upwardLeagueID ?? '',
      programTypeId: team?.typeProgramID ?? '',
      divisionId: team?.divisionID ?? 0,
      teamId: team?.teamID ?? 0,
    })
  }

  private async loadCheerHistory() {
    const x = await gameClient.retreiveCheerleaderRecognitions(
      this.currentTeam.upwardLeagueID,
      this.currentTeam.typeProgramID,
      this.currentTeam.divisionID,
      this.currentTeam.teamID,
      this.individualId
    )
    return x.map((p) => {
      return {
        individualId: p.playerIndividualID,
        recognitionId: p.typePlayerRecognitionID,
        comment: p.note,
        gameOrAptId: p.cheerSquadGameAppointmentID,
      } as DisplayableRecognition
    })
  }

  private async loadPlayerHistory() {
    const x = await gameClient.retreivePlayerRecognitions(
      this.currentTeam.upwardLeagueID,
      this.individualId,
      this.currentTeam.teamID
    )
    return x.map((p) => {
      return {
        individualId: p.playerIndividualID,
        recognitionId: p.typePlayerRecognitionID,
        comment: p.recognitionNote,
        gameOrAptId: p.gameID,
      } as DisplayableRecognition
    })
  }

  @Watch('currentTeam', { deep: true })
  private currentProgramChanged() {
    this.retreiveStars()
  }
}
