import Vue from 'vue'
import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex'
import { ActionsImpl, createDirectStore } from 'direct-vuex'
import createPersistedState from 'vuex-persistedstate'
import { RootState } from '@/store/rootState'
import { errors } from '@/store/errors'
import { sidebar } from '@/store/sidebar'
import { programs } from '@/store/programs'
import { teams } from '@/store/team'
import { partners } from '@/store/partner'
import { message } from '@/store/message'
import { authentication } from '@/store/authentication'
import { authorization } from '@/store/authorization'
import { search } from '@/store/search'
import { upwardTypes } from '@/store/upwardTypes'
import { resources } from '@/store/resources'
import { cheerResources } from '@/store/cheerResources'
import { lineup } from '@/store/lineup'
import { game } from '@/store/game'
import { divisions } from '@/store/divisions'

Vue.use(Vuex)

export enum actionNames {
  startTimekeeping = 'startTimekeeping',
}

const actions: ActionTree<RootState, RootState> & ActionsImpl = {
  [actionNames.startTimekeeping]: ({ commit }, payload?: boolean) => {
    if (!payload) setInterval(() => commit('updateTime'), 1000 * 30)
  },
}
const initialState: RootState = {
  now: new Date(),
  userLastSeen: new Date(),
}

export enum getterNames {
  now = 'now',
  userLastSeen = 'userLastSeen',
  userInactiveMS = 'userInactiveMS',
}

const getters: GetterTree<RootState, RootState> = {
  [getterNames.now]: (state) => state.now,
  [getterNames.userLastSeen]: (state) => state.userLastSeen,
  [getterNames.userInactiveMS]: (state) => state.now.getTime() - state.userLastSeen.getTime(),
}

export enum mutationNames {
  updateTime = 'updateTime',
  updateUserLastSeen = 'updateUserLastSeen',
}

const mutations: MutationTree<RootState> = {
  [mutationNames.updateTime]: (state) => {
    state.now = new Date()
  },
  [mutationNames.updateUserLastSeen]: (state) => {
    state.userLastSeen = new Date()
  },
}

const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } =
  createDirectStore({
    strict: process.env.NODE_ENV !== 'production',
    state: initialState,
    getters,
    actions,
    mutations,
    plugins: [
      createPersistedState({
        paths: ['lineup'],
      }),
    ],
    modules: {
      sidebar,
      programs,
      teams,
      partners,
      message,
      errors,
      authentication,
      authorization,
      search,
      upwardTypes,
      resources,
      lineup,
      game,
      cheerResources,
      divisions,
    },
  })

// The following exports will be used to enable types in the
// implementation of actions and getters.
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext }

// export the typed store.
export default store

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store

declare module 'vuex' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    direct: AppStore
  }
}
