import { cloneDeep } from 'lodash'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { TeamPlayerInfo } from 'src/GeneratedTypes/ListInfo/TeamPlayerInfo'
import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'
import { ProgramEnum } from '@/common/programEnum'
import {
  GameLineupWithRotation,
  Lineup,
  GameLineup,
  getEmptyGameLineupWithRotation,
  getEmptyGameLineup,
  GameLineupPlayer,
  getEmptyVolleyballPlayer,
  getEmptyBasketballPlayer,
} from '@/models/Lineup'
import store from '@/store'

export function inactivatePlayer(
  player: TeamPlayerInfo,
  gameLineup: GameLineupWithRotation
): GameLineupWithRotation {
  const clone = cloneDeep(gameLineup)
  clone.lineup.forEach((l) => {
    if (l.segment >= Number(clone.currentSegment)) {
      if (l.inactivePlayers === undefined) {
        l.inactivePlayers = []
      }
      const isExistingInactivePlayer = l.inactivePlayers.some((p) => p.individualID === player.individualID)
      if (!isExistingInactivePlayer) {
        l.inactivePlayers.push(player)
      }
    }
  })
  return clone
}

export function inactivePlayers(gameLineup: GameLineupWithRotation): TeamPlayerInfo[] | undefined {
  //Loop through the inactive players across all the segments and
  //create a list of unique inactived players
  if (!gameLineup) return

  let inactivePlayersFromAllSegments = [] as TeamPlayerInfo[]
  gameLineup.lineup.forEach((l) => {
    if (l.inactivePlayers) {
      inactivePlayersFromAllSegments = [...inactivePlayersFromAllSegments, ...l.inactivePlayers]
    }
  })

  if (!inactivePlayersFromAllSegments) return
  const uniquePlayerIds = [...new Set(inactivePlayersFromAllSegments.map((p) => p.individualID))]
  const uniqueInactivePlayers = [] as TeamPlayerInfo[]
  uniquePlayerIds.forEach((id) => {
    const player = inactivePlayersFromAllSegments.find((p) => p.individualID === id)
    if (player) uniqueInactivePlayers.push(player)
  })

  return uniqueInactivePlayers
}

export function activatePlayer(
  gameLineup: GameLineupWithRotation,
  playerId: number
): GameLineupWithRotation | undefined {
  const gameLineupClone: GameLineupWithRotation = cloneDeep(gameLineup)
  if (!gameLineupClone?.currentSegment) return
  const currentSegment = gameLineupClone?.currentSegment
  gameLineupClone.lineup?.forEach((l) => {
    if (l.segment >= currentSegment && l.inactivePlayers) {
      l.inactivePlayers = l.inactivePlayers.filter((p) => p.individualID != playerId)
    }
  })
  return gameLineupClone
}

export function adjustLineup(
  originalLineup: GameLineupWithRotation,
  players: TeamPlayerInfo[] | null,
  programType: UpwardProgramTypeID | undefined
): GameLineupWithRotation {
  if (!players) throw Error('Cannot adjust game lineup wihtout players')
  if (!programType) throw Error('Cannot adjust game lineup without programType')
  const numberOfSegments = store.getters.teams.currentTeamNumberOfSegments

  //Generate a large pool of players from which to generate lineups
  const lgPlayerPool = generateLineupPool(numberOfSegments, players)

  //Pool of player offset for this particular game in the season
  const gamePlayerPool = getGamePlayerPool(originalLineup.round, lgPlayerPool)

  // copy current GameLineup to preserve values
  const newLineup = cloneDeep(originalLineup)

  //create each segment
  newLineup.lineup.forEach((seg, i) => {
    const lastPlayerPreviousSegment = getLastPlayerFromPreviousSegment(i, newLineup)
    const newPlayerList = playerListAdjusted(
      originalLineup.lineup[i].inactivePlayers,
      gamePlayerPool,
      lastPlayerPreviousSegment,
      originalLineup.playersPerSegment
    )
    //preserve any postiionTexts set for players that didn;t drop out of this rotation
    seg.players = newPlayerList.map((x) => {
      return {
        ...x,
        positionText:
          originalLineup.lineup[i]?.players.find((op) => {
            return op.individualID == x.individualID
          })?.positionText ?? '',
      }
    })
  })
  return newLineup
}
function getGamePlayerPool(gameNumberInTheSeason: number, playerPool: TeamPlayerInfo[]) {
  //For each game in the season the first player is offset by one
  //so everyone gets a change to start. This function returns the pool
  //of players for this game taking into account that offset, but ingnoring
  //any substitutions
  const naturalGameLineupStart = gameNumberInTheSeason - 1
  return playerPool.slice(naturalGameLineupStart)
}

function getLastPlayerFromPreviousSegment(
  index: number,
  newLineup: GameLineupWithRotation
): TeamPlayerInfo | null {
  //Find last player from the previous segment, or set to null
  if (index != 0) {
    const prevSegmentPlayers = newLineup.lineup[index - 1].players
    return prevSegmentPlayers[prevSegmentPlayers.length - 1]
  }
  return null
}

function playerListAdjusted(
  inactivePlayers: TeamPlayerInfo[],
  gamePlayerPool: TeamPlayerInfo[],
  lastPlayerPreviousSegment: TeamPlayerInfo | null,
  playersPerSegment: number
): TeamPlayerInfo[] {
  let startIndex: number | null = null
  let activePlayers = cloneDeep(gamePlayerPool)

  if (inactivePlayers) {
    activePlayers = gamePlayerPool.filter((p) => {
      return !inactivePlayers.some((ip) => ip.individualID === p.individualID)
    })
  }

  //If lastPlayerPreviousSegment is null, then it's the first segment so the staring index is 0
  if (!lastPlayerPreviousSegment) {
    startIndex = 0
  } else {
    // If there is a lastPlayerPreviousSegment, check to see if it is in the active players.
    // If it is, start the segement with the player after lastPlayerPreviousSegment. If it isn't, find the next
    // ranked player in the roster to start with.
    startIndex = getNextPlayerIndex(gamePlayerPool, activePlayers, lastPlayerPreviousSegment.individualID)
  }
  const endIndex = startIndex + playersPerSegment
  return activePlayers.slice(startIndex, endIndex)
}

function getNextPlayerIndex(
  gamePlayerPool: TeamPlayerInfo[],
  activePlayers: TeamPlayerInfo[],
  individualId: number
) {
  let i = 0
  let nextPlayerIndex: number | null = null
  while (!nextPlayerIndex) {
    const idx = activePlayers.findIndex((p) => {
      return p.individualID === getPlayerIdByOffest(gamePlayerPool, individualId, i)
    })
    if (idx === -1) {
      nextPlayerIndex = 0
      i++
    } else {
      nextPlayerIndex = idx + 1
    }
  }
  return nextPlayerIndex
}

function getPlayerIdByOffest(playerPool: TeamPlayerInfo[], id: number, offset: number): number {
  if (offset === 0) return id
  const idx = playerPool.findIndex((p) => p.individualID === id)
  if (idx === playerPool.length - 1) return playerPool[0].individualID
  return playerPool[idx + offset].individualID
}

export function getRound(currentGame: DivisionGameInfo | undefined, gameId: number): number {
  if (currentGame) return currentGame.roundNumber
  return gameId
}

export function getIsScheduled(currentGame: DivisionGameInfo | undefined): boolean {
  if (currentGame) return true
  return false
}

export function generateLineup(
  programType: UpwardProgramTypeID,
  divisionId: number,
  teamId: number,
  gameId: number,
  round: number,
  players: TeamPlayerInfo[],
  isScheduleGame: boolean
): GameLineup {
  const gameLineup = getEmptyGameLineup()

  gameLineup.gameId = gameId
  gameLineup.teamId = teamId
  gameLineup.isScheduledGame = isScheduleGame
  gameLineup.divisionId = divisionId
  gameLineup.upwardTypeId = programType.upwardTypeID
  gameLineup.createDate = new Date().toISOString()
  gameLineup.round = round
  if (programType.upwardTypeID === ProgramEnum.BASKETBALL) {
    gameLineup.players = basketballPlayers(players)
  }

  if (programType.upwardTypeID === ProgramEnum.VOLLEYBALL) {
    gameLineup.players = volleyballPlayers(players)
  }
  return gameLineup
}

function volleyballPlayers(players: TeamPlayerInfo[]): GameLineupPlayer[] {
  return players.map((p) => {
    const lineupPlayer = getEmptyVolleyballPlayer()
    lineupPlayer.player = p
    return lineupPlayer
  })
}

function basketballPlayers(players: TeamPlayerInfo[]): GameLineupPlayer[] {
  return players.map((p) => {
    const lineupPlayer = getEmptyBasketballPlayer()
    lineupPlayer.player = p
    return lineupPlayer
  })
}

export function generateLineupWithRotation(
  programType: UpwardProgramTypeID,
  divisionId: number,
  teamId: number,
  gameId: number,
  round: number,
  players: TeamPlayerInfo[],
  playersPerSegment: number,
  isScheduleGame: boolean
): GameLineupWithRotation {
  const numberOfSegments = store.getters.teams.currentTeamNumberOfSegments
  const gameLineup = getEmptyGameLineupWithRotation()

  gameLineup.gameId = gameId
  gameLineup.teamId = teamId
  gameLineup.isScheduledGame = isScheduleGame
  gameLineup.divisionId = divisionId
  gameLineup.upwardTypeId = programType.upwardTypeID
  gameLineup.createDate = new Date().toISOString()
  gameLineup.round = round
  gameLineup.currentSegment = 1
  gameLineup.playersPerSegment = playersPerSegment
  gameLineup.lineup = generateSegments(numberOfSegments)
  const lineupPool = generateLineupPool(numberOfSegments, players)
  gameLineup.lineup.forEach((seg) => {
    seg.players = playerList(lineupPool, seg.segment, round, playersPerSegment).map((x) => {
      return { ...x, positionText: '' }
    })
  })
  if (programType.upwardTypeID === ProgramEnum.FOOTBALL) {
    adjustSegments(gameLineup)
  }
  return gameLineup
}

function adjustSegments(gameLineup: GameLineupWithRotation) {
  //For football each segment has two lineups. One for defense and one for offense.
  //Correct the lineup from 1 2 3 4 5 6  to 1 1 2 2 3 3
  gameLineup.lineup.forEach((l) => (l.segment = Math.ceil(l.segment / 2)))
}

function generateLineupPool(numberOfSegments: number, players: TeamPlayerInfo[]): TeamPlayerInfo[] {
  //create a player list long enough to support lineup generation
  const localPlayers = sortPlayers(players)
  let lineupPool = [] as TeamPlayerInfo[]
  const fullSize = numberOfSegments * 2
  for (let i = 0; i < fullSize; i++) {
    lineupPool = [...lineupPool, ...localPlayers]
  }
  return lineupPool
}

function sortPlayers(players: TeamPlayerInfo[]): TeamPlayerInfo[] {
  const clonePlayers = cloneDeep(players)
  return clonePlayers.sort((a, b) => {
    const aRank = a.rosterRanking ?? ''
    const bRank = b.rosterRanking ?? ''
    if (aRank < bRank) return -1
    if (aRank > bRank) return 1
    return 0
  })
}

function playerList(
  lineupPool: TeamPlayerInfo[],
  segment: number,
  gameNumberInTheSeason: number,
  playersPerSegment: number
): TeamPlayerInfo[] {
  const start = playersPerSegment * (segment - 1) + (gameNumberInTheSeason - 1)
  const end = start + playersPerSegment
  const segmentPlayers: TeamPlayerInfo[] = lineupPool.slice(start, end)
  return segmentPlayers.length ? segmentPlayers : ([] as TeamPlayerInfo[])
}

function generateSegments(numberOfSegments: number): Lineup[] {
  return [...Array(numberOfSegments)].map((_, i) => {
    return {
      segment: i + 1,
      players: [] as TeamPlayerInfo[],
    } as Lineup
  })
}
