
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'

import * as authStore from '@/store/authentication'
import * as authorizationStore from '@/store/authorization'
import * as programStore from '@/store/programs'
import * as teamStore from '@/store/team'

import PrimaryHeading from '@/elements/PrimaryHeading.vue'

@Component({
  components: {
    PrimaryHeading,
  },
})
export default class Impersonating extends Vue {
  @Getter(authStore.getterNames.accountName, { namespace: authStore.namespace })
  private accountName!: string

  @Getter(authStore.getterNames.impersonationAccountNumber, { namespace: authStore.namespace })
  private impersonationAccountNumber!: string

  @Action(authStore.actionNames.unimpersonate, { namespace: authStore.namespace })
  private unimpersonate!: () => Promise<void>

  @Mutation(programStore.mutationNames.reset, { namespace: programStore.namespace })
  private programReset!: () => void

  @Mutation(teamStore.mutationNames.reset, { namespace: teamStore.namespace })
  private teamReset!: () => void

  @Mutation(authorizationStore.mutationNames.reset, { namespace: authorizationStore.namespace })
  private authorizationReset!: () => void

  private stop() {
    this.unimpersonate()
    this.authorizationReset()
    this.programReset()
    this.teamReset()
    this.$router.push('/account/impersonate')
  }
}
