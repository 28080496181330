<template>
  <tr :class="[rowClass]" :style="style" @click="handleRowSelected">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: 'smart-tr',
  props: {
    row: {
      required: true,
    },
    allowRowSelection: {
      required: false,
    },
  },
  inject: ['store'],
  data() {
    return {
      state: this.store._data,
    }
  },
  mounted() {
    this.$el.style.cursor = 'pointer'
  },
  beforeDestroy() {
    this.$el.removeEventListener('click', this.handleRowSelected)
  },
  computed: {
    canSelectRows() {
      return this.allowRowSelection
    },
    isSelected() {
      return this.state.selectedRows.find((it) => it === this.row)
    },
    rowClass: function () {
      return this.isSelected && this.allowRowSelection ? this.state.selectedClass : ''
    },
    style() {
      return {
        ...(!this.state.customSelection ? { cursor: 'pointer' } : {}),
      }
    },
  },
  methods: {
    handleRowSelected(event) {
      const source = event.target || event.srcElement
      if (source.tagName.toLowerCase() === 'td') {
        if (this.isSelected) {
          this.$emit('rowSelected', this)
        } else {
          this.store.selectRow(this.row)
          this.$emit('rowSelected', this)
        }
      }
    },
  },
}
</script>
