
import { Component, Vue, Prop } from 'vue-property-decorator'
import dayjs from 'dayjs'
import { getEmptyLeagueSpecialEventInfo } from '@/modelHelpers/LeagueSpecialEventInfo'
import { LeagueSpecialEventInfo } from '@/GeneratedTypes/ListInfo/LeagueSpecialEventInfo'
import LabelInput from '@/elements/InputLabel.vue'
import DateCard from '@/components/DateCard.vue'
import moment from 'moment'

@Component({
  components: {
    LabelInput,
    DateCard,
  },
})
export default class Practice extends Vue {
  @Prop({ type: Object, default: getEmptyLeagueSpecialEventInfo, required: false })
  private readonly specialEvent!: LeagueSpecialEventInfo

  private time(dt: string) {
    return moment(dt).format('h:mmA')
  }
  private eventInfo(e: LeagueSpecialEventInfo): string {
    return e.eventDescription ?? ''
  }

  private eventTime(e: LeagueSpecialEventInfo): string {
    const startJS = dayjs(e.eventStart!)
    const endJS = dayjs(e.eventEnd ?? '')
    let retval = ''
    if (startJS.startOf('day').isSame(startJS) && e.eventEnd == null) {
      retval = ''
    } else {
      if (e.eventEnd != null) {
        retval = startJS.format('h:mmA') + ' - ' + endJS.format('h:mmA')
      } else {
        retval = startJS.format('h:mmA')
      }
    }

    if (e.facilityID > 0) {
      if (retval) {
        retval = `${retval} at ${e.facilityName}`
      } else {
        retval = e.facilityName ?? ''
      }
    }

    return retval
  }
}
