
import VueLib from 'vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import { RolesEnum } from '@/modelHelpers/LeagueVolunteer'
import participantClient from '@/clients/participantClient'
import teamClient from '@/clients/teamClient'
import volunteerClient from '@/clients/volunteerClient'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { PlayerSearchInfo } from '@/GeneratedTypes/ListInfo/PlayerSearchInfo'
import { VolunteerSearchInfo } from '@/GeneratedTypes/ListInfo/VolunteerSearchInfo'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import * as programStore from '@/store/programs'
import * as search from '@/store/search'
import VueSmartGrid from '@/elements/VueSmartGrid/VueSmartGrid.vue'
import { GridColumn } from '@/common/GridColumn'
import { SearchItem } from '@/models/SearchItem'
import { SearchResultTypes } from '@/modelHelpers/SearchResult'

@Component({
  components: {
    TextInput,
    Loading,
    VueSmartGrid,
    Breadcrumbs,
  },
})
export default class Search extends Vue {
  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  @Getter(search.getterNames.searchTerms, { namespace: search.namespace })
  private searchTerms!: string

  @Mutation(search.mutationNames.setSearchTerms, { namespace: search.namespace })
  private setSearchTerms!: ({ searchTerms }: { searchTerms: string }) => void

  private localSearchTerms = ''
  private isLoading = false
  private searchableSet: SearchItem[] = []
  private filteredSet: SearchItem[] = []
  private breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.SEARCH, isActive: false },
  ])

  private async mounted() {
    this.localSearchTerms = this.searchTerms
    await this.loadData()
    this.filterData()
  }

  private async loadData() {
    if (!this.currentProgram || !this.currentProgram.upwardLeagueID || !this.currentProgram.typeProgramID) {
      return
    }
    this.isLoading = true
    const upwId = this.currentProgram.upwardLeagueID

    try {
      const [teams, coaches, players] = await Promise.all([
        teamClient.retrieveLeagueTeamList({ upwId }),
        volunteerClient.search(upwId, RolesEnum.COACH),
        participantClient.search(upwId),
      ])

      this.mergeDataSets(teams, players, coaches)
    } finally {
      this.isLoading = false
    }
  }

  private filterData() {
    if (this.searchTerms) {
      this.filteredSet = this.searchableSet.filter((searchItem: SearchItem) => {
        return searchItem.searchableTerm.toLowerCase().includes(this.searchTerms.toLowerCase())
      })
    } else {
      this.filteredSet = this.searchableSet
    }
  }

  private mergeDataSets(
    teams: DivisionTeamInfo[],
    players: PlayerSearchInfo[],
    coaches: VolunteerSearchInfo[]
  ) {
    const searchableTeams = this.convertTeamsToSearchItems(teams)
    const searchableCoaches = this.convertIndividualToSearchItems(coaches, SearchResultTypes.COACH)
    const searchablePlayers = this.convertIndividualToSearchItems(players, SearchResultTypes.PLAYER)
    this.searchableSet = [...searchableTeams, ...searchableCoaches, ...searchablePlayers]
  }

  private convertTeamsToSearchItems(teams: DivisionTeamInfo[]) {
    return teams.reduce((searchItems: SearchItem[], team: DivisionTeamInfo) => {
      if (team.teamName) {
        searchItems.push({
          searchableTerm: team.teamName,
          searchResultType: SearchResultTypes.TEAM,
          programId: team.typeProgramID,
          divisionId: team.divisionID.toString(),
          teamId: team.teamID.toString(),
          individualId: null,
        })
      }
      return searchItems
    }, [])
  }

  private convertIndividualToSearchItems(
    individuals: PlayerSearchInfo[] | VolunteerSearchInfo[],
    type: SearchResultTypes
  ) {
    const searchItems: SearchItem[] = []
    individuals.forEach((individual: PlayerSearchInfo | VolunteerSearchInfo) => {
      const fullName = `${individual.firstName} ${individual.lastName}`
      if (fullName) {
        searchItems.push({
          searchableTerm: fullName,
          searchResultType: type,
          programId: null,
          divisionId: null,
          teamId: null,
          individualId: individual.individualID.toString(),
        })
      }
    })
    return searchItems
  }

  @Watch('localSearchTerms')
  private searchTermsChange(newVal: string) {
    this.setSearchTerms({ searchTerms: newVal })
    this.filterData()
  }

  private columns: GridColumn[] = [
    {
      width: 120,
      headerText: 'Name',
      template: () => ({
        template: VueLib.component('columnTemplate', {
          props: {
            data: {},
          },
          methods: {
            getRoute(data: any) {
              if (data.searchResultType === SearchResultTypes.TEAM) {
                return `team/${data.programId}/${data.divisionId}/${data.teamId}`
              } else if (data.searchResultType === SearchResultTypes.COACH) {
                return `coach/${data.individualId}`
              } else if (data.searchResultType === SearchResultTypes.PLAYER) {
                return `player/${data.individualId}`
              } else {
                throw 'invalid searchResultType'
              }
            },
          },
          template: `<router-link :to="getRoute(data)" class="text-underlined"><u>{{data.searchableTerm}}</u></router-link>`,
        }),
      }),
    },
    {
      width: 120,
      headerText: 'Type',
      template: () => ({
        template: VueLib.component('columnTemplate', {
          props: { data: {} },
          template: `<div>{{data.searchResultType}}</div>`,
        }),
      }),
    },
  ]
}
