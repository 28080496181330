
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { Getter } from 'vuex-class'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import { getEmptyTeamPlayerInfo } from '@/modelHelpers/TeamPlayerInfo'
import GameDayPlayer from '@/components/GameDayPlayer.vue'
import * as teamStore from '@/store/team'
import { inactivatePlayer } from '@/services/lineupService'
import { RecalculateLineupMixin } from '@/common/RecalculateLineupMixin'
import { CurrentGameLineupWithRotationMixin } from '@/common/CurrentGameLineupWithRotationMixin'
import { SidesEnum } from '@/models/Lineup'
import { ProgramEnum } from '@/common/programEnum'

@Component({ components: { GameDayPlayer } })
export default class GameDayLineup extends Mixins(
  RecalculateLineupMixin,
  CurrentGameLineupWithRotationMixin
) {
  @Prop({ type: Array, default: getEmptyTeamPlayerInfo, required: false })
  private readonly players!: TeamPlayerInfo[]

  @Getter(teamStore.getterNames.hasScheduledGames, { namespace: teamStore.namespace })
  private readonly hasScheduledGames!: boolean

  private removePlayer(player: TeamPlayerInfo) {
    //Inactivate Player
    const inactivePlayers = inactivatePlayer(player, this.currentGameLineupWithRotation)
    this.upsertLineup({ lineup: inactivePlayers })
    this.recalculateLineup(this.currentGameLineupWithRotation)
    this.$emit('player-removed')
  }

  get sortedPlayers() {
    const p = cloneDeep(this.players)
    return p.sort((a, b) =>
      (!a.rosterRanking || a.rosterRanking == '' ? 'Z' : a.rosterRanking) >
      (!b.rosterRanking || b.rosterRanking == '' ? 'Z' : b.rosterRanking)
        ? 1
        : -1
    )
  }

  get showPositionEdit() {
    return (
      !(
        this.currentGameLineupWithRotation.upwardTypeId == ProgramEnum.VOLLEYBALL ||
        this.currentGameLineupWithRotation.upwardTypeId == ProgramEnum.MSSSELECT
      ) && this.currentGameLineupWithRotation.currentSide != SidesEnum.BATTING
    )
  }
}
