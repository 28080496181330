
import { Component, Mixins } from 'vue-property-decorator'
import InputLabel from '@/elements/InputLabel.vue'
import MenuItem from '@/components/MenuItem.vue'
import { MainMenuMixin } from '@/common/MainMenuMixin'
import TextInput from '@/elements/TextInput.vue'
import SelectStylable, { SelectStylableConfig } from '@/elements/SelectStylable.vue'
import { Getter, Mutation } from 'vuex-class'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import * as programStore from '@/store/programs'
import * as upwardTypes from '@/store/upwardTypes'
import * as authorization from '@/store/authorization'
import * as search from '@/store/search'
import { RoleUnion } from '@/common/Authorization/RoleUnion'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { coachGroup, allVolunteerRoles, VolunteerRoles } from '@/common/Authorization/VolunteerRoles.ts'
import { ApplicationRoles } from '@/common/Authorization/ApplicationRoles'
import CurrentTeamSelect from '@/components/CurrentTeamSelect.vue'
import { UpwardLeagueTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueTypeID'

@Component({
  components: {
    InputLabel,
    MenuItem,
    SelectStylable,
    TextInput,
    CurrentTeamSelect,
  },
})
export default class Home extends Mixins(MainMenuMixin) {
  @Getter(authorization.getterNames.currentRole, { namespace: authorization.namespace })
  private readonly currentRole!: () => RoleUnion

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  @Mutation(search.mutationNames.setSearchTerms, { namespace: search.namespace })
  private setSearchTerms!: ({ searchTerms }: { searchTerms: string }) => void

  @Getter(upwardTypes.getterNames.currentLeagueType, { namespace: upwardTypes.namespace })
  private currentLeagueType!: UpwardLeagueTypeID
  private searchTerm = ''
  private superUsers = superUsers
  private coachGroup = [...coachGroup]
  private allVolunteers = allVolunteerRoles
  private isLoadingTeam = false

  private get isCurrentRoute() {
    return this.$route.path
  }

  private get hideMenu(): boolean {
    // Show the menu unless this user has the Coach Role and no no teams
    const isCoach = this.$hasAtLeastOne([VolunteerRoles.COACH])
    if (!isCoach) return false // this is not a coach, so show the menu

    const isActiveCoach = this.$hasAllOf([VolunteerRoles.COACH, ApplicationRoles.COACH_WITH_TEAM])
    const isCamp = this.currentLeagueType.isCamp
    const hideMenu = !isActiveCoach && !(isCamp && isCoach)
    return hideMenu
  }

  private search() {
    this.setSearchTerms({ searchTerms: this.searchTerm })
    this.$router.push('/search')
  }

  private setTeamLoadingStatus(val: boolean) {
    this.isLoadingTeam = val
  }

  private colors: SelectStylableConfig = {
    bgColor: 'blue',
    color: 'white',
    placeholderColor: 'white',
    highlightBgColor: 'lightblue',
    highlightColor: 'white',
  }
}
