
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { mainMenuItemConfigEmpty, MainMenuItemConfig, DisplayLocation } from '@/models/MenuItem'
import { MenuItemValidProgramMixin } from '@/common/MenuItemValidProgramMixin'
import { ExcludedLeagueTypesMixin } from '@/common/ExcludedLeagueTypesMixin'

@Component
export default class MenuItem extends Mixins(MenuItemValidProgramMixin, ExcludedLeagueTypesMixin) {
  @Prop({ type: Object, default: mainMenuItemConfigEmpty, required: false })
  readonly config!: MainMenuItemConfig

  get validLocation(): boolean {
    if (!this.config.displayLocation) return true
    return this.config.displayLocation.includes(DisplayLocation.HOME)
  }

  get show() {
    return this.validLocation && this.validProgramType && !this.leagueTypeExcluded
  }
}
