
import { Getter } from 'vuex-class'
import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

import HtmlEditor from '@/elements/HtmlEditor2.vue'
import DateInput from '@/elements/DateInput.vue'
import Loading from '@/elements/Loading.vue'
import Alert from '@/elements/Alert/Alert.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import { AlertStatusEnum, TeamAlertOptions } from '@/elements/Alert/data'
import StylableSelectInput, { SelectConfig } from '@/elements/StylableSelectInput.vue'

import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { LeagueProgramInfo } from 'src/GeneratedTypes/ListInfo/LeagueProgramInfo'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { getEmptyTeamPageAlertInfo } from '@/modelHelpers/AlertInfo'
import { TeamPageAlertInfo } from '@/GeneratedTypes/ListInfo/TeamPageAlertInfo'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'

import * as programStore from '@/store/programs'
import * as divisionStore from '@/store/divisions'
import * as teamStore from '@/store/team'
import * as upwardTypesStore from '@/store/upwardTypes'
import * as authorization from '@/store/authorization'

import { RoleUnion } from '@/common/Authorization/RoleUnion'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'

import alertClient from '@/clients/alertClient'
import programClient from '@/clients/programsClient'

import { convertToOldFashionDateTime } from '@/utils/convertToOldFashionDateTime'

interface ProgramSelectInputOption {
  optionText: string
  optionValue: string | null
}

interface DivisionSelectInputOption {
  optionText: string
  optionValue: number
}

interface TeamSelectInputOption {
  optionText: string
  optionValue: string | null
}

@Component({
  components: {
    HtmlEditor,
    DateInput,
    Loading,
    Alert,
    VeeValidateForm,
    StylableSelectInput,
  },
})
export default class TeamAlert extends Vue {
  @Ref('alertForm') readonly alertForm!: HTMLFormElement

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  @Prop({ type: Object, default: getEmptyTeamPageAlertInfo, required: false })
  readonly alert!: TeamPageAlertInfo

  @Getter(divisionStore.getterNames.allItems, { namespace: divisionStore.namespace })
  readonly allDivisions!: LeagueDivisionInfo[]

  @Getter(teamStore.getterNames.teams, { namespace: teamStore.namespace })
  readonly allTeams!: DivisionTeamInfo[]

  @Getter(upwardTypesStore.getterNames.programTypes, { namespace: upwardTypesStore.namespace })
  readonly allProgramTypes!: UpwardProgramTypeID[]

  @Getter(authorization.getterNames.currentRole, { namespace: authorization.namespace })
  private readonly currentRole!: RoleUnion

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  private readonly currentTeam!: DivisionTeamInfoExt

  private isLoading = false
  private isClearing = false
  private isSaving = false
  private alertIds: number[] = []
  private programs: LeagueProgramInfo[] | null = [] as LeagueProgramInfo[]
  private alertOption = TeamAlertOptions.get('default')

  private selectedProgram: ProgramSelectInputOption | null = null

  private programDivisions: LeagueDivisionInfo[] | null = [] as LeagueDivisionInfo[]
  private selectedDivision: DivisionSelectInputOption | null = null

  private divisionTeams: DivisionTeamInfo[] | null = [] as DivisionTeamInfo[]
  private selectedTeam: TeamSelectInputOption | null = null

  private selectConfig: SelectConfig = {
    bgColor: 'white',
    color: 'black',
    borderColor: '#3e3e3e',
    placeholderColor: 'white',
    highlightBgColor: '#3e3e3e',
    highlightColor: 'white',
  }

  private internalAlert = cloneDeep(this.alert)

  private async mounted() {
    await this.loadData()
    if (this.isCoach) {
      this.selectedProgram = this.programOptions[0]
      this.selectedDivision = this.divisionOptions[0]
      this.selectedTeam = this.teamOptions[0]
      this.internalAlert.typeProgramID = this.selectedProgram.optionValue
      this.internalAlert.divisionID = this.selectedDivision.optionValue
      this.internalAlert.upwardTeamID = this.selectedTeam.optionValue
    } else {
      if (this.alert.id == 0) {
        if (this.programOptions.length == 1) {
          this.selectedProgram = this.programOptions[0]
        } else {
          this.selectedProgram = this.blankProgramOption
        }
        this.selectedDivision = this.blankDivisionOption
        this.selectedTeam = this.blankTeamOption
      } else {
        this.selectedProgram =
          this.alert.typeProgramID != 'NONE'
            ? {
                optionText: this.programTypeDescription(this.alert.typeProgramID) ?? '',
                optionValue: this.alert.typeProgramID,
              }
            : this.blankProgramOption
        this.selectedDivision =
          this.alert.divisionID != 0
            ? {
                optionText: this.alert.divisionName ?? '',
                optionValue: this.alert.divisionID,
              }
            : this.blankDivisionOption
        this.selectedTeam =
          this.alert.teamID > 0
            ? {
                optionText: this.alert.teamName ?? '',
                optionValue: this.alert.upwardTeamID,
              }
            : this.blankTeamOption
      }
    }
  }
  private async loadData() {
    this.isLoading = true
    await this.retrievePrograms()
    this.setAlertOption()
    this.isLoading = false
  }

  private async retrievePrograms() {
    if (!this.currentProgram) return
    const result = await programClient.retrieve(this.currentProgram.upwardLeagueID || '')
    if (result?.myLeagueInfo?.programs) {
      this.programs = result.myLeagueInfo?.programs
    }
  }

  private get currentAlertStatus(): string {
    if (!this.alert.startDate || !this.alert.expireDate || this.alert.id === 0) {
      return AlertStatusEnum.default
    }
    const startDate = new Date(this.alert.startDate).setHours(0, 0, 0, 0)
    const expireDate = new Date(this.alert.expireDate).setHours(0, 0, 0, 0)
    const today = new Date().setHours(0, 0, 0, 0)
    if (startDate <= today && expireDate >= today) return AlertStatusEnum.info
    if (startDate > today) return AlertStatusEnum.warning
    if (expireDate < today) return AlertStatusEnum.danger
    return AlertStatusEnum.default
  }

  private setAlertOption() {
    this.alertOption = TeamAlertOptions.get(this.currentAlertStatus)
  }

  private get showAlertStatus() {
    return this.currentAlertStatus != AlertStatusEnum.default
  }

  private async formIsValid() {
    const isValid = await this.alertForm.reportValidity()
    if (!isValid) {
      this.moveTo('page_top')
    }
    return isValid
  }

  private moveTo(id: string) {
    Vue.nextTick(() => {
      const elem = document.getElementById(id)
      if (elem) elem.scrollIntoView()
    })
  }

  private async save() {
    if (await this.formIsValid()) {
      if (!this.currentProgram || !this.programs || !this.internalAlert.expireDate) return
      this.upsertAlert()
    }
  }

  private async upsertAlert() {
    // See comment at the top of this component
    const upwId = this.currentProgram.upwardLeagueID ?? ''
    const alert = this.dealWithLocalTime()
    try {
      this.isSaving = true
      await alertClient.save(upwId, alert)
      //await this.retrieveAlert()
      this.setAlertOption()
      this.$emit('saved')
    } finally {
      this.isSaving = false
    }
  }

  private get programList(): string[] {
    if (!this.programs) return []
    return this.programs.map((p) => p.typeProgramID ?? '')
  }

  private async clear() {
    this.isClearing = true
    await alertClient.remove(this.alert.id)
    this.$emit('saved')
    this.isClearing = false
  }

  private dealWithLocalTime() {
    // deal with local vs UTC time
    const newAlert = cloneDeep(this.internalAlert)
    //@ts-ignore
    newAlert.startDate = convertToOldFashionDateTime(this.internalAlert.startDate, '00', '00', '00')
    //@ts-ignore
    newAlert.expireDate = convertToOldFashionDateTime(this.internalAlert.expireDate, '23', '59', '59')

    return newAlert
  }

  private get isCoach() {
    return this.currentRole.roleId == VolunteerRoles.COACH
  }

  private programTypeDescription(typeProgramID: string | null): string | null {
    const allprograms = cloneDeep(this.allProgramTypes)
    const prog = allprograms.find((p) => p.upwardTypeID == typeProgramID)
    return prog ? prog.shortDescription : ''
  }

  private get programOptions(): ProgramSelectInputOption[] {
    if (this.isCoach) {
      return [
        {
          optionText: this.programTypeDescription(this.currentTeam.typeProgramID) ?? '',
          optionValue: this.currentTeam.typeProgramID,
        },
      ]
    } else {
      if (this.programs) {
        if (this.programs.length == 1) {
          const p = this.programs[0]
          return [
            {
              optionText: this.programTypeDescription(p.typeProgramID) ?? '',
              optionValue: p.typeProgramID,
            },
          ]
        } else {
          const retval = [this.blankProgramOption]
          const progs =
            this.programs?.map((p) => {
              return {
                optionText: this.programTypeDescription(p.typeProgramID) ?? '',
                optionValue: p.typeProgramID,
              } as ProgramSelectInputOption
            }) ?? []

          retval.push(...progs)
          return retval
        }
      } else {
        return []
      }
    }
  }

  private get blankProgramOption(): ProgramSelectInputOption {
    return {
      optionText:
        this.programs && this.programs.length > 0
          ? `Both ${this.programTypeDescription(this.programs[0].typeProgramID)} and Cheer`
          : '?All?',
      optionValue: 'NONE',
    }
  }

  private get divisionOptions(): DivisionSelectInputOption[] {
    if (this.isCoach) {
      return [
        {
          optionText: this.currentTeam.divisionName ?? '',
          optionValue: this.currentTeam.divisionID,
        },
      ]
    } else {
      const retval = [this.blankDivisionOption]
      const divs =
        this.programDivisions?.map((p) => {
          return {
            optionText: p.divisionName ?? '',
            optionValue: p.divisionID,
          }
        }) ?? []

      retval.push(...divs)

      return retval
    }
  }

  private get blankDivisionOption(): DivisionSelectInputOption {
    return {
      optionText: 'All Divisions',
      optionValue: 0,
    }
  }

  private get teamOptions(): TeamSelectInputOption[] {
    if (this.isCoach) {
      return [
        {
          optionText: this.currentTeam.teamName ?? '',
          optionValue: this.currentTeam.upwardTeamID,
        },
      ]
    } else {
      const retval = [this.blankTeamOption]
      const teams =
        this.divisionTeams?.map((p) => {
          return {
            optionText: p.teamName ?? '',
            optionValue: p.upwardTeamID ?? '',
          }
        }) ?? []

      retval.push(...teams)

      return retval
    }
  }

  private get blankTeamOption(): TeamSelectInputOption {
    return {
      optionText: 'All Teams',
      optionValue: 'N/A',
    }
  }

  private setProgram(item: ProgramSelectInputOption) {
    this.selectedProgram = item
  }

  private setDivision(item: DivisionSelectInputOption) {
    this.selectedDivision = item
  }

  private setTeam(item: TeamSelectInputOption) {
    this.selectedTeam = item
  }

  private nullNA(val: string | null) {
    return val == 'N/A' ? null : val
  }

  @Watch('selectedProgram')
  private onProgramChanged() {
    if (!this.isCoach) {
      if (this.selectedProgram != null && this.selectedProgram.optionValue != 'NONE') {
        this.programDivisions = this.allDivisions.filter(
          (x) => x.typeProgramID == this.selectedProgram?.optionValue
        )
      } else {
        this.programDivisions = []
      }

      if (this.internalAlert.typeProgramID != this.selectedProgram?.optionValue) {
        this.selectedDivision = this.blankDivisionOption
        this.internalAlert.typeProgramID = this.selectedProgram?.optionValue ?? 'NONE'
      }
    }
  }

  @Watch('selectedDivision')
  private onDivisionChanged() {
    if (!this.isCoach) {
      if (this.selectedDivision != null && this.selectedDivision.optionValue > 0) {
        this.divisionTeams = this.allTeams.filter(
          (x) =>
            x.typeProgramID == this.selectedProgram?.optionValue &&
            x.divisionID == this.selectedDivision?.optionValue
        )
      } else {
        this.divisionTeams = []
      }

      if (this.internalAlert.divisionID != (this.selectedDivision?.optionValue ?? 0)) {
        this.selectedTeam = this.blankTeamOption
        this.internalAlert.divisionID = this.selectedDivision?.optionValue ?? 0
      }
    }
  }

  @Watch('selectedTeam')
  private onTeamChanged() {
    if (!this.isCoach) {
      if (this.internalAlert.upwardTeamID != this.nullNA(this.selectedTeam?.optionValue ?? null)) {
        this.internalAlert.upwardTeamID = this.nullNA(this.selectedTeam?.optionValue ?? null)
      }
    }
  }
}
