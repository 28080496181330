import restService from '@/services/restService'
import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { TypeProgramGameFormatInfo } from '@/GeneratedTypes/ListInfo/TypeProgramGameFormatInfo'

const baseUrl = 'divisions/'

const retrieve = async (upwId: string | null, programType: string | null, divisionId: number) => {
  const results = await restService.get<LeagueDivision>(`${baseUrl}${upwId}/${programType}/${divisionId}`)
  if (results.isSuccess) {
    return results.data
  }
}

const retrieveAll = async (id: string): Promise<LeagueDivisionInfo[]> => {
  const results = await restService.get<LeagueDivisionInfo[]>(`${baseUrl}${id}`)

  if (results.isSuccess && results.data) {
    return results.data
  }
  return []
}

const gameFormats = async (programType: string | null) => {
  const results = await restService.get<TypeProgramGameFormatInfo[]>(`${baseUrl}gameFormats/${programType}`)
  if (results.isSuccess && results.data) {
    return results.data
  }
  return [] as TypeProgramGameFormatInfo[]
}

export default {
  retrieve,
  retrieveAll,
  gameFormats,
}
