export const PlayerPaymentStatusOptions = ['All', 'Paid', 'Not Paid']
export const PlayerEvaluationStatusOptions = ['All', 'Evaluated', 'Not Evaluated']
export const CoachAssignmentStatusOptions = ['All', 'Assigned to a Team', 'Unassigned']
export const CoachApprovalStatusOptions = ['All', 'Approved', 'Unapproved']
export const FilterByOptions = [
  { text: 'All', value: 'all' },
  { text: 'Division', value: 'division' },
  { text: 'Team', value: 'team' },
  { text: 'Practice Night', value: 'night' },
]
export enum RecipientTypes {
  ParentsGuardians = 'Parents/Guardians',
  Coaches = 'Coaches',
  Referees = 'Referees',
  Commissioners = 'Commissioners',
  OtherVolunteers = 'Other Volunteers',
}
