import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import partnerClient from '@/clients/partnerClient'
import { AccountInfo } from '@/GeneratedTypes/ListInfo/AccountInfo'
import { ActionsImpl } from 'direct-vuex'

interface PartnerState {
  partners: AccountInfo[]
  currentPartner: AccountInfo | null
}

const partnerState: PartnerState = {
  partners: [],
  currentPartner: null,
}

export enum getterNames {
  partners = 'partners',
  currentPartner = 'currentPartner',
}

const getterTree: GetterTree<PartnerState, RootState> = {
  [getterNames.partners]: (state) => state.partners,
  [getterNames.currentPartner]: (state) => state.currentPartner,
}

export enum mutationNames {
  setPartners = 'setPartners',
  setCurrentPartner = 'setCurrentPartner',
}

const mutations: MutationTree<PartnerState> = {
  [mutationNames.setPartners](state, { partners }: { partners: AccountInfo[] }) {
    state.partners = partners
  },
  [mutationNames.setCurrentPartner](state, { partner }: { partner: AccountInfo }) {
    state.currentPartner = partner
  },
}

export enum actionNames {
  fetchPartners = 'fetchPartners',
}

const actions: ActionTree<PartnerState, RootState> & ActionsImpl = {
  async [actionNames.fetchPartners](
    { commit },
    { accountNumbers }: { accountNumbers: string[] }
  ): Promise<boolean> {
    const result = await partnerClient.retreivePartners(accountNumbers)

    if (result) {
      commit(mutationNames.setPartners, { partners: result })
      return true
    }

    return false
  },
}

export const namespace = 'partners'

export const partners = {
  namespaced: true as true,
  state: partnerState,
  getters: getterTree,
  actions,
  mutations,
}
