
import { remove, cloneDeep } from 'lodash'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import TextInput from '@/elements/TextInput.vue'
import ToggleSlot from '@/elements/ToggleSlot.vue'
import SelectInput from '@/elements/SelectInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import InputLabel from '@/elements/InputLabel.vue'
import MemoInput from '@/elements/MemoInput.vue'
import Loading from '@/elements/Loading.vue'
import InputlessStringValidator from '@/elements/InputlessStringValidator.vue'

import ProgramSelector from '@/components/MessageRecipient/MessageProgramSelector.vue'

import { forbidSettingProgramForEmail } from '@/common/Authorization/PermissionGroups'
import { denyIfAnyOnly } from '@/plugins/Authorization/permissionChecks'
import { superUsers } from '@/common/Authorization/AccountRoles'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'

import { getEmptyPreviewMessage } from '@/modelHelpers/PreviewMessage'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import { ContactBuilder, getEmptyContactBuilder } from '@/models/Communication/ContactBuilder'

import {
  PlayerPaymentStatusOptions,
  PlayerEvaluationStatusOptions,
  CoachAssignmentStatusOptions,
  RecipientTypes,
  CoachApprovalStatusOptions,
} from '@/components/MessageRecipient/data'

import { Getter } from 'vuex-class'
import * as programStore from '@/store/programs'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'

@Component({
  components: {
    TextInput,
    ToggleSlot,
    SelectInput,
    CheckboxInput,
    MemoInput,
    Loading,
    InputlessStringValidator,
    ProgramSelector,
    InputLabel,
  },
})
export default class MessageRecipient extends Vue {
  @Prop({ type: String, default: 'Email', required: false })
  private readonly messageType!: string

  @Prop({ type: Object, default: getEmptyPreviewMessage, required: false })
  private readonly value!: PreviewMessage

  @Prop({ type: Object, default: getEmptyContactBuilder, required: false })
  private readonly contactBuilder!: ContactBuilder

  @Prop({ type: Boolean, default: false, required: false })
  private readonly loading!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  private readonly hasCheer!: boolean

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private currentProgram!: LeagueInfoCondensed

  private readonly playerPaymentStatusOptions = PlayerPaymentStatusOptions
  private readonly playerEvaluationStatusOptions = PlayerEvaluationStatusOptions
  private readonly coachAssignmentStatusOptions = CoachAssignmentStatusOptions
  private readonly forbidSettingProgramForEmail = forbidSettingProgramForEmail
  private readonly coachApprovalStatusOptions = CoachApprovalStatusOptions
  private readonly superUsers = superUsers
  private readonly denyIfAnyOnly = denyIfAnyOnly
  private readonly volunteerRoles = VolunteerRoles
  private readonly recipientTypes = RecipientTypes
  private localMessagePreview = getEmptyPreviewMessage()
  private test = false

  private async mounted() {
    this.setDefaults()
  }

  private setDefaults() {
    this.localMessagePreview.paymentStatus =
      this.localMessagePreview.paymentStatus || this.playerPaymentStatusOptions[0]

    this.localMessagePreview.evalStatus =
      this.localMessagePreview.evalStatus || this.playerEvaluationStatusOptions[0]

    this.localMessagePreview.coachTeamStatus =
      this.localMessagePreview.coachTeamStatus || this.coachAssignmentStatusOptions[0]

    this.localMessagePreview.approvalStatus =
      this.localMessagePreview.approvalStatus || this.coachApprovalStatusOptions[0]
  }

  private get allRecipients() {
    // Joins selectedGroup and Additional recipients together for the purpose of validation
    return this.localMessagePreview.selectedGroups.join().concat(this.localMessagePreview.toDelimited)
  }

  private setIndividualContactInfo(val: string) {
    let emails: string[] = []
    if (val.length > 0) {
      let additionalEmails = val.split(';').join(',') // remove semi-colons
      additionalEmails = additionalEmails.split(' ').join('') // remove spaces
      emails = additionalEmails.split(',') // split into array on commas
    }
    this.localMessagePreview.individualContactInfo = emails
  }

  private removeTrailingComma() {
    this.localMessagePreview.toDelimited = this.localMessagePreview.toDelimited.replace(/(^,)|(,$)/g, '')
  }

  private get additionalRecipientConfig() {
    if (this.messageType === 'Email') {
      return {
        label: 'Additional email addresses',
        subText: 'Separate emails with commas',
        validation: 'exclude-semi-colon|email-list:multiple',
      }
    } else {
      return {
        label: 'Additional phone numbers',
        subText: 'Separate with commas - (999)999-9999, 999-999-9999',
        validation: 'exclude-semi-colon|northamerican_phone:multiple',
      }
    }
  }

  private setSelectionGroup(checked: boolean, type: string) {
    if (checked) {
      this.localMessagePreview.selectedGroups.push(type)
      this.localMessagePreview.selectedGroups = [...new Set(this.localMessagePreview.selectedGroups)]
    } else {
      this.localMessagePreview.selectedGroups = remove(
        this.localMessagePreview.selectedGroups,
        (group: string) => {
          return group != type
        }
      )
    }
    this.emitPreviewMessageChange()
  }

  private async restParentFilters(isChecked: boolean) {
    // if the checkbox is unchecked and the paymentStatus or evalStatus has changed, reset the filters
    if (!isChecked) {
      if (
        this.localMessagePreview.paymentStatus != this.playerPaymentStatusOptions[0] ||
        this.localMessagePreview.evalStatus != this.playerEvaluationStatusOptions[0]
      ) {
        this.localMessagePreview.paymentStatus = this.playerPaymentStatusOptions[0]
        this.localMessagePreview.evalStatus = this.playerEvaluationStatusOptions[0]
        this.emitPreviewMessageChange()
        this.emitRefreshContactBuilder()
      }
    }
  }

  private async resetCoachFilter(isChecked: boolean) {
    // if the checkbox is unchecked and the coachTeamStatus has changed, reset the filter
    if (!isChecked) {
      if (this.localMessagePreview.coachTeamStatus != this.coachAssignmentStatusOptions[0]) {
        this.localMessagePreview.coachTeamStatus = this.coachAssignmentStatusOptions[0]
        this.emitPreviewMessageChange()
        this.emitRefreshContactBuilder()
      }
    }
  }

  private async recipientFilterChange() {
    this.emitPreviewMessageChange()
    this.emitRefreshContactBuilder()
  }

  private emitPreviewMessageChange() {
    this.$emit('change', this.localMessagePreview)
    this.$emit('input', this.localMessagePreview)
  }

  private emitRefreshContactBuilder() {
    this.$emit('refreshContactBuilder')
  }

  private get sendToReferees() {
    return this.localMessagePreview.selectedGroups.includes(this.recipientTypes.Referees)
  }
  private set sendToReferees(checked: boolean) {
    this.setSelectionGroup(checked, this.recipientTypes.Referees)
  }

  private get sendToCoaches() {
    return this.localMessagePreview.selectedGroups.includes(this.recipientTypes.Coaches)
  }
  private set sendToCoaches(checked: boolean) {
    this.setSelectionGroup(checked, this.recipientTypes.Coaches)
    this.resetCoachFilter(checked)
  }

  private get sendToParents() {
    return this.localMessagePreview.selectedGroups.includes(this.recipientTypes.ParentsGuardians)
  }
  private set sendToParents(checked: boolean) {
    this.setSelectionGroup(checked, this.recipientTypes.ParentsGuardians)
    this.restParentFilters(checked)
  }

  private get sendToCommissioners() {
    return this.localMessagePreview.selectedGroups.includes(this.recipientTypes.Commissioners)
  }
  private set sendToCommissioners(checked: boolean) {
    this.setSelectionGroup(checked, this.recipientTypes.Commissioners)
  }

  private get sendToOtherVolunteers() {
    return this.localMessagePreview.selectedGroups.includes(this.recipientTypes.OtherVolunteers)
  }
  private set sendToOtherVolunteers(checked: boolean) {
    this.setSelectionGroup(checked, this.recipientTypes.OtherVolunteers)
  }

  private setSelectedGroups(val: boolean, groupType: string) {
    if (val) {
      this.localMessagePreview.selectedGroups.push(groupType)
    } else {
      this.localMessagePreview.selectedGroups = remove(
        this.localMessagePreview.selectedGroups,
        (group: string) => {
          return group != groupType
        }
      )
    }
    this.emitPreviewMessageChange()
  }
  @Watch('value', { deep: true, immediate: true })
  private syncToLocalValue() {
    this.localMessagePreview = cloneDeep(this.value)
    this.setDefaults()
  }
}
