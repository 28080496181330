
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PlayerContactInfo } from '@/GeneratedTypes/ListInfo/PlayerContactInfo'
import { TeamCoachInfo } from '@/GeneratedTypes/ListInfo/TeamCoachInfo'
import { getEmptyPlayerContactInfoList } from '@/modelHelpers/PlayerContactInfo'
import Contact from '@/components/Contact.vue'

@Component({
  components: {
    Contact,
  },
})
export default class ContactList extends Vue {
  @Prop({ type: Array, default: getEmptyPlayerContactInfoList, required: false })
  private readonly contacts!: PlayerContactInfo[] | TeamCoachInfo[]

  @Prop({ type: String, default: 'Contacts', required: false })
  private readonly title!: string
}
