
import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import InputLabel from '@/elements/InputLabel.vue'
import uuid from 'uuid'
import { ComponentArgumentRuntimeException } from '@/elements/common/ComponentArgumentRuntimeException'
import Multiselect from 'vue-multiselect'

export interface SelectConfig {
  bgColor: string
  color: string
  borderColor: string
  placeholderColor: string
  highlightBgColor: string
  highlightColor: string
}

const defaultConfig: SelectConfig = {
  bgColor: 'white',
  color: 'black',
  borderColor: 'black',
  placeholderColor: '#adadad',
  highlightBgColor: 'lightgrey',
  highlightColor: 'black',
}

@Component({
  components: {
    ValidationProvider,
    InputLabel,
    Multiselect,
  },
})
export default class SelectInput extends Vue {
  private readonly guid = uuid.v4()

  @Prop({ type: String, default: '', required: false })
  private readonly label!: string

  @Prop({ type: String, default: '', required: false })
  private readonly tabIndex!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly required!: boolean

  @Prop({ type: Boolean, default: true, required: false })
  private readonly enabled!: boolean

  @Prop({ type: String, default: '', required: false })
  private readonly prependIcon!: string

  @Prop({ type: String, default: '', required: false })
  private readonly appendIcon!: string

  @Prop({ type: String, default: '', required: false })
  private readonly id!: string

  @Prop({ default: null, required: true })
  private readonly value!: any | null

  @Prop({ type: String, default: '', required: false })
  private readonly textPropertyName!: string

  @Prop({ type: String, default: '', required: false })
  private readonly valuePropertyName!: string

  @Prop({ type: String, default: '', required: false })
  private readonly blankItemText!: string

  @Prop({ default: null, required: false })
  private readonly blankItemValue!: any

  @Prop({ type: Boolean, default: false, required: false })
  private readonly showBlankItem!: boolean

  @Prop({ required: true }) //type is asserted on the watch
  private readonly itemsList!: [] | Record<symbol | string, unknown>

  @Prop({ type: Boolean, default: false, required: false })
  private readonly valueAndTextTheSame!: boolean

  @Prop({ type: String, default: '', required: false })
  private readonly name!: string

  @Prop({ type: String, default: '', required: false })
  private readonly subLabel!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly subLabelInline!: boolean

  @Prop({ type: String, default: '', required: false })
  private readonly VeeValidateRules!: string

  @Prop({ required: false, type: Object, default: () => defaultConfig })
  private readonly config!: SelectConfig

  @Prop({ type: Boolean, default: true, required: false })
  private readonly preselectFirst!: boolean

  private internalValue: any | null = this.value

  private getOptionValue(item: any, index: number | symbol | string): string {
    if (this.valueAndTextTheSame) {
      return item
    }
    if (this.valuePropertyName !== '') {
      return item[this.valuePropertyName]
    }
    if (this.textPropertyName !== '') {
      return item[this.textPropertyName]
    }
    return index.toString()
  }

  private getOptionKey(item: any, index: number | symbol | string): string {
    return JSON.stringify(this.getOptionValue(item, index))
  }

  private getOptionText(item: Record<symbol | string, unknown>): string {
    if (this.textPropertyName !== '') {
      return `${item[this.textPropertyName]}`
    }
    return item.toString()
  }

  public get elementId() {
    return this.id || `select-${this.guid}`
  }

  private handleChange() {
    this.$emit('input', this.internalValue)
    this.$emit('change', this.internalValue)
  }

  @Watch('value')
  private onValueChanged() {
    if (this.internalValue !== this.value) {
      this.internalValue = this.value
    }
  }

  assertItemListType() {
    if (!Array.isArray(this.itemsList) && !(this.itemsList instanceof Object)) {
      throw new ComponentArgumentRuntimeException(
        `Select Input ${this.name || this.label} should have an item list of type Array or Object`
      )
    }
  }

  private get cssVars() {
    return {
      '--bg-color': this.config.bgColor,
      '--color': this.config.color,
      '--highlight-bg-color': this.config.highlightBgColor,
      '--highlight-color': this.config.highlightColor,
      '--placeholder-color': this.config.placeholderColor,
      '--border-color': this.config.borderColor,
    }
  }

  @Watch('itemsList')
  private itemsListChanged() {
    this.assertItemListType()
  }
  created() {
    this.assertItemListType()
  }
}
