
import { Vue, Component } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'

import * as authorization from '@/store/authorization'
import * as auth from '@/store/authentication'
import * as programStore from '@/store/programs'
import * as upwardTypesStore from '@/store/upwardTypes'
import * as divisionStore from '@/store/divisions'
import * as teamStore from '@/store/team'

import alertClient from '@/clients/alertClient'
import teamClient from '@/clients/teamClient'

import Loading from '@/elements/Loading.vue'
import Accordion from '@/components/Accordion.vue'
import AccordionItem from '@/components/AccordionItem.vue'
import TeamAlert from '@/views/TeamAlert.vue'

import { RoleUnion } from '@/common/Authorization/RoleUnion'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'

import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { UpwardLeagueTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueTypeID'
import { TeamPageAlertInfo } from '@/GeneratedTypes/ListInfo/TeamPageAlertInfo'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { getEmptyTeamPageAlertInfo } from '@/modelHelpers/AlertInfo'

interface Taxonomy {
  alertID: number
  title: string
  subTitle: string
  visibility: string
  message: string
  isActive: boolean
  isPast: boolean
  isFuture: boolean
  alertInfo: TeamPageAlertInfo
}

@Component({
  components: {
    Loading,
    Accordion,
    AccordionItem,
    TeamAlert,
  },
})
export default class TeamAlertList extends Vue {
  @Getter(authorization.getterNames.currentRole, { namespace: authorization.namespace })
  private readonly currentRole!: RoleUnion

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private readonly currentProgram!: LeagueInfoCondensed

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  private readonly currentTeam!: DivisionTeamInfoExt

  @Getter(auth.getterNames.email, { namespace: auth.namespace })
  private readonly email!: string

  @Getter(auth.getterNames.firstAccountNumber, { namespace: auth.namespace })
  private readonly firstAccountNumber!: string

  @Action(upwardTypesStore.actionNames.fetchLeagueTypes, { namespace: upwardTypesStore.namespace })
  private fetchLeagueTypes!: ({ force }: { force: boolean }) => Promise<boolean>

  @Getter(upwardTypesStore.getterNames.leagueTypes, { namespace: upwardTypesStore.namespace })
  private readonly leagueTypes!: UpwardLeagueTypeID[]

  @Action(upwardTypesStore.actionNames.fetchProgramTypes, { namespace: upwardTypesStore.namespace })
  private fetchProgramTypes!: ({ force }: { force: boolean }) => Promise<boolean>

  @Getter(upwardTypesStore.getterNames.programTypes, { namespace: upwardTypesStore.namespace })
  readonly allProgramTypes!: UpwardProgramTypeID[]

  @Action(divisionStore.actionNames.fetchAll, { namespace: divisionStore.namespace })
  private fetchAllDivisions!: ({ upwardLeagueId }: { upwardLeagueId: string }) => Promise<boolean>

  @Getter(divisionStore.getterNames.allItems, { namespace: divisionStore.namespace })
  readonly allDivisions!: LeagueDivisionInfo[]

  @Mutation(teamStore.mutationNames.setTeams, { namespace: teamStore.namespace })
  private setTeams!: ({ teams }: { teams: DivisionTeamInfo[] }) => void

  private loading = false
  private alerts: TeamPageAlertInfo[] = []

  private async mounted() {
    try {
      this.loading = true
      await this.fetchDivisions()
      await this.fetchTeams()
      await this.fetchProgramTypes({ force: false })
      await this.fetchLeagueTypes({ force: false })
      await this.fetchAlerts()
    } finally {
      this.loading = false
    }
  }

  private async fetchAlerts() {
    this.alerts = []

    if (!this.currentRole || !this.currentRole.roleId || !this.currentProgram) {
      return
    }

    this.alerts = (await alertClient.retrieve(this.currentProgram.upwardLeagueID ?? '')) ?? []
  }

  private async fetchDivisions() {
    if (
      !this.currentRole ||
      !this.currentRole.roleId ||
      this.currentRole.roleId == VolunteerRoles.COACH ||
      !this.currentProgram
    ) {
      return
    }

    await this.fetchAllDivisions({ upwardLeagueId: this.currentProgram.upwardLeagueID ?? '' })
  }

  private async fetchTeams() {
    if (
      !this.currentRole ||
      !this.currentRole.roleId ||
      this.currentRole.roleId == VolunteerRoles.COACH ||
      !this.currentProgram
    ) {
      return
    }

    const teams = await teamClient.retrieveLeagueTeamList({ upwId: this.currentProgram.upwardLeagueID ?? '' })
    this.setTeams({ teams: teams })
  }

  private allAlertTaxonomies() {
    return this.alerts
      .filter((f) => f.message && f.message.trim())
      .map((a) => {
        return this.createTaxonomy(a)
      })
  }

  private coachAlertTaxonomies() {
    return this.alerts
      .filter((f) => f.message && f.message.trim() && f.upwardTeamID == this.currentTeam.upwardTeamID)
      .map((a) => {
        return this.createTaxonomy(a)
      })
  }

  private createTaxonomy(a: TeamPageAlertInfo): Taxonomy {
    const isActive = dayjs().isAfter(a.startDate) && dayjs().isBefore(a.expireDate)
    const isPast = dayjs().isAfter(a.expireDate)
    const isFuture = dayjs().isBefore(a.startDate)
    return {
      alertID: a.id,
      title: a.message?.replace(/<p>/g, '').replace(/<\/p>/g, '') ?? '',
      subTitle: a.teamName
        ? `Intended for Team: ${a.teamName}`
        : a.divisionName
        ? `Intended for Division: ${a.divisionName}`
        : `Intended for ${this.programTypeDescription(a.typeProgramID) ?? 'Everyone'}`,
      visibility: isActive
        ? `Currently showing.  Expires on ${this.formattedDate(a.expireDate)}`
        : isPast
        ? `Expired on ${this.formattedDate(a.expireDate)}`
        : `Becomes visible on ${this.formattedDate(a.startDate)}`,
      message: a.message ?? '',
      isActive: isActive,
      isPast: isPast,
      isFuture: isFuture,
      alertInfo: a,
    }
  }

  private get taxonomies() {
    const addNew = {
      alertID: -1,
      title: 'Add New Team Alert',
      subTitle: '',
      visibility: '',
      message: '',
      isActive: false,
      isPast: false,
      isFuture: false,
      alertInfo: getEmptyTeamPageAlertInfo(),
    } as Taxonomy
    const retval = [addNew]
    if (this.currentRole.roleId == VolunteerRoles.COACH) {
      retval.push(...this.coachAlertTaxonomies())
    } else {
      retval.push(...this.allAlertTaxonomies())
    }

    return retval
  }

  private programTypeDescription(typeProgramID: string | null): string | null {
    const allprograms = cloneDeep(this.allProgramTypes)
    const prog = allprograms.find((p) => p.upwardTypeID == typeProgramID)
    return prog ? prog.shortDescription : null
  }

  private formattedDate(d: Date | null) {
    return dayjs(d).format('MMM DD')
  }

  private async onAlertSaved() {
    try {
      this.loading = true
      await this.fetchAlerts()
    } finally {
      this.loading = false
    }
  }
}
