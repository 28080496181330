
import { Component, Vue, Prop } from 'vue-property-decorator'
import TextInput from '@/elements/TextInput.vue'
import { GameLineupPositionPlayer, getEmptyGameLineupPositionPlayer } from '@/models/Lineup'
import { UpwardPositionTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPositionTypeID'
import store from '@/store'

@Component({
  components: {
    TextInput,
  },
})
export default class Player extends Vue {
  @Prop({ type: Object, default: getEmptyGameLineupPositionPlayer, required: false })
  private readonly player!: GameLineupPositionPlayer

  @Prop({ type: Boolean, default: false, required: false })
  private readonly showRank!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  private readonly showJerseyEdit!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  private readonly showPositionEdit!: boolean

  get positionDescription() {
    const posType = store.getters.upwardTypes.positionTypes.find(
      (x: UpwardPositionTypeID) => x.upwardTypeID == this.player.typePositionID
    )
    return posType?.description ?? ''
  }

  get playerNameFormatted() {
    return this.showJerseyEdit
      ? this.player.formattedNameFirstLast ?? ''
      : this.player.formattedNameFirstLast
      ? `${this.player.formattedNameFirstLast} ${this.player.coachAssignedJerseyNumber ? '#' : ''}${
          this.player.coachAssignedJerseyNumber ?? ''
        }`
      : ''
  }
}
