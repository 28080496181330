
import { Vue, Component } from 'vue-property-decorator'
import PageStateToggle from '@/components/PageStateToggle.vue'
import Breadcrumbs from '@/elements/Breadcrumbs.vue'
import { getCrumbs, CrumbsEnum, Crumb } from '@/common/Crumbs'
import ministryRoutes from '@/router/ministryResources'

@Component({
  components: {
    PageStateToggle,
    Breadcrumbs,
  },
})
export default class MinistryResrouces extends Vue {
  private pageStates: string[] = []
  private currentToggle: string | null = null
  private breadcrumbs: Crumb[] = getCrumbs([
    { crumb: CrumbsEnum.HOME, isActive: true },
    { crumb: CrumbsEnum.MINISTRY_RESOURCES, isActive: false },
  ])

  private mounted() {
    const titles = ministryRoutes.filter((f) => f.meta?.level === 1).map((r) => r.meta?.title)
    this.pageStates = titles
    this.currentToggle = titles[0]
    this.stateChanged(this.currentToggle)
  }

  private stateChanged(state: string | null) {
    if (state && this.$route.name !== state) {
      const nextRoute = ministryRoutes.find((r) => r.meta?.level === 1 && r.meta?.title === state)
      if (nextRoute) this.$router.push(`/ministryresources/${nextRoute.path}`)
    }
  }
}
