import { Component, Vue } from 'vue-property-decorator'
import { AccountRoles, BuiltInAccountRoles } from '@/common/Authorization/AccountRoles'
import { RoleUnionTypes, RoleUnion } from '@/common/Authorization/RoleUnion'
import { allVolunteerRolesAsRoleUnion, BuiltInVolunteerRoles } from '@/common/Authorization/VolunteerRoles'
import { VolunteerRole } from '@/models/VolunteerRole'

@Component
export class RoleMixin extends Vue {
  private convertVolunteerRoleToRoleUnion(leagueRoles: VolunteerRole[]) {
    const roles: RoleUnion[] = []
    if (!leagueRoles) return roles

    leagueRoles.forEach((role: VolunteerRole) => {
      roles.push({
        roleId: role.RoleID,
        roleType: RoleUnionTypes.VOLUNTEER,
        roleDescription: BuiltInVolunteerRoles.find((x) => x.role == role.RoleID)?.description ?? role.RoleID,
      })
    })
    return roles
  }

  protected roleUnion(volunteerRoles: VolunteerRole[], accountRoles: string[], hasCheer: boolean) {
    const vRoles = this.convertVolunteerRoleToRoleUnion(volunteerRoles)
    const aRoles = this.convertAccountRoleToRoleUnion(accountRoles, hasCheer)
    return [...vRoles, ...aRoles]
  }

  private convertAccountRoleToRoleUnion(accountRoles: string[], hasCheer: boolean) {
    let roles: RoleUnion[] = []

    if (!accountRoles) return roles

    accountRoles.forEach((role: string) => {
      if (
        role !== AccountRoles.SUPER_ADMIN &&
        role !== AccountRoles.DIRECTOR &&
        role != AccountRoles.PARTNER_SUPPORT &&
        role != AccountRoles.SYS_AND_SUPPORT &&
        role != AccountRoles.UPWARD_STAFF
      ) {
        return
      }

      roles.push({
        roleId: role,
        roleType: RoleUnionTypes.ACCOUNT,
        roleDescription: BuiltInAccountRoles.find((x) => x.role == role)?.description ?? role,
      })

      if (roles.length > 0) {
        const volunteerRoles = allVolunteerRolesAsRoleUnion(hasCheer)
        roles = [...roles, ...volunteerRoles]
      }
    })
    return roles
  }
}
