
import { Component, Mixins } from 'vue-property-decorator'
import BaseInputWrapper from '@/elements/BaseInputWrapper/BaseInputWrapper.vue'
import { TextLikeWrapperProps } from '@/elements/TextLikeWrapper/TextLikeWrapperProps'
@Component({
  components: {
    BaseInputWrapper,
  },
})
export default class TextLikeWrapper extends Mixins(TextLikeWrapperProps) {
  get hasIcon() {
    return !!(this.prependIcon || this.appendIcon)
  }
}
